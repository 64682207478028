import type { owner as Owner } from '@finalytic/graphql';
import type { Maybe } from '@finalytic/utils';
import { type FormatAddressOptions, formatAddress } from './format-address';

export const getOwnerAddress = (
  owner: Maybe<Owner>,
  options?: FormatAddressOptions
) => {
  const values: Parameters<typeof formatAddress>[0] = {
    line1: owner?.address?.line || '',
    line2: owner?.address?.lineDetails || '',
    city: owner?.address?.city || '',
    postcode: owner?.address?.postalCode || '',
    state: owner?.address?.state,
    stateCode: owner?.address?.stateCode,
    country: owner?.address?.country,
    countryCode: owner?.address?.countryCode,
  };

  const full = formatAddress(values, options) || owner?.address?.full;

  return {
    values,
    full,
  };
};
