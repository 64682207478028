import { captureSentryError } from './sentry';

const extensions = {
  finalytic: {
    id: 'lmjjdicajpllfgekjbnnjobadadidoih',
    store_url:
      'https://chrome.google.com/webstore/detail/finalytic-connect-web-ext/lmjjdicajpllfgekjbnnjobadadidoih',
  },
  vrplatform: {
    id: 'hphpodppjfegboiaddkpkceedjjjjjma',
    store_url:
      'https://chrome.google.com/webstore/detail/vrplatform-connect-web-ex/hphpodppjfegboiaddkpkceedjjjjjma',
  },
} as const;

export const useExtension = () => {
  const key = import.meta.env.VITE_PLATFORM_NAME as keyof typeof extensions;
  const extension = extensions[key];

  const sendMessage = async (
    { message, data }: { message: string; data: object },
    callback?: (reply: { exists: boolean }) => void
  ) => {
    try {
      window.chrome?.runtime?.sendMessage?.(
        extension.id,
        {
          data: {
            ...data,
            message,
          },
        },
        {},
        async (props: { exists?: boolean }) =>
          callback?.({ exists: !!props?.exists })
      );
    } catch (error: any) {
      if (
        error?.message ===
        'Could not establish connection. Receiving end does not exist.'
      ) {
        // override error when extension is not installed
        return;
      }

      console.error(error);
      captureSentryError(error);
    }
  };

  return { sendMessage, extension };
};
