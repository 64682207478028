import { CustomThemes } from '../styles';

export const useAppName = () => {
  const name = import.meta.env.VITE_PLATFORM_NAME as CustomThemes;

  const formats: Record<CustomThemes, string> = {
    finalytic: 'Finalytic',
    vrplatform: 'VRPlatform',
  };

  const appName = formats[name];

  if (!name) throw new Error('VITE_PLATFORM_NAME is not defined in .env file');
  if (!appName) throw new Error('VITE_PLATFORM_NAME is not valid');

  return {
    themeName: name,
    appName: formats[name],
  };
};
