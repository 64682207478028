export const formatOwnerName = (
  props:
    | {
        firstName?: string | null;
        name?: string | null;
      }
    | undefined,
  options?: { lastNameFirst?: boolean; showEmpty?: boolean }
) => {
  const n = props?.name?.trim() || '';
  const f = props?.firstName?.trim() || '';
  if (!f) return n; // companyName

  // default to last name first
  const lastNameFirst = options?.lastNameFirst ?? true;

  if (lastNameFirst) return `${n}, ${f}`;
  return `${f} ${n}`.trim();
};
