import {
  type MantineThemeOverride,
  MantineProvider as Provider,
  createTheme,
} from '@mantine/core';
import {
  Global,
  MantineEmotionProvider,
  emotionTransform,
} from '@mantine/emotion';
import { Notifications } from '@mantine/notifications';
import { type ReactNode, useMemo } from 'react';
import { useV2UI } from '../hooks';
import { type ExtendedCustomColors, theme as defaultTheme } from './theme';

import '@mantine/notifications/styles.css';

export const RootThemeProvider = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => {
  const { isDarkTheme } = useV2UI();

  const theme = useMemo<MantineThemeOverride>(() => {
    return createTheme({
      ...defaultTheme,
      primaryColor: themePrimaryColor || defaultTheme.primaryColor,
    });
  }, [themePrimaryColor]);

  return (
    <Provider
      theme={theme}
      forceColorScheme={isDarkTheme ? 'dark' : 'light'}
      stylesTransform={emotionTransform}
      withGlobalClasses
    >
      <MantineEmotionProvider>
        {children}
        <Global
          styles={{
            '#sentry-feedback': {
              zIndex: 100,
            },
            'tfoot tr': {
              borderBottom: 'none!important',
            },
          }}
        />
        <Notifications
          sx={(theme) => ({
            '> div': {
              display: 'flex',
              gap: theme.spacing.xs,
              flexDirection: 'column',
            },
          })}
        />
      </MantineEmotionProvider>
    </Provider>
  );
};
