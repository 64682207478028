import { Button } from '@finalytic/components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Text, useMantineTheme } from '@mantine/core';
import { ConfirmModalResultIcon } from '../_components';

type SuccessScreenProps = {
  resultMessage: string;
  onClose: () => void;
};

export const SuccessScreen = ({
  resultMessage,
  onClose,
}: SuccessScreenProps) => {
  const theme = useMantineTheme();

  return (
    <>
      <Stack gap={0} align="center" justify="center" mb="md" sx={{ flex: 1 }}>
        <ConfirmModalResultIcon color={theme.colors.green[8]}>
          <FontAwesomeIcon icon={faCheck} color="#fff" size="lg" />
        </ConfirmModalResultIcon>
        <Text fw={500} mb={10} sx={{ textAlign: 'center' }} test-id="result">
          {resultMessage}
        </Text>
      </Stack>

      <Button
        variant="primary"
        onClick={onClose}
        test-id="confirm-close"
        sx={{ height: 40, width: '100%', marginBottom: 10 }}
      >
        Close
      </Button>
      <Button onClick={onClose} test-id="confirm-cancel" sx={{ width: '100%' }}>
        Cancel
      </Button>
    </>
  );
};
