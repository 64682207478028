import { StringParam, UrlUpdateType, useQueryParams } from '@finalytic/ui';

type View = 'overview' | 'edit';

export function useReservationDetailDrawer() {
  const [opts, set] = useQueryParams({
    reservation: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.reservation,
    open: (
      id: string,
      view?: 'overview' | 'edit',
      updateType?: UrlUpdateType
    ) => set({ reservation: id, view }, updateType),
    close: () => set({ reservation: undefined, view: undefined }),
    reservationId: opts.reservation,
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}
