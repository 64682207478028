import { ExtendedCustomColors } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Badge as MBadge, rem } from '@mantine/core';
import { PropsWithChildren } from 'react';

export const OwnerUserStatusBadge = (props: {
  status: Maybe<string>;
}) => {
  // membership.status options: 'active', 'unconfirmed', 'inactive', "archived"
  if (props.status === 'active') {
    return <Badge color="green">Active</Badge>;
  }

  if (props.status === 'unconfirmed') {
    return <Badge color="yellow">Pending</Badge>;
  }

  if (props.status === 'pending') {
    return <Badge color="yellow">Pending</Badge>;
  }

  if (props.status === 'expired') {
    return <Badge color="yellow">Expired</Badge>;
  }

  if (props.status === 'archived') {
    return <Badge color="orange">Archived</Badge>;
  }

  // Default to inactive
  return <Badge color="gray">Not invited</Badge>;
};

const Badge = ({
  color,
  children,
}: PropsWithChildren<{ color: ExtendedCustomColors }>) => (
  <MBadge
    variant="light"
    color={color}
    size="md"
    styles={(theme) => ({
      label: {
        textTransform: 'none',
        fontWeight: 500,
        color: theme.colors[color][8],
      },
      root: {
        paddingInline: rem(5),
      },
    })}
  >
    {children}
  </MBadge>
);
