import {
  Button,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import { useTeam, useTrpcMutation } from '@finalytic/data';
import {
  showErrorNotification,
  showNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import { Box, Group, Modal, useMantineTheme } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

type InviteMemberModalProps = {
  opened: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

type FormInputs = { memberEmail: string; role: string };

export const InviteMemberModal = ({
  opened,
  onClose,
  onSuccess,
}: InviteMemberModalProps) => {
  const theme = useMantineTheme();

  const [{ id: teamId, partnerId }] = useTeam();

  const { mutate } = useTrpcMutation('inviteUser', {
    errorMessage: {
      title: 'Invitation failed.',
      message: (err) =>
        err.message || 'Please contact us if this errors persists.',
    },
  });

  const {
    formState,
    reset: resetForm,
    control,
    handleSubmit,
  } = useForm<FormInputs>({});

  const handleInviteMembers = async (data: FormInputs) => {
    const email = data.memberEmail.toLowerCase().trim();

    if (!email) {
      showNotification({
        title: 'Missing invites.',
        message: 'Please contact us if this errors persists.',
        color: 'dark',
        radius: 10,
      });
      return;
    }

    const result = await mutate({
      email,
      teamId,
      role: data.role || 'user',
      partnerId,
    });

    if (result.ok) {
      showSuccessNotification({
        title: 'Success!',
        message:
          result.message || 'Your team members were successfully invited.',
      });

      if (onSuccess) onSuccess();

      resetForm();
      onClose();
    } else {
      showErrorNotification({
        title: 'Invitation failed.',
        message: result.message || 'Please contact us if this errors persists.',
      });
    }
  };
  return (
    <Modal
      opened={opened}
      centered
      onClose={onClose}
      size={'lg'}
      title={'Invite team member'}
      radius="md"
      styles={{
        title: {
          fontWeight: 500,
        },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleInviteMembers)}
        onReset={() => {
          resetForm();
          onClose();
        }}
      >
        <Box
          mt={20}
          mb={30}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gap: theme.spacing.md,
          })}
        >
          <Controller
            name="memberEmail"
            defaultValue=""
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field: { name, onChange, value }, fieldState }) => (
              <Box sx={{ flex: 1 }}>
                <InputWrapper
                  required
                  label="Email address"
                  error={fieldState.error?.message}
                >
                  <Input
                    name={name}
                    onChange={onChange}
                    value={value}
                    placeholder={`mail@${theme.primaryColor.toLowerCase()}.app`}
                    type="email"
                    data-autofocus
                    required
                    error={!!fieldState.error}
                  />
                </InputWrapper>
              </Box>
            )}
          />

          <Controller
            name="role"
            defaultValue="user"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value: v }, fieldState }) => {
              const options = [
                {
                  value: 'admin',
                  label: 'Admin',
                },
                {
                  label: 'User',
                  value: 'user',
                },
              ];

              const value = options.find(({ value }) => value === v) || null;

              return (
                <InputWrapper
                  required
                  label="Role"
                  error={fieldState.error?.message}
                >
                  <InputSelect
                    data={{
                      options,
                    }}
                    type="single"
                    setValue={(newValue) =>
                      newValue?.value && onChange(newValue?.value)
                    }
                    value={value}
                    dropdownProps={{
                      withinPortal: true,
                      width: 170,
                    }}
                    inputProps={{
                      placeholder: 'Role',
                      width: 150,
                      error: !!fieldState.error,
                    }}
                  />
                </InputWrapper>
              );
            }}
          />
        </Box>
        <Group justify="right">
          <Button type="reset">Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            loading={formState.isSubmitting}
          >
            Submit
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
