import { gqlV2, useDashboard, useTeam } from '@finalytic/data';
import { Maybe, ensure } from '@finalytic/utils';
import {
  Dashboard,
  TenantStatus,
  whereConnectionStatusDefault,
  whereTenants,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { usePartnerConnectionsTableFilter } from './PartnerConnectionsTableFilter';

export function useWherePartnerConnectionErrors() {
  const [{ id: teamId }] = useTeam();
  const { filter } = usePartnerConnectionsTableFilter();
  const search = filter?.search?.trim();
  const [dashboard] = useDashboard();

  return useMemo<gqlV2.connection_bool_exp>(() => {
    return wherePartnerConnectionErrors({
      partnerId: teamId,
      search,
      tenantId: filter?.tenantId,
      appId: filter?.appId,
      dashboard,
      status: filter?.status as TenantStatus | 'onboarding',
    });
  }, [
    teamId,
    search,
    filter?.appId,
    filter.tenantId,
    dashboard,
    filter.status,
  ]);
}

type FilterInput = {
  search: Maybe<string>;
  appId: Maybe<string>;
  tenantId: Maybe<string>;
  status: Maybe<TenantStatus | 'onboarding'>;
};

export function wherePartnerConnectionErrors(
  input: FilterInput & { partnerId: string; dashboard: Dashboard }
) {
  return ensure<gqlV2.connection_bool_exp>({
    tenant: whereTenants({
      partnerId: input.partnerId,
      dashboard: input.dashboard,
      status: input.status,
      tenantId: input.tenantId,
    }),
    status: whereConnectionStatusDefault,
    appId: input.appId ? { _eq: input.appId } : { _neq: 'finalytic' },
    fetchStatus: { _eq: 'failed' },
    _and: input.search
      ? [
          {
            jobs: {
              _or: [
                {
                  title: { _ilike: `%${input.search}%` },
                },
                {
                  connection: { name: { _ilike: `%${input.search}%` } },
                },
              ],
            },
          },
        ]
      : undefined,
  });
}
