import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const ActiveListingsCard = (props: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      {...props}
      title="Active Listings Created"
      href="/listings"
      total="sum"
      type="activeListingsCreated"
    />
  );
};
