import { Center, Stack, Text, Title, rem } from '@mantine/core';
import { ReactElement } from 'react';

type Props =
  | {
      title: string;
      description?: React.ReactNode;
      actions: React.ReactNode;
      icon?: ReactElement;
      children?: React.ReactNode;
    }
  | {
      title?: undefined;
      description?: React.ReactNode;
      actions?: React.ReactNode;
      icon?: ReactElement;
      children: React.ReactNode;
    };

export const EmptyViewWrapper = ({
  actions,
  description,
  title,
  children,
  icon,
}: Props) => {
  return (
    <Center
      mih="90vh"
      sx={{
        flexDirection: 'column',
        maxWidth: '28.75rem',
        marginInline: 'auto',
      }}
    >
      {icon || <Icon />}
      {children || (
        <>
          <Title component={'h1'} ta="center" size={'1.7rem'} mb={rem(50)}>
            {title}
          </Title>
          {description && (
            <Text ta="center" component="p" c="neutral" size="sm" mb={rem(50)}>
              {description}
            </Text>
          )}
          <Stack
            sx={{
              '&, & > a ': {
                width: '100%',
                maxWidth: '25rem',
              },
            }}
          >
            {actions}
          </Stack>
        </>
      )}
    </Center>
  );
};

const Icon = () => (
  <svg
    width="240"
    height="241"
    viewBox="0 0 240 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <rect
        x="0.25"
        y="0.75"
        width="239.5"
        height="239.5"
        rx="119.75"
        fill="url(#paint0_linear_2932_42316)"
        fillOpacity="0.4"
      />
      <rect
        x="30.25"
        y="30.75"
        width="179.5"
        height="179.5"
        rx="89.75"
        fill="url(#paint1_linear_2932_42316)"
        fillOpacity="0.4"
      />
      <rect
        x="60.25"
        y="60.75"
        width="119.5"
        height="119.5"
        rx="59.75"
        fill="url(#paint2_linear_2932_42316)"
        fillOpacity="0.3"
      />
      <rect
        x="60.25"
        y="60.75"
        width="119.5"
        height="119.5"
        rx="59.75"
        stroke="url(#paint3_linear_2932_42316)"
        strokeWidth="0.5"
      />
      <rect
        x="30.25"
        y="30.75"
        width="179.5"
        height="179.5"
        rx="89.75"
        stroke="url(#paint4_linear_2932_42316)"
        strokeWidth="0.5"
      />
      <rect
        x="0.25"
        y="0.75"
        width="239.5"
        height="239.5"
        rx="119.75"
        stroke="url(#paint5_linear_2932_42316)"
        strokeWidth="0.5"
      />
    </g>
    <g opacity="0.95">
      <path
        d="M125.852 113.295C125.852 110.268 128.306 107.814 131.333 107.814C134.361 107.814 136.815 110.268 136.815 113.295C136.815 116.323 134.361 118.777 131.333 118.777C128.306 118.777 125.852 116.323 125.852 113.295Z"
        fill="url(#paint6_linear_2932_42316)"
      />
      <path
        d="M110.613 113.57C110.613 110.424 113.421 107.826 116.568 107.826C119.714 107.826 120.939 109.882 122.875 112.363C123.691 113.409 128.315 119.838 128.315 119.838C128.315 119.838 129.31 121.568 129.31 122.986C129.31 126.508 123.695 132.048 123.695 132.048L111.441 116.417C111.441 116.417 110.613 115.607 110.613 113.57Z"
        fill="url(#paint7_linear_2932_42316)"
        fillOpacity="0.8"
      />
      <path
        d="M103.186 113.52C103.186 110.374 105.994 107.776 109.14 107.776C112.287 107.776 113.512 109.833 115.447 112.314C116.263 113.359 120.359 118.743 123.214 122.708C125.186 125.447 125.335 125.45 125.335 128.842C125.335 132.235 121.073 133.72 119.773 133.789C117.037 133.934 115.136 131.573 115.136 131.573L104.014 116.367C104.014 116.367 103.186 115.557 103.186 113.52Z"
        fill="url(#paint8_linear_2932_42316)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2932_42316"
        x1="44.3282"
        y1="0.500002"
        x2="198.254"
        y2="240.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8D8D8D" stopOpacity="0.56" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2932_42316"
        x1="63.2462"
        y1="30.5"
        x2="178.691"
        y2="210.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8D8D8D" stopOpacity="0.56" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2932_42316"
        x1="82.1641"
        y1="60.5"
        x2="159.127"
        y2="180.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#939393" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2932_42316"
        x1="94.1396"
        y1="54.5"
        x2="133.14"
        y2="180.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B8F94" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2932_42316"
        x1="49.7579"
        y1="15.6666"
        x2="146.846"
        y2="201.857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#888888" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2932_42316"
        x1="62.3667"
        y1="-20.899"
        x2="164.918"
        y2="274.567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A6A8A9" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2932_42316"
        x1="141.102"
        y1="121.318"
        x2="124.541"
        y2="101.964"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01244C" />
        <stop offset="1" stopColor="#0151B7" />
        <stop offset="1" stopColor="#959595" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2932_42316"
        x1="136.621"
        y1="137.663"
        x2="99.7791"
        y2="104.433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01244C" />
        <stop offset="1" stopColor="#0151B7" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2932_42316"
        x1="133.996"
        y1="139.827"
        x2="94.2114"
        y2="100.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01244C" />
        <stop offset="1" stopColor="#0151B7" />
        <stop offset="1" stopColor="#959595" />
      </linearGradient>
    </defs>
  </svg>
);
