import { useV2UI } from '@finalytic/ui';
import { useContext, useMemo } from 'react';
import { teamContext, useTeamRole, userContext } from './team';

export function useEnabledFeatures() {
  const user = useContext(userContext);
  const { team } = useContext(teamContext);
  const { isV2 } = useV2UI();

  return useMemo<Record<keyof typeof features, boolean>>(
    () =>
      Object.entries(features).reduce((state, [key, value]) => {
        const returnState = (value: boolean) => {
          state[key] = value;
          return state;
        };

        // Hirarchy:
        // 1. SPOTLIGHT_V2_UI => any feature with "_UI" suffix
        // 2. Tester
        // 3. Enabled team
        const isSpotlightV2_UI = key.endsWith('_UI') && isV2;

        if (isSpotlightV2_UI) return returnState(true);

        const testerStatus = user.featureApprovals.find(
          (approval) => approval.featureId === value
        )?.status;

        // if tester and always enabled
        if (testerStatus) {
          if (testerStatus === 'all') return returnState(true);
          if (testerStatus === 'disabled') return returnState(false);
        }

        const teamStatus = team.enabledFeatures.find(
          (enabledFeature) => enabledFeature.featureId === value
        )?.status;

        // if team is testing
        if (teamStatus) {
          if (teamStatus === 'all') return returnState(true);
          if (teamStatus === 'partially' && testerStatus === 'partially')
            return returnState(true);
          if (teamStatus === 'disabled') return returnState(false);
        }

        return returnState(false);
      }, {} as any),
    [user.featureApprovals, team.enabledFeatures, isV2]
  );
}

export function useHasEnabledFeatures() {
  const user = useContext(userContext);
  const { isSuperAdmin, isPartnerAdmin } = useTeamRole();

  return useMemo<boolean>(() => {
    if (isSuperAdmin || isPartnerAdmin) return true;

    return !!user.featureApprovals.length;
  }, [user.featureApprovals, isSuperAdmin, isPartnerAdmin]);
}

const features = {
  // to be decided
  OVERVIEW_UI: 'xxx',
  // needed in production to handle views on team basis
  // BILLING: '9e38781a-3c44-4bed-a4c5-33c5917aee0b',
  CUSTOM_FEE_MULTI_CURRENCY_INPUTS: '1e5982bd-b597-4288-b9d1-51cb54440e5e',
  GL: 'e60dcc9e-73ee-4b02-b700-d1804aaa49fc',
  GL_WITH_LEGACY_AUTOMATIONS: 'b76f85d5-9627-4f6b-a943-080304c0de68',
  // Currently in testing
  SETUP_GUIDE_UI: '43715fd0-1b82-49c4-b393-0ac97d21986d',
  // NEW FEATURES
  LIVEBLOCKS_NOTIFICATIONS: 'f8b66858-e1fa-4d6d-8bf2-4ce1b4edf386',
} as const;
