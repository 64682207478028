import { listing as Listing } from '@finalytic/graphql';
import { whereListingIsExcludedSetting } from '../queries';

export function getListingDisabledAutomations(listing: Listing) {
  const disabledAutomations = listing
    .settingsRight({
      where: whereListingIsExcludedSetting({
        automationIds: undefined,
        listingId: undefined,
      }),
      order_by: [{ updated_at: 'desc' }],
    })
    .map((setting) => ({
      settingId: setting.id as string,
      automationId: setting.automationId as string,
    }));

  return { disabledAutomations };
}
