import { Badge } from '@finalytic/components';
import { paidStatus_enum } from '@finalytic/graphql';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  FolderOpenIcon,
  HashtagIcon,
  LoaderIcon,
  NoteTextIcon,
  UserIcon,
} from '@finalytic/icons';
import { Maybe, day, formatCurrency, toTitleCase } from '@finalytic/utils';
import { DrawerInfoCard } from '../../../drawers/_components';
import { ExpenseData } from '../detail/useExpenseQuery';

export const ExpenseInfoCard = ({ expense }: { expense: ExpenseData }) => {
  return (
    <DrawerInfoCard
      rows={[
        {
          icon: CircleDollarIcon,
          title: 'Amount',
          text: formatCurrency(expense.centTotal / 100, expense.currency),
        },
        {
          icon: LoaderIcon,
          title: 'Status',
          text: <ExpensePaidStatusBadge paidStatus={expense.paidStatus} />,
        },
        {
          icon: CalendarEventIcon,
          title: 'Date',
          text: day(expense.date).format('MMM D, YYYY'),
        },
        {
          icon: CircleDollarIcon,
          title: 'Paid At',
          text: expense.paidAt
            ? day(expense.paidAt).format('MMM D, YYYY')
            : null,
        },
        {
          icon: HashtagIcon,
          title: 'Bill ID',
          text: expense.uniqueRef,
        },
        {
          icon: FolderOpenIcon,
          title: 'Bank/CC Account',
          text: expense.paidAt
            ? expense.bankAccount.title || 'Non-Trust Account'
            : null,
        },
        {
          icon: UserIcon,
          title: 'Contact',
          text: expense.contact?.id ? expense.contact?.name : null,
        },
        {
          icon: NoteTextIcon,
          title: 'Description',
          text: expense.description,
        },
      ]}
    />
  );
};

const ExpensePaidStatusBadge = ({
  paidStatus,
}: {
  paidStatus: Maybe<paidStatus_enum>;
}) => {
  if (!paidStatus) return null;

  return (
    <Badge color={paidStatus === 'paid' ? 'green' : 'yellow'}>
      {toTitleCase(paidStatus)}
    </Badge>
  );
};
