import { gqlV2 } from '@finalytic/data';
import { audit_log } from '@finalytic/graphql';
import { Maybe, isUUID } from '@finalytic/utils';
import { ActivityRow } from './_types';

export const ACTIVITY_START_DATE = '2024-05-01';

export const getActivityActor = (log: audit_log): ActivityRow['actor'] => {
  const actor: ActivityRow['actor'] = {
    id: log.actorUserId,
    email: log.actorUser?.email || '',
    firstName: log.actorUser?.firstName || '',
    lastName: log.actorUser?.lastName || '',
    avatar: undefined,
  };

  if (actor.id) return actor;

  return null;
};

export const whereActorUserId = (
  actorUserId: Maybe<string>
): gqlV2.InputMaybe<gqlV2.uuid_comparison_exp> | undefined =>
  actorUserId
    ? actorUserId === 'system'
      ? {
          _is_null: true,
        }
      : isUUID(actorUserId)
        ? { _eq: actorUserId }
        : undefined
    : undefined;
