import { useQuery, useTeamId } from '@finalytic/data';
import {
  Query,
  accountAssignmentType_bool_exp,
  accountAssignmentType_enum,
} from '@finalytic/graphql';
import { formatAssignmentType } from '@vrplatform/ui-common';

export function useMissingAccountAssignmentsQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return getMissingAccountAssignments(q, args);
    },
    {
      queryKey: 'accounts',
      variables: {
        teamId,
      },
    }
  );
}

export function getMissingAccountAssignments(
  q: Query,
  args: {
    teamId: string;
    whereAccountAssigments?: accountAssignmentType_bool_exp;
  }
) {
  const accountAssignments = q
    .accountAssignmentTypes({
      where: args.whereAccountAssigments,
    })
    .map((x) => ({
      id: x.name as accountAssignmentType_enum,
      title: formatAssignmentType(x.name as accountAssignmentType_enum),
    }));

  const existingAssignments = q
    .accountAssignments({
      where: {
        tenantId: { _eq: args.teamId },
      },
      distinct_on: ['type'],
    })
    .map((account) => account.type!);

  return accountAssignments.filter((x) => !existingAssignments.includes(x.id));
}
