import { useUser } from '@clerk/clerk-react';
import { Banner, Button } from '@finalytic/components';
import {
  useBilling,
  useDashboard,
  useIntercom,
  useSubscription,
  useTeam,
  useTeamId,
  useTeamRole,
  useTrpcQuery,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  LoadingIndicator,
  StringParam,
  useAppName,
  useQueryParam,
} from '@finalytic/ui';
import { Center, Group, Text, Title } from '@mantine/core';
import { HyperlineEntities } from '@vrplatform/hyperline-client';
import { VRP_TENANT_ID } from '@vrplatform/ui-common';
import { ReactNode, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { OwnerRoutes } from './OwnerRoutes';
import { PartnerRoutes } from './PartnerRoutes';
import { PmRoutes } from './PmRoutes';
import { VrpRoutes } from './VrpRoutes';

const billingStatusesHints: Record<
  HyperlineEntities['Subscription']['status'],
  string
> = {
  active: 'Teams subscription is active',
  cancelled: 'Team is locked, because teams subscription has been canceled.',
  errored: 'Team is locked, because payment failed on teams subscription.',
  paused: 'Team is locked, because teams subscription has been paused.',
  pending: 'Team is locked, because teams subscription is pending.',
  voided: 'Team is locked, because teams subscription has been voided.',
  draft: 'Team is locked, because teams subscription is in draft.',
};

export const DashboardRoutes = () => {
  const [dashboard] = useDashboard();
  const [teamId] = useTeamId();

  if (dashboard === 'owner') {
    return (
      <SuspendedWrapper>
        <OwnerRoutes />
      </SuspendedWrapper>
    );
  }

  if (dashboard === 'overview') {
    return <PartnerRoutes />;
  }

  if (dashboard === 'partner') {
    return (
      <Wrapper>
        {teamId === VRP_TENANT_ID ? <VrpRoutes /> : <PartnerRoutes />}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PmRoutes />
    </Wrapper>
  );
};

const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <StagingWrapper>
      <SuspendedWrapper>
        <BillingWrapper>
          <TwoFaWrapper>{children}</TwoFaWrapper>
        </BillingWrapper>
      </SuspendedWrapper>
    </StagingWrapper>
  );
};

const StagingWrapper = ({ children }: { children: ReactNode }) => {
  const { isVrpAdmin } = useTeamRole();
  const [overwriteQueryParam, setOverwriteQueryParam] = useQueryParam(
    'vrp_staging_overwrite',
    StringParam
  );

  useEffect(() => {
    const isDev =
      import.meta.env.DEV || window.location.hostname === 'localhost';
    const enableOverwrite = overwriteQueryParam === 'true';
    const stagingSessionStorage = sessionStorage.getItem(
      'vrp_staging_overwrite'
    );
    const stagingDomain = 'staging.portal.vrplatform.app';
    const isStagingDomain = window.location.hostname === stagingDomain;

    // do nothing in any of these cases
    if (!isVrpAdmin || isDev || stagingSessionStorage || isStagingDomain)
      return;

    // redirect to staging
    if (!isStagingDomain && !enableOverwrite && !stagingSessionStorage) {
      window.location.href = `https://${stagingDomain}${window.location.pathname}${window.location.search}`;
      return;
    }

    // setSessionStorage if user was redirected and remove query param
    if (enableOverwrite) {
      sessionStorage.setItem('vrp_staging_overwrite', 'true');
      setOverwriteQueryParam(undefined, 'replaceIn');
      return;
    }
  }, [isVrpAdmin, overwriteQueryParam, setOverwriteQueryParam]);

  return children;
};

const SuspendedWrapper = ({ children }: { children: ReactNode }) => {
  const [{ status, partnerId }] = useTeam();
  const { isVrpAdmin } = useTeamRole();
  const location = useLocation();
  const [dashboard] = useDashboard();
  const { show } = useIntercom();
  const { appName } = useAppName();

  const isOwnerPortal = dashboard === 'owner';

  if (
    location.pathname.startsWith('/settings') ||
    location.pathname.startsWith('/features') ||
    status !== 'inactive'
  )
    return children;

  const isVrpTeam = partnerId === VRP_TENANT_ID;

  if (isVrpAdmin) {
    return (
      <>
        <Banner themeColor="yellow">
          <Group gap={'xs'}>
            <Icon
              icon="AlertTriangleIcon"
              size={20}
              color={({ colors }) => colors.yellow[8]}
            />
            <Text size="xs" component="p" m={0}>
              Team is suspended
            </Text>
          </Group>
        </Banner>
        {children}
      </>
    );
  }

  return (
    <Center
      mih="90%"
      sx={(theme) => ({
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing.md,
        textAlign: 'center',
      })}
    >
      <Icon
        icon="Lock2Icon"
        color={({ colors }) => colors.yellow[6]}
        size={40}
      />
      {isOwnerPortal ? (
        <>
          <Title order={2} c="neutral">
            Property Manager Suspended
          </Title>
          <Text component="p" c="gray" my="sm" maw={550}>
            Your property managers access to {appName} has been temporarily
            suspended. Please get in touch with your property manager.
          </Text>
        </>
      ) : (
        <>
          <Title order={2} c="neutral">
            Team suspended
          </Title>
          <Text component="p" color="gray" my="sm" maw={550}>
            Your team was suspended.{' '}
            {isVrpTeam
              ? 'Please reach out to us using Help & Support.'
              : 'Please reach out to your partner to resolve the suspension.'}
          </Text>
          {isVrpTeam && (
            <Button
              variant="light"
              onClick={show}
              leftIcon={'CommentQuestionIcon'}
            >
              Help & Support
            </Button>
          )}
        </>
      )}
    </Center>
  );
};

const TwoFaWrapper = ({ children }: { children: ReactNode }) => {
  const [{ forceTwoFactorAuth }] = useTeam();
  const { isVrpAdmin } = useTeamRole();
  const { user } = useUser();
  const location = useLocation();
  const { appName } = useAppName();

  const showChildren = forceTwoFactorAuth ? user?.twoFactorEnabled : true;

  if (
    isVrpAdmin ||
    showChildren ||
    location.pathname.startsWith('/settings') ||
    location.pathname.startsWith('/features')
  )
    return children;

  return (
    <Center
      mih="90%"
      sx={(theme) => ({
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing.md,
        textAlign: 'center',
      })}
    >
      <Icon
        icon="Lock2Icon"
        color={({ colors, primaryColor }) => colors[primaryColor][6]}
        size={40}
      />
      <Title order={2} c="neutral">
        Authentication required
      </Title>
      <Text component="p" color="gray" my="sm" maw={550}>
        Your team admins have required you to enable 2FA authentication. You can
        go into your settings and enable it to view data on {appName}.
      </Text>
      <Button
        variant="primary"
        component={Link}
        to="/settings/security"
        rightIcon={'ArrowRightIcon'}
      >
        Add 2FA authentication
      </Button>
    </Center>
  );
};

const BillingWrapper = ({ children }: { children: ReactNode }) => {
  const { billingStatus, showBilling } = useBilling();

  if (showBilling && billingStatus !== 'active')
    return <LockedBilling>{children}</LockedBilling>;

  return children;
};

const LockedBilling = ({ children }: { children: ReactNode }) => {
  const { billingStatus } = useBilling();
  const location = useLocation();
  const [{ id: teamId, billingSubscriptionStatus }] = useTeam();
  const [, refetchTeam] = useTeam();
  const { isVrpAdmin } = useTeamRole();

  const { data: subscription } = useSubscription(
    (q, args) => {
      const tenant = q.tenantById({
        id: args.teamId,
      });

      return {
        billingStatus:
          tenant?.billingSubscriptionStatus as typeof billingSubscriptionStatus,
      };
    },
    {
      teamId,
    },
    {
      skip: !teamId,
    }
  );

  useEffect(() => {
    if (subscription?.billingStatus) refetchTeam();
  }, [refetchTeam, subscription?.billingStatus]);

  if (
    location.pathname.startsWith('/settings') ||
    location.pathname.startsWith('/features')
  )
    return children;

  if (isVrpAdmin)
    return (
      <>
        <Banner themeColor="orange">
          <Group gap={'xs'}>
            <Icon
              icon="CircleDollarIcon"
              size={20}
              color={({ colors }) => colors.orange[8]}
            />
            <Text size="xs" component="p" m={0}>
              {billingStatusesHints[billingStatus]}
            </Text>
          </Group>
        </Banner>
        {children}
      </>
    );

  if (billingStatus === 'pending')
    return (
      <Center
        mih="90%"
        sx={(theme) => ({
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing.md,
          textAlign: 'center',
        })}
      >
        <LoadingIndicator size="md" variant="dots" mb="xl" />

        <Title order={2} c="neutral">
          Awaiting Subscription Confirmation
        </Title>
        <Text component="p" c="gray" my="sm" maw={550}>
          We are currently confirming your payment method. If this takes longer
          than expected, please reach out to us using Help & Support.
        </Text>
        <SupportButton />
      </Center>
    );

  return <BillingError />;
};

const BillingError = () => {
  const { appName } = useAppName();
  const [teamId] = useTeamId();
  const { isTeamAdmin } = useTeamRole();

  const { data, loading } = useTrpcQuery('getHyperlineSubscription', {
    tenantId: teamId,
  });

  const info: Record<
    NonNullable<NonNullable<typeof data>['subscription']>['status'],
    {
      title: string;
      description: string;
    }
  > = useMemo(() => {
    const action = isTeamAdmin
      ? `Please update your billing to view your data on ${appName}.`
      : 'Please reach out to your team admin to update the billing.';

    return {
      active: {
        title: 'Your subscription is active',
        description: `Please refresh the window to access your data. ${action}`,
      },
      cancelled: {
        title: 'Your subscription has been canceled',
        description: `The team's subscription has been canceled. ${action}`,
      },
      errored: {
        title: "There was an issue with the team's subscription",
        description: `We have found an issue with the team's subscription. ${action}`,
      },
      paused: {
        title: 'Your subscription has been paused',
        description: `Your subscription was recently paused. ${action}`,
      },
      pending: {
        title: 'Your subscription is pending',
        description:
          'We are currently confirming your payment method. If this takes longer than expected, please reach out to us using Help & Support.',
      },
      voided: {
        title: 'Your subscription has been voided',
        description: `Your subscription was recently voided. ${action}`,
      },
      draft: {
        title: 'Your subscription is in draft',
        description: `Your subscription is in draft. ${action}`,
      },
    };
  }, [isTeamAdmin, appName]);

  const defaultTitle = 'Subscription required';
  const defaultDescription = `We have found in an issue with the team's subscription.`;

  return (
    <Center
      mih="90%"
      sx={(theme) => ({
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing.md,
        textAlign: 'center',
      })}
    >
      {loading ? (
        <LoadingIndicator variant="dots" size="md" />
      ) : (
        <>
          <Icon
            icon="Lock2Icon"
            color={({ colors }) => colors.red[6]}
            size={40}
          />
          <Title order={2} c="neutral">
            {data?.subscription?.status
              ? info[data.subscription.status]?.title || defaultTitle
              : defaultTitle}
          </Title>
          <Text component="p" color="gray" my="sm" maw={550}>
            {data?.subscription?.status
              ? info[data.subscription.status]?.description ||
                defaultDescription
              : defaultDescription}
          </Text>
          {isTeamAdmin && <HyperlinePortalButton />}
        </>
      )}
    </Center>
  );
};

const HyperlinePortalButton = () => {
  const [teamId] = useTeamId();
  const { data, loading } = useTrpcQuery('getHyperlinePortal', {
    tenantId: teamId,
  });

  return (
    <Button
      color="red"
      variant="filled"
      loading={loading}
      disabled={!loading && !data?.url}
      component={'a'}
      href={data?.url || ''}
      rightIcon={'ArrowRightIcon'}
      sx={{
        path: {
          stroke: 'white',
        },
      }}
    >
      Manage billing
    </Button>
  );
};

const SupportButton = () => {
  const { show } = useIntercom();

  return (
    <Button variant="light" onClick={show} leftIcon={'CommentQuestionIcon'}>
      Help & Support
    </Button>
  );
};
