import { useRegisterSW } from 'virtual:pwa-register/react';
import { Button } from '@finalytic/components';
import { captureSentryError } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { Affix, Box, Text, rem } from '@mantine/core';
import { useEffect } from 'react';

export function ReloadPrompt() {
  const isStaging =
    window.location.hostname === 'staging.portal.vrplatform.app' ||
    window.location.hostname === 'localhost';

  const {
    // offlineReady: [_offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true, // What does this do?
    onRegisteredSW: (swUrl, r) => {
      if (!r) return;

      const updateSw = async () => {
        console.group('SW registration');
        if (isStaging) console.log(swUrl);

        if (!(!r.installing && navigator)) return;

        if ('connection' in navigator && !navigator.onLine) return;

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        });

        if (resp?.status === 200) await r.update();
        else {
          if (isStaging) console.log('Failed to update sw');
        }
        console.groupEnd();
      };

      updateSw();
      setInterval(updateSw, 20 * 1000); // 20 seconds
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const refreshSw = async () =>
    await updateServiceWorker(true).catch((err) => {
      console.error(err);
      captureSentryError(err, {
        level: 'error',
        fingerprint: ['pwa'],
      });
    });

  // Force update on browser refresh if an update is available
  useEffect(() => {
    const handleBeforeUnload = async () => {
      if (needRefresh) {
        await updateServiceWorker(true); // Apply the update
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [needRefresh, updateServiceWorker]);

  if (needRefresh) {
    return (
      <Affix
        withinPortal
        position={{
          bottom: 20,
          right: 50,
        }}
      >
        <Box
          sx={(theme) => ({
            border: '1px solid',
            borderColor: theme.colors.neutral[2],
            borderRadius: theme.radius.md,
            backgroundColor: theme.colors[theme.primaryColor][0] + 20,
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            padding: theme.spacing.xs,
            color: theme.colors.neutral[7],
            fontSize: theme.fontSizes.xs,
            lineHeight: 'normal',
            width: 300,
            display: 'flex',
            alignItems: 'center',
            boxShadow: theme.shadows.sm,
            gap: theme.spacing.xs,
          })}
        >
          <Icon icon="RefreshCwIcon" size={18} />
          <Text component="span" fw={'bold'} c="neutral">
            New version available
          </Text>
          <Button
            onClick={refreshSw}
            sx={(theme) => ({
              marginLeft: 'auto',
              paddingInline: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              paddingBlock: rem(8),
              boxShadow: theme.shadows.sm,
              '&, *': {
                fontWeight: 600,
                color: theme.colors.neutral[7],
              },
            })}
            size="xs"
          >
            Refresh
          </Button>
        </Box>
      </Affix>
    );

    // LEGACY
    // return (
    //   <Box
    //     sx={{
    //       position: 'fixed',
    //       display: 'flex',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       padding: 0,
    //       margin: 0,
    //       inset: 0,
    //       zIndex: 10000,
    //       backgroundColor: 'rgba(0, 0, 0, 0.5)',
    //       overflow: 'hidden',
    //     }}
    //   >
    //     <Card withBorder maw={400} p={rem(32)} radius="lg">
    //       <RefreshCwIcon
    //         size={30}
    //         color={colors[primaryColor][5]}
    //         sx={(theme) => ({
    //           display: 'block',
    //           marginInline: 'auto',
    //           marginTop: theme.spacing.md,
    //           marginBottom: theme.spacing.xl,
    //         })}
    //       />
    //       <Title order={3} c="dark" ta="center" mb="md">
    //         Update Available
    //       </Title>
    //       <Text component="p" ta="center" mb="xl" c="neutral">
    //         New content available. Update now to use the latest version of{' '}
    //         {appName}.
    //       </Text>
    //       <Button
    //         onClick={refreshSw}
    //         variant="primary"
    //         sx={{
    //           width: '100%',
    //         }}
    //       >
    //         Reload
    //       </Button>
    //     </Card>
    //   </Box>
    // );
  }

  return null;
}
