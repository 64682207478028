import { Select } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useInfiniteQuery,
  useQuery,
  useTeam,
  useTeamId,
  useTeamRole,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { EllipsisMenuDivider, Logo, SelectItem } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import { Center, MenuItem } from '@mantine/core';
import { VRP_TENANT_ID } from '@vrplatform/ui-common';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AddTeamDrawer } from '../../../drawers';
import { useIsSignInMobile } from '../views/sign-in-up/useIsSignInMobile';

export function useOnboardingTeamSwithQuery({ search: s }: { search: string }) {
  const search = s.trim();

  const queryData = useInfiniteQuery(
    (q, { search }, { limit, offset }) => {
      const where: gqlV2.tenant_bool_exp = {
        status: { _neq: 'deleted' },
        type: { _nin: ['partner', 'admin'] },
        name: search ? { _ilike: `%${search}%` } : undefined,
      };

      const aggregate = q.tenantAggregate({ where })?.aggregate?.count() || 0;

      const list = q
        .tenant({
          order_by: [{ type: 'asc_nulls_last' }, { name: 'asc' }],
          where,
          limit: limit,
          offset,
        })
        .map<SelectItem>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'teams',
      variables: {
        search,
      },
    }
  );

  const { data, isLoading: loading2 } = useQuery(
    (q) => {
      const canSwitch =
        q
          .tenant({ where: { status: { _neq: 'deleted' } }, limit: 3 })
          .map((x) => x.id).length > 1;

      const pinnedTeams = q
        .tenant({
          where: {
            status: { _neq: 'deleted' },
            type: { _in: ['admin', 'partner'] },
          },
          order_by: [{ name: 'asc' }],
        })
        .map<SelectItem<string>>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      return {
        canSwitch,
        pinnedTeams,
      };
    },
    {
      queryKey: 'teams',
    }
  );

  return {
    queryData,
    loadingValue: loading2,
    canSwitch: !!data?.canSwitch,
    pinnedTeams: data?.pinnedTeams || [],
  };
}

export const OnboardingTeamSwitch = () => {
  const [team] = useTeam();
  const [, setTeamId] = useTeamId();
  const { isVrpAdmin } = useTeamRole();
  const [searchParams] = useSearchParams();
  const [, toggleTeamOverview] = useDashboard();
  const goto = useNavigate();

  const isMobile = useIsSignInMobile();

  const [search, setSearch] = useState('');

  const { queryData, loadingValue, canSwitch, pinnedTeams } =
    useOnboardingTeamSwithQuery({
      search,
    });

  const setNewTeamValue = useCallback(
    (newValue: SelectItem | null) => {
      if (!newValue?.value) return;
      setTeamId(newValue?.value);

      const hasKeys = [...searchParams.values()].filter(hasValue).length > 0;
      if (hasKeys) {
        goto(window.location.pathname);
      }
      toggleTeamOverview(false);
    },
    [setTeamId, toggleTeamOverview, searchParams, goto]
  );

  const value = useMemo(() => {
    return team?.id
      ? {
          label: team?.name || '',
          value: team?.id || '',
        }
      : null;
  }, [team?.id, team?.name]);

  const customActionTop = useMemo(
    () => ({
      label: 'VRPlatform',
      icon: (
        <Center
          sx={{
            borderRadius: '100%',
            overflow: 'hidden',
          }}
        >
          <Logo width={16} />
        </Center>
      ),
      onSubmit: () =>
        setNewTeamValue({
          label: 'VRPlatform',
          value: VRP_TENANT_ID,
        }),
    }),
    [setNewTeamValue]
  );

  if (!canSwitch) return null;

  return (
    <>
      <Select
        type="single"
        setValue={setNewTeamValue}
        infiniteData={{ ...queryData, setSearch }}
        dropdownProps={{
          withinPortal: false,
          position: isMobile ? 'bottom-end' : 'right-end',
          width: 260,
        }}
        pinnedItems={pinnedTeams}
        inputProps={{
          loadingQuery: loadingValue,
          backgroundColor: 'transparent',
        }}
        customActionTop={isVrpAdmin ? customActionTop : undefined}
        value={value}
      >
        {({ handlers }) => {
          return (
            <MenuItem
              onMouseEnter={isMobile ? undefined : handlers.open}
              onClick={handlers.toggle}
              leftSection={
                <Icon
                  icon="OfficeIcon"
                  size={16}
                  color={(theme) => theme.colors.gray[6]}
                />
              }
            >
              Select team
            </MenuItem>
          );
        }}
      </Select>
      <EllipsisMenuDivider />
      <AddTeamDrawer />
    </>
  );
};
