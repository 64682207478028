import { connection_bool_exp } from '@finalytic/graphql';
import { Maybe, emptyUUID, ensure } from '@finalytic/utils';
import { connectionHiddenStatusDefault } from './whereConnectionStatusDefault';

export function whereConnections({
  search: s,
  teamId,
  status,
  appId,
}: {
  teamId: string;
  search?: Maybe<string>;
  appId?: Maybe<string>;
  status?: Maybe<'archived' | 'active' | 'pending' | 'error'>;
}) {
  const _and: connection_bool_exp[] = [];

  // SEARCH
  const search = s?.trim();
  if (search) {
    _and.push({
      _or: [
        {
          name: { _ilike: `%${search.trim()}%` },
        },
        {
          uniqueRef: { _ilike: `%${search.trim()}%` },
        },
      ],
    });
  }

  if (status === 'pending') {
    _and.push(PENDING);
  }

  if (status === 'error') {
    _and.push(ERROR);
  }

  return ensure<connection_bool_exp>({
    tenantId: { _eq: teamId || emptyUUID },
    appId: appId
      ? { _eq: appId, _nin: ['finalytic', 'api'] }
      : { _nin: ['finalytic', 'api'] },
    status:
      status !== 'archived'
        ? { _nin: connectionHiddenStatusDefault }
        : { _in: connectionHiddenStatusDefault },
    _and: _and.length ? _and : undefined,
  });
}

const EXTRACT_TYPES = ['extract', 'extractLegacy'];

const PENDING: connection_bool_exp = {
  // LEGACY
  jobPlans: {
    status: { _in: ['queued', 'started'] },
    _or: [
      {
        jobs: {
          kind: { _in: EXTRACT_TYPES },
        },
      },
      {
        type: { _in: EXTRACT_TYPES },
      },
    ],
  },
};

const ERROR: connection_bool_exp = {
  _or: [
    // NEW
    {
      fetchStatus: { _eq: 'failed' },
    },
    // // LEGACY
    // {
    //   jobPlans: {
    //     status: { _eq: 'failed' },
    //     isCurrentOnConnection: { _eq: true },
    //     _or: [
    //       {
    //         jobs: {
    //           kind: { _in: EXTRACT_TYPES },
    //         },
    //       },
    //       {
    //         type: { _in: EXTRACT_TYPES },
    //       },
    //     ],
    //   },
    // },
    // // LEGACY
    // {
    //   status: { _eq: 'error' }, // connection.status
    //   _or: [
    //     {
    //       jobPlans: {
    //         status: { _in: ['failed', 'completed'] },
    //         isCurrentOnConnection: { _eq: true },
    //         _or: [
    //           {
    //             jobs: {
    //               kind: { _in: EXTRACT_TYPES },
    //             },
    //           },
    //           {
    //             type: { _in: EXTRACT_TYPES },
    //           },
    //         ],
    //       },
    //     },
    //     {
    //       _not: { jobPlans: {} },
    //     },
    //   ],
    // },
  ],
};
