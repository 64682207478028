import { setting_bool_exp, source_bool_exp } from '@finalytic/graphql';
import { Maybe, day, ensure } from '@finalytic/utils';
import { whereConnectionStatusDefault } from './whereConnectionStatusDefault';

type Props = {
  type: string;
  tenantId: string;
  search?: Maybe<string>;
  connectionId?: Maybe<string>;
  status?: Maybe<('active' | 'archived')[]>;
  date?: Maybe<[string | null, string | null]>;
};

export const whereSources = ({
  tenantId,
  search,
  type,
  connectionId,
  status,
  date,
}: Props): source_bool_exp => {
  const getStatusFilter = (): source_bool_exp['status'] => {
    if (status?.length) {
      const _in = [];

      if (status.includes('active')) {
        _in.push('active');
      }
      if (status.includes('archived')) {
        _in.push('archived', 'deleted', 'inactive');
      }

      return {
        _in,
      };
    }

    return {
      _eq: 'active',
    };
  };

  const getDateFilter = (): source_bool_exp['date'] => {
    if (!date || !date[0]) return undefined;

    const [start, end] = date;

    return {
      _gte: start,
      _lt: end
        ? day(end).add(1, 'day').yyyymmdd()
        : day(start).add(1, 'day').yyyymmdd(),
    };
  };

  return {
    tenantId: { _eq: tenantId },
    connection: connectionId
      ? {
          id: { _eq: connectionId },
        }
      : {
          status: whereConnectionStatusDefault,
        },
    type: {
      _eq: type,
    },
    date: getDateFilter(),
    status: getStatusFilter(),
    _or: search
      ? [
          { description: { _ilike: `%${search}%` } },
          { remoteId: { _ilike: `%${search}%` } },
        ]
      : undefined,
  };
};

export const ACCOUNT_COLLECTION_SETTING_RIGHT_TYPE =
  'finalytic.accountCollection';

export const TAX_ACCOUNT_COLLECTION_SETTING_KEY = 'taxAccountCollection';

export const whereTaxAccounts = ({
  ...props
}: Omit<Props, 'type'> & TaxSettingParams) => {
  return ensure<source_bool_exp>({
    ...whereSources({
      ...props,
      type: 'account',
    }),
    settingsLeft: whereTaxAccountSetting({
      collectionId: props.connectionId,
      tenantId: props.tenantId,
      leftConnectionId: props.leftConnectionId,
      rightConnectionId: props.rightConnectionId,
    }),
  });
};

type TaxSettingParams = {
  collectionId?: Maybe<string>;
  tenantId: string;
  leftConnectionId: string;
  rightConnectionId: string;
};

export const whereTaxAccountSetting = ({
  leftConnectionId,
  rightConnectionId,
  collectionId,
}: TaxSettingParams) => {
  return ensure<setting_bool_exp>({
    key: {
      _eq: TAX_ACCOUNT_COLLECTION_SETTING_KEY,
    },
    rightType: { _eq: ACCOUNT_COLLECTION_SETTING_RIGHT_TYPE },
    value: collectionId ? { _eq: collectionId } : undefined,
    leftConnectionId: { _eq: leftConnectionId },
    rightConnectionId: { _eq: rightConnectionId },
  });
};
