import { Icon, IconDefinition } from '@finalytic/icons';
import { Box, List, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { useIsSignInMobile } from '../views/sign-in-up/useIsSignInMobile';

type Props = {
  title: string;
  items: {
    id: string;
    title: string;
    description: string;
    icon: IconDefinition;
  }[];
};

export const OnboardingSidebarCard = ({ items, title }: Props) => {
  const theme = useMantineTheme();
  const isMobile = useIsSignInMobile();

  return (
    <Box
      sx={(theme) => ({
        padding: isMobile ? theme.spacing.md : theme.spacing.xl,
        borderRadius: theme.radius.md,
        backgroundColor: isMobile ? theme.colors.neutral[1] : '#ffffff05',
        backdropFilter: 'blur(10px)',
      })}
    >
      {!isMobile && (
        <Text
          component="h4"
          fw={'bold'}
          c={isMobile ? 'dark' : 'white'}
          size="lg"
          mb="md"
        >
          {title}
        </Text>
      )}

      <List spacing="md">
        {items.map((item) => (
          <List.Item
            key={item.id}
            styles={{
              itemWrapper: {
                alignItems: 'flex-start',
              },
              itemIcon: {
                paddingTop: 4,
              },
            }}
            icon={
              <Icon
                icon={item.icon}
                color={(theme) =>
                  isMobile ? theme.colors.neutral[8] : theme.white
                }
                size={16}
              />
            }
          >
            <Text size="md" fw={500} c={isMobile ? 'dark' : 'white'}>
              {item.title}
            </Text>
            <Text c={isMobile ? theme.colors.neutral[5] : theme.colors.gray[4]}>
              {item.description}
            </Text>
          </List.Item>
        ))}
      </List>
    </Box>
  );
};
