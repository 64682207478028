import { useTeamId, useTrpcMutation } from '@finalytic/data';
import { InputFormSchema } from '@vrplatform/ui-common';

export type ConnectFn = ReturnType<typeof useConnect>;
export function useConnect() {
  const [teamId] = useTeamId();
  const { mutate } = useTrpcMutation('addConnection', {
    invalidateQueryKeys: ['connections', 'setupGuide'],
  });

  return async ({
    sessionKey,
    connectionId,
    appId,
    params,
  }: {
    appId?: string;
    connectionId?: string;
    params: any;
    sessionKey?: string;
  }) => {
    try {
      const data = await mutate({
        sessionKey,
        tenantId: teamId,
        appId,
        params,
        connectionId,
        autoExtract: true,
      });
      if ('id' in data) {
        return { type: 'success', error: undefined, issue: undefined };
      }
      throw data;
    } catch (res: any) {
      // ISSUE
      if (res.type === 'issue') {
        return { type: 'issue', issue: res as IssueType, error: undefined };
      } else {
        return {
          type: 'error',
          error: { title: res?.name, message: res?.message },
          issue: undefined,
        };
      }
    }
  };
}

export type IssueType = {
  type: 'issue';
  code: 'ISSUE_REQUEST_FORM' | 'ISSUE_REQUEST_LINK';
  message: string;
  state: {
    sessionKey: string;
    expiresIn: string;
  };
  action:
    | {
        value: InputFormSchema;
        type: 'form';
      }
    | {
        value: string;
        type: 'link' | 'oauth';
      }
    | {
        type: 'extension';
        value?: string;
      };
};
