import { Modal } from '@mantine/core';
import { useStore } from 'zustand';
import { GeneralLedgerDetailTable } from '../../views/general-ledger/GeneralLedgerDetailTable';
import {
  journalEntriesModalStore,
  useJournalEntriesModal,
} from './useJournalEntriesModal';

export const JournalEntriesModal = () => {
  const where = useStore(journalEntriesModalStore, (state) => state.where);
  const { close: closeModal } = useJournalEntriesModal();

  return (
    <Modal
      opened={!!where}
      onClose={closeModal}
      title={'Journal Entries'}
      size={1300}
      // zIndex={150}
      centered
      radius="lg"
      styles={{
        title: {
          fontWeight: 500,
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 0,
          paddingTop: 2,
          minHeight: '80dvh',
        },
      }}
    >
      <GeneralLedgerDetailTable />
    </Modal>
  );
};
