import { useEnabledFeatures } from '@finalytic/data';
import { LedgerReservationDetailDrawer } from './LedgerReservationDetailDrawer';
import { LegacyReservationDetailDrawer } from './LegacyReservationDetailDrawer';

export const ReservationDetailDrawer = () => {
  const { GL } = useEnabledFeatures();

  if (GL) return <LedgerReservationDetailDrawer />;

  return <LegacyReservationDetailDrawer />;
};
