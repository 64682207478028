import { Center, type MantineTheme, useMantineTheme } from '@mantine/core';
import { forwardRef } from 'react';
import Icons, { type IconProps } from './Icons';

export type IconDefinition = keyof typeof Icons;

type Props = Omit<IconProps, 'color'> & {
  icon: IconDefinition;
  color?: string | ((theme: MantineTheme) => string);
};

export const Icon = forwardRef<HTMLDivElement, Props>(
  ({ color = '#737373', size = 24, icon, ...props }, ref) => {
    const theme = useMantineTheme();

    const Comp = Icons[icon];

    return (
      <Center ref={ref}>
        <Comp
          size={size}
          color={typeof color === 'function' ? color(theme) : color}
          {...props}
        />
      </Center>
    );
  }
);
