import { IconButton } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import { EllipsisMenu } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import {
  Center,
  Checkbox,
  Group,
  MantineTheme,
  Progress,
  Radio,
  Text,
  Title,
} from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import {
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_GroupingState,
  MRT_Icons,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { UIEvent } from 'react';
import {
  TableBasicRow,
  TablePagination,
  TableSortOptions,
} from './LazyTable.types';
import { LazyTablePagination } from './LazyTablePagination';
import { LazyTableSetting } from './LazyTableSettings';
import { TableKey, useSavedTableSettings } from './useSavedTableSettings';

type UseMantineTableParams<TData extends TableBasicRow> = Parameters<
  typeof useMantineReactTable<TData>
>[0];

export type LazyTableProps<TData extends TableBasicRow> = {
  data: {
    rows: TData[];
    rowCount: number;
    loading: boolean;
    isFetching?: boolean;
    error: string | null | undefined | boolean;
  };
  table: {
    key?: TableKey;
    columns: MRT_ColumnDef<TData>[];
    minHeight?: number | string;
    onRowClick?: {
      handler: (row: MRT_Row<TData>, cell: MRT_Cell<TData>) => void;
      disabled?: (row: MRT_Row<TData>, cell: MRT_Cell<TData>) => boolean;
    };
    hideSettings?: boolean;
    hideHeader?: boolean;
    hideTopBar?: boolean;
    hideExpandColumn?: boolean;
    hidePagination?: boolean;
    enableStickyHeader?: boolean;
    enableStickyFooter?: boolean;
    emptyRowsFallback?:
      | UseMantineTableParams<TData>['renderEmptyRowsFallback']
      | string;
  };
  pagination?: TablePagination;
  infinite?: {
    fetchMoreOnBottomReached: (
      containerRefElement?: HTMLDivElement | null
    ) => void;
    rowVirtualizerInstanceRef: any;
    tableContainerRef: any;
  };
  virtualization?: {
    overscan: number;
    enableRowVirtualization: boolean;
  };
  sorting?: {
    sorting: MRT_SortingState;
    setSorting: UseMantineTableParams<TData>['onSortingChange'];
    options?: TableSortOptions;
  };
  styles?: {
    row?: (
      props: {
        row: MRT_Row<TData>;
        renderedRowIndex?: number | undefined;
        isDetailPanel?: boolean;
        table: MRT_TableInstance<TData>;
      },
      theme: MantineTheme
    ) => EmotionSx;
  };
  selecting?: {
    rowSelection: { rows: MRT_RowSelectionState; allPagesSelected: boolean };
    setRowSelection: (selected: Record<string, boolean>) => void;
    setAllPagesSelected: (v: boolean) => void;
    enableRowSelection?: (row: MRT_Row<TData>) => boolean;
    disableSelectAll?: boolean;
    selectDisplayMode?: 'checkbox' | 'radio';
  };
  subRows?: {
    defaultExpanded?: boolean;
    getSubRows?: (originalRow: TData) => TData[] | undefined;
    subRowBackgroundColor?: string;
    getRowCanExpand: NonNullable<
      UseMantineTableParams<TData>['getRowCanExpand']
    >;
    renderDetailPanel?: UseMantineTableParams<TData>['renderDetailPanel'];
  };
  groupBy?: {
    groupBy: MRT_GroupingState;
    setGroupBy: UseMantineTableParams<TData>['onGroupingChange'];
    defaultExpanded?: boolean;
    allowExpanding?: boolean;
    menuItems?: (props: {
      cell: MRT_Cell<TData>;
      column: MRT_Column<TData>;
      row: MRT_Row<TData>;
      table: MRT_TableInstance<TData>;
    }) => React.ReactNode;
  };
  dragAndDrop?: {
    enableDragging: boolean;
    onTableEnter: () => void;
    isTableHovered: boolean;
    onRowDrop: () => void;
    onRowDragged: UseMantineTableParams<TData>['onDraggingRowChange'];
    draggingRow: MRT_Row<TData> | null; // row id OR null
  };
  rowMenu?: {
    menuItems?: NonNullable<
      UseMantineTableParams<TData>['renderRowActionMenuItems']
    >;
    width?: 'target' | number;
    openDetail?: (row: MRT_Row<TData>) => void;
    openDetailTooltip?: string;
    hideMenu?: (
      o: Parameters<
        NonNullable<UseMantineTableParams<TData>['renderRowActionMenuItems']>
      >[0]
    ) => boolean;
  };
};

export type TableFilterProps =
  | { children?: undefined; resetFilter?: undefined }
  | { children: React.ReactNode; resetFilter: () => void };

export function LazyTable<TRow extends TableBasicRow>({
  table: t,
  data,
  pagination,
  sorting,
  subRows,
  rowMenu,
  groupBy,
  selecting,
  children,
  resetFilter,
  infinite,
  styles,
  dragAndDrop,
  virtualization,
}: LazyTableProps<TRow> & TableFilterProps) {
  const { value } = useSavedTableSettings();
  const savedSettings = t.key ? value[t.key] : undefined;

  const { error, loading } = data;
  const {
    columns,
    hideSettings = false,
    hideHeader = false,
    hideTopBar = false,
    hideExpandColumn = false,
    hidePagination = false,
    emptyRowsFallback,
    enableStickyHeader = true,
    enableStickyFooter,
    minHeight,
  } = t;

  const isShowingPagination = Boolean(!hidePagination && pagination);

  const isGroupedBy = savedSettings?.groupBy
    ? savedSettings?.groupBy?.[0]
    : groupBy?.groupBy?.[0];

  const allowExpanding = groupBy?.allowExpanding ?? true;

  const rowActionColumnWidth =
    rowMenu?.menuItems && rowMenu.openDetail ? 90 : 50;

  const table = useMantineReactTable({
    columns,
    data: data.rows,
    enableColumnFilterModes: false,
    manualFiltering: true,
    manualPagination: true,
    enablePagination: infinite ? false : isShowingPagination,
    // manualGrouping: true,
    enableRowVirtualization: !!virtualization?.enableRowVirtualization,
    enableGrouping: !!isGroupedBy,
    onGroupingChange: groupBy?.setGroupBy,
    manualSorting: !!sorting?.sorting,
    enableFilters: false,
    enableRowDragging: !!dragAndDrop?.enableDragging,
    enableSorting: false,
    enableColumnActions: false,
    selectAllMode: 'all',
    enableRowSelection:
      selecting?.enableRowSelection || !!selecting?.rowSelection,
    enableExpanding: !!isGroupedBy || !!subRows?.getRowCanExpand,
    getRowCanExpand: subRows?.getRowCanExpand,
    getSubRows: subRows?.getSubRows,
    renderDetailPanel: subRows?.renderDetailPanel,
    mantineLoadingOverlayProps: {
      zIndex: 50,
    },
    positionToolbarAlertBanner: 'none',
    mantineToolbarAlertBannerProps: error
      ? {
          color: 'red',
          children: `Failed to load data${
            typeof error === 'string' && ` - ${error}`
          }`,
        }
      : {
          display: 'none',
        },
    renderEmptyRowsFallback:
      emptyRowsFallback || error
        ? (props) => {
            if (error) {
              return (
                <Center
                  maw={400}
                  mt="lg"
                  mih={minHeight}
                  mx="auto"
                  sx={{
                    flexDirection: 'column',
                  }}
                >
                  <Icon icon="AlertTriangleIcon" size={30} color="orange" />
                  <Title ta="center" order={4} mt="lg" mb="md">
                    {typeof error === 'string' ? error : 'Failed to load data'}
                  </Title>
                  <Text size="sm" ta="center" c="gray">
                    There was an error loading the data. Please try again later.
                    <br />
                    If the problem persists, please contact support.
                  </Text>
                </Center>
              );
            }

            if (typeof emptyRowsFallback === 'string')
              return (
                <Center>
                  <Text size="sm" c="gray" ta="center">
                    {emptyRowsFallback}
                  </Text>
                </Center>
              );

            return emptyRowsFallback?.(props) || null;
          }
        : undefined,
    ...(sorting?.setSorting ? { onSortingChange: sorting.setSorting } : {}),
    onRowSelectionChange: (props) => {
      const currentSelection =
        typeof props === 'function'
          ? (props as any)?.(selecting?.rowSelection.rows)
          : props;
      const values = Object.values(currentSelection);

      const hasDeselected = !values.length || values.some((i) => !i);

      if (hasDeselected && selecting?.rowSelection.allPagesSelected) {
        selecting?.setRowSelection?.({});
        selecting.setAllPagesSelected(false);
      } else {
        selecting?.setRowSelection?.(currentSelection);
      }
    },
    rowCount: data.rowCount,
    enableTopToolbar: false,
    getRowId: (row) => row.id,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    mantineTableHeadProps: hideHeader
      ? {
          sx: {
            visibility: 'hidden',
            height: 0,
          },
        }
      : undefined,
    mantineTableBodyRowProps: styles?.row
      ? (props) => {
          return {
            style: (theme) => styles.row?.(props, theme) as any,
          };
        }
      : undefined,
    mantineTableBodyCellProps: t.onRowClick?.handler
      ? ({ cell, row }) => ({
          onClick: (_event) => {
            if (cell.column.id === 'mrt-row-select') {
              return row.toggleSelected();
            }
            if (cell.column.id === 'mrt-row-actions') return;
            if (cell.column.id === 'mrt-row-expand') return;

            if (_event.altKey && selecting) {
              row.toggleSelected();
            } else {
              if (t.onRowClick?.disabled?.(row, cell)) return;

              t.onRowClick?.handler?.(row, cell);
            }
          },
          sx: {
            cursor: t.onRowClick?.disabled?.(row, cell) ? undefined : 'pointer',
            whiteSpace: 'normal', // this is needed as overwrite when density is set to "xs"
            backgroundColor:
              row.parentId && subRows?.subRowBackgroundColor
                ? subRows.subRowBackgroundColor
                : 'unset',
          },
        })
      : ({ row }) => ({
          sx: {
            whiteSpace: 'normal', // this is needed as overwrite when density is set to "xs"
            backgroundColor:
              row.parentId && subRows?.subRowBackgroundColor
                ? subRows.subRowBackgroundColor
                : 'unset',
          },
        }),
    mantineTableHeadCellProps: {
      sx: (theme) => ({
        fontWeight: 400,
        color: theme.colors.gray[7],
      }),
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        maxSize: hideExpandColumn ? 0 : 50,
        size: hideExpandColumn ? 0 : 50,
        minSize: hideExpandColumn ? 0 : 50,
        mantineTableHeadCellProps: {
          maw: 50,
          sx: hideExpandColumn
            ? {
                padding: 0,
              }
            : undefined,
        },
        mantineTableBodyCellProps: (props) => ({
          maw: 50,
          sx: hideExpandColumn
            ? {
                padding: 0,
              }
            : {
                backgroundColor: props.row.parentId
                  ? subRows?.subRowBackgroundColor
                  : undefined,
              },
        }),
        enableHiding: true,
        Header: ({ table }) => {
          const canExpand = table.getCanSomeRowsExpand() && allowExpanding;

          if (!canExpand) return null;

          const isOpen = table.getIsSomeRowsExpanded();

          return (
            <IconButton
              onClick={() => table.toggleAllRowsExpanded()}
              size={20}
              icon="ChevronIcon"
              style={{
                transform: !isOpen ? 'rotate(-90deg)' : 'rotate(0deg)',
                transition: 'transform 100ms ease',
              }}
              color={(theme) => theme.colors.neutral[5]}
            />
          );
        },
        Cell: ({ row }) => {
          const canExpand = row.getCanExpand() && allowExpanding;

          if (!canExpand) return null;

          const isOpen = row.getIsExpanded();

          return (
            <IconButton
              onClick={() => row.toggleExpanded()}
              icon="ChevronIcon"
              size={20}
              color={(theme) => theme.colors.neutral[5]}
              style={{
                transform: !isOpen ? 'rotate(-90deg)' : 'rotate(0deg)',
                transition: 'transform 100ms ease',
              }}
            />
            //   <ChevronIcon
            //     size={20}
            //     color={colors.neutral[5]}
            //     sx={{
            //       transform: !isOpen ? 'rotate(-90deg)' : 'rotate(0deg)',
            //       transition: 'transform 100ms ease',
            //     }}
            //   />
            // </IconButton>
          );
        },
      },
      'mrt-row-actions': {
        header: '',
        mantineTableHeadCellProps: {
          maw: rowActionColumnWidth,
          miw: rowActionColumnWidth,
          sx: () => ({
            boxShadow: 'none',
            borderLeft: 'none!important',
          }),
        },
        mantineTableFooterCellProps: {
          sx: () => ({
            boxShadow: 'none',
            borderLeft: 'none!important',
          }),
          maw: rowActionColumnWidth,
          miw: rowActionColumnWidth,
        },
        mantineTableBodyCellProps: (props) => ({
          maw: rowActionColumnWidth,
          miw: rowActionColumnWidth,
          sx: () => ({
            boxShadow: 'none',
            borderLeft: 'none!important',
            backgroundColor:
              props.row.parentId && subRows?.subRowBackgroundColor
                ? subRows.subRowBackgroundColor
                : 'unset',
          }),
        }),
        AggregatedCell: groupBy?.menuItems
          ? (...params) => {
              const items = groupBy.menuItems!(...params);

              if (!items) return null;

              return <EllipsisMenu>{items}</EllipsisMenu>;
            }
          : undefined,
      },
      'mrt-row-select': {
        maxSize: 10,
        Header: ({ table }) => {
          const isSomeSelected = table.getIsSomeRowsSelected();

          if (!selecting) return null;

          const deselectAll = () => {
            selecting.setRowSelection({});
            selecting.setAllPagesSelected(false);
          };

          const selectAll = () => {
            selecting?.setAllPagesSelected(true);
            table.toggleAllRowsSelected(true);
          };

          if (
            selecting.disableSelectAll ||
            selecting.selectDisplayMode === 'radio'
          )
            return null;

          return (
            <Checkbox
              size={'1.125rem'}
              checked={selecting.rowSelection.allPagesSelected}
              indeterminate={isSomeSelected}
              onChange={(event) => {
                if (!event.target.checked) deselectAll();
                else selectAll();
              }}
              styles={{
                input: {
                  cursor: 'pointer',
                },
              }}
            />
          );
        },
        Cell: ({ cell }) => {
          const checked = cell.row.getIsSelected();

          const isGrouped = cell.row.getIsGrouped();
          if (isGrouped) return null;

          if (selecting?.selectDisplayMode === 'radio')
            return (
              <Radio
                size="xs"
                checked={checked}
                disabled={
                  selecting?.enableRowSelection &&
                  !selecting?.enableRowSelection?.(cell.row)
                }
                onChange={() => {
                  if (selecting?.rowSelection.allPagesSelected) {
                    selecting.setAllPagesSelected(false);
                  } else {
                    cell.row.toggleSelected();
                  }
                }}
              />
            );

          return (
            <Checkbox
              size={'1.125rem'}
              checked={checked}
              disabled={
                selecting?.enableRowSelection &&
                !selecting?.enableRowSelection?.(cell.row)
              }
              onChange={() => {
                if (selecting?.rowSelection.allPagesSelected) {
                  selecting.setAllPagesSelected(false);
                } else {
                  cell.row.toggleSelected();
                }
              }}
              styles={{
                input: {
                  cursor: 'pointer',
                },
              }}
            />
          );
        },
      },
      'mrt-row-drag': {
        header: '',
        size: 5,
        maxSize: 5,
      },
    },
    renderToolbarAlertBannerContent: ({ groupedAlert, selectedAlert }) => {
      if (groupedAlert) return groupedAlert;
      if (selectedAlert) {
        if (selecting?.rowSelection.allPagesSelected) {
          return `${data.rowCount} of ${data.rowCount} row(s) selected`;
        }

        return selectedAlert;
      }
      return null;
    },
    enableRowActions: !!rowMenu?.menuItems,
    renderRowActions: rowMenu?.menuItems
      ? (...props) => {
          // if (rowMenu.hideMenu && !!rowMenu.hideMenu?.(...props)) return null;

          const hideMenu = rowMenu.hideMenu && !!rowMenu.hideMenu?.(...props);

          const children = rowMenu.menuItems?.(...props);

          const isMenuHidden = hideMenu || !children;

          // const hasOpenDetail = !!rowMenu.openDetail

          if (isMenuHidden && !rowMenu.openDetail) return null;

          return (
            <Group gap="sm">
              {!!rowMenu.openDetail && (
                <IconButton
                  icon="ArrowRightCircleIcon"
                  onClick={() => rowMenu.openDetail?.(props[0].row)}
                  tooltip={rowMenu.openDetailTooltip ?? 'Open quick view'}
                  variant="outline"
                />
              )}
              {isMenuHidden ? null : (
                <EllipsisMenu
                  width={rowMenu.width}
                  data-testid={`row-menu-${props[0].row.index}`}
                >
                  {children}
                </EllipsisMenu>
              )}
            </Group>
          );
        }
      : undefined,
    mantineTableContainerProps: ({ table }) => ({
      ref: infinite?.tableContainerRef, //get access to the table container element
      onScroll: infinite?.fetchMoreOnBottomReached
        ? (
            event: UIEvent<HTMLDivElement> //add an event listener to the table container element
          ) => infinite.fetchMoreOnBottomReached(event.target as HTMLDivElement)
        : undefined,
      sx: {
        height:
          table.refs.topToolbarRef.current?.offsetHeight !== undefined
            ? `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)!important`
            : undefined,
        'tbody tr:last-child td': isShowingPagination
          ? undefined
          : {
              borderBottom: 'none!important',
            },
        '::-webkit-scrollbar': {
          WebkitAppearance: 'none',
          height: 6,
          width: 6,
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          height: 1,
          backgroundColor: 'rgba(0, 0, 0, .2)',
          boxShadow: '0 0 1px rgba(255, 255, 255, .3)',
        },
      },
    }),
    enableStickyHeader,
    enableStickyFooter,
    onDraggingRowChange: dragAndDrop?.onRowDragged,
    mantineRowDragHandleProps: dragAndDrop
      ? {
          onDragEnd: dragAndDrop?.onRowDrop,
        }
      : undefined,
    mantinePaperProps: {
      onDragEnter: dragAndDrop?.onTableEnter,
      sx: (theme) => ({
        border: 'none!important',
        boxShadow: 'none',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        maxHeight: '100%',
        height: '100%',
        overflow: 'hidden',
        flexBasis: 0,
        outline: dragAndDrop?.isTableHovered
          ? `2px dashed ${theme.colors[theme.primaryColor][5]}`
          : undefined,
      }),
    },
    renderBottomToolbar: () =>
      isShowingPagination &&
      pagination && (
        <LazyTablePagination pagination={pagination} rowCount={data.rowCount} />
      ),

    rowVirtualizerInstanceRef: infinite?.rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: { overscan: virtualization?.overscan || 10 },
    initialState: {
      density: 'xs',
      ...(subRows?.defaultExpanded || groupBy?.defaultExpanded
        ? { expanded: true }
        : {}),
      ...(hideExpandColumn
        ? {
            columnOrder: [
              selecting?.rowSelection ? 'mrt-row-select' : undefined,
              ...columns.map((c) => c.id || c.accessorKey || c.header),
            ].filter(hasValue),
          }
        : {}),
      ...(savedSettings?.visibility
        ? {
            columnVisibility: {
              ...savedSettings.visibility,
              [isGroupedBy || 'dummy_key']: false,
            },
          }
        : {
            columnVisibility: {
              [isGroupedBy || 'dummy_key']: false,
            },
          }),
      ...(isGroupedBy ? { grouping: [isGroupedBy] } : {}),
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    state: {
      isLoading: infinite ? loading && !data.rowCount : loading,
      showAlertBanner: false,
      draggingRow: dragAndDrop?.draggingRow,
      showProgressBars: infinite ? data?.isFetching || loading : loading,
      ...(savedSettings?.visibility || groupBy?.groupBy
        ? {
            columnVisibility: {
              ...(savedSettings?.visibility || {}),
              [isGroupedBy || 'dummy_key']: false,
            },
          }
        : {
            columnVisibility: {
              [isGroupedBy || 'dummy_key']: false,
            },
          }),
      ...(pagination?.pagination ? { pagination: pagination.pagination } : {}),
      ...(sorting?.sorting ? { sorting: sorting.sorting } : {}),
      ...(selecting?.rowSelection.rows
        ? {
            rowSelection: !selecting.rowSelection.allPagesSelected
              ? selecting.rowSelection.rows
              : {
                  ...selecting.rowSelection.rows,
                  ...Object.fromEntries(data.rows.map((row) => [row.id, true])),
                },
          }
        : {}),
      ...(isGroupedBy ? { grouping: [isGroupedBy] } : { grouping: [] }),
    },

    icons: customIcons,
  });

  return (
    <>
      {!hideTopBar && (!hideSettings || !!children) && (
        <Group justify="space-between" mb="md" wrap="nowrap">
          {children}
          {!hideSettings && (
            <LazyTableSetting
              table={table}
              columnDefs={columns}
              tableKey={t.key}
              canGroup={!!groupBy?.groupBy}
              resetFilter={resetFilter}
              sortOptions={sorting?.options}
            />
          )}
        </Group>
      )}
      {infinite && (
        <Progress
          value={100}
          striped
          animated
          styles={{
            root: {
              opacity: loading ? '1' : '0',
              transition: 'opacity 400ms ease',
            },
          }}
        />
      )}
      <MantineReactTable table={table} />
    </>
  );
}

const customIcons: Partial<MRT_Icons> = {
  IconGripHorizontal: (props: any) => (
    <Icon icon={'MoreHorizontalIcon'} {...props} size={16} />
  ),
  IconX: (props: any) => <Icon icon={'CrossIcon'} {...props} />,
};
