import { Icon, IconDefinition } from '@finalytic/icons';
import { EllipsisMenu, ExtendedCustomColors } from '@finalytic/ui';
import {
  Box,
  Center,
  Group,
  LoaderProps,
  Button as MButton,
  MantineSize,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { EmotionSx as Sx } from '@mantine/emotion';
import React, {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  PropsWithChildren,
  forwardRef,
} from 'react';
import { Link } from 'react-router-dom';

export type ButtonVariant =
  | 'transparent'
  | 'light'
  | 'primary'
  | 'default'
  | 'filled';

type BaseProps = {
  children: React.ReactNode;
  variant?: ButtonVariant;
  iconColor?: string;
  leftIconSize?: number;
  rightIconSize?: number;
  leftIcon?:
    | IconDefinition
    | ((opts: { color?: string; size?: number }) => JSX.Element);
  rightIcon?:
    | IconDefinition
    | ((opts: { color?: string; size?: number }) => JSX.Element);
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  color?: ExtendedCustomColors | 'primary';
  size?: MantineSize;
  sx?: Sx;
  menu?: MenuBaseProps & {
    items: React.ReactNode;
  };
  loaderProps?: LoaderProps;
} & Omit<ComponentPropsWithoutRef<'button'>, 'color'>;

type Props = BaseProps &
  (
    | { component?: 'button' }
    | { component?: 'a'; href: string; target?: string }
    | { component?: typeof Link; to: string }
  );

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      variant,
      leftIcon: LIcon,
      rightIcon: RIcon,
      color,
      component,
      menu,
      leftIconSize = 18,
      rightIconSize = 18,
      iconColor: ic,
      ...props
    },
    ref
  ) => {
    const { colorScheme } = useMantineColorScheme();
    const { black, colors, primaryColor, radius, white } = useMantineTheme();

    const iconColor =
      ic ||
      (color
        ? colors[color === 'primary' ? primaryColor : color][6]
        : variant === 'primary'
          ? '#fff'
          : colors.neutral[5]);

    const hasMenu = !!menu;
    const isMenuSeparate = !!props.onClick;

    let buttonComponent: React.ReactNode = null;

    const paddingRight = hasMenu ? (isMenuSeparate ? 10 : 5) : undefined;
    const borderRadius =
      hasMenu && isMenuSeparate ? `${radius.sm} 0 0 ${radius.sm}` : undefined;

    const menuComponent = (
      <Menu
        iconColor={iconColor}
        variant={variant || 'default'}
        isSeparated={isMenuSeparate}
        disabled={!!props.disabled}
        loading={!!props.loading}
        {...menu}
      >
        {menu?.items}
      </Menu>
    );

    const content = (
      <>
        <span>{children}</span>
        {!isMenuSeparate && hasMenu && menuComponent}
      </>
    );

    const LeftIcon =
      typeof LIcon === 'string' ? (
        <Icon icon={LIcon} color={iconColor} size={leftIconSize} />
      ) : LIcon ? (
        <LIcon color={iconColor} size={leftIconSize} />
      ) : undefined;
    const RightIcon =
      typeof RIcon === 'string' ? (
        <Icon icon={RIcon} color={iconColor} size={rightIconSize} />
      ) : RIcon ? (
        <RIcon color={iconColor} size={rightIconSize} />
      ) : undefined;

    if (variant === 'transparent' || variant === 'light') {
      const textColor = color
        ? colors[color === 'primary' ? primaryColor : color][6]
        : colorScheme === 'dark'
          ? white
          : black;

      const variantColor = color || 'gray';
      buttonComponent = (
        <MButton
          ref={ref}
          component={component as any}
          variant={variant === 'light' ? 'subtle' : 'white'}
          color={variantColor}
          leftSection={LeftIcon}
          rightSection={RightIcon}
          styles={(_theme) => ({
            label: {
              fontWeight: 400,
              color: textColor,
            },
            root: {
              backgroundColor:
                variant === 'transparent' ? 'transparent' : undefined,
              ':disabled': {
                backgroundColor: 'initial',
                opacity: 0.5,
              },
            },
          })}
          pr={paddingRight}
          {...props}
        >
          {content}
        </MButton>
      );
    } else if (variant === 'filled') {
      const variantColor = color || 'gray';
      buttonComponent = (
        <MButton
          ref={ref}
          component={component as any}
          variant={'filled'}
          color={variantColor}
          leftSection={LeftIcon}
          rightSection={RightIcon}
          pr={paddingRight}
          {...props}
        >
          {content}
        </MButton>
      );
    } else if (variant === 'primary') {
      buttonComponent = (
        <MButton
          ref={ref}
          variant="filled"
          color={primaryColor}
          component={component as any}
          leftSection={LeftIcon}
          rightSection={RightIcon}
          styles={(theme) => ({
            label: {
              fontWeight: 500,
              color: 'white',
            },
            root: {
              background:
                hasMenu && isMenuSeparate
                  ? theme.colors[theme.primaryColor][6]
                  : `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), ${
                      theme.colors[theme.primaryColor][6]
                    }`,
              border:
                hasMenu && isMenuSeparate
                  ? undefined
                  : `1px solid ${
                      theme.colors[theme.primaryColor][
                        colorScheme === 'dark' ? 9 : 6
                      ]
                    }`,
              borderRadius,
              '::before': {
                borderRadius: `${borderRadius}!important`,
              },
              ':disabled': {
                opacity: 0.5,
              },
              '&:hover':
                colorScheme === 'dark'
                  ? {
                      backgroundColor: theme.colors[theme.primaryColor][7],
                    }
                  : theme.primaryColor === 'vrplatform'
                    ? undefined
                    : {
                        backgroundColor: theme.colors[theme.primaryColor][8],
                      },
            },
          })}
          pr={paddingRight}
          {...props}
        >
          {content}
        </MButton>
      );
    } else {
      buttonComponent = (
        <MButton
          ref={ref}
          variant="default"
          component={component as any}
          leftSection={LeftIcon}
          rightSection={RightIcon}
          styles={() => ({
            label: {
              fontWeight: 500,
            },
            root: {
              background: hasMenu
                ? undefined
                : colorScheme === 'dark'
                  ? undefined
                  : 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF',
              boxShadow: hasMenu
                ? undefined
                : '0px 0px 0px 1px rgba(0, 0, 0, 0.02)',
              borderRadius,
            },
          })}
          pr={paddingRight}
          {...props}
        >
          {content}
        </MButton>
      );
    }

    if (!isMenuSeparate && hasMenu)
      return (
        <Menu
          target={buttonComponent}
          iconColor={iconColor}
          variant={variant || 'default'}
          isSeparated={isMenuSeparate}
          disabled={!!props.disabled}
          loading={!!props.loading}
          {...menu}
        >
          {menu?.items}
        </Menu>
      );

    if (isMenuSeparate && hasMenu) {
      return (
        <Group wrap="nowrap" gap={0} sx={{ alignItems: 'stretch' }}>
          {buttonComponent}
          {isMenuSeparate && hasMenu && menuComponent}
        </Group>
      );
    }

    return buttonComponent;
  }
);

type MenuBaseProps = {
  trigger?: 'click' | 'hover';
  width?: number | 'target';
  zIndex?: number;
};

type MenuProps = MenuBaseProps & {
  iconColor: string;
  target?: React.ReactNode;
  variant: ButtonVariant;
  isSeparated: boolean;
  disabled: boolean;
  loading: boolean;
};

const Menu = ({
  trigger = 'hover',
  children,
  iconColor,
  target,
  width,
  zIndex,
  variant,
  isSeparated,
  disabled,
  loading,
}: PropsWithChildren<MenuProps>) => {
  const { colors, primaryColor, radius } = useMantineTheme();

  return (
    <EllipsisMenu
      width={width}
      trigger={trigger}
      zIndex={zIndex}
      disabled={disabled || loading}
      withinPortal
      withArrow
      target={
        target ? (
          <Box>{target}</Box>
        ) : (
          <Center
            px={isSeparated ? 5 : undefined}
            pl={isSeparated ? undefined : 5}
            ml={isSeparated ? undefined : 10}
            sx={{
              ...(variant === 'primary' && {
                borderLeft: '1px solid',
                borderLeftColor: colors.neutral[3],
              }),
              ...(variant === 'default' && {
                borderRight: '1px solid',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: colors.neutral[3],
              }),
              flex: 1,
              height: isSeparated ? undefined : '100%',
              borderRadius: `0 ${radius.sm} ${radius.sm} 0`,
              opacity: disabled || loading ? 0.5 : undefined,
              backgroundColor: disabled
                ? '#adb5bd'
                : !isSeparated
                  ? undefined
                  : variant === 'primary'
                    ? colors[primaryColor][6]
                    : undefined,
              cursor: disabled || loading ? undefined : 'pointer',
              ':hover':
                isSeparated && !(disabled || loading)
                  ? {
                      backgroundColor:
                        variant === 'primary'
                          ? colors[primaryColor][7]
                          : variant === 'default'
                            ? colors.gray[0]
                            : undefined,
                    }
                  : undefined,
            }}
          >
            <Icon icon="ChevronIcon" size={20} color={iconColor} />
          </Center>
        )
      }
    >
      {children}
    </EllipsisMenu>
  );
};
