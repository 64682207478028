import { useTeamId } from '@finalytic/data';
import { showErrorNotification } from '@finalytic/ui';
import { useState } from 'react';

export function useFileDownload() {
  const [teamId] = useTeamId();
  const [loading, setLoading] = useState(false);

  const download = async (
    files: {
      fileId: string;
      filename: string;
    }[]
  ) => {
    try {
      setLoading(true);
      const blobs = await Promise.all(
        files.map(async (file) => {
          const response = await fetch(
            `https://files-provider.dev7695.workers.dev/download-file?id=${file.fileId}&tenantId=${teamId}`,
            {
              method: 'GET',
              headers: {
                'x-verification-key': '5db60e10-fe6f-4726-9128-d2708285892e',
              },
            }
          );

          if (!response.ok) {
            throw new Error(response.statusText);
          }

          const blob = await response.blob();

          return {
            blob,
            filename: file.filename,
          };
        })
      );

      downloadFiles(blobs);
    } catch (error: any) {
      console.error(error);
      showErrorNotification({
        title: error?.name || 'Failed to download',
        message: error?.message || 'Missing download url.',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    download,
    loading: loading,
  };
}

function downloadFiles(
  files: {
    blob: Blob;
    filename: string;
  }[]
) {
  function download_next(i: number) {
    if (i >= files.length) {
      return;
    }
    const href = window.URL.createObjectURL(files[i].blob);
    const a = document.createElement('a');

    a.href = href;
    a.target = '_parent';
    // Use a.download if available, it prevents plugins from opening.
    if ('download' in a) {
      a.download = files[i].filename;
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    }
    // Delete the temporary link.
    a.parentNode?.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(() => {
      download_next(i + 1);
    }, 500);
  }
  // Initiate the first download.
  download_next(0);
}
