import { listing as Listing, listing_status_enum } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';

export const getListingStatus = (
  listing: Maybe<Pick<Listing, 'status' | 'pmsStatus'>>
): listing_status_enum | 'pmsDisabled' => {
  if (!listing) return 'active';

  if (listing.status) return listing.status;

  // TODO: VRP-4868 remove enabled/disabled
  if (listing.pmsStatus === 'disabled' || listing.pmsStatus === 'inactive')
    return 'pmsDisabled';

  return 'active';
};
