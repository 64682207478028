import { CSSObject } from '@emotion/serialize';
import { Avatar } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ReactNode } from 'react';

type Props = {
  leftIcon: string | ReactNode;
  leftBgColor?: string;
  rightIcon?: string | ReactNode | undefined;
  rightBgColor?: string;
  size?: number;
  spacing?: number;
};

export const AvatarGroup = ({
  leftIcon,
  rightIcon,
  rightBgColor,
  leftBgColor,
  size = 100,
  spacing = 30,
}: Props) => {
  const iconStyling: EmotionSx = {
    border: '6px solid #fff',
  };

  const dropShadow =
    'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))';

  const iconBg = (bg?: string): CSSObject => ({
    ...iconStyling,
    ...(bg
      ? {
          '.mantine-Avatar-placeholder': {
            backgroundColor: bg,
          },
        }
      : undefined),
  });

  return (
    <Avatar.Group
      spacing={spacing}
      mb={10}
      sx={{
        borderRadius: size,
        filter: dropShadow,
        msFilter: dropShadow,
        MozFilter: dropShadow,
        WebkitFilter: dropShadow,
      }}
    >
      <Avatar
        src={typeof leftIcon === 'string' ? leftIcon : undefined}
        size={size}
        radius={size}
        sx={iconBg(leftBgColor) as any}
      >
        {typeof leftIcon !== 'string' && leftIcon}
      </Avatar>
      {rightIcon && (
        <Avatar
          src={typeof rightIcon === 'string' ? rightIcon : undefined}
          size={size}
          radius={size}
          sx={iconBg(rightBgColor) as any}
        >
          {typeof rightIcon !== 'string' && rightIcon}
        </Avatar>
      )}
    </Avatar.Group>
  );
};
