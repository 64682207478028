import { listing as Listing } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';

export const getListingName = (
  listing: Maybe<Listing>,
  opts?: { allowEmpty?: boolean }
) => {
  const name = listing?.name?.trim() || '';
  const title = listing?.title?.trim() || '';
  const uniqueRef = listing?.uniqueRef?.trim() || '';
  const listingConnection = listing?.connections()?.[0];
  const connectionName = listingConnection?.name?.trim() || '';

  return (
    title ||
    name ||
    uniqueRef ||
    connectionName ||
    (opts?.allowEmpty ? '' : 'No name')
  );
};
