import { LoadingIndicator } from '@finalytic/ui';
import { formatCurrency, toTitleCase } from '@finalytic/utils';
import { Group, HoverCard, Stack } from '@mantine/core';
import { Text } from '@mantine/core';
import { formatPercentage } from '@vrplatform/ui-common';
import { useExpenseLineCentTotal } from '../_hooks';
import { ExpenseData } from '../detail/useExpenseQuery';

export const ExpenseLineAmountHoverCard = ({
  line,
  currency,
  disabled,
}: {
  currency: string;
  line: ExpenseData['lines'][number];
  disabled?: boolean;
}) => {
  const { data, loading, error } = useExpenseLineCentTotal({
    ...line,
    markup: line.markup?.centTotal || 0,
    rateBehavior: line.rateBehavior || 'included',
    rateId: line.rateId || null,
    party: line.party || 'owners',
  });

  if (loading) return <LoadingIndicator size="xs" />;

  return (
    <HoverCard
      position="bottom-end"
      withArrow
      withinPortal
      width={250}
      shadow="md"
      radius="md"
      arrowOffset={10}
      disabled={disabled || loading || !!error}
    >
      <HoverCard.Target>
        <Text>
          {error?.message ?? formatCurrency((data?.centTotal ?? 0) / 100)}
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack gap="xs">
          <Group wrap="nowrap" align="flex-start">
            <Text c="gray" flex={1}>
              Applied amount
            </Text>
            <Text ta="right" fw={500}>
              {formatCurrency(line.centTotal / 100, currency)}
            </Text>
          </Group>
          {line && 'rateId' in line && !!line.rateId && (
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Tax Rate{' '}
                <Text span size="xs" c="gray">
                  ({toTitleCase(line.rateBehavior)})
                </Text>
              </Text>
              {loading ? (
                <LoadingIndicator size="xs" />
              ) : (
                <Text ta="right" fw={500}>
                  {formatPercentage(data?.rate?.percentage ?? 0)}
                </Text>
              )}
            </Group>
          )}

          <Group wrap="nowrap" align="flex-start">
            <Text c="gray" flex={1}>
              Markup
            </Text>
            <Text ta="right" fw={500}>
              {formatCurrency((line.markup?.centTotal || 0) / 100, currency)}
            </Text>
          </Group>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
