import { Filter, UserAvatar } from '@finalytic/components';
import { SearchFilter } from '@finalytic/components/src/filter/SearchFilter';
import { gqlV2, useQuery, useTeamId } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { IconButton, Logo, SelectItem, useAppName } from '@finalytic/ui';
import { Maybe, day, sortBy } from '@finalytic/utils';
import { Box, Center, Divider, Group, Text, Tooltip } from '@mantine/core';
import { formatUserName } from '@vrplatform/ui-common';
import { ComponentProps, ReactNode, useMemo, useState } from 'react';
import { ActivityIcon } from './ActivityIcon';
import { ActivityDetailPanel } from './_components';
import { ACTIVITY_START_DATE } from './_queries';
import {
  ActivityTable as ATable,
  ActivityOperation,
  ActivityPanelProps,
  ActivityRow,
} from './_types';

type Props = {
  data: ComponentProps<typeof InfiniteTable<ActivityRow>>['queryData'];
  tables: ATable[];
  setFilter: (
    filter: Partial<{
      search: string;
      actorUserId: Maybe<string>;
      op: Maybe<ActivityOperation>;
    }>
  ) => void;
  children?: ReactNode;
  renderDetailPanel?: (props: ActivityPanelProps) => {
    rows: { label: ReactNode; date: string }[];
    loading: boolean;
    error: Error | null;
  };
};

export const ActivityTable = ({
  data,
  children,
  setFilter,
  renderDetailPanel,
  tables,
}: Props) => {
  const columns = useMemo<MRT_ColumnDef<ActivityRow>[]>(
    () => [
      {
        header: 'Icon',
        maxSize: 15,
        Cell: ({ row }) => {
          return (
            <Box pos="relative">
              <UserAvatar size={33} user={row.original.actor} v2VrpIcon />
              <ActivityIcon icon={row.original.icon} />
            </Box>
          );
        },
      },
      {
        header: 'Message',
        Cell: ({ row }) => {
          const { appName } = useAppName();
          const data = row.original;
          const isSystem = !row.original.actor?.id;

          const name = isSystem ? appName : formatUserName(data.actor);
          const diff = (day(data.date) as any)?.fromNow?.();

          const canExpand = !!row.original.detailIds?.length;
          const isExpanded = row.getIsExpanded();

          const disableDateTooltip = (data.detailIds?.length || 1) > 1;

          return (
            <Group justify="space-between" wrap="nowrap" w="100%">
              <Box>
                <Text component="span" display="block" color="gray" size="xs">
                  {name}・
                  <Tooltip
                    label={day(data.date).format('MMM D, YYYY - h:mm A')}
                    withArrow
                    withinPortal
                    disabled={disableDateTooltip}
                  >
                    <Text component="span">{diff}</Text>
                  </Tooltip>
                </Text>
                {data.label}
              </Box>
              {canExpand && (
                <IconButton
                  sx={{
                    transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                    transition: 'transform 0.2s',
                  }}
                >
                  <Icon icon="ChevronIcon" size={16} />
                </IconButton>
              )}
            </Group>
          );
        },
      },
    ],
    []
  );

  const pageData = useMemo(
    () =>
      data?.data?.pages.map((page) => {
        page.list = page.list.filter((x) => x.label);
        return page;
      }),
    [data.data?.pages]
  );

  return (
    <InfiniteTable
      queryData={{ ...data, data: { pages: pageData || [] } }}
      columns={columns}
      table={{
        hideHeader: true,
        hidePagination: true,
        hideSettings: true,
        hideExpandColumn: true,
        onRowClick: {
          disabled: (row) => !row.getCanExpand(),
          handler: (row) => row.toggleExpanded(),
        },
        emptyRowsFallback: () => (
          <Center
            mt="lg"
            sx={{
              flexDirection: 'column',
            }}
          >
            <Icon
              icon="HistoryIcon"
              size={20}
              color={({ colors }) => colors.gray[5]}
            />

            <Text c="neutral" mt="sm" fw={500}>
              No activity
            </Text>
            <Text c="gray" mt="xs" size="xs">
              We couldn't find any activity since{' '}
              {day(ACTIVITY_START_DATE).format('MMM D, YYYY')}.
            </Text>
          </Center>
        ),
      }}
      resetFilter={() => {}}
      subRows={{
        defaultExpanded: false,
        getRowCanExpand: (row) => !!row.original.detailIds?.length,
        renderDetailPanel: ({ row }) => {
          if (!renderDetailPanel) return null;

          return (
            <ActivityDetailPanel
              renderDetailPanel={() =>
                renderDetailPanel({
                  isExpanded: row.getIsExpanded(),
                  row: row.original,
                })
              }
            />
          );
        },
      }}
    >
      <Group>
        <SearchFilter
          value=""
          setValue={(search) =>
            setFilter({
              search,
            })
          }
        />
        <ActorUserFilter
          setActorUserId={(v) =>
            setFilter({
              actorUserId: v,
            })
          }
          tables={tables}
        />
        <OperationFilter setOperation={(o) => setFilter({ op: o })} />
        {children && <Divider orientation="vertical" />}
        {children}
      </Group>
    </InfiniteTable>
  );
};

const ActorUserFilter = ({
  setActorUserId,
  tables,
}: {
  setActorUserId: (id: Maybe<string>) => void;
  tables: Props['tables'];
}) => {
  const [teamId] = useTeamId();
  const [actorUserId, setTemp] = useState<Maybe<string>>(undefined);
  const { appName } = useAppName();

  const queryData = useQuery(
    (q, { teamId, tables, ACTIVITY_START_DATE }) => {
      const where: gqlV2.audit_log_bool_exp = {
        tableName: { _in: tables },
        tenantId: { _eq: teamId },
        actorUserId: { _is_null: false },
        createdAt: { _gte: ACTIVITY_START_DATE },
      };

      const list = sortBy(
        q
          .auditLogs({
            where,
            order_by: [
              {
                actorUserId: 'asc_nulls_last',
              },
            ],
            distinct_on: ['actorUserId'],
          })
          .map<SelectItem>((res) => ({
            value: res.actorUserId,
            label: formatUserName(res.actorUser),
            description: res.actorUser?.email,
          })),
        'label'
      );

      return {
        list,
      };
    },
    {
      skip: !teamId,
      queryKey: ['activity', 'users'],
      variables: {
        teamId,
        tables,
        ACTIVITY_START_DATE,
      },
    }
  );

  const options = queryData.data?.list || [];

  const pinned = useMemo<SelectItem[]>(() => {
    return [
      {
        label: appName,
        value: 'system',
        icon: (
          <Center>
            <Logo width={14} background="#ffffff00" />
          </Center>
        ),
      },
    ];
  }, [appName]);

  const value =
    [...pinned, ...options].find((u) => u.value === actorUserId) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => {
        setTemp(v?.value);
        setActorUserId(v?.value);
      }}
      label="User"
      type="single"
      withinPortal
      data={{
        options,
        loading: queryData.isLoading,
      }}
      pinnedItems={[
        {
          label: appName,
          value: 'system',
          icon: (
            <Center>
              <Logo width={14} background="#ffffff00" />
            </Center>
          ),
        },
      ]}
    />
  );
};

const OperationFilter = ({
  setOperation,
}: { setOperation: (op: Maybe<ActivityOperation>) => void }) => {
  const [v, setTemp] = useState<Maybe<string>>(null);

  const options = useMemo<SelectItem<ActivityOperation>[]>(
    () => [
      {
        value: 'insert',
        label: 'Created',
        icon: <Icon icon="PlusIcon" size={16} />,
      },
      {
        value: 'update',
        label: 'Updated',
        icon: <Icon icon="Edit3Icon" size={16} />,
      },
      {
        value: 'delete',
        label: 'Deleted',
        icon: <Icon icon="MinusCircleIcon" size={16} />,
      },
    ],
    []
  );

  const value = options.find((o) => o.value === v) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => {
        setTemp(v?.value);
        setOperation(v?.value);
      }}
      label="Action"
      type="single"
      withinPortal
      data={{
        options,
      }}
    />
  );
};
