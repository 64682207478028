import { App as RootApp } from '@finalytic/router';

function App() {
  return (
    <div className="App">
      <RootApp themePrimaryColor="vrplatform" />
    </div>
  );
}

export default App;
