export const formatPercentage = (val: number | string) => {
  if (typeof val === 'string') val = Number(val);

  // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
  if (isNaN(val)) return 'Invalid percentage';

  return val.toLocaleString('en', {
    style: 'percent',
    maximumFractionDigits: 2,
  });
};
