import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { Maybe, day, ensure, sortBy, utc } from '@finalytic/utils';
import { Group } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { useMemo } from 'react';
import { useTaxStatementStatusOptions } from './useTaxStatementStatusOptions';

export const useTaxStatementListQueryFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    year: StringParam,
    status: StringParam,
  });

  return {
    filter,
    setFilter,
    year: Number(filter?.year)
      ? day().set('year', Number(filter.year)).year()
      : day().year(),
    resetFilter: () =>
      setFilter((e) => ({
        year: e.year,
        search: undefined,
      })),
  };
};

export const TaxStatementListFilter = () => {
  const { filter, setFilter } = useTaxStatementListQueryFilter();

  return (
    <Group>
      <Filter.Search
        value={filter?.search || ''}
        setValue={(v) => setFilter({ search: v })}
      />
      <TaxStatementsYearFilter />
      <StatusFilter />
    </Group>
  );
};

export const TaxStatementsYearFilter = () => {
  const { id: meId } = useMe();
  const [teamId] = useTeamId();
  const { filter, setFilter } = useTaxStatementListQueryFilter();
  const [dashboard] = useDashboard();

  const { data: months, isLoading: loading } = useQuery(
    (q, args) => {
      return q
        .ownerStatements({
          where: args.where,
          distinct_on: ['startAt'],
          order_by: [{ startAt: 'desc' }],
        })
        .map((statement) => ({
          id: statement.id,
          startAt: statement.startAt || '',
        }));
    },
    {
      queryKey: ['ownerStatements'],
      variables: {
        where: ensure<gqlV2.owner_statement_bool_exp>({
          tenantId: { _eq: teamId },

          owners:
            dashboard === 'owner'
              ? {
                  newOwner: {
                    userAccesses: {
                      userId: { _eq: meId },
                    },
                  },
                }
              : {
                  newOwnerId: {
                    _is_null: false,
                  },
                },
        }),
      },
    }
  );

  const availableMonths = useMemo<SelectItem[]>(() => {
    const sortByMonth = sortBy(months || [], (x) => x.startAt);

    return sortByMonth
      .map((i: { id: string; startAt: string }) => ({
        label: utc(i.startAt).format('YYYY'),
        value: utc(i.startAt).format('YYYY'),
      }))
      .filter(
        (i, index, self) => self.findIndex((t) => t.value === i.value) === index
      )
      .reverse();
  }, [months]);

  const value = availableMonths.find((i) => i.value === filter?.year) || null;

  useDidUpdate(() => {
    if (!value && availableMonths.length > 0)
      setFilter({ year: availableMonths[0].value }, 'replaceIn');
  }, [value, availableMonths]);

  return (
    <Filter.Select
      value={value}
      setValue={(v) => v?.value && setFilter({ year: v?.value })}
      label="Month"
      type="single"
      withinPortal
      data={{
        options: availableMonths,
        loading,
      }}
      hideClearButton
    />
  );
};

const StatusFilter = () => {
  const {
    setFilter,
    filter: { status },
  } = useTaxStatementListQueryFilter();

  return (
    <TaxStatementStatusFilter
      setValue={(v) =>
        setFilter({
          status: v,
        })
      }
      value={status}
    />
  );
};

export const TaxStatementStatusFilter = ({
  setValue,
  value: current,
}: { value: Maybe<string>; setValue: (v: string | undefined) => void }) => {
  const opts = useTaxStatementStatusOptions('draft', 'single');
  const options = useMemo<SelectItem<gqlV2.tax_statement_status_enum>[]>(
    () =>
      opts.map((x) => ({
        ...x,
        disabled: false,
      })),
    [opts]
  );

  const value = options.find((i) => i.value === current) || null;

  return (
    <Filter.Select
      value={value ? { ...value, icon: undefined } : null}
      setValue={(v) => setValue(v?.value)}
      label="Status"
      type="single"
      withinPortal
      popoverWidth={340}
      data={{
        options: options,
      }}
    />
  );
};
