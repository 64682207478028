import {
  Modal as MantineModal,
  ModalProps as MantineModalProps,
} from '@mantine/core';

export const Modal = ({
  opened,
  onClose,
  children,
  styles,
  ...modalProps
}: MantineModalProps) => {
  return (
    <MantineModal
      opened={opened}
      onClose={onClose}
      centered={true}
      radius={15}
      closeButtonProps={{
        id: 'close-modal',
      }}
      styles={{
        title: {
          fontSize: 18,
          fontWeight: 700,
          letterSpacing: '0.4px',
        },
        close: {
          borderRadius: 5,
        },
        body: { paddingBottom: 0 },
        ...styles,
      }}
      {...modalProps}
    >
      {children}
    </MantineModal>
  );
};
