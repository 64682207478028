import { Box, MantineSize } from '@mantine/core';
import { PropsWithChildren, forwardRef } from 'react';

type Props = {
  shadow?: MantineSize;
};

export const Tag = forwardRef<HTMLSpanElement, PropsWithChildren<Props>>(
  ({ children, shadow }, ref) => (
    <Box
      ref={ref}
      component="span"
      mr={5}
      sx={(theme) => ({
        border: '1px solid #EEEFF1',
        borderRadius: 5,
        padding: '2px 7px',
        backgroundColor: '#fff',
        fontSize: 13,
        lineHeight: '1rem',
        boxShadow: shadow ? theme.shadows[shadow] : undefined,
      })}
    >
      {children}
    </Box>
  )
);
