import { ExtendedCustomColors, LoadingIndicator } from '@finalytic/ui';
import { Center, MantineFontSize, Text, rem } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { forwardRef } from 'react';

type Props = {
  color?: 'red' | 'yellow' | 'green';
  count: number;
  size?: MantineFontSize;
  sx?: EmotionSx;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  loadingColor?: ExtendedCustomColors;
};

export const CountBagde = forwardRef<HTMLDivElement, Props>(
  (
    {
      count,
      color = 'red',
      size = '0.75rem',
      sx = {},
      loading,
      onClick,
      loadingColor,
    },
    ref
  ) => {
    return (
      <Center
        ref={ref}
        onClick={onClick}
        sx={(theme, u) =>
          Object.assign(
            {
              display: 'inline-flex',
              borderRadius: theme.radius.sm,
              backgroundColor: loading
                ? undefined
                : color === 'yellow' || color === 'green'
                  ? theme.colors[color][7]
                  : theme.colors.red[9],
              color: theme.white,
              fontWeight: 500,
              paddingInline: rem(4),
              paddingBlock: rem(3),
              marginLeft: theme.spacing.xs,
            },
            typeof sx === 'function' ? sx(theme, u) : sx
          )
        }
      >
        {loading ? (
          <LoadingIndicator size={'0.75rem'} color={loadingColor} />
        ) : (
          <Text component="span" size={size}>
            {count}
          </Text>
        )}
      </Center>
    );
  }
);
