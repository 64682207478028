import { z } from 'zod';

export const bank_records_csv_import_schema = z.object({
  Date: z.string().refine(
    (date) => {
      return date.match(/^\d{4}-\d{2}-\d{2}$/);
    },
    {
      message: 'Please provide a date in the format YYYY-MM-DD',
    }
  ),
  Reference: z.coerce.string({ required_error: 'Reference is required' }),
  Description: z.coerce.string({ required_error: 'Description is required' }),
  Amount: z.number({
    required_error: 'Amount is required',
    invalid_type_error: 'Amount must be a number',
  }),
  Balance: z.number({}).optional(),
});
