export function getActionMessage(
  message: string,
  overwrites: {
    pattern: string;
    message: string;
  }[]
) {
  // check if message matches any of the overwrites
  let m = message;

  for (const overwrite of overwrites) {
    const regex = globToRegex(overwrite.pattern);

    if (new RegExp(regex, 'g').test(message)) {
      m = overwrite.message;
      break;
    }
  }

  return m;
}

export function globToRegex(pattern: string) {
  // Escape regex special characters
  const specialChars = '\\^$+{}[]|()'.split('');
  let regex = '';

  for (let i = 0; i < pattern.length; i++) {
    const c = pattern[i];
    if (c === '*') {
      regex += '.*';
    } else if (c === '?') {
      regex += '.';
    } else if (c === '[') {
      // Handle character classes and negations
      let j = i;
      if (pattern[j + 1] === '!') {
        j++;
        regex += '[^';
      } else {
        regex += '[';
      }
      while (pattern[j] !== ']') {
        j++;
        regex += pattern[j];
      }
      i = j;
    } else if (specialChars.includes(c)) {
      // Escape special regex characters
      regex += `\\${c}`;
    } else {
      regex += c;
    }
  }

  // Match the whole string
  return `${regex}`;
}
