import { useInfiniteQuery } from '@finalytic/data';
import { MRT_SortingState } from '@finalytic/table';
import { useWherePayments } from './useWherePayments';

type Params = {
  sorting: MRT_SortingState;
};

export type PaymentRow = NonNullable<
  ReturnType<typeof usePaymentTableQuery>['data']
>['pages'][number]['list'][number];

export const usePaymentTableQuery = ({ sorting }: Params) => {
  const where = useWherePayments();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const aggregate = q.paymentAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .payments({
          where,
          limit,
          offset,
          order_by,
        })
        .map((item) => {
          return {
            id: item.id,
            payedAt: item.payedAt,
            type: item.type,
            centTotal: item.centTotal,
            sourceId: item.sourceId,
            currency: item.currency,
            tenant: {
              id: item.tenant?.id,
              name: item.tenant?.name,
              logo: item.tenant?.logo,
              colorPrimary: item.tenant?.colorPrimary,
            },
            connection: {
              id: item.connection?.id,
              name: item.connection?.name,
              appId: item.connection?.appId,
              app: {
                id: item.connection?.app?.id,
                iconRound: item.connection?.app?.iconRound,
              },
            },
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'payments',
      variables: { where, sorting },
    }
  );
};
