import { listing_bool_exp } from '@finalytic/graphql';
import { whereListingIsActive } from './whereListingIsActive';

export function whereListingIsExcluded(
  automationId: string | string[]
): listing_bool_exp {
  return {
    ...whereListingIsActive(),
    _not: {
      settingsRight: {
        key: {
          _eq: 'exclude',
        },
        automationId: Array.isArray(automationId)
          ? { _in: automationId }
          : {
              _eq: automationId,
            },
        value: {
          _eq: 'true',
        },
      },
    },
  };
}
