import { StringParam, UrlUpdateType, useQueryParams } from '@finalytic/ui';

export function useDepositDetailDrawer() {
  const [opts, set] = useQueryParams({
    deposit: StringParam,
  });

  return {
    opened: !!opts.deposit,
    open: (id: string, type?: UrlUpdateType) => set({ deposit: id }, type),
    close: () => set({ deposit: undefined }),
    depositId: opts.deposit,
  };
}
