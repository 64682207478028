import { useAuth, useSignIn } from '@clerk/clerk-react';
import {
  StringParam,
  showErrorNotification,
  useQueryParam,
} from '@finalytic/ui';
import { useEffect } from 'react';

export function useSignInToken() {
  const { signOut, isSignedIn } = useAuth();
  const { signIn, setActive: setSignInActive } = useSignIn();

  const [token, setToken] = useQueryParam('__clerk_ticket', StringParam);

  useEffect(() => {
    if (token) loginWithToken(token);
  }, [token]);

  // Handle email with password flow
  const loginWithToken = async (tok: string) => {
    if (!signIn) return console.error('no sign in with token');

    if (isSignedIn) await signOut();

    await signIn
      .create({
        strategy: 'ticket',
        ticket: tok,
      })
      .then((result) => {
        if (result.status === 'complete') {
          setSignInActive({ session: result.createdSessionId });
          setToken(null);
          window.location.reload();
        }
      })
      .catch((err) => {
        const errorMessage =
          err.errors?.[0]?.longMessage || 'Please try again.';
        console.error('error', errorMessage);
        setToken(null);
        showErrorNotification({
          title: "We couldn't sign you up.",
          message: errorMessage,
        });
      });
  };

  return {
    tokenIsActive: !!token,
  };
}
