import { StringParam, UrlUpdateType, useQueryParams } from '@finalytic/ui';

export function useExpenseDetailDrawer() {
  const [opts, set] = useQueryParams({
    expense: StringParam,
  });

  return {
    opened: !!opts.expense,
    open: (id: string, type?: UrlUpdateType) => set({ expense: id }, type),
    close: () => set({ expense: undefined }),
    expenseId: opts.expense,
  };
}
