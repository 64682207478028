import { StringParam, UrlUpdateType, useQueryParams } from '@finalytic/ui';

type View = 'overview' | 'edit';

export function useOwnerUserDetailDrawer() {
  const [opts, set] = useQueryParams({
    'owner-user': StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts['owner-user'],
    open: (id: string, view?: 'overview' | 'edit', type?: UrlUpdateType) =>
      set({ 'owner-user': id, view }, type),
    close: () => set({ 'owner-user': undefined, view: undefined }),
    ownerId: opts['owner-user'],
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}
