import { LoadingIndicator, showErrorNotification } from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { Autocomplete } from '@mantine/core';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const GOOGLE_MAPS_KEY = 'AIzaSyBTcW3kF8xwTfQPMCgZENHrUfHjO9w4Wy8';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onAddressSelect: (address: {
    line1: string;
    city: string;
    postcode: string;
    stateCode: string;
    countryCode: string;
  }) => void;
  placeholder?: string;
  error?: boolean;
  countryCode: string;
};

export const InputAddressAutocomplete = ({
  value: input,
  onChange: setInput,
  onAddressSelect: onChange,
  placeholder,
  error,
  countryCode,
}: Props) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: GOOGLE_MAPS_KEY,
    options: {
      types: ['address'],
      fields: ['address_components'],
      componentRestrictions: {
        country: countryCode,
      },
    },
  });

  const [loadingMutation, setLoadingMutation] = useState(false);

  const data = useMemo<{ value: string; label: string }[]>(
    () =>
      placePredictions.map((place) => {
        return {
          value: place.place_id,
          label: place.description,
        };
      }),
    [placePredictions]
  );

  const [debouncedSearch] = useDebouncedValue(input, 500, { leading: true });

  const updateValue = async (newValue: Maybe<string>) => {
    if (!newValue?.trim()) return;
    try {
      setLoadingMutation(true);
      await placesService?.getDetails(
        {
          placeId: newValue,
          fields: ['address_component'],
          language: 'en',
        },
        (place: any) => {
          const components: {
            long_name: string;
            short_name: string;
            types: string[];
          }[] = place?.address_components || [];

          const city =
            components.find((item) => item.types.includes('locality'))
              ?.long_name || '';
          const countryCode =
            components.find((item) => item.types.includes('country'))
              ?.short_name || '';
          const postcode =
            components.find((item) => item.types.includes('postal_code'))
              ?.long_name || '';
          const line1 =
            components.find((item) => item.types.includes('route'))
              ?.long_name || '';
          const streeNumber =
            components.find((item) => item.types.includes('street_number'))
              ?.long_name || '';
          // find state
          const stateCode =
            components.find((item) =>
              item.types.includes('administrative_area_level_1')
            )?.short_name || '';

          const newAddress = {
            city,
            countryCode,
            postcode,
            line1: `${streeNumber} ${line1}`.trim(),
            stateCode,
          };

          onChange(newAddress);
        }
      );
    } catch (error: any) {
      console.error(error);
      showErrorNotification({
        message: error?.message || 'Failed to fetch address',
        icon: null,
        color: 'yellow',
      });
    } finally {
      setLoadingMutation(false);
    }
  };

  useDidUpdate(() => {
    if (!debouncedSearch.trim()) return;
    getPlacePredictions({
      input: debouncedSearch,
      componentRestrictions: { country: countryCode },
    });
  }, [debouncedSearch, countryCode]);

  return (
    <Autocomplete
      value={input}
      onChange={setInput}
      error={!!error}
      data={data}
      // filter={(value) => !!value}
      onOptionSubmit={(item) => updateValue(item)}
      sx={(theme) => ({
        height: '100%',
        input: {
          '&:focus': {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[theme.primaryColor][4]
            }40`,
          },
        },
      })}
      comboboxProps={{ zIndex: 400, shadow: 'sm' }}
      placeholder={placeholder}
      rightSection={
        (loadingMutation || isPlacePredictionsLoading) && (
          <LoadingIndicator size="xs" />
        )
      }
    />
  );
};
