import { Maybe } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { Group } from '@mantine/core';
import { useState } from 'react';
import { IconButton } from './button/IconButton';

export const TaxId = ({ taxId }: { taxId: Maybe<string> }) => {
  const [visible, setVisible] = useState(false);

  const id = visible ? taxId : `•••••${taxId?.slice(-4)}`;

  if (!taxId) return null;

  return (
    <Group wrap="nowrap" gap={5} pb={2}>
      <Text component="span" pt={2}>
        {id}
      </Text>
      <IconButton
        icon={visible ? 'EyeSlashIcon' : 'EyeIcon'}
        size={18}
        onClick={() => setVisible((e) => !e)}
      />
    </Group>
  );
};
