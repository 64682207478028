import { Button } from '@finalytic/components';
import { useAutomationModal } from '@finalytic/data-ui';
import { RocketIcon } from '@finalytic/icons';
import { Maybe, sortBy } from '@finalytic/utils';
import {
  Avatar,
  Card,
  Center,
  Checkbox,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Title,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useMemo, useState } from 'react';

type Automation = {
  automationId: string;
  title: Maybe<string>;
  connections: {
    [appId: string]: string;
  };
  leftConnectionId: string | undefined;
  leftConnection:
    | {
        id: string | undefined | null;
        name: string | undefined | null;
        icon: string | undefined | null;
      }
    | undefined;
  rightConnectionId: string | undefined;
  rightConnection:
    | {
        id: string | undefined | null;
        name: string | undefined | null;
        icon: string | undefined | null;
      }
    | undefined;
};

type Props = {
  opened: boolean;
  onClose: () => void;
  onAutomationSubmit: (automationId: string[]) => Promise<any>;
  automations: Automation[];
  type: Parameters<typeof useAutomationModal>[0];
};

export const SelectPostAutomationModal = ({
  opened,
  onClose,
  automations,
  onAutomationSubmit,
  type,
}: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const { colors, primaryColor } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const isDarkTheme = colorScheme === 'dark';

  const selectAutomation = (automationId: string, val: boolean) => {
    if (val) {
      return setSelected([...selected, automationId]);
    }
    setSelected(selected.filter((id) => id !== automationId));
  };

  const submit = async () => {
    setLoading(true);

    await onAutomationSubmit(selected)
      .then(() => {
        setSelected([]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formattedType = type.split('.').reverse()[0].toLowerCase();

  const sorted = useMemo(
    () => sortBy(automations, (x) => x.title || ''),
    [automations]
  );

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={550}
      zIndex={600}
      styles={() => ({
        body: {
          padding: rem(48),
        },
      })}
      centered
    >
      <Stack
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
        }}
        mb="lg"
      >
        <Center>
          <RocketIcon size={24} color={colors[primaryColor][6]} />
        </Center>
        <Title order={3}>Push {formattedType}s to automations?</Title>
        <Text
          color={isDarkTheme ? undefined : 'neutral'}
          component="p"
          m={0}
          size="sm"
        >
          What automation would you like to run
          <br />
          with the selected {formattedType}s?
        </Text>
      </Stack>

      <ScrollArea.Autosize
        // placeholder="Automations"
        mah={400}
        mb="lg"
        // onPointerEnterCapture={undefined}
        // onPointerLeaveCapture={undefined}
      >
        <Stack h="100%">
          {sorted.map((automation) => (
            <AutomationRow
              key={automation.automationId}
              automation={automation}
              selectAutomation={selectAutomation}
              isSelected={
                !!selected.find((id) => id === automation.automationId)
              }
            />
          ))}
        </Stack>
      </ScrollArea.Autosize>

      <Button
        variant="primary"
        disabled={!selected.length}
        loading={loading}
        onClick={submit}
        sx={{
          width: '100%',
        }}
      >
        Push to selected automations{' '}
        {selected.length ? `(${selected.length})` : ''}
      </Button>
    </Modal>
  );
};

const AutomationRow = ({
  automation,
  isSelected,
  selectAutomation,
}: {
  automation: Automation;
  selectAutomation: (automationId: string, value: boolean) => void;
  isSelected: boolean;
}) => {
  const { colorScheme } = useMantineColorScheme();

  const getIcon = () => {
    const appId = Object.keys(automation.connections).filter(
      (x) => x !== 'finalytic'
    );
    if (!appId) return undefined;
    const connectionId = automation.connections[appId[0]];

    const connection =
      automation.leftConnectionId === connectionId
        ? automation.leftConnection
        : automation.rightConnection;

    if (!connection) return undefined;
    return connection.icon;
  };

  const select = () => selectAutomation(automation.automationId, !isSelected);

  return (
    <Card
      w={'100%'}
      onClick={select}
      radius="md"
      withBorder
      py={'xs'}
      sx={(theme) => ({
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing.md,
        transition: 'all 0.1s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
          // boxShadow: theme.shadows.xl,
          backgroundColor: `${theme.colors[theme.primaryColor][0]}${
            colorScheme === 'dark' ? '20' : '50'
          }`,
        },
      })}
    >
      <Checkbox
        checked={isSelected}
        onChange={() => {
          select();
        }}
        styles={{
          input: {
            cursor: 'pointer',
          },
        }}
      />
      <Avatar src={getIcon()} radius="xl" size="md" />
      <Text ta="center" size="sm">
        {automation.title}
      </Text>
    </Card>
  );
};
