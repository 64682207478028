import { ButtonProps, Button as MantineButton } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type Props = ButtonProps & {
  component?: any;
  href?: string;
  target?: string;
  to?: string;
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef(
  (
    { children, leftSection, sx, component = 'button', ...props }: Props,
    ref
  ) => {
    return (
      <MantineButton
        component={component}
        variant="outline"
        color="dark"
        radius={10}
        px="sm"
        leftSection={leftSection}
        sx={
          {
            borderColor: '#EEEFF1',
            ...sx,
          } as any
        }
        {...props}
        ref={ref}
      >
        {children}
      </MantineButton>
    );
  }
);

Button.displayName = 'Button';
