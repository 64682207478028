import { Filter } from '@finalytic/components';
import { gqlV2, useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { Avatar, Group } from '@mantine/core';
import { useMemo, useState } from 'react';

export const useConnectionFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    status: StringParam,
    appId: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () =>
      setFilter({
        search: undefined,
        status: undefined,
        appId: undefined,
      }),
  };
};

export const ConnectionFilter = ({
  hide,
}: {
  hide?: 'status'[];
}) => {
  const { filter, setFilter } = useConnectionFilter();

  return (
    <Group>
      <Filter.Search
        value={filter.search || ''}
        setValue={(v) => setFilter({ search: v })}
      />
      <AppFilter />
      {!hide?.includes('status') && <StatusFilter />}
    </Group>
  );
};

const AppFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useConnectionFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.connection_bool_exp = {
        tenantId: { _eq: teamId },
        appId: { _is_null: false, _neq: 'finalytic' },
        _or: search
          ? [
              {
                appId: { _ilike: `%${search}%` },
              },
              {
                app: { name: { _ilike: `%${search}%` } },
              },
            ]
          : undefined,
      };

      const list = q
        .connection({
          where,
          order_by: [{ appId: 'asc' }],
          limit,
          offset,
          distinct_on: ['appId'],
        })
        .map<SelectItem>((res) => ({
          value: res.appId!,
          label: res.app.name!,
          icon: res.app.iconRound && (
            <Avatar
              src={res.app.iconRound}
              size={16}
              mt={2}
              styles={{
                placeholder: { visibility: 'hidden' },
              }}
            />
          ),
        }));

      const aggregate =
        q
          .connectionAggregate({ where, distinct_on: ['appId'] })
          .aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'connections'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { appId }) => {
      const value = q
        .app({ where: { id: { _eq: appId } }, limit: 1 })
        .map<SelectItem>((item) => ({
          label: item.name || '',
          value: item.id!,
          icon: item.iconRound && (
            <Avatar
              src={item.iconRound}
              size="sm"
              styles={{
                placeholder: { visibility: 'hidden' },
              }}
            />
          ),
        }))?.[0];

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'connections'],
      variables: {
        appId: filter.appId,
      },
    }
  );

  const value = filter.appId ? data?.value || null : null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ appId: v?.value })}
      label="App"
      type="single"
      hideIcon
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

// const StatusFilter = () => {
//   const [teamId] = useTeamId();
//   const { filter, setFilter } = useConnectionFilter();

//   const [limit, setLimit] = useState(50);
//   const [search, setSearch] = useState('');

//   const { data, isLoading: loading } = useQuery(
//     (q, { limit, teamId, search }) => {
//       const where: gqlV2.connection_bool_exp = {
//         tenantId: { _eq: teamId },
//         status: { _is_null: false },
//         _or: search
//           ? [
//               {
//                 status: { _ilike: `%${search}%` },
//               },
//             ]
//           : undefined,
//       };

//       const data = q
//         .connection({
//           where,
//           order_by: [{ status: 'asc' }],
//           limit,
//           distinct_on: ['status'],
//         })
//         .map((res) => res.status!);

//       return {
//         data,
//         value,
//       };
//     },
//     {
//       skip: !teamId,
//       queryKey: ['connections'],
//       variables: {
//         teamId,
//         limit,
//         search: search?.trim(),
//       },
//     }
//   );

//   const value = filter.status
//     ? ensure<SelectItem>({
//         value: filter.status,
//         label: toTitleCase(filter.status)!,
//       })
//     : null;

//   const options = useMemo(() => {
//     const statuses = data?.data || [];

//     const includesDeleted =
//       statuses.includes('archived') || statuses.includes('deleted');

//     const extended = includesDeleted
//       ? statuses.filter((i) => !['archived', 'deleted'].includes(i))
//       : statuses;

//     return (
//       extended.map<SelectItem>((status) => ({
//         label: toTitleCase(status)!,
//         value: status,
//       })) || []
//     );
//   }, [data?.data]);

//   return (
//     <Filter.Select
//       value={value}
//       setValue={(v) => setFilter({ status: v?.value })}
//       label="Status"
//       type="single"
//       withinPortal
//       data={{
//         options,
//         loading,
//         count: options.length,
//         onFetchMore: setLimit,
//         onSearchChange: setSearch,
//       }}
//     />
//   );
// };

const StatusFilter = () => {
  const { filter, setFilter } = useConnectionFilter();

  const options = useMemo<SelectItem[]>(
    () => [
      {
        label: 'Active',
        value: 'active',
        icon: (
          <Avatar size="xs" radius="xl" color={'green'} pt={3}>
            <Icon
              size={12}
              icon={'CheckIcon'}
              color={(theme) => theme.colors.green[7]}
            />
          </Avatar>
        ),
      },
      {
        label: 'Pending',
        value: 'pending',
        icon: (
          <Avatar pt={3} size="xs" radius="xl" color={'yellow'}>
            <Icon
              size={12}
              icon={'RefreshCwIcon'}
              color={(theme) => theme.colors.yellow[7]}
            />
          </Avatar>
        ),
      },
      {
        label: 'Error',
        value: 'error',
        icon: (
          <Avatar pt={3} size="xs" radius="xl" color={'red'}>
            <Icon
              size={12}
              icon={'CrossIcon'}
              color={(theme) => theme.colors.red[7]}
            />
          </Avatar>
        ),
      },
    ],
    []
  );

  const value = useMemo<SelectItem | null>(() => {
    if (!filter?.status) return null;

    return options.find((i) => i.value === filter?.status) || null;
  }, [filter?.status, options]);

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ status: v?.value })}
      label="Status"
      type="single"
      withinPortal
      hideIcon
      // infiniteData={{ ...queryData, setSearch }}
      data={{
        options,
      }}
    />
  );
};
