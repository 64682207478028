import { create } from 'zustand';

type State = {
  mappings: {
    [teamId: string]: {
      [settingKey: string]: {
        count: number;
        hasDefaultSetting: boolean;
      };
    };
  };
};

type Actions = {
  setMapping: (
    teamId: string,
    settingKey: string,
    mapping: State['mappings'][string][string]
  ) => void;
  setMappings: (teamId: string, mappings: State['mappings'][string]) => void;
  getState: () => State['mappings'];
  reset: (teamId?: string) => void;
};

const initialState: State = {
  mappings: {},
};

export const useMissingMappingStore = create<State & Actions>()(
  (set, _get) => ({
    ...initialState,
    getState: () => _get().mappings,
    setMapping: (teamId, settingKey, mapping) => {
      set((state) => ({
        mappings: {
          ...state.mappings,
          [teamId]: {
            ...(state.mappings[teamId] || {}),
            [settingKey]: mapping,
          },
        },
      }));
    },
    setMappings: (teamId, mappings) => {
      set((state) => {
        return {
          ...state,
          mappings: {
            ...state.mappings,
            [teamId]: { ...state.mappings[teamId], ...mappings },
          },
        };
      });
    },
    reset: (teamId) => {
      set((state) => {
        if (!teamId) return initialState;

        state.mappings[teamId] = {};
        return state;
      });
    },
  })
);
