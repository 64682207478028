import { InputSelect, InputWrapper } from '@finalytic/components';
import { useInfiniteQuery } from '@finalytic/data';
import { day } from '@finalytic/utils';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

export const MonthSelect = ({
  label,
  formKey,
  defaultValue,
  onValueChange,
}: FormInputBaseType) => {
  const { control, setValue } = useFormContext();

  const queryData = useInfiniteQuery(
    (_q, _v, { limit, offset }) => {
      const maxAmount = limit + offset;

      const dates =
        Array(maxAmount)
          .fill(new Date())
          .map((i: Date, index) => {
            const d = day(i).add(6 - index, 'months');
            return {
              label: d.format('MMMM YYYY'),
              value: `${d.format('YYYY-MM')}-01`,
              group: `${d.format('YYYY')}`, // Add space at the front to prevent incorrect group sorting
            };
          }) || [];

      return { list: dates.slice(offset), aggregate: 12 * 11 };
    },
    {
      pageLimit: 36,
    }
  );

  useEffect(() => {
    setValue(formKey, defaultValue);
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={formKey}
      defaultValue={defaultValue || day().format('YYYY-MM-01')}
      rules={{ required: true }}
      render={({ field }) => {
        const value = field.value ? formatValue(field.value) : null;

        return (
          <InputWrapper label={label}>
            <InputSelect
              infiniteData={{ ...queryData, setSearch: () => {} }}
              value={value}
              type="single"
              setValue={(newValue) => {
                if (!newValue?.value) return;
                onValueChange?.(formKey, newValue.value);
                field.onChange(newValue.value);
              }}
              dropdownProps={{
                withinPortal: true,
                width: 'target',
                hideSearch: true,
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

const formatValue = (v: string) => ({
  label: day(v).format('MMMM YYYY'),
  value: `${day(v).format('YYYY-MM')}-01`,
  group: `${day(v).format('YYYY')}`, // Add space at the front to prevent incorrect group sorting
});
