import { Badge } from '@finalytic/components';
import type { paidStatus_enum } from '@finalytic/graphql';
import type { ExtendedCustomColors } from '@finalytic/ui';
import { type Maybe, formatCurrency } from '@finalytic/utils';

type Props = {
  paidStatus: Maybe<paidStatus_enum>;
  paymentsCentTotal: number;
  reservationCentTotal: number;
  currency: string;
};

const OPTIONS: Record<
  paidStatus_enum,
  {
    label: string;
    color: ExtendedCustomColors;
  }
> = {
  unpaid: {
    label: 'Unpaid',
    color: 'red',
  },
  underpaid: {
    label: 'Underpaid',
    color: 'yellow',
  },
  overpaid: {
    label: 'Overpaid',
    color: 'red',
  },
  paid: {
    label: 'Paid',
    color: 'green',
  },
};

export const ReservationPaymentStatusBadge = ({
  paidStatus,
  currency,
  paymentsCentTotal,
  reservationCentTotal,
}: Props) => {
  if (!paidStatus) return null;

  const { label, color } = OPTIONS[paidStatus];

  const balance = reservationCentTotal - paymentsCentTotal;

  if (paidStatus === 'paid') {
    return <Badge color={color}>Paid</Badge>;
  }

  if (paidStatus === 'unpaid') {
    return <Badge color={color}>Unpaid</Badge>;
  }

  if (paidStatus === 'overpaid') {
    return (
      <Badge color={color}>
        Overpaid ({formatCurrency(balance / 100, currency)})
      </Badge>
    );
  }

  if (paidStatus === 'underpaid') {
    return (
      <Badge color={color} tooltip="Not fully paid">
        {formatCurrency(balance / 100, currency)}
      </Badge>
    );
  }

  return <Badge color={color}>{label}</Badge>;
};
