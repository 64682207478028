import { setting_bool_exp } from '@finalytic/graphql';

export const whereListingIsExcludedSetting = ({
  automationIds,
  listingId,
}: {
  listingId: string | undefined;
  automationIds: string[] | undefined;
}): setting_bool_exp => ({
  automationId:
    automationIds === undefined ? undefined : { _in: automationIds },
  target: listingId ? { _eq: listingId } : undefined,
  key: { _eq: 'exclude' },
  leftType: {
    _eq: 'finalytic.listing',
  },
});
