import { useMantineTheme } from '@mantine/core';

export const useLegacyColors = () => {
  const { colors: themeColors, primaryColor } = useMantineTheme();

  return {
    primaryColor,
    themeColors,
    green: {
      base: themeColors.green[6], // old: '#26BF66'
    },
    red: {
      base: themeColors.red[6],
    },
    blue: {
      light: '#0EA5E9',
    },
    black: {
      base: '#141719',
    },
    purple: {
      base: '#8330D5',
      light: '#6c29af',
    },
    gold: {
      base: '#FFD700',
    },
    gray: {
      dark: '#5C6178',
      light: '#D9D9D9',
    },
    border: {
      gray: '#E6E7EA',
      input: '#CED0D7',
    },
  };
};
