import { useInfiniteQuery, useTeamId } from '@finalytic/data';
import type {
  Query,
  accountClassification_enum,
  journalEntry_bool_exp,
  journalEntry_order_by,
  party_enum,
} from '@finalytic/graphql';
import { Maybe, formatCurrency, toTitleCase } from '@finalytic/utils';
import {
  JournalEntryType,
  formatOwnerName,
  getJournalEntryType,
  getListingName,
  whereJournalEntries,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useJournalEntriesModalWhere } from '../../modals/journal-entries-modal';
import { useGeneralLedgerDetailFilter } from './GeneralLedgerDetailFilter';

export type GeneralLedgerDetailRow = NonNullable<
  ReturnType<typeof useGeneralLedgerDetailTableQuery>['data']
>['pages'][number]['list'][number];

export const generalLedgerSorting: journalEntry_order_by[] = [
  {
    txnAt: 'desc_nulls_last',
  },
  {
    txnNum: 'asc_nulls_last',
  },
  {
    centTotal: 'desc_nulls_last',
  },
];
export const useWhereGeneralLedgerDetail = () => {
  const [teamId] = useTeamId();

  const { filter } = useGeneralLedgerDetailFilter();

  const search = (filter.search || '').trim();

  const whereJournalEntryModal = useJournalEntriesModalWhere();

  return useMemo<journalEntry_bool_exp>(() => {
    const whereByFilter = whereJournalEntries({
      date: filter.date,
      tenantId: teamId,
      type: filter.type as JournalEntryType,
      search,
      party: filter.party as Maybe<party_enum>,
      listingId: filter.listingId,
      accountClassification: filter.accountClassification as
        | accountClassification_enum
        | undefined
        | null,
      accountId: filter.accountId,
      status: filter.status as 'draft' | 'published' | undefined | null,
    });

    if (whereJournalEntryModal) {
      return {
        _and: [whereByFilter, whereJournalEntryModal],
      };
    }

    return whereByFilter;
  }, [
    teamId,
    search,
    filter.date,
    filter.listingId,
    filter.party,
    filter.type,
    filter.accountId,
    filter.accountClassification,
    filter.status,
    whereJournalEntryModal,
  ]);
};

export const getGeneralLedgerDetailRows = (
  q: Query,
  args: {
    where: journalEntry_bool_exp;
    teamId: string;
    limit: number | undefined;
    offset: number | undefined;
  }
) =>
  q
    .journalEntries({
      where: args.where,
      limit: args.limit,
      offset: args.offset,
      order_by: generalLedgerSorting,
    })
    .map((item) => {
      const period = item.listingOwnershipPeriod;

      const centTotal = item.centTotal || 0;
      const currency = item.currency || 'usd';
      const debit =
        centTotal > 0 ? formatCurrency(centTotal / 100, currency) : '';
      const credit =
        centTotal < 0 ? formatCurrency(centTotal / 100, currency) : '';

      const transaction = {
        id: item.transaction?.id,
        uniqueRef: item.transaction?.uniqueRef,
        type: item.transaction?.type,
        paidStatus: item.transaction?.paidStatus,
      };

      return {
        id: item.id,
        status: item.status,
        reservation: {
          id: item.reservation?.id,
          checkIn: item.reservation?.checkIn,
          confirmationCode: item.reservation?.confirmationCode,
        },
        transaction,
        attachedToOwnerStatement: {
          id: item.attachedToOwnerStatementId,
          startAt: item.attachedToOwnerStatement?.startAt,
          currency: item.attachedToOwnerStatement?.currency,
          listingOwnershipPeriodId:
            item.attachedToOwnerStatement?.listingOwnershipPeriodId,
        },
        transferOwnerStatement: {
          id: item.transferOwnerStatementId,
          startAt: item.transferOwnerStatement?.startAt,
          currency: item.transferOwnerStatement?.currency,
          listingOwnershipPeriodId:
            item.transferOwnerStatement?.listingOwnershipPeriodId,
        },
        contact: {
          id: item.contactId,
          name: formatOwnerName(item.contact, {
            lastNameFirst: false,
            showEmpty: true,
          }),
          type: item.contact?.type,
          companyType: item.contact?.companyType || 'individual',
        },
        listingId: period?.listing?.id,
        listingOwnershipPeriodId: period?.id,
        period: {
          id: period?.id,
          startAt: period?.startAt,
          endAt: period?.endAt,
          listing: {
            id: period?.listing?.id,
            name: getListingName(period?.listing, { allowEmpty: true }),
          },
          owners: period
            ?.members({
              order_by: [{ owner: { name: 'asc_nulls_last' } }],
            })
            .map((member) => ({
              id: member.owner?.id,
              name: formatOwnerName(member.contact, {
                lastNameFirst: false,
                showEmpty: true,
              }),
              type: member.contact?.type || 'individual',
            })),
        },
        party: toTitleCase(item.party),
        currency: item.currency || 'usd',
        type: getJournalEntryType(item),
        accountId: item.accountId,
        accountCode: item.account?.uniqueRef,
        accountTitle: item.account?.title,
        debit,
        credit,
        txnAt: item.txnAt,
        txnNum: item.txnNum,
        description: item.description,
        uniqueRef: item.uniqueRef,
      };
    });

export const useGeneralLedgerDetailTableQuery = () => {
  const [teamId] = useTeamId();

  const where = useWhereGeneralLedgerDetail();

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.journalEntryAggregate({ where }).aggregate?.count() || 0;

      const list = getGeneralLedgerDetailRows(q, {
        where,
        teamId,
        limit,
        offset,
      });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'generalLedger',
      variables: { teamId, where },
    }
  );
};
