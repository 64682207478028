import { Button } from '@finalytic/components';
import { useLegacyColors } from '@finalytic/ui';
import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Text } from '@mantine/core';
import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';

type ConnectionResultModalScreenProps = {
  type: 'success' | 'error';
};

export const ConnectionResultModalScreen = ({
  type,
}: ConnectionResultModalScreenProps) => {
  const { app, resetView, setView, errorMessage } = useConnectionModalContext();

  const { green } = useLegacyColors();

  const isSuccess = type === 'success';

  return (
    <ConnectingContainer
      icon={
        isSuccess ? (
          <FontAwesomeIcon icon={faCheck} color="#fff" size="lg" />
        ) : (
          <FontAwesomeIcon icon={faExclamation} color="#FF5656" size="lg" />
        )
      }
      showSingleIcon
      iconBackgroundColor={isSuccess ? green.base : '#FFEEEE'}
      text={
        isSuccess ? (
          <>
            <Text
              id="success-text"
              fw={500}
              sx={{ fontSize: 22, letterSpacing: '0.5px' }}
            >
              {app?.name} connected successfully
            </Text>
          </>
        ) : (
          <>
            <Text size="md">{app?.name} connection failed</Text>
            {errorMessage && (
              <Text fw={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
                {errorMessage}
              </Text>
            )}
          </>
        )
      }
    >
      {/* Buttons */}
      <Stack sx={{ width: 210, margin: '30px auto 0' }}>
        <Button variant="primary" onClick={resetView}>
          {isSuccess ? 'Add another connection' : 'Retry'}
        </Button>

        <Button
          onClick={() => (isSuccess ? setView('close-view') : resetView())}
        >
          {isSuccess ? 'Back to connections' : 'Cancel'}
        </Button>
      </Stack>
    </ConnectingContainer>
  );
};
