import { useEnabledFeatures, useMe, useTeam } from '@finalytic/data';
import { DEMO_TEST_PARTNER_TENANT_ID } from '@vrplatform/ui-common';

export const useIsLedgerTesting = () => {
  const { GL } = useEnabledFeatures();
  const [{ partnerId }] = useTeam();
  const { email } = useMe();

  const isTesting = GL && partnerId === DEMO_TEST_PARTNER_TENANT_ID;

  const isPlaywright = ['e2e-sign-up@finalytic.io'].includes(email);

  const isManualTesting = isTesting && !isPlaywright;

  return {
    GL,
    isManualTesting,
  };
};
