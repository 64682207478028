import type { sync as Sync, syncStatus_enum } from '@finalytic/graphql';
import type { Maybe } from '@finalytic/utils';

export const getSyncStatus = (sync: Maybe<Sync>): syncStatus_enum => {
  const syncStatus = sync?.status;

  const hasQueuedSubtask = !!sync
    ?.subtasks({
      where: {
        status: { _eq: 'queued' },
      },
      limit: 1,
      order_by: [{ createdAt: 'desc_nulls_last' }],
    })
    .map((x) => x.id)[0];

  const hasStartedSubTask = !!sync
    ?.subtasks({
      where: {
        status: { _eq: 'started' },
      },
      limit: 1,
      order_by: [{ createdAt: 'desc_nulls_last' }],
    })
    .map((x) => x.id)[0];

  const hasQueuedChange = !!sync
    ?.changes({
      where: {
        status: { _eq: 'queued' },
      },
      limit: 1,
      order_by: [{ createdAt: 'desc_nulls_last' }],
    })
    .map((x) => x.id)[0];

  const isQueued = hasQueuedChange || hasQueuedSubtask;

  return hasStartedSubTask
    ? 'started'
    : isQueued
      ? 'queued'
      : syncStatus || 'completed';
};
