import { Maybe } from '@finalytic/utils';

export const TAX_ID_REGEX = /^\d{9}$/;

export const validateTin = (tin: Maybe<string>, require = true) => {
  const trimmed = tin?.trim();

  if (!trimmed && !require) return true;

  const isNineDigits = TAX_ID_REGEX.test(trimmed || '');

  if (!isNineDigits) return 'Please enter exactly 9 digits';

  return true;
};
