import { Avatar, Box } from '@mantine/core';
import { ReactNode } from 'react';

export const ConfirmModalResultIcon = ({
  color,
  children,
}: {
  color: string;
  children: ReactNode;
}) => {
  return (
    <Box
      sx={{
        borderRadius: 100,
        transform: 'translateZ(0)',
        filter:
          'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))',

        msFilter:
          'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))',

        MozFilter:
          'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))',

        WebkitFilter:
          'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))',
      }}
    >
      <Avatar
        radius={100}
        size={100}
        mb={30}
        sx={{
          border: '6px solid #fff',
          '.mantine-Avatar-placeholder': {
            backgroundColor: color,
          },
        }}
      >
        {children}
      </Avatar>
    </Box>
  );
};
