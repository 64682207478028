import { Maybe, source as Source } from '@finalytic/graphql';

export function getAccountDescription(source: Maybe<Source> | undefined) {
  const remoteId = source?.remoteId || '';
  const status = source?.status || '';
  const x = source?.description?.split(':').map((x) => x.trim()) || [];

  const lastIndex = x.length - 1;
  const main = x[lastIndex] || '';
  const path = (x.slice(0, lastIndex) || []).join(' / ');

  const prefix = remoteId && remoteId.length < 10 ? `${remoteId} / ` : '';

  const account =
    (status === 'inactive'
      ? `ARCHIVED / ${prefix}${main}`
      : `${prefix}${main || 'No description'}`) || '';

  return {
    account,
    path,
  };
}
