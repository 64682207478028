export const OWNER_PORTAL_COLORS = {
  orange: '#9A3412',
  teal: '#004d40',
  indigo: '#1D3783',
  brown: '#4e342e',
  green: '#3a5d57',
  blue: '#15638f',
  gray: '#707786',
  black: '#22201f',
  red: '#EB144C',
  violett: '#9900EF',
  deepPurple: '#4527a0',
  purple: '#6a1b9a',
};

export const THEME_COLORS: Record<
  string,
  [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    ...string[],
  ]
> = {
  purple: [
    '#BF98E7',
    '#AE7CE0',
    '#9E62DA',
    '#904BD5',
    '#8336D0',
    '#772DC1',
    '#6C29AF',
    '#61259D',
    '#57218E',
    '#4F1E80',
  ],

  finalytic: [
    '#C1C2C5',
    '#A6A7AB',
    '#909296',
    '#5c5f66',
    '#373A40',
    '#2C2E33',
    '#25262b',
    '#1A1B1E',
    '#141517',
    '#101113',
  ],

  vrplatform: [
    // baseColor: #012F64
    '#E6F1FF',
    '#B8D9FE',
    '#8BC0FE',
    '#5DA8FD',
    '#308FFD',
    '#0377FC',
    '#025FCA',
    '#024797',
    '#012F65',
    '#011832',
  ],

  // * Tailwind colors // leaving out last color to due mantine 10 color limit
  neutral: [
    '#fafafa',
    '#f5f5f5',
    '#e5e5e5',
    '#d4d4d4',
    '#a3a3a3',
    '#737373',
    '#525252',
    '#404040',
    '#262626',
    '#171717',
    // "#0a0a0a"
  ],

  sky: [
    '#f0f9ff',
    '#e0f2fe',
    '#bae6fd',
    '#7dd3fc',
    '#38bdf8',
    '#0ea5e9',
    '#0284c7',
    '#0369a1',
    '#075985',
    '#0c4a6e',
    // '#082f49',
  ],

  indigo: [
    '#eef2ff',
    '#e0e7ff',
    '#c7d2fe',
    '#a5b4fc',
    '#818cf8',
    '#6366f1',
    '#4f46e5',
    '#4338ca',
    '#3730a3',
    '#312e81',
    // '#1e1b4b',
  ],
  violet: [
    '#f5f3ff',
    '#ede9fe',
    '#ddd6fe',
    '#c4b5fd',
    '#a78bfa',
    '#8b5cf6',
    '#7c3aed',
    '#6d28d9',
    '#5b21b6',
    '#4c1d95',
    // '#2e1065',
  ],
  amber: [
    '#fffbeb',
    '#fef3c7',
    '#fde68a',
    '#fcd34d',
    '#fbbf24',
    '#f59e0b',
    '#d97706',
    '#b45309',
    '#92400e',
    '#78350f',
  ],

  // Portal Colors
  'portal.orange': [
    '#DBCDC8',
    '#C8ABA1',
    '#BC8C7C',
    '#B76E55',
    '#AE5639',
    '#A34425',
    '#9A3412',
    '#76311B',
    '#5B2D1E',
    '#48291E',
  ],

  'portal.teal': [
    '#45766E',
    '#366B62',
    '#296259',
    '#1D5B50',
    '#13554A',
    '#095044',
    '#004D40',
    '#063A31',
    '#0A2D27',
    '#0B231F',
  ],
  'portal.indigo': [
    '#B9BECD',
    '#969FBA',
    '#7483AE',
    '#5469A6',
    '#405698',
    '#2D468C',
    '#1D3783',
    '#213265',
    '#212D4F',
    '#20283F',
  ],
  'portal.brown': [
    '#9E9390',
    '#8D7C78',
    '#7C6964',
    '#6E5954',
    '#614B46',
    '#573F39',
    '#4E342E',
    '#3F2D29',
    '#332725',
    '#2A2220',
  ],
  'portal.green': [
    '#B4BCBB',
    '#97A6A3',
    '#7D9490',
    '#68847F',
    '#57756F',
    '#476862',
    '#3A5D57',
    '#344B47',
    '#2D3D3A',
    '#273230',
  ],
  'portal.blue': [
    '#BDCBD3',
    '#98B2C0',
    '#749EB5',
    '#508DB0',
    '#3A7DA3',
    '#276F98',
    '#15638F',
    '#1C506E',
    '#1E4255',
    '#1E3643',
  ],
  'portal.gray': [
    '#DEDEE0',
    '#BDBFC3',
    '#A0A4AB',
    '#878C97',
    '#707786',
    '#60656F',
    '#52555C',
    '#46484C',
    '#3B3C3F',
    '#323335',
  ],
  'portal.black': [
    '#504F4E',
    '#454443',
    '#3C3A3A',
    '#343232',
    '#2D2B2A',
    '#272524',
    '#22201F',
    '#1C1B1A',
    '#181716',
    '#141313',
  ],
  'portal.red': [
    '#EFDFE3',
    '#DFAFBB',
    '#D97F96',
    '#DC4C71',
    '#EB144C',
    '#B32348',
    '#8B2943',
    '#6C2B3C',
    '#552A35',
    '#44272E',
  ],
  'portal.violett': [
    '#FBFAFC',
    '#DDC9E8',
    '#C497DE',
    '#B265DD',
    '#A52EE8',
    '#9900EF',
    '#7B14B5',
    '#631E8A',
    '#51236B',
    '#422354',
  ],
  'portal.deepPurple': [
    '#F1F0F4',
    '#CCC8DA',
    '#ABA2C7',
    '#8C7CBA',
    '#6E57B5',
    '#573BAC',
    '#4527A0',
    '#3F2B7C',
    '#382B62',
    '#32294D',
  ],
  'portal.purple': [
    '#DED6E3',
    '#C2AFCD',
    '#AA8ABE',
    '#9764B6',
    '#8743B0',
    '#772EA4',
    '#6A1B9A',
    '#562177',
    '#47235D',
    '#3A2249',
  ],
  'portal.hostingBlue': [
    '#C4C6CA',
    '#A6ABB1',
    '#8B939C',
    '#727E8C',
    '#5F6C7C',
    '#4F5D6E',
    '#415062',
    '#394350',
    '#323941',
    '#2B3035',
  ],
};
