import { setting_bool_exp } from '@finalytic/graphql';
import { hasValue } from '@finalytic/utils';

export const whereSettingAcrossAutomations = ({
  leftConnectionId,
  leftSchema,
  rightConnectionId,
  rightSchema,
  automationId,
  targetId,
}: {
  automationId?: string;
  leftConnectionId: string;
  rightConnectionId?: string;
  leftSchema: string | undefined;
  rightSchema: string | undefined;
  targetId?: string;
}): setting_bool_exp[] =>
  [
    leftConnectionId && rightConnectionId
      ? {
          leftConnectionId: { _eq: rightConnectionId },
          rightConnectionId: { _eq: leftConnectionId },
          leftType: leftSchema ? { _eq: leftSchema } : undefined,
          rightType: rightSchema ? { _eq: rightSchema } : undefined,
          target: targetId ? { _eq: targetId } : undefined,
        }
      : undefined,
    leftConnectionId && rightConnectionId
      ? {
          rightConnectionId: { _eq: rightConnectionId },
          leftConnectionId: { _eq: leftConnectionId },
          rightType: leftSchema ? { _eq: leftSchema } : undefined,
          leftType: rightSchema ? { _eq: rightSchema } : undefined,
          value: targetId ? { _eq: targetId } : undefined,
        }
      : undefined,
    //
    leftConnectionId && rightConnectionId
      ? {
          leftConnectionId: { _eq: leftConnectionId },
          rightConnectionId: { _eq: rightConnectionId },
          leftType: leftSchema ? { _eq: leftSchema } : undefined,
          rightType: rightSchema ? { _eq: rightSchema } : undefined,
          target: targetId ? { _eq: targetId } : undefined,
        }
      : undefined,
    leftConnectionId && rightConnectionId
      ? {
          rightConnectionId: { _eq: leftConnectionId },
          leftConnectionId: { _eq: rightConnectionId },
          rightType: leftSchema ? { _eq: leftSchema } : undefined,
          leftType: rightSchema ? { _eq: rightSchema } : undefined,
          value: targetId ? { _eq: targetId } : undefined,
        }
      : undefined,
    automationId && leftConnectionId
      ? {
          leftType: leftSchema ? { _eq: leftSchema } : undefined,
          leftConnectionId: { _eq: leftConnectionId },
          automationId: { _eq: automationId },
          target: targetId ? { _eq: targetId } : undefined,
        }
      : undefined,
  ].filter(hasValue);
