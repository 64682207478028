import { Checkbox, Input as MantineInput } from '@mantine/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

export const CheckInput = ({
  label,
  formKey,
  defaultValue = '',
  required = true,
  disabled,
  onValueChange,
}: FormInputBaseType) => {
  const { control } = useFormContext();

  return (
    <MantineInput.Wrapper label={label}>
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => {
          return (
            <Checkbox
              checked={value === 'true' || value === true}
              onChange={(event) => {
                console.log(event.target.checked.toString());
                onValueChange?.(formKey, event.target.checked.toString());
                onChange(event);
              }}
              name={formKey}
              disabled={disabled}
              sx={{ input: { borderColor: '#CED0D780' } }}
            />
          );
        }}
      />
    </MantineInput.Wrapper>
  );
};
