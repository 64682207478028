import { LoadingIndicator } from '@finalytic/ui';
import {
  DatePickerInput,
  DatePickerInputProps,
  DatePickerType,
} from '@mantine/dates';

export const InputDay = <DType extends DatePickerType = 'default'>({
  loading,
  type = 'default',
  styles,
  sx,
  ...props
}: DatePickerInputProps<DType> & {
  loading?: boolean;
  placeholder?: string;
}) => {
  return (
    <DatePickerInput
      color="dark"
      type={type}
      numberOfColumns={2}
      radius="md"
      disabled={loading}
      rightSection={loading && <LoadingIndicator size="xs" />}
      {...props}
      styles={(theme, c, t) => ({
        input: {
          '&:focus': {
            border: `1px solid ${theme.colors[props.error ? 'red' : theme.primaryColor][6]}`,
          },
          '&[aria-expanded=true]': {
            border: `1px solid ${theme.colors[props.error ? 'red' : theme.primaryColor][6]}`,
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[props.error ? 'red' : theme.primaryColor][4]
            }40`,
          },
          '&:disabled': {
            color: 'unset',
          },
        },
        ...(typeof styles === 'function' ? styles(theme, c, t) : styles),
      })}
      sx={(theme, c) =>
        ({
          '.mantine-CloseButton-root path, .mantine-CloseButton-root svg': {
            fill: theme.colors.neutral[3],
            color: theme.colors.neutral[3],
          },
          ...(typeof sx === 'function' ? sx(theme, c) : sx),
        }) as any
      }
      popoverProps={{
        shadow: 'md',
        radius: 'md',
        ...props?.popoverProps,
      }}
    />
  );
};
