import { useRunDrawer } from '@finalytic/data-ui';
import { Drawer } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import { useState } from 'react';
import { MultiWorkflowOverview } from './MultiWorkflowOverview';
import { SingleWorkflow } from './SingleWorkflow';

const previewMaxWidth = 1100;

export const AutomationHistoryDrawer = () => {
  const { close, navigateBack, workflowIds, activeDrawer } = useRunDrawer();

  const opened = !!activeDrawer && activeDrawer === 'workflows';

  return (
    <Drawer
      opened={opened}
      zIndex={101}
      onClose={close}
      size={previewMaxWidth}
      containerSx={{
        marginRight: 20,
        borderRadius: 20,
        padding: 0,
      }}
    >
      <Content
        workflowIds={workflowIds?.filter(hasValue) || []}
        closeDrawer={navigateBack}
      />
    </Drawer>
  );
};

const Content = ({
  closeDrawer,
  workflowIds,
}: {
  workflowIds: string[];
  closeDrawer: () => void;
}) => {
  const [activeWorkflowId, setActiveWorkflowId] = useState<string | null>(null);

  const isOneWorkflow = workflowIds.length === 1;

  if (isOneWorkflow || activeWorkflowId)
    return (
      <SingleWorkflow
        workflowId={activeWorkflowId || workflowIds[0]}
        hasMultiple={workflowIds.length > 1}
        closeDrawer={closeDrawer}
        resetActiveWorkflowId={() => setActiveWorkflowId(null)}
      />
    );

  if (!workflowIds.length) return null;

  return (
    <MultiWorkflowOverview
      workflowIds={workflowIds}
      closeDrawer={closeDrawer}
      setWorkflowId={setActiveWorkflowId}
    />
  );
};
