import { useInfiniteQuery } from '@finalytic/data';
import { activeStatus_enum, order_by, transaction } from '@finalytic/graphql';
import { MRT_SortingState } from '@finalytic/table';
import { sortBy, sum, utc } from '@finalytic/utils';
import { formatOwnerName, getListingName } from '@vrplatform/ui-common';
import { getDepositManualLinesCentTotal } from '../edit/_utils';
import { getDepositLines } from '../edit/get-deposit-lines';
import { useWhereDeposits } from './useWhereDeposits';

type Params = {
  sorting: MRT_SortingState;
  status: activeStatus_enum;
};

export type DepositRow = NonNullable<
  ReturnType<typeof useDepositsTableQuery>['data']
>['pages'][number]['list'][number];

export const getDeposit = (
  deposit: transaction,
  opts?: { includeDetails: boolean }
) => {
  const { lines, totals } = getDepositLines(deposit.lines);

  const bankRecords = deposit
    .bankRecordPayments({
      order_by: [
        {
          bankRecord: {
            date: 'desc_nulls_last',
          },
        },
      ],
      where: {
        bankRecordId: { _is_null: false },
      },
    })
    .map((bankRecordPayment) => ({
      id: bankRecordPayment.bankRecordId,
      centTotal: bankRecordPayment.bankRecord.centTotal ?? 0,
      description: !opts?.includeDetails
        ? ''
        : bankRecordPayment.bankRecord.description,
      bankAccount: !opts?.includeDetails
        ? ''
        : bankRecordPayment.bankRecord.bankAccount?.account?.title || '',
      date: opts?.includeDetails ? bankRecordPayment.bankRecord.date : null,
    }));

  const isReconciled =
    sum(bankRecords.map((x) => x.centTotal)) === totals.centTotal;

  const uniqueReservations = !opts?.includeDetails
    ? []
    : deposit
        .lines({
          distinct_on: ['reservationId'],
          where: {
            reservationId: { _is_null: false },
          },
        })
        .map((line) => {
          const item = line.reservation;

          const reservationlistingConnectionListing = {
            id: item?.listingConnection?.listing?.id,
            name: getListingName(item?.listingConnection?.listing),
          };

          const reservationListing = {
            id: item?.listingId,
            name: getListingName(item?.listing),
          };

          return {
            id: item?.id,
            guestName: item?.guestName,
            nights: item?.nights,
            guests: item?.guests,
            checkIn: item?.checkIn,
            checkOut: item?.checkOut,
            connection: {
              id: item?.connection?.id,
              name: item?.connection?.name,
              icon: item?.connection?.app?.iconRound,
            },
            listing: reservationListing?.id
              ? reservationListing
              : reservationlistingConnectionListing,
          };
        });

  const uniqueContacts = !opts?.includeDetails
    ? []
    : deposit
        .lines({
          distinct_on: ['contactId'],
          where: {
            contactId: { _is_null: false },
          },
        })
        .map((line) => {
          const item = line.contact;

          return {
            id: item?.id,
            name: formatOwnerName(item),
          };
        });

  const uniqueListings = !opts?.includeDetails
    ? []
    : deposit
        .lines({
          distinct_on: ['listingId'],
          where: {
            listingId: { _is_null: false },
          },
        })
        .map((line) => {
          const item = line.listing;

          return {
            id: item?.id,
            name: getListingName(item),
          };
        });

  return {
    id: deposit.id as string,
    date: deposit.date ? utc(deposit.date).yyyymmdd() : null,
    uniqueRef: deposit.uniqueRef,
    status:
      deposit.status === 'active' ? ('Paid' as const) : ('Unpaid' as const),
    bankAccount: {
      id: deposit.accountId,
      title: deposit.account?.title,
    },
    connection: {
      id: deposit.connectionId,
      name: deposit.connection?.name,
      logo: deposit.connection?.app?.iconRound,
    },
    isReconciled,
    description: deposit.description,
    bankRecords,
    centTotal: totals.centTotal,
    currency: deposit.currency!,
    lines,
    details: {
      reservations: sortBy(
        lines.reservations.map((line) => ({
          id: line.id,
          reservation: uniqueReservations.find(
            (x) => x.id === line.reservationId
          ),
          line,
        })),
        (x) => x.reservation?.checkIn,
        'desc'
      ),
      manuals: sortBy(
        lines.manuals.map((line) => ({
          id: line.id,
          listing: uniqueListings.find((x) => x.id === line.listingId),
          contact: uniqueContacts.find((x) => x.id === line.contactId),
          line,
        })),
        (x) => getDepositManualLinesCentTotal([x.line]),
        'desc'
      ),
    },
  };
};

export const useDepositsTableQuery = ({ sorting, status }: Params) => {
  const where = useWhereDeposits(status);

  return useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate =
        q.transactionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => {
        const sortId = sort.id as 'date' | 'description';
        const order: order_by = sort.desc
          ? 'desc_nulls_last'
          : 'asc_nulls_last';

        return {
          [sortId]: order,
        };
      });

      const list = q
        .transactions({
          where,
          limit,
          offset,
          order_by,
        })
        .map((deposit) => getDeposit(deposit));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'deposits',
      variables: {
        where,
        sorting,
      },
    }
  );
};
