import { Badge } from '@finalytic/components';
import { gqlV2 } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { useAppName } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Box, Center, Group, Tooltip, useMantineTheme } from '@mantine/core';

type Props = {
  id: string;
  tenantId: string;
  pmsStatus: Maybe<gqlV2.owner_pms_status_enum>;
  status: Maybe<gqlV2.owner_status_enum>;
};

export const OwnerStatusBadge = (data: Props) => {
  if (data.pmsStatus === 'inactive' && !data.status) return <PmsStatus />;

  return <Status {...data} />;
};

const PmsStatus = () => {
  return (
    <Badge
      color="orange"
      leftIcon={
        <Icon
          icon="AlertTriangleIcon"
          size={12}
          color={(theme) => theme.colors.orange[8]}
        />
      }
    >
      Disabled in PMS
    </Badge>
  );
};

const Status = (data: Props) => {
  const { colors } = useMantineTheme();
  const { appName } = useAppName();

  const isDisabledInPms = data.pmsStatus === 'inactive';

  if (data.status === 'inactive') {
    return (
      <Group wrap="nowrap" gap={'xs'}>
        {isDisabledInPms && (
          <Tooltip label="Archived in PMS" withinPortal withArrow>
            <Center>
              <Icon
                icon="AlertTriangleIcon"
                size={12}
                color={colors.orange[7]}
              />
            </Center>
          </Tooltip>
        )}

        <Box>
          <Badge
            color="yellow"
            leftIcon={
              <Icon
                icon="CrossCircleIcon"
                size={12}
                color={(theme) => theme.colors.yellow[8]}
              />
            }
          >
            Archived
          </Badge>
        </Box>
      </Group>
    );
  }

  return (
    <Group wrap="nowrap" gap={'xs'}>
      {isDisabledInPms && (
        <Tooltip label="Archived in PMS" withinPortal withArrow>
          <Center>
            <Icon icon="AlertTriangleIcon" size={12} color={colors.orange[7]} />
          </Center>
        </Tooltip>
      )}

      <Box>
        <Badge color="green">Active on {appName}</Badge>
      </Box>
    </Group>
  );
};
