import { useClerk } from '@clerk/clerk-react';
import { Button } from '@finalytic/components';
import { useIntercom, useTeamId } from '@finalytic/data';
import { EmptyViewWrapper } from '../../components';

export default () => {
  const [, setTeamId] = useTeamId();
  const { signOut } = useClerk();
  const { show } = useIntercom();

  return (
    <EmptyViewWrapper
      title="No Team found"
      description="We couldn't find a team for you. Was your team deleted or did you not receive your invitation?"
      actions={
        <>
          <Button
            variant="primary"
            leftIcon={'RefreshCwIcon'}
            onClick={() => setTeamId(null)}
          >
            Refresh
          </Button>

          {show ? (
            <Button onClick={show} leftIcon={'CommentQuestionIcon'}>
              Contact support
            </Button>
          ) : null}

          <Button variant="light" onClick={() => signOut()}>
            Sign out
          </Button>
        </>
      }
    />
  );
};
