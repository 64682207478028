import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@mantine/core';

export const PercentageBadge = ({
  value,
  precision = 2,
  inverseColors = false,
}: {
  value: number | null | undefined;
  precision?: number;
  inverseColors?: boolean;
}) => {
  if (value === null || value === undefined) return null;

  const getBadgeColor = () => {
    if (!value) return 'yellow';

    if (inverseColors) {
      return value > 0 ? 'red' : 'teal';
    } else {
      return value > 0 ? 'teal' : 'red';
    }
  };

  const color = getBadgeColor();

  return (
    <Badge
      color={color}
      variant="light"
      leftSection={
        value ? (
          <FontAwesomeIcon icon={value > 0 ? faArrowUp : faArrowDown} />
        ) : undefined
      }
    >
      {(value < 0 ? value * -1 : value).toFixed(precision)}%
    </Badge>
  );
};
