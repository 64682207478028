import { captureException, captureMessage } from '@sentry/react';

type SentryExceptionOptions = Parameters<typeof captureException>[1];
export const captureSentryError = (
  initial: Error | string,
  options?: SentryExceptionOptions
) => {
  const error = initial instanceof Error ? initial : new Error(initial);

  captureException(error, {
    fingerprint: [error.message] as any,
    ...(options || {}),
  });
};

export const captureSentryMessage = captureMessage;
