import { Filter } from '@finalytic/components';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { hasValue, toTitleCase } from '@finalytic/utils';
import {
  Box,
  Center,
  Divider,
  Modal,
  Tabs,
  Text,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import { MappingLeftSchemaUnion } from '@vrplatform/ui-common';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import { AutomationMappingEditor } from './AutomationMappingEditor';
import { useAutomationOverrideAggregateQuery } from './useAutomationOverrideAggregateQuery';
import {
  OverrideRow,
  useAutomationOverrideTableQuery,
} from './useAutomationOverrideTableQuery';

type ModalProps = {
  opened: boolean;
  closeModal: () => void;
  modalTitle?: string | ReactNode;
};

type Props = {
  automation: {
    automationId: string;
    leftConnectionId: string;
    rightConnectionId: string;
    leftAppId: string | undefined;
    rightAppId: string | undefined;
    templateId: string | undefined;
  };
  parentMapping: {
    settingKey: string;
    leftType: string;
    rightType: string;
    isLocal: boolean;
    label: string | undefined | ReactNode;
    altLeftTypes: MappingLeftSchemaUnion[];
    exceptionFilters: Record<string, Record<string, any>> | undefined;
    rightParams: Record<string, any> | undefined;
    leftParams: Record<string, any> | undefined;
  };
  parentSetting: {
    target: string;
    settingId: string;
    value: string;
  } | null;
  isDefaultExceptions: boolean;
  hideParentMapping: boolean;
};

export const AutomationOverrideModal = ({
  opened,
  closeModal,
  modalTitle,
  ...props
}: ModalProps & Props) => {
  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      size={1200}
      title={modalTitle ? `${modalTitle} Exceptions` : 'Mapping exceptions'}
      styles={(theme) => ({
        title: {
          fontWeight: 500,
          fontSize: theme.fontSizes.lg,
        },
        header: {
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        },
        body: {
          paddingInline: theme.spacing.xs,
          paddingBottom: 0,
          overflowY: 'hidden',
        },
        root: {
          overflowY: 'hidden',
        },
      })}
    >
      {!props.parentSetting?.settingId && !props.isDefaultExceptions ? (
        <Center mih={'50vh'}>
          <Text>Missing default exception to override.</Text>
        </Center>
      ) : (
        <MappingTabs {...props} />
      )}
    </Modal>
  );
};

const MappingTabs = (props: Props) => {
  const parentMapping = props.parentMapping;
  const { colorScheme } = useMantineColorScheme();

  const tables = useMemo<MappingLeftSchemaUnion[]>(() => {
    const alt = parentMapping.altLeftTypes || [];

    const options = alt.filter(
      (key) =>
        key !== parentMapping?.leftType && key !== parentMapping?.rightType
    );

    // return sortBy(t, undefined, 'asc');
    return options;
  }, [
    parentMapping.altLeftTypes,
    parentMapping.leftType,
    parentMapping.rightType,
  ]);

  const overrideTables = [
    props.hideParentMapping ? undefined : parentMapping.leftType,
    ...tables,
  ].filter(hasValue);

  return (
    <Tabs
      defaultValue={overrideTables[0]}
      orientation="vertical"
      variant="pills"
      keepMounted={false}
      sx={(theme) => ({
        '.mantine-Tabs-list': {
          width: rem(240),
          gap: theme.spacing.xs,
          paddingTop: theme.spacing.xs,
          paddingRight: theme.spacing.xs,
          borderRight: `1px solid ${theme.colors.gray[2]}`,
          flexWrap: 'nowrap',
          overflowY: 'scroll',
        },
        '.mantine-Tabs-tab': {
          "&[data-active='true']":
            colorScheme === 'dark'
              ? {
                  backgroundColor: theme.colors.dark[3],
                  '&:hover': {
                    backgroundColor: theme.colors.dark[3],
                  },
                }
              : {
                  backgroundColor: theme.colors[theme.primaryColor][0],
                  color: theme.colors[theme.primaryColor][6],
                  '&:hover': {
                    backgroundColor: theme.colors[theme.primaryColor][0],
                  },
                },
        },
        '.mantine-Tabs-tabLabel': {
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          width: '100%',
          alignItems: 'center',
          cursor: 'pointer',
        },
      })}
      styles={(theme) => ({
        root: {
          display: 'flex',
          height: '70vh',
        },
        panel: {
          height: '100%',
          flex: 1,
          paddingLeft: theme.spacing.md,
          paddingBottom: theme.spacing.xl,
          marginTop: theme.spacing.md,
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      })}
    >
      <Tabs.List>
        {overrideTables.map((table) => {
          const showDivider = parentMapping.leftType === table;

          return (
            <Fragment key={table}>
              <Tab key={table} table={table} {...props} />
              {showDivider && <Divider />}
            </Fragment>
          );
        })}
      </Tabs.List>
      {overrideTables.map((table) => {
        return (
          <Tabs.Panel key={table} value={table}>
            <MappingTable {...props} table={table} />
          </Tabs.Panel>
        );
      })}
    </Tabs>
  );
};

const Tab = ({
  table,
  parentSetting,
  ...props
}: { table: MappingLeftSchemaUnion | (string & {}) } & Props) => {
  const { data: count } = useAutomationOverrideAggregateQuery({
    ...props,
    parentSettingId:
      parentSetting?.target !== '*' ? parentSetting?.settingId || null : null,
    mapping: { leftType: table },
  });

  return (
    <Tabs.Tab key={table} value={table}>
      {formatSchemaToTitle(table)}
      {count ? (
        <Center
          sx={(theme) => ({
            backgroundColor: theme.colors.gray[3],
            color: theme.colors.gray[7],
            width: rem(20),
            height: rem(20),
            fontSize: theme.fontSizes.xs,
            borderRadius: theme.radius.sm,
          })}
        >
          {count}
        </Center>
      ) : null}
    </Tabs.Tab>
  );
};
const MappingTable = ({
  table,
  automation,
  parentMapping,
  parentSetting,
  isDefaultExceptions,
}: { table: MappingLeftSchemaUnion | (string & {}) } & Props) => {
  const tableName = formatSchemaToTitle(table);

  const [search, setSearch] = useState('');

  const queryData = useAutomationOverrideTableQuery({
    filter: {
      search: search?.trim(),
    },
    table,
    exceptionFilters: parentMapping.exceptionFilters || {},
    leftParams: parentMapping.leftParams || {},
  });

  const columns = useMemo<MRT_ColumnDef<OverrideRow>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: tableName || 'Name',
        enableSorting: false,
      },
      {
        header: 'Exception',
        enableSorting: false,
        Cell: ({ row }) => {
          const data = row.original;

          return (
            <Box w="100%">
              <AutomationMappingEditor
                targetId={data.id}
                automation={{
                  automationId: automation.automationId,
                  leftConnectionId: automation.leftConnectionId,
                  rightConnectionId: automation.rightConnectionId,
                  leftAppId: automation.leftAppId,
                  rightAppId: automation.rightAppId,
                  templateId: automation.templateId,
                }}
                mapping={{
                  leftType: table,
                  rightType: parentMapping.rightType,
                  settingKey: parentMapping.settingKey,
                  isLocal: parentMapping.isLocal,
                  label: parentMapping.label,
                  exceptionFilters: undefined,
                  altLeftTypes: [],
                  rightParams: parentMapping.rightParams || {},
                  leftParams: parentMapping.leftParams || {},
                }}
                parentSetting={isDefaultExceptions ? null : parentSetting}
                invalidateKeys={
                  isDefaultExceptions ? ['globalSettings'] : ['settings']
                }
              />
            </Box>
          );
        },
      },
    ];
  }, [
    tableName,
    automation,
    parentMapping,
    parentSetting,
    isDefaultExceptions,
    table,
  ]);

  return (
    <InfiniteTable
      columns={columns}
      table={{
        enableStickyHeader: true,
        emptyRowsFallback: () => (
          <Center>
            <Text ta="center" c="gray">
              No {tableName?.toLowerCase()} to display
            </Text>
          </Center>
        ),
      }}
      queryData={queryData}
      resetFilter={() => setSearch('')}
    >
      <Filter.Search width={200} value={search} setValue={setSearch} />
    </InfiniteTable>
  );
};

const formatSchemaToTitle = (
  schema: MappingLeftSchemaUnion | (string & {})
) => {
  if (schema === 'finalytic.listingConnection') return 'Unit';

  return toTitleCase(schema.split('.').reverse()[0]);
};
