import { syncStatus_enum, sync_bool_exp } from '@finalytic/graphql';
import { Maybe, hasValue } from '@finalytic/utils';

export const whereSyncs = (args: {
  tenantId: string;
  connectionId?: Maybe<string>;
  search?: Maybe<string>;
  status?: syncStatus_enum[];
  type?: Maybe<string>; // automationId OR "extract"
}): sync_bool_exp => {
  const _orStatus: sync_bool_exp[] = [];

  if (
    args.status?.includes('completed') ||
    args.status?.includes('canceled') ||
    args.status?.includes('failed')
  ) {
    _orStatus.push({
      status: { _in: args.status },
    });
  }

  if (args.status?.includes('queued')) {
    _orStatus.push({
      _or: [
        {
          status: { _eq: 'queued' },
        },
        {
          subtasks: {
            status: { _eq: 'queued' },
          },
        },

        {
          changes: {
            status: { _eq: 'queued' },
          },
        },
      ],
    });
  }
  if (args.status?.includes('started')) {
    _orStatus.push({
      _or: [
        {
          status: { _eq: 'started' },
        },
        {
          subtasks: {
            status: { _eq: 'started' },
          },
        },
      ],
    });
  }

  const _orSearch = args.search
    ? [
        { message: { _ilike: `%${args.search}%` } },
        {
          connection: {
            _or: [
              {
                name: { _ilike: `%${args.search}%` },
              },
              {
                uniqueRef: { _ilike: `%${args.search}%` },
              },
            ],
          },
        },
        {
          user: {
            _or: [
              {
                firstName: { _ilike: `%${args.search}%` },
              },
              {
                lastName: { _ilike: `%${args.search}%` },
              },
            ],
          },
        },
      ]
    : undefined;

  return {
    tenantId: { _eq: args.tenantId },
    automationId:
      args.type === 'extract'
        ? { _is_null: true }
        : args.type
          ? { _eq: args.type }
          : undefined,
    connectionId: args.connectionId
      ? { _eq: args.connectionId }
      : { _is_null: false },
    _and: [
      _orSearch
        ? {
            _or: _orSearch,
          }
        : undefined,
      _orStatus.length
        ? {
            _or: _orStatus,
          }
        : undefined,
    ].filter(hasValue),
  };
};
