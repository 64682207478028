import { Icon } from '@finalytic/icons';
import {
  AppShell,
  Box,
  Center,
  Group,
  List,
  Text,
  ThemeIcon,
  rem,
} from '@mantine/core';
import { type PropsWithChildren, useMemo } from 'react';
import { useIsSignInMobile } from '../views/sign-in-up/useIsSignInMobile';
import { LayoutBackground } from './LayoutBackground';

type SignupTeamType = 'partner' | 'propertyManager';

type Props = {
  title: string;
  description?: string;
  sidebar: React.ReactNode;
  footer: React.ReactNode;
  menu: React.ReactNode;
  activeStep?: OnboardingStep;
  GL: undefined | 'no-billing' | 'with-billing';
  teamType: SignupTeamType;
  teamSelectComponent?: React.ReactNode;
};

export type OnboardingStep =
  | 'choose-role'
  | 'create-account'
  | 'verify-email'
  | 'add-billing'
  | 'connect-pms'
  | 'connect-accounting'
  | 'start-date'
  | 'select-listing';

function useOnboardingSteps(GL: Props['GL'], type: SignupTeamType) {
  type Step = {
    id: OnboardingStep;
    title: string;
  };

  return useMemo<Step[]>(() => {
    const signUpSteps: Step[] = [
      {
        id: 'choose-role',
        title: 'Choose your role',
      },
      {
        id: 'create-account',
        title: 'Create your account',
      },
      {
        id: 'verify-email',
        title: 'Verify your email',
      },
    ];

    const PartnerSteps: Step[] = [
      {
        id: 'add-billing',
        title: 'Add your billing information',
      },
    ];

    const PmNonGLSteps: Step[] = [
      {
        id: 'add-billing',
        title: 'Add your billing information',
      },
      {
        id: 'connect-pms',
        title: 'Connect your PMS',
      },
      {
        id: 'connect-accounting',
        title: 'Connect your Accounting',
      },
    ];

    const PmGLSteps: Step[] = [
      {
        id: 'connect-pms',
        title: 'Connect your PMS',
      },
      {
        id: 'start-date',
        title: 'Owner Statement Settings',
      },
      {
        id: 'add-billing',
        title: 'Add your billing information',
      },
      {
        id: 'select-listing',
        title: 'Select a listing to get started',
      },
    ];

    if (type === 'partner') return [...signUpSteps, ...PartnerSteps];

    if (GL) {
      return [
        ...signUpSteps,
        ...(GL === 'with-billing' ? PmGLSteps : PmGLSteps.slice(0, 2)),
      ];
    }

    return [...signUpSteps, ...PmNonGLSteps];
  }, [GL]);
}

export const Layout = ({
  title,
  children,
  sidebar,
  activeStep,
  description,
  footer,
  menu,
  GL,
  teamType,
}: PropsWithChildren<Props>) => {
  const isMobile = useIsSignInMobile();
  const steps = useOnboardingSteps(GL, teamType);

  return (
    <>
      <AppShell>
        {isMobile && (
          <AppShell.Header
            hidden={!isMobile}
            p="md"
            sx={(theme) => ({
              backgroundColor: theme.colors[theme.primaryColor][8],
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '60px',
            })}
          >
            <Group gap="xs" wrap="nowrap">
              <VrpSvg />
              <Text c="white" size="lg" fw={500}>
                VRPlatform
              </Text>
            </Group>
            {menu}
          </AppShell.Header>
        )}
        <AppShell.Main>
          <Box
            sx={(theme) => ({
              paddingInline: isMobile ? undefined : theme.spacing.lg,
              paddingBlock: isMobile ? undefined : rem(60),
              paddingTop: isMobile ? '80px' : rem(60),
              // paddingBottom: isMobile ? theme.spacing.md : undefined,
              minHeight: '100dvh',
              marginInline: 'auto',
              overflowY: 'hidden',
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'flex-start',
              background: isMobile
                ? undefined
                : 'linear-gradient(291.34deg, #011D3F 0%, #012F65 55.5%)',
              gap: theme.spacing.md,
              flexDirection: 'row-reverse',
            })}
          >
            {/* // * MAIN CONTENT */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                minHeight: '100%',
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: theme.white,
                  borderRadius: theme.radius.md,
                  width: isMobile ? undefined : '580px',
                  flex: 1,
                  zIndex: 2,
                  paddingInline: isMobile ? theme.spacing.xs : rem(70),
                  paddingTop: isMobile ? theme.spacing.xs : rem(60),
                  paddingBottom: isMobile ? theme.spacing.lg : theme.spacing.xl,
                  marginInline: isMobile ? 0 : 'auto',
                })}
              >
                <Box mb="lg" mih={120}>
                  <Text
                    mt="sm"
                    component="h1"
                    size={'1.5rem'}
                    mb="lg"
                    fw={'bold'}
                    test-id="onboarding-title"
                    ta="center"
                  >
                    {title}
                  </Text>

                  {description && (
                    <Text c="neutral" mb="xl" ta="center">
                      {description}
                    </Text>
                  )}
                </Box>

                <Box
                  w="100%"
                  mb="sm"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {children}
                </Box>

                {isMobile && sidebar && (
                  <Box mt="lg" mb="sm">
                    {sidebar}
                  </Box>
                )}

                {footer && <Box mt="auto">{footer}</Box>}
              </Box>
            </Box>

            {/* // * SIDEBAR */}
            {!isMobile && (
              <Box
                sx={(theme) => {
                  return {
                    zIndex: 2,
                    flex: 0.8,
                    borderRadius: theme.radius.lg,
                    overflow: 'hidden',
                    minHeight: '100%',
                    position: 'relative',
                    paddingInline: theme.spacing.xl,
                    paddingBlock: theme.spacing.xs,
                    display: 'flex',
                    flexDirection: 'column',
                  };
                }}
              >
                <Group
                  justify="space-between"
                  pos="relative"
                  mb="lg"
                  sx={{
                    zIndex: 2,
                  }}
                >
                  <SidebarLogo />
                </Group>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                  }}
                >
                  {sidebar}
                </Box>
                <Group justify="space-between" align="flex-end" mt="xl">
                  {activeStep && (
                    <List spacing={'xs'}>
                      {steps.map((step, i, arr) => {
                        const isChecked = arr
                          .slice(i + 1)
                          .some((x) => x.id === activeStep);

                        const isActive = step.id === activeStep;

                        return (
                          <List.Item
                            key={step.id}
                            icon={
                              <Center>
                                <ThemeIcon
                                  variant="outline"
                                  radius={'xl'}
                                  size="sm"
                                  color={'white'}
                                  opacity={isActive ? 1 : 0.5}
                                >
                                  {isActive ? (
                                    <Box
                                      sx={() => ({
                                        width: '5px',
                                        height: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: '#fff',
                                        opacity: 0.5,
                                      })}
                                    />
                                  ) : (
                                    <Icon
                                      size={12}
                                      icon="CheckIcon"
                                      color={'white'}
                                      sx={{
                                        visibility: isChecked
                                          ? 'visible'
                                          : 'hidden',
                                      }}
                                    />
                                  )}
                                </ThemeIcon>
                              </Center>
                            }
                            sx={() => ({
                              position: 'relative',
                              zIndex: 2,
                              color: 'white',
                              opacity: isActive ? 1 : 0.5,
                            })}
                          >
                            {step.title}
                          </List.Item>
                        );
                      })}
                    </List>
                  )}
                  {menu}
                </Group>
              </Box>
            )}

            {!isMobile && <LayoutBackground />}
          </Box>
        </AppShell.Main>
      </AppShell>
    </>
  );
};

const SidebarLogo = () => {
  return (
    <Group wrap="nowrap" gap={8}>
      <VrpSvg />

      <Text c={'white'} size={'24px'} fw={400}>
        VR
        <Text span fw={'bold'} inherit>
          Platform
        </Text>
      </Text>
    </Group>
  );
};

const VrpSvg = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1875 8.22231C19.1875 5.86774 21.0963 3.95898 23.4508 3.95898C25.8054 3.95898 27.7142 5.86774 27.7142 8.22231C27.7142 10.5769 25.8054 12.4856 23.4508 12.4856C21.0963 12.4856 19.1875 10.5769 19.1875 8.22231Z"
      fill="white"
      fillOpacity="0.98"
    />
    <path
      d="M19.1875 8.22231C19.1875 5.86774 21.0963 3.95898 23.4508 3.95898C25.8054 3.95898 27.7142 5.86774 27.7142 8.22231C27.7142 10.5769 25.8054 12.4856 23.4508 12.4856C21.0963 12.4856 19.1875 10.5769 19.1875 8.22231Z"
      fill="url(#paint0_linear_4044_12298)"
      fillOpacity="0.12"
    />
    <path
      d="M7.95703 8.43627C7.95703 5.98901 10.1412 3.96838 12.5885 3.96838C15.0357 3.96838 15.9884 5.56818 17.4938 7.49761C18.1284 8.31087 21.7247 13.3116 21.7247 13.3116C21.7247 13.3116 22.4986 14.6566 22.4986 15.7598C22.4986 18.499 18.1319 22.8084 18.1319 22.8084L8.60103 10.6502C8.60103 10.6502 7.95703 10.0205 7.95703 8.43627Z"
      fill="white"
      fillOpacity="0.8"
    />
    <path
      d="M2.17969 8.39745C2.17969 5.95019 4.36385 3.92957 6.81111 3.92957C9.25838 3.92957 10.2111 5.52937 11.7165 7.45882C12.351 8.27205 15.5367 12.4593 17.7573 15.5433C19.2912 17.6735 19.4067 17.6756 19.4067 20.3145C19.4067 22.9533 16.0918 24.1085 15.0809 24.162C12.9529 24.2746 11.4744 22.4387 11.4744 22.4387L2.82368 10.6114C2.82368 10.6114 2.17969 9.98172 2.17969 8.39745Z"
      fill="white"
      fillOpacity="0.98"
    />
    <path
      d="M2.17969 8.39745C2.17969 5.95019 4.36385 3.92957 6.81111 3.92957C9.25838 3.92957 10.2111 5.52937 11.7165 7.45882C12.351 8.27205 15.5367 12.4593 17.7573 15.5433C19.2912 17.6735 19.4067 17.6756 19.4067 20.3145C19.4067 22.9533 16.0918 24.1085 15.0809 24.162C12.9529 24.2746 11.4744 22.4387 11.4744 22.4387L2.82368 10.6114C2.82368 10.6114 2.17969 9.98172 2.17969 8.39745Z"
      fill="url(#paint1_linear_4044_12298)"
      fillOpacity="0.12"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4044_12298"
        x1="23.4508"
        y1="3.95898"
        x2="23.4508"
        y2="12.4856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4044_12298"
        x1="10.7932"
        y1="3.92957"
        x2="10.7932"
        y2="24.167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
