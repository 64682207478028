import {
  owner_statement,
  owner_statement_line_bool_exp,
} from '@finalytic/graphql';
import { distributeCent, ensure, sum } from '@finalytic/utils';
import { TAX_ACCOUNT_COLLECTION_SETTING_KEY } from '../queries';

export function getStatementDistributionByCollection(
  ownerStatement: owner_statement | undefined,
  {
    collectionId,
    gteDate,
    ltDate,
    applyListingStartBalance,
  }: {
    collectionId: string;
    applyListingStartBalance: boolean;
    gteDate?: string;
    ltDate?: string;
  }
) {
  const splits =
    ownerStatement
      ?.owners({
        where: {
          // role: { _neq: 'ownerMigrated' },
          newOwnerId: { _is_null: false },
        },
      })
      ?.map((split) => {
        return {
          newOwnerId: split?.newOwnerId,
          split: split?.split,
        };
      }) || [];

  const listingStartBalance =
    ownerStatement?.listing?.centTaxStartingBalance || 0;

  const lines =
    ownerStatement
      ?.lines({
        where: {
          ...statementLinesWhereCollection({ collectionId }),
          date:
            gteDate || ltDate
              ? {
                  _gte: gteDate,
                  _lte: ltDate,
                }
              : undefined,
          role: { _eq: 'bill' },
        },
      })
      .map((line) => {
        return {
          id: line.id,
          remoteId: line.group?.remoteId,
          description: line.description,
          centTotal: line.centTotal || 0,
        };
      }) || [];

  const netRentalRevenue = sum(lines.map((x) => x.centTotal || 0));

  // if this is the first tax statement, we need to add the starting balance to the net revenue
  const totalRevenue = applyListingStartBalance
    ? netRentalRevenue + listingStartBalance
    : netRentalRevenue;

  return {
    lines,
    splits,
    listingStartBalance: applyListingStartBalance ? listingStartBalance : 0,
    dist: distributeCent(splits, 'split', totalRevenue).map(
      ([{ newOwnerId, split }, centTotal]) => {
        return {
          newOwnerId,
          split,
          centTotal,
        };
      }
    ),
  };
}

const statementLinesWhereCollection = ({
  collectionId,
}: { collectionId: string }) => {
  return ensure<owner_statement_line_bool_exp>({
    group: {
      settingsLeft: {
        key: {
          _eq: TAX_ACCOUNT_COLLECTION_SETTING_KEY,
        },
        rightOwnerStatementAccountCollection: {
          id: { _eq: collectionId },
        },
      },
    },
  });
};
