import { StateCreator } from 'zustand';

type State = {
  allPagesSelected: boolean;
  selected: Record<string, boolean>;
};

type Actions = {
  setAllPagesSelected: (v: boolean) => void;
  setSelected: (data: Record<string, boolean>) => void;
  getSelectionState: () => State;
  resetSelection: () => void;
};

export type TableSelectionSlice = State & Actions;

const initialState: State = {
  allPagesSelected: false,
  selected: {},
};

export const createTableSelectionSlice: StateCreator<TableSelectionSlice> = (
  set,
  get
) => ({
  allPagesSelected: false,
  selected: {},
  setAllPagesSelected: (val) => {
    set((state) => ({
      ...state,
      allPagesSelected: val,
    }));
  },
  getSelectionState: () => {
    return get();
  },
  setSelected: (selected) => {
    set((state) => ({
      ...state,
      selected,
    }));
  },
  resetSelection: () => set(initialState),
});
