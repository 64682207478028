import { Box } from '@mantine/core';

export const LayoutBackground = () => (
  <Box
    component="svg"
    width="869"
    height="851"
    viewBox="0 0 869 851"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 0,
    }}
  >
    <g opacity="0.3">
      <rect
        x="-631"
        width="1500"
        height="1500"
        rx="750"
        fill="url(#paint0_linear_11183_2617)"
        fillOpacity="0.3"
      />
      <rect
        x="-630.5"
        y="0.5"
        width="1499"
        height="1499"
        rx="749.5"
        stroke="url(#paint1_linear_11183_2617)"
        strokeOpacity="0.3"
      />
      <rect
        x="-443.265"
        y="187.735"
        width="1124.53"
        height="1124.53"
        rx="562.265"
        fill="url(#paint2_linear_11183_2617)"
        fillOpacity="0.3"
      />
      <rect
        x="-442.765"
        y="188.235"
        width="1123.53"
        height="1123.53"
        rx="561.765"
        stroke="url(#paint3_linear_11183_2617)"
        strokeOpacity="0.3"
      />
      <rect
        x="-255.894"
        y="375.106"
        width="749.061"
        height="749.061"
        rx="374.531"
        fill="url(#paint4_linear_11183_2617)"
        fillOpacity="0.2"
      />
      <rect
        x="-255.394"
        y="375.606"
        width="748.061"
        height="748.061"
        rx="374.031"
        stroke="url(#paint5_linear_11183_2617)"
        strokeOpacity="0.3"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11183_2617"
        x1="-353.948"
        y1="1.23304e-05"
        x2="608.09"
        y2="1500"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8D8D8D" stopOpacity="0.56" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11183_2617"
        x1="-241.208"
        y1="-133.744"
        x2="399.74"
        y2="1712.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A6A8A9" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_11183_2617"
        x1="-235.563"
        y1="187.735"
        x2="485.664"
        y2="1312.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8D8D8D" stopOpacity="0.56" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_11183_2617"
        x1="-319.83"
        y1="95.0643"
        x2="286.716"
        y2="1258.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#888888" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_11183_2617"
        x1="-117.541"
        y1="375.106"
        x2="362.876"
        y2="1124.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#939393" />
        <stop offset="1" stopColor="white" stopOpacity="0.05" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_11183_2617"
        x1="-42.7881"
        y1="337.653"
        x2="200.657"
        y2="1124.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B8F94" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </Box>
);
