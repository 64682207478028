import JSZip from 'jszip';

export async function zipUrls(
  data: { url: string; filename: string | undefined }[],
  fileType: 'csv' | 'pdf'
) {
  const zip = new JSZip();

  const urls = data.map((d) => d.url);

  const files = await Promise.all(
    urls.map(async (url) => fetch(url).then((res) => res.blob()))
  );

  files.forEach((file, index) => {
    const defaultFileName = `statement_${index}`;

    const filename = data[index].filename || defaultFileName;

    zip.file(`${filename || defaultFileName}.${fileType}`, file);
  });

  await zip.generateAsync({ type: 'blob' }).then((content) => {
    // create zip blob file
    const zipblob = new Blob([content]);

    // For development and testing purpose
    // Download the zipped file
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(zipblob);
    elem.download = 'compressed.zip';
    elem.click();
  });
}

export async function zipBlobs(
  files: { filename: string; blob: Blob }[],
  fileType: 'csv' | 'pdf'
) {
  const zip = new JSZip();

  files.forEach((file, index) => {
    const defaultFileName = `statement_${index}`;
    const filename = file.filename;
    zip.file(`${filename || defaultFileName}.${fileType}`, file.blob);
  });

  await zip.generateAsync({ type: 'blob' }).then((content) => {
    // create zip blob file
    const zipblob = new Blob([content]);

    // For development and testing purpose
    // Download the zipped file
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(zipblob);
    elem.download = 'compressed.zip';
    elem.click();
  });
}
