import { AmountFilter as AmountInput } from '@finalytic/ui';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { FilterPill } from './FilterPill';

type Value<TValue extends string | number> = { lt?: TValue; gt?: TValue };

type Props = {
  value: Value<string> | null | undefined;
  setValue: (v: Value<string>) => void;
  debounce?: number;
};

export const AmountFilter = ({
  value: v,
  setValue: set,
  debounce = 500,
}: Props) => {
  const [input, setInput] = useState<Value<number> | undefined>(undefined);

  const [delayed] = useDebouncedValue(input, debounce);

  const value = useMemo(() => {
    if (!v) return undefined;
    const { lt, gt } = v;
    return {
      lt: lt && lt !== 'undefined' ? Number(lt) : undefined,
      gt: gt && gt !== 'undefined' ? Number(gt) : undefined,
    };
  }, [v]);

  const setValue = (v: Value<number>) => {
    set({
      lt: v.lt ? String(v.lt) : undefined,
      gt: v.gt ? String(v.gt) : undefined,
    });
  };

  useEffect(() => {
    if (typeof delayed === 'object') {
      setValue(delayed);
    }
  }, [delayed]);

  useEffect(() => {
    setInput(value);
  }, [value]);

  return (
    <AmountInput
      withinPortal
      defaultButtonLabel={'Amount'}
      filterValue={input}
      setFilterValue={setInput}
      customButton={({ buttonLabel, togglePopover, opened }) => {
        const isDefault = input
          ? Object.values(input).every((v) => typeof v !== 'number')
          : !input;

        return (
          <FilterPill
            opened={opened}
            isDefault={isDefault}
            onClick={togglePopover}
            label={buttonLabel}
            resetFilter={() => setInput({ gt: undefined, lt: undefined })}
          />
        );
      }}
    />
  );
};
