import { Maybe } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { ActivityIcon as ActivityIconType } from './_types';

type Props = { icon: Maybe<ActivityIconType> };

export const ActivityIcon = (props: Props) => {
  if (!props.icon) return null;
  return (
    <Box pos="absolute" bottom={-8} right={-5}>
      <Icon icon={props.icon} />
    </Box>
  );
};

const Icon = ({ icon: initial }: Props) => {
  switch (initial) {
    case 'update':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#3B82F6"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.84391 6.30242L6.45479 8.69151C6.20013 8.94616 6.07281 9.07348 5.97163 9.21855C5.88183 9.34731 5.8075 9.4862 5.75018 9.63234C5.6856 9.79699 5.65029 9.97356 5.57966 10.3267L5.48915 10.7792C5.44194 11.0153 5.41834 11.1333 5.45317 11.2175C5.48362 11.2911 5.54209 11.3496 5.61569 11.38C5.69987 11.4148 5.81791 11.3912 6.05397 11.344L6.50649 11.2535L6.5065 11.2535C6.85963 11.1829 7.03619 11.1476 7.20084 11.083C7.34698 11.0257 7.48587 10.9513 7.61463 10.8615C7.75969 10.7604 7.88701 10.633 8.14166 10.3784L10.5308 7.9893C9.76435 7.68062 9.15257 7.06884 8.84391 6.30242ZM11.1258 7.39426L11.1768 7.34329L11.1768 7.34328C11.3034 7.21665 11.3667 7.15333 11.4117 7.09222C11.6708 6.73982 11.6708 6.25992 11.4117 5.90752C11.3667 5.84641 11.3034 5.78309 11.1768 5.65645L11.1768 5.65644C11.0501 5.52979 10.9868 5.46647 10.9257 5.42153C10.5733 5.16243 10.0934 5.16243 9.74099 5.42152C9.67988 5.46645 9.61656 5.52977 9.48992 5.65641L9.48991 5.65642L9.43895 5.70738C9.64002 6.53876 10.2944 7.19318 11.1258 7.39426Z"
            fill="white"
          />
        </svg>
      );
    case 'insert':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#84CC16"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M6 8.49999H8.33333M10.6667 8.49999H8.33333M8.33333 8.49999V6.16666M8.33333 8.49999V10.8333"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'sync':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#8B5CF6"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M6.33333 5.16666L5 6.49999M5 6.49999L6.33333 7.83332M5 6.49999H9.66667M10.3333 9.16666L11.6667 10.5M11.6667 10.5L10.3333 11.8333M11.6667 10.5H7"
            stroke="white"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'error':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#EF4444"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M6.3335 10.5L10.3335 6.5M6.3335 6.5L10.3335 10.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'delete':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#F97316"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M6 8.5H8.33333H10.6667"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'fetch':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#10B981"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M11 5.83334L5.66667 11.1667M5.66667 11.1667V7.5M5.66667 11.1667H9.33333"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'refresh':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#6366F1"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M5 8.50001C5 10.341 6.49238 11.8333 8.33333 11.8333C10.1743 11.8333 11.3333 10 11.3333 10M11.6667 8.50001C11.6667 6.65906 10.1852 5.16667 8.33333 5.16667C6.11111 5.16667 5 7.00001 5 7.00001M5 7.00001V5.83334M5 7.00001H6.16667M11.3333 10H10.1667M11.3333 10V11.1667"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'resolve':
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            fill="#22C55E"
          />
          <rect
            x="1.3335"
            y="1.5"
            width="14"
            height="14"
            rx="7"
            stroke="white"
            strokeWidth="1.5"
          />
          <path
            d="M5.66699 8.83333L7.33366 10.5L11.0003 6.5"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};
