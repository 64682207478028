import { TenantSetupGuide } from '@vrplatform/ui-common';
import { create } from 'zustand';

export type Job = {
  id: string;
  title: string | undefined;
  createdAt: string;
  status: 'failed' | 'pending';
};

type State = {
  setupGuide: TenantSetupGuide | null;
  jobs: Job[];
};

type Actions = {
  setSetupGuide: (updated: TenantSetupGuide | null) => void;
  setJobs: (pending: Job[]) => void;
  reset: () => void;
};

const initialState: State = {
  setupGuide: null,
  jobs: [],
};

export const useSetupGuideStore = create<State & Actions>()((set, _get) => ({
  ...initialState,
  setJobs: (st) => {
    set((state) => ({
      ...state,
      jobs: st,
    }));
  },
  setSetupGuide: (updated) => {
    set((state) => ({
      ...state,
      setupGuide: updated,
    }));
  },
  reset: () => {
    set(initialState);
  },
}));
