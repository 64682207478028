import { Maybe, source as Source } from '@finalytic/graphql';

export function getSourceDescription(
  source: Maybe<Source> | undefined,
  options?: {
    ignoreRemoteIdLength?: boolean;
  }
) {
  const remoteId = source?.remoteId || '';
  const status = source?.status || '';
  const description = source?.description || '';

  const getPrefix = () => {
    if (!remoteId) return '';

    if (options?.ignoreRemoteIdLength) return `[${remoteId}] `;

    return remoteId.length < 6 ? `[${remoteId}] ` : '';
  };

  const prefix = getPrefix();

  return (
    (status === 'inactive'
      ? `**ARCHIVED** ${prefix}${description}`
      : `${prefix}${description || 'No description'}`) || ''
  );
}
