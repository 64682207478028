import { InputSelect } from '@finalytic/components';
// import { Select as InputSelect, SingleSelect } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

type SelectType = {
  options: string[];
  withSearch?: boolean;
  multiple?: boolean;
};

type Props = SelectType & FormInputBaseType;

export const Select = ({
  options,
  label,
  defaultValue,
  formKey,
  placeholder,
  withSearch,
  required,
  onValueChange,
  multiple,
}: Props) => {
  const { control } = useFormContext();

  const opts = useMemo(
    () => options.map((i) => ({ label: toTitleCase(i) || i, value: i })),
    [options]
  );

  return (
    <>
      {label && (
        <Text size="sm" ta="left" fw={500}>
          {label}
        </Text>
      )}
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue || undefined}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          if (multiple) {
            const c =
              (typeof value === 'string'
                ? value?.split(',')
                : (value as string[])) || [];

            const current = opts.filter((i) => c.includes(i.value));

            return (
              <InputSelect
                data={{
                  options: opts,
                  sort: null,
                }}
                dropdownProps={{
                  hideSearch:
                    typeof withSearch === 'boolean' ? !withSearch : false,
                  width: 'target',
                }}
                value={current}
                type="multiple"
                setValue={(item) => {
                  // onValueChange?.(formKey, item);
                  onChange(item);
                }}
                inputProps={{
                  placeholder,
                }}
              />
            );
          }

          return (
            <InputSelect
              data={{
                options: opts,
                sort: null,
              }}
              type="single"
              dropdownProps={{
                hideSearch:
                  typeof withSearch === 'boolean' ? !withSearch : false,
                width: 'target',
              }}
              value={value ? { label: toTitleCase(value), value } : null}
              setValue={(item) => {
                if (!item?.value) return;

                onValueChange?.(formKey, item?.value);
                onChange(item?.value);
              }}
              inputProps={{
                placeholder,
              }}
            />
          );
        }}
      />
    </>
  );
};
