import { Button } from '@finalytic/components';
import {
  Query,
  useMutation,
  useQuery,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import {
  HiddenFeatureIndicator,
  useSettingByIdMutation,
} from '@finalytic/data-ui';
import { CrossIcon, Icon, OwnerPortalIcon } from '@finalytic/icons';
import {
  LoadingIndicator,
  showErrorNotification,
  showNotification,
  showSuccessNotification,
  updateNotification,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Alert,
  Anchor,
  Box,
  Center,
  CheckIcon,
  CloseButton,
  Collapse,
  Divider,
  FileButton,
  Group,
  Image,
  LoadingOverlay,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { OWNER_PORTAL_COLORS, getTenantAddress } from '@vrplatform/ui-common';
import { useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import { SettingsTitle, SettingsViewContainer } from './_components';

export const SettingsFeaturesOwnerPortal = () => {
  return (
    <SettingsViewContainer>
      <SettingsTitle type="view-title">Owner portal</SettingsTitle>
      <Text
        component="p"
        size={'sm'}
        mb="xl"
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        Adjust settings and theme used by the owner portal.
      </Text>
      <OwnerPortalSettingsForm />

      <HiddenFeatureIndicator permission="partner-admin" p="xs" mt="xl">
        <SettingsTitle type="heading" mb="md">
          Feature flags (partner admins only)
        </SettingsTitle>
      </HiddenFeatureIndicator>
    </SettingsViewContainer>
  );
};

type FormValues = {
  logo: string | File | undefined | null;
  primaryColor: string | undefined | null;
  showReservationsView: boolean;
  showTaxStatements: boolean;
  showCancelledReservations: boolean;
  showReservationTotal: boolean;
};

export const getOwnerPortalSettings = (q: Query, tenantId: string) => {
  const showReservationSettingId = q.setting({
    where: {
      tenant_id: { _eq: tenantId },
      key: { _eq: SETTING_KEY },
      target: { _eq: SETTING_TARGETS.showReservations },
    },
    limit: 1,
  })[0]?.id as string | undefined;

  const hideTaxStatementSettingId = q.setting({
    where: {
      tenant_id: { _eq: tenantId },
      key: { _eq: SETTING_KEY },
      target: { _eq: SETTING_TARGETS.hideTaxStatements },
    },
    limit: 1,
  })[0]?.id as string | undefined;

  const hideReservationTotalSettingId = q.setting({
    where: {
      tenant_id: { _eq: tenantId },
      key: { _eq: SETTING_KEY },
      target: { _eq: SETTING_TARGETS.hideReservationTotal },
    },
    limit: 1,
  })[0]?.id as string | undefined;

  const hideCancelledReservationsSettingId = q.setting({
    where: {
      tenant_id: { _eq: tenantId },
      key: { _eq: SETTING_KEY },
      target: { _eq: SETTING_TARGETS.hideCancelledReservations },
    },
    limit: 1,
  })[0]?.id as string | undefined;

  return {
    showReservationSettingId,
    hideTaxStatementSettingId,
    hideReservationTotalSettingId,
    hideCancelledReservationsSettingId,
  };
};

const SETTING_TARGETS = {
  showReservations: 'showReservations',
  hideCancelledReservations: 'hideCancelledReservations',
  hideReservationTotal: 'hideReservationTotal',
  hideTaxStatements: 'hideTaxStatements',
} as const;

const SETTING_KEY = 'tenantSettings';
const SETTING_LEFT_TYPE = 'ownerPortal';

const useTeamData = () => {
  const [teamId] = useTeamId();

  const {
    data,
    isLoading: loading,
    refetch,
    error,
  } = useQuery(
    (q, args) => {
      const team = q.tenantById({ id: args.teamId });

      const ownerPortalSettings = getOwnerPortalSettings(q, teamId);

      return {
        logo: team?.logo,
        colorPrimary: team?.colorPrimary,
        address: getTenantAddress(team),
        ...ownerPortalSettings,
      };
    },
    {
      variables: {
        teamId,
      },
    }
  );

  return {
    existingLogo: data?.logo,
    existingColorPrimary: data?.colorPrimary,
    showReservationSettingId: data?.showReservationSettingId,
    hideTaxStatementSettingId: data?.hideTaxStatementSettingId,
    hideReservationTotalSettingId: data?.hideReservationTotalSettingId,
    hideCancelledReservationsSettingId:
      data?.hideCancelledReservationsSettingId,
    teamAddress: data?.address,
    loading,
    refetch,
    error,
  };
};

const OwnerPortalSettingsForm = () => {
  const [{ id: teamId }, refetchTeamContext] = useTeam();
  const { colors } = useMantineTheme();
  const {
    existingLogo,
    existingColorPrimary,
    loading,
    refetch,
    showReservationSettingId,
    hideTaxStatementSettingId,
    hideCancelledReservationsSettingId,
    hideReservationTotalSettingId,
    error,
    teamAddress,
  } = useTeamData();

  const methods = useForm<FormValues>({
    values: {
      logo: existingLogo,
      primaryColor: existingColorPrimary,
      showReservationsView: !!showReservationSettingId,
      showTaxStatements: !hideTaxStatementSettingId,
      showCancelledReservations: !hideCancelledReservationsSettingId,
      showReservationTotal: !hideReservationTotalSettingId,
    },
  });

  const { handleLogoRemoval, handleLogoUpload, handlePrimaryColorChange } =
    useOwnerPortalFormMutations();

  const { mutate } = useSettingByIdMutation({
    successMessage: '',
  });

  const resetHandler = () =>
    methods.reset(
      {
        logo: existingLogo,
        primaryColor: existingColorPrimary,
        showReservationsView: !!showReservationSettingId,
        showTaxStatements: !hideTaxStatementSettingId,
      },
      { keepDirty: false }
    );

  const submitHandler = async (data: FormValues) => {
    const logoHasChanged = data.logo !== existingLogo;
    const primaryColorHasChanged = data.primaryColor !== existingColorPrimary;

    // SETTINGS
    const reservationsHasChanged =
      data.showReservationsView !== !!showReservationSettingId;
    const taxStatementsHasChanged =
      data.showTaxStatements !== !hideTaxStatementSettingId;
    const reservationTotalHasChanged =
      data.showReservationTotal !== !hideReservationTotalSettingId;
    const cancelledReservationsHasChanged =
      data.showCancelledReservations !== !hideCancelledReservationsSettingId;

    try {
      let newLogoUrl: string | undefined;

      if (logoHasChanged && typeof data.logo !== 'string') {
        if (data.logo) {
          const res = await handleLogoUpload(data.logo);
          const url = res?.url;

          if (url) newLogoUrl = url;
        } else await handleLogoRemoval().then(() => refetch());
      }

      if (primaryColorHasChanged) {
        await handlePrimaryColorChange(data.primaryColor);
      }

      const upserts: {
        key: string;
        leftType: string;
        teamId: string;
        target: string;
      }[] = [];

      const deletions: string[] = [];

      if (reservationsHasChanged) {
        if (showReservationSettingId) {
          deletions.push(showReservationSettingId);
        } else {
          upserts.push({
            key: SETTING_KEY,
            leftType: SETTING_LEFT_TYPE,
            teamId,
            target: SETTING_TARGETS.showReservations,
          });
        }
      }

      if (taxStatementsHasChanged) {
        if (data.showTaxStatements && hideTaxStatementSettingId) {
          deletions.push(hideTaxStatementSettingId);
        }

        if (!data.showTaxStatements && !hideTaxStatementSettingId) {
          upserts.push({
            key: SETTING_KEY,
            leftType: SETTING_LEFT_TYPE,
            teamId,
            target: SETTING_TARGETS.hideTaxStatements,
          });
        }
      }

      if (reservationTotalHasChanged) {
        if (data.showReservationTotal && hideReservationTotalSettingId) {
          deletions.push(hideReservationTotalSettingId);
        }

        if (!data.showReservationTotal && !hideReservationTotalSettingId) {
          upserts.push({
            key: SETTING_KEY,
            leftType: SETTING_LEFT_TYPE,
            teamId,
            target: SETTING_TARGETS.hideReservationTotal,
          });
        }
      }

      if (cancelledReservationsHasChanged) {
        if (
          data.showCancelledReservations &&
          hideCancelledReservationsSettingId
        ) {
          deletions.push(hideCancelledReservationsSettingId);
        }

        if (
          !data.showCancelledReservations &&
          !hideCancelledReservationsSettingId
        ) {
          upserts.push({
            key: SETTING_KEY,
            leftType: SETTING_LEFT_TYPE,
            teamId,
            target: SETTING_TARGETS.hideCancelledReservations,
          });
        }
      }

      // UPSERT & DELETE settings
      await mutate({
        type: 'upsert_many',
        settings: upserts,
        extraMutation: (q) => {
          if (!deletions.length) return;

          q.delete_setting({
            where: {
              tenant_id: { _eq: teamId },
              id: { _in: deletions },
            },
          })?.affected_rows;
        },
      });

      methods.reset(
        {
          logo: newLogoUrl || data.logo,
          primaryColor: data.primaryColor,
          showReservationsView: data.showReservationsView,
          showTaxStatements: data.showTaxStatements,
        },
        { keepDirty: false }
      );

      refetchTeamContext();
      refetch();
      showSuccessNotification({
        title: 'Success!',
        message: 'Your changes have been saved.',
      });
    } catch (error: any) {
      console.error(error);
      showErrorNotification({
        title: 'Failed to save changes.',
        message:
          error?.message ||
          error ||
          'We failed to save your changes. Please reload the window and try again.',
      });
    }
  };

  const isCustomPrimaryColor = !!(
    existingColorPrimary && ['hostingBlue'].includes(existingColorPrimary)
  );

  const errorHandler: SubmitErrorHandler<FormValues> = (errors) => {
    Object.entries(errors || {}).forEach(([key, value]) => {
      showErrorNotification({
        title: 'Form validation failed',
        message: `${toTitleCase(key)}: ${value?.message}`,
      });
    });
  };

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Icon icon="AlertTriangleIcon" size={24} color="red" mb="lg" />
        <Text component="p" size="sm" m={0} c="red" mb="md">
          Failed to load data. Please try again.
        </Text>
        <Button
          onClick={() => refetch()}
          variant="primary"
          leftIcon={'RefreshCwIcon'}
        >
          Try again
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <FormProvider {...methods}>
        <SettingsTitle type="sub-heading">Team Logo</SettingsTitle>
        <InputLogo loading={loading} />

        <SettingsTitle type="sub-heading">Primary color</SettingsTitle>

        {isCustomPrimaryColor && (
          <Text mb="xs" size="sm" component="p" m={0}>
            Custom color pallette:{' '}
            <Text component="span" td="underline">
              {existingColorPrimary} (
              {colors[`portal.${existingColorPrimary}`][6]})
            </Text>
          </Text>
        )}

        <InputPrimaryColor />

        <Divider my="xl" />

        <SettingsTitle type="sub-heading">Reservations</SettingsTitle>
        <InputReservations />

        <Divider mb="xl" mt="lg" />

        <SettingsTitle type="sub-heading">Tax statements</SettingsTitle>
        <InputTaxStatements teamAddress={teamAddress} />

        <Group mt={40} justify="right">
          <Button type="button" onClick={resetHandler}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={methods.handleSubmit(submitHandler, errorHandler)}
            loading={methods.formState.isSubmitting}
            disabled={!methods.formState.isDirty}
          >
            Save changes
          </Button>
        </Group>
      </FormProvider>
      <LoadingOverlay visible={loading} />
    </Box>
  );
};

const InputReservations = () => {
  const { control, watch } = useFormContext<FormValues>();

  const showReservationsView = watch('showReservationsView');

  return (
    <>
      <Group wrap="nowrap" align="flex-start" gap="lg" justify="space-between">
        <Text mb="xs" c="gray" size="sm" component="p" m={0}>
          Show reservations for all owners on your owner portal. Each owner will
          be able to see the reservations for their listings.
        </Text>
        <Controller
          control={control}
          defaultValue={false}
          name="showReservationsView"
          render={({ field }) => {
            return (
              <Switch
                checked={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
              />
            );
          }}
        />
      </Group>

      <Collapse in={showReservationsView}>
        <SettingsTitle type="sub-heading" mt="md">
          Totals & Payments
        </SettingsTitle>

        <Group
          wrap="nowrap"
          align="flex-start"
          gap="lg"
          justify="space-between"
        >
          <Text mb="xs" c="gray" size="sm" component="p" m={0}>
            The owner is able to view the total of a reservation and outstanding
            amounts. Disable this setting to hide the total from the owner.
          </Text>
          <Controller
            control={control}
            defaultValue={true}
            name="showReservationTotal"
            render={({ field }) => {
              return (
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  disabled={field.disabled}
                />
              );
            }}
          />
        </Group>

        <SettingsTitle type="sub-heading" mt="md">
          Cancellations
        </SettingsTitle>

        <Group
          wrap="nowrap"
          align="flex-start"
          gap="lg"
          justify="space-between"
        >
          <Text mb="xs" c="gray" size="sm" component="p" m={0}>
            By default, we display cancelled reservations on the owner portal.
            Disable this setting to hide them from the owner.
          </Text>
          <Controller
            control={control}
            defaultValue={true}
            name="showCancelledReservations"
            render={({ field }) => {
              return (
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  disabled={field.disabled}
                />
              );
            }}
          />
        </Group>
      </Collapse>
    </>
  );
};

const InputTaxStatements = ({
  teamAddress,
}: { teamAddress: ReturnType<typeof getTenantAddress> | undefined }) => {
  const { control } = useFormContext<FormValues>();

  const isDisabled = teamAddress?.values.countryCode !== 'US';

  return (
    <>
      <Group wrap="nowrap" align="flex-start" gap="lg" justify="space-between">
        <Text mb="xs" c="gray" size="sm" component="p" m={0}>
          Provide tax statements for your US-based owners. Generate statements
          and they will be able to see them on their owner portal.
        </Text>
        <Controller
          control={control}
          defaultValue={false}
          name="showTaxStatements"
          render={({ field }) => {
            return (
              <Group wrap="nowrap">
                {isDisabled && (
                  <Tooltip
                    label="Please update your team address to use this feature"
                    withArrow
                  >
                    <Icon icon="AlertTriangleIcon" size={18} color={'orange'} />
                  </Tooltip>
                )}
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  disabled={isDisabled}
                />
              </Group>
            );
          }}
        />
      </Group>
      {isDisabled && (
        <Alert
          mt="xs"
          color="gray"
          title={
            <Group gap={'xs'}>
              <Icon icon="FileInvoiceIcon" size={18} />
              <Text component="span" size="sm" pt={2} fw={500} c="black">
                US Address Required
              </Text>
            </Group>
          }
          mb="lg"
          p={'xs'}
        >
          Tax statements are only available for teams & owners with a US
          address. Please{' '}
          <Anchor component={Link} to="/settings/team/general">
            update your address
          </Anchor>{' '}
          to start using this feature.
          {teamAddress?.full && ` Your team address is: ${teamAddress.full}`}
        </Alert>
      )}
    </>
  );
};

const InputPrimaryColor = () => {
  const { control } = useFormContext<FormValues>();

  return (
    <>
      <Controller
        control={control}
        name="primaryColor"
        render={({ field }) => {
          return (
            <>
              <SimpleGrid cols={12} spacing={12}>
                {Object.entries(OWNER_PORTAL_COLORS).map(([key, color]) => {
                  const isActive = key === field.value;

                  const setColor = (colorKey: string) => {
                    if (field.value === colorKey) field.onChange(undefined);
                    else field.onChange(colorKey);
                  };

                  return (
                    <Box
                      component="button"
                      onClick={() => setColor(key)}
                      key={key}
                      sx={(theme) => ({
                        cursor: 'pointer',
                        height: 32,
                        width: 32,
                        transition: '0.1s outline-color ease-in-out',
                        backgroundColor: color,
                        borderRadius: '100%',
                        outline: '1.5px solid',
                        outlineColor: isActive
                          ? theme.colors[theme.primaryColor][4]
                          : 'white',
                        border: isActive ? '2px solid white' : 'none',
                      })}
                    />
                  );
                })}
              </SimpleGrid>
            </>
          );
        }}
      />
    </>
  );
};

const InputLogo = ({ loading }: { loading: boolean }) => {
  const { control } = useFormContext<FormValues>();

  return (
    <Controller
      control={control}
      name="logo"
      render={({ field }) => {
        const setFile = (file: File | null) => {
          if (!file) return;
          // check if file size is greater than 5mb
          if (file.size > 5 * 1024 * 1024) {
            showErrorNotification({
              color: 'yellow',
              title: 'Error: File Size',
              message: 'File size must be less than 5mb',
            });
            return;
          }

          field.onChange(file);
        };

        const resetFile = () => field.onChange(undefined);

        const imageSrc =
          typeof field.value === 'string' || typeof field.value === 'undefined'
            ? field.value
            : field.value
              ? URL.createObjectURL(field.value as any)
              : undefined;

        return (
          <Group wrap="nowrap" mb="xl">
            <Center
              w={50}
              h={50}
              sx={(theme) => ({
                border: `1px solid ${theme.colors.gray[2]}`,
                backgroundColor: theme.colors.gray[0],
                borderRadius: theme.radius.md,
                position: 'relative',
              })}
            >
              {imageSrc ? (
                <Image width={40} height={40} fit="contain" src={imageSrc} />
              ) : loading ? (
                <LoadingIndicator size="xs" />
              ) : (
                <OwnerPortalIcon size={30} />
              )}
              {field.value && (
                <CloseButton
                  size={'sm'}
                  title="Remove logo"
                  onClick={resetFile}
                  sx={(theme) => ({
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    borderRadius: '100%',
                  })}
                />
              )}
            </Center>

            <Text size="xs" color="gray" sx={{ flexGrow: 1 }}>
              JPG, PNG, GIF, or WEBP image smaller than 5 MB
            </Text>
            <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => (
                <Button sx={{ marginLeft: 'auto' }} {...props}>
                  Upload image
                </Button>
              )}
            </FileButton>
          </Group>
        );
      }}
    />
  );
};

const useOwnerPortalFormMutations = () => {
  const [teamId] = useTeamId();

  const { mutate: getSignedUrl, loading: loadingTrpc } =
    useTrpcMutation('uploadTeamLogo');

  const { mutate, loading: loadingMutation } = useMutation(
    (
      q,
      args:
        | { type: 'updateLogoUrl'; url: string | undefined; teamId: string }
        | {
            type: 'updatePrimaryColor';
            color: string | undefined | null;
            teamId: string;
          }
    ) => {
      if (args.type === 'updateLogoUrl') {
        const res = q.updateTenantById({
          pk_columns: { id: args.teamId },
          _set: { logo: args.url },
        });

        return {
          ok: !!res?.id,
        };
      }

      if (args.type === 'updatePrimaryColor') {
        const res = q.updateTenantById({
          pk_columns: { id: args.teamId },
          _set: { colorPrimary: args.color || null },
        });

        return {
          ok: !!res?.id,
        };
      }

      return {
        ok: true,
      };
    }
  );

  const [loading, setLoading] = useState(false);

  const cloudflareVariantKey = '256x256';

  const handleLogoUpload = async (file: File) => {
    setLoading(true);

    if (!file) {
      setLoading(false);
      showErrorNotification({ message: 'Missing file to upload.' });
      return {
        ok: false,
      };
    }

    const notificationId = 'update-logo';
    try {
      showNotification({
        id: notificationId,
        loading: true,
        title: 'Loading...',
        message: 'We will update you shortly.',
        autoClose: false,
        color: 'violet',
        radius: 10,
      });

      // Get cloudflare upload url using hasura backend
      const data = await getSignedUrl({ teamId });
      const url = data.signedUrl;

      if (!url) throw new Error('Error fetching signed url.');

      const formData = new FormData();

      function renameFile(originalFile: File, newName: string) {
        return new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
      }

      const updatedFile = renameFile(file, `${teamId}-${file.name}`);

      formData.append('file', updatedFile);

      // Send image to cloudflare
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Error sending image to cloudflare');
        }
        return res;
      });

      // Get cloudflare image url and add to tenant logo
      const imageUrl = await response.json().then((data) => {
        const variant = ((data as any)?.result?.variants as string[]).find(
          (i) => i.includes(cloudflareVariantKey)
        );
        if (!variant) {
          throw new Error('Missing variant');
        }
        return variant;
      });

      await mutate({
        args: { type: 'updateLogoUrl', url: imageUrl, teamId },
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Error uploading logo to DB.');
        }
      });

      updateNotification({
        id: notificationId,
        message: 'Successfully updated your action.',
        title: 'Success!',
        color: 'teal',
        icon: <CheckIcon size={18} color={'#fff'} />,
        radius: 10,
        loading: false,
        autoClose: 3000,
      });
      setLoading(false);

      return { ok: true, url: imageUrl };
    } catch (error: any) {
      console.error(error);
      updateNotification({
        id: notificationId,
        message: error?.message || 'Error uploading logo.',
        title: 'Failed to update.',
        color: 'red',
        icon: <CrossIcon size={18} color={'#fff'} />,
        radius: 10,
        loading: false,
        autoClose: 3000,
      });
      setLoading(false);

      return {
        ok: false,
      };
    }
  };

  return {
    handleLogoUpload,
    loading: loading || loadingMutation || loadingTrpc,
    handleLogoRemoval: () =>
      mutate({ args: { type: 'updateLogoUrl', teamId, url: '' } }),
    handlePrimaryColorChange: (color: string | undefined | null) =>
      mutate({ args: { type: 'updatePrimaryColor', teamId, color } }),
  };
};
