import { gqlV2, useEnabledFeatures } from '@finalytic/data';
import { HomeIcon, LoaderIcon, PinIcon } from '@finalytic/icons';
import { Maybe, hasValue } from '@finalytic/utils';
import { ComponentProps } from 'react';
import { DrawerInfoCard } from '../../../drawers/_components';
import { ListingCollectionSelect } from './ListingCollectionSelect';
import { ListingStatusSelect } from './ListingStatusSelect';

type StatusSelectProps = ComponentProps<typeof ListingStatusSelect>;

type Props = {
  id: string;
  formattedAddress: string | undefined;
  collection: ComponentProps<typeof ListingCollectionSelect>['collection'];
  currency: Maybe<gqlV2.currency_enum>;
} & StatusSelectProps;

export const ListingInfoCard = (listing: Props) => {
  const { GL } = useEnabledFeatures();

  return (
    <DrawerInfoCard
      rows={[
        {
          icon: PinIcon,
          title: 'Address',
          text: listing.formattedAddress,
        },
        GL
          ? undefined
          : {
              icon: LoaderIcon,
              title: 'Automations',
              text: <ListingStatusSelect inputDisabled {...listing} />,
            },
        {
          icon: HomeIcon,
          title: 'Listing Group',
          text: (
            <ListingCollectionSelect
              collection={listing.collection}
              listingId={listing.id}
            />
          ),
        },
      ].filter(hasValue)}
    />
  );
};
