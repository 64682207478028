import { Button } from '@finalytic/components';
import { captureSentryError, useDashboard, useTracking } from '@finalytic/data';
import {
  LoadingIndicator,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { Maybe, hasValue } from '@finalytic/utils';
import {
  ActionIcon,
  Box,
  Center,
  Group,
  Modal,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useId, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { downloadFromUrl } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import { zipUrls } from '../views/statements/_hooks/zip.util';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

//   'https://files-bucket.dev7695.workers.dev/2023-11-20/87597df0-1b1f-406c-b3ae-94b58e369a5e.pdf';

export const PdfPreviewModal = ({
  closeModal,
  opened,
  entity,
  type,
}: {
  opened: boolean;
  closeModal: () => void;
  entity: {
    urls: Maybe<string>[];
    status: string;
    id: Maybe<string>;
  } | null;
  type: 'tax_statement' | 'gl_owner_statement';
}) => {
  const { track } = useTracking();
  const [dashboard] = useDashboard();
  const [debounced] = useDebouncedValue(entity, 500, {
    leading: true,
  });

  const notifyId = useId();

  const [activeUrlIndex, setActiveUrlIndex] = useState(0);

  const [numPages, setNumPages] = useState<number>();
  const { colorScheme } = useMantineColorScheme();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const urls = (debounced?.urls || entity?.urls)?.filter(hasValue);
  const url = urls?.[activeUrlIndex];

  const downloadUrls = async () => {
    showLoadingNotification({
      id: notifyId,
    });

    try {
      if (!urls?.length) throw new Error('No PDFs found');

      if (urls.length > 1) {
        await zipUrls(
          urls.map((x, index) => ({
            url: x,
            filename: `${entity?.id || notifyId}_${index}`,
          })),
          'pdf'
        );
      } else {
        downloadFromUrl(
          urls[0],
          `${type === 'gl_owner_statement' ? 'owner-statement' : 'tax-statement'}.pdf`
        );
      }

      updateSuccessNotification({
        id: notifyId,
        title: 'PDF Export',
        message: 'PDF has been downloaded successfully',
      });
    } catch (error: any) {
      captureSentryError(error);
      updateErrorNotification({
        id: notifyId,
        title: 'PDF Download Error',
        message:
          error?.message ||
          'An error occurred while downloading the PDF. Please try again.',
      });
    }
  };

  return (
    <Modal
      opened={opened}
      size={820}
      radius="md"
      onClose={closeModal}
      title={
        <Group justify="space-between" w="100%" pr="xs">
          <Box>
            <Text
              component="span"
              display="block"
              c={colorScheme === 'dark' ? 'dimmed' : 'gray'}
              size="xs"
            >
              PDF Preview
            </Text>{' '}
            <Text fw={500}>
              {type === 'gl_owner_statement'
                ? 'Owner Statement'
                : 'Tax Statement'}
            </Text>
          </Box>
          {url && (
            <Button
              leftIcon={'FileDownloadIcon'}
              size="xs"
              onClick={() => {
                const user_type =
                  dashboard === 'owner' ? 'owner' : 'property_manager';

                if (type === 'tax_statement') {
                  track('tax_statement_downloaded', {
                    taxStatementStatus: entity?.status,
                    taxStatementId: entity?.id,
                    taxStatementUrl: url,
                    userType: user_type,
                  });
                } else {
                  track('gl_owner_statement_downloaded', {
                    ownerStatementId: entity?.id,
                    ownerStatementUrls: urls.join(', '),
                    userType: user_type,
                  });
                }

                downloadUrls();
              }}
            >
              Download
            </Button>
          )}
        </Group>
      }
      centered
      styles={{
        title: {
          fontWeight: 500,
          flex: 1,
        },
      }}
    >
      {(urls?.length || 0) > 1 && (
        <Group justify="space-between" gap={5} w="100%">
          <ActionIcon
            disabled={!activeUrlIndex}
            variant="subtle"
            color="gray"
            onClick={() => setActiveUrlIndex((e) => e - 1)}
            sx={{
              transform: 'rotate(90deg)',
              ':active': {
                transform: 'rotate(90deg) translate(1px)',
              },
            }}
          >
            <Icon icon="ChevronIcon" size={20} />
          </ActionIcon>
          {!!urls?.length && (
            <Text size="xs" c="gray" component="span">
              File {activeUrlIndex + 1} of {urls?.length}
            </Text>
          )}
          <ActionIcon
            disabled={!urls || activeUrlIndex === urls.length - 1}
            onClick={() => setActiveUrlIndex((e) => e + 1)}
            variant="subtle"
            color="gray"
            sx={{
              transform: 'rotate(-90deg)',
              ':active': {
                transform: 'rotate(-90deg) translate(-1px)',
              },
            }}
          >
            <Icon icon="ChevronIcon" size={20} />
          </ActionIcon>
        </Group>
      )}

      <Center mih="40vh" miw={700}>
        {url ? (
          <Document
            loading={<LoadingIndicator size="md" />}
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => {
              captureSentryError(error);
            }}
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={800}
              />
            ))}
          </Document>
        ) : (
          <Text>Missing PDF. Unable to load preview.</Text>
        )}
      </Center>
    </Modal>
  );
};
