import { hasValue } from '@finalytic/utils';
import { getCountryByIsoCode, getStateByIsoCode } from '../iso-3166';

export type FormatAddressOptions = {
  format?: 'owner_statement';
};

export type Address = {
  line1: string;
  line2: string | undefined;
  city: string;
  postcode: string;
  stateCode: string;
  countryCode: string;
};

export const formatAddress = (
  values: {
    line1: string;
    line2: string | undefined;
    city: string;
    postcode: string;
    state: string | undefined;
    stateCode: string | undefined;
    country: string | undefined;
    countryCode: string | undefined;
  },
  options?: FormatAddressOptions
) => {
  const getState = () => {
    const state =
      values.stateCode &&
      values.countryCode &&
      getStateByIsoCode(values.stateCode, values.countryCode)?.name;

    if (state) return state;

    return values.state || ''; // legacy state field
  };

  const getCountry = () => {
    const country =
      values.countryCode && getCountryByIsoCode(values.countryCode)?.name;

    if (country) return country;

    return values.country || ''; // legacy country field
  };

  const temp: Address = {
    line1: values.line1,
    line2: values.line2,
    city: values.city,
    stateCode: getState(),
    postcode: values.postcode,
    countryCode: getCountry(),
  };

  if (options?.format === 'owner_statement') {
    // custom formatting for owner statement
    if (values.countryCode?.toLowerCase() === 'us') {
      return [
        temp.line2,
        temp.line1,
        temp.city,
        `${values.stateCode || values.state} ${temp.postcode}`,
      ]
        .filter(hasValue)
        .join(', ');
    }
  }

  return Object.values(temp).filter(hasValue).join(', ');
};
