import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const AverageDailyRateCard = (props: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      {...props}
      title="Average Daily Rate"
      href="/reservations"
      total="average"
      isCurrencyAmount
      type="averageDailyRate"
    />
  );
};
