import {
  Query,
  gqlV2,
  useInvalidateQueries,
  useSubscriptionOrQuery,
} from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { Subscription } from '@finalytic/graphql';
import { Maybe, hasValue, sortBy } from '@finalytic/utils';
import {
  AutomationTemplateVisibility,
  formatUserName,
  getActionMessage,
  getSyncStatus,
} from '@vrplatform/ui-common';
import { useEffect } from 'react';
import { getWorkflowActionRow, getWorkflowChangeRow } from './_utils';

export const getSync = (
  sync: gqlV2.sync,
  {
    includeDetails,
    overwrites,
  }: {
    includeDetails: boolean;
    overwrites: Parameters<typeof getWorkflowActionRow>[1];
  }
) => {
  const leftConnection = sync?.automation?.leftConnection;
  const rightConnection = sync?.automation?.rightConnection;
  const extractConnection = sync.connection;

  const errors = includeDetails
    ? sync
        .changes({
          where: {
            status: { _eq: 'failed' },
          },
        })
        .map<Workflow['errors']['rowData'][number]>((x) =>
          getWorkflowChangeRow(x, overwrites, 'synced-from')
        )
    : [];

  const aggregateSyncedTo: Workflow['aggregateSyncedTo'] = includeDetails
    ? sync
        .changes_aggregate({
          where: {
            syncType: { _eq: 'push' },
          },
        })
        .aggregate?.count() || 0
    : 0;

  const aggregateSyncedFrom: Workflow['aggregateSyncedFrom'] = includeDetails
    ? sync
        .changes_aggregate({
          where: {
            syncType: { _eq: 'pull' },
          },
        })
        .aggregate?.count() || 0
    : 0;

  return {
    id: sync.id,
    status: getSyncStatus(sync),
    title: getActionMessage(sync.message || '', overwrites),
    triggerRef: sync.triggerRef,
    createdAt: sync.createdAt,
    workflowId: sync.id,
    hypervisorRef: 'trigger' as const,
    triggeredBy: formatUserName(sync.user || {}, {
      showEmpty: true,
    }),
    automation: {
      id: sync.automationId,
      name: sync.automation?.title || sync?.automation?.ttemplate?.title || '',
      templateType: sync?.automation?.ttemplate?.type,
      visibility: sync?.automation?.ttemplate
        ?.visibility as Maybe<AutomationTemplateVisibility>,
    },
    leftConnection: {
      id: leftConnection?.app?.id,
      icon: leftConnection?.app?.iconRound,
      name: leftConnection?.name || leftConnection?.app?.name,
    },
    rightConnection: {
      id: rightConnection?.app?.id,
      icon: rightConnection?.app?.iconRound,
      name: rightConnection?.name || rightConnection?.app?.name,
    },
    extractConnection: {
      id: sync.connectionId,
      icon: extractConnection?.app?.iconRound,
      name: extractConnection?.name || extractConnection?.app?.name,
      uniqueRef: extractConnection?.uniqueRef,
      appName: extractConnection?.app?.name,
    },
    isExtractWorkflow: !!extractConnection?.id && !sync?.automationId,
    errors: {
      aggregate: errors.length,
      rowData: errors,
    },
    aggregateSyncedTo,
    aggregateSyncedFrom,
  };
};

const getJobPlan = (
  q: Query | Subscription,
  jobPlan: gqlV2.jobPlan,
  workflowIds: string[],
  overwrites: Parameters<typeof getWorkflowActionRow>[1]
) => {
  const leftConnection = jobPlan?.automation?.leftConnection;
  const rightConnection = jobPlan?.automation?.rightConnection;
  const extractConnection = jobPlan.connection;

  const whereErrors: gqlV2.action_bool_exp = {
    status: {
      _eq: 'failed',
    },
  };

  const actionErrors = jobPlan
    ?.actions({
      where: whereErrors,
      limit: 5,
      order_by: [
        {
          createdAt: 'asc_nulls_last',
        },
      ],
    })
    .map((action) => getWorkflowActionRow(action, overwrites));

  const jobErrors = jobPlan
    .jobs({
      where: {
        status: {
          _eq: 'failed',
        },
      },
    })
    .map((j) => getLegacyJob(j, overwrites));

  const errorCount =
    jobPlan?.actions_aggregate({ where: whereErrors }).aggregate?.count() || 0;

  const planError = getLegacyJob(jobPlan, overwrites);

  const errors = {
    rowData: [...actionErrors, ...jobErrors, planError].filter(
      (x) => x.status === 'failed'
    ),
    aggregate: errorCount + jobErrors.length,
  };

  // const issuesCount =
  //   jobPlan
  //     .issues_aggregate({
  //       where: {
  //         uniqueRef: { _is_null: false },
  //         status: { _eq: 'open' },
  //       },
  //       distinct_on: ['uniqueRef'],
  //     })
  //     .aggregate?.count() || 0;

  return {
    id: jobPlan.id,
    status: jobPlan.status,
    title: jobPlan.title,
    triggerRef: undefined as string | undefined,
    createdAt: jobPlan.createdAt,
    workflowId: jobPlan.workflowId,
    hypervisorRef: jobPlan.hypervisorRef,
    triggeredBy: formatUserName(jobPlan.user || {}, {
      showEmpty: true,
    }),
    automation: {
      id: jobPlan.automationId,
      templateType: jobPlan?.automation?.ttemplate?.type,
      visibility: jobPlan?.automation?.ttemplate
        ?.visibility as Maybe<AutomationTemplateVisibility>,
      name:
        jobPlan.automation?.title ||
        jobPlan?.automation?.ttemplate?.title ||
        '',
    },
    leftConnection: {
      id: leftConnection?.app?.id,
      icon: leftConnection?.app?.iconRound,
      name: leftConnection?.name || leftConnection?.app?.name,
    },
    rightConnection: {
      id: rightConnection?.app?.id,
      icon: rightConnection?.app?.iconRound,
      name: rightConnection?.name || rightConnection?.app?.name,
    },
    extractConnection: {
      id: jobPlan.connectionId,
      icon: extractConnection?.app?.iconRound,
      uniqueRef: extractConnection?.uniqueRef,
      name: extractConnection?.name || extractConnection?.app?.name,
      appName: extractConnection?.app?.name,
    },
    isExtractWorkflow: !!extractConnection?.id && !jobPlan?.automationId,
    errors,
    aggregateSyncedTo:
      jobPlan
        .actions_aggregate({
          where: { status: { _neq: 'failed' } },
        })
        .aggregate?.count() || 0,
    aggregateSyncedFrom:
      q
        .aggregateSourceOps({
          where: {
            job: {
              plan: {
                workflowId: { _in: workflowIds },
              },
            },
          },
        })
        ?.aggregate?.count() || 0,
  };
};

export type Workflow = ReturnType<typeof getJobPlan>;

export function useAutomationHistoryDrawerSubscription(workflowIds: string[]) {
  const { refreshKeys } = useRunDrawer();
  const invalidate = useInvalidateQueries();
  const { syncIds } = useRunDrawer();

  const queryData = useSubscriptionOrQuery(
    (q, args) => {
      if (!args.workflowIds.length) return null;

      const overwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      const oldWorkflowIds = args.workflowIds.filter(
        (x) => !args.syncIds.includes(x)
      );
      const newSyncIds = args.workflowIds.filter((x) =>
        args.syncIds.includes(x)
      );

      const jobPlans = !oldWorkflowIds.length
        ? []
        : q
            .jobPlans({
              where: {
                workflowId: {
                  _in: oldWorkflowIds,
                },
              },
              order_by: [
                {
                  createdAt: 'desc',
                },
              ],
            })
            .map((jobPlan) => getJobPlan(q, jobPlan, workflowIds, overwrites));

      const syncs = !newSyncIds.length
        ? []
        : q
            .syncs({
              where: {
                id: { _in: newSyncIds },
              },
              order_by: [{ createdAt: 'desc' }],
            })
            .map<Workflow>((s) =>
              getSync(s, {
                includeDetails: true,
                overwrites,
              })
            );

      return sortBy([...syncs, ...jobPlans], 'createdAt', 'desc').filter(
        (x) => x.id
      );
    },
    {
      queryKey: ['tasks', 'automations', 'jobPlans'],
      skip: !workflowIds.length,
      variables: {
        workflowIds,
        syncIds: syncIds || [],
      },
      subscribe: true,
    }
  );

  const statuses = queryData.data?.map((x) => x.status).filter(hasValue);

  const isCompleted =
    !!statuses?.length && statuses.every((x) => x === 'completed');

  useEffect(() => {
    const keys = refreshKeys?.filter(hasValue) || [];

    if (!!keys.length && isCompleted) {
      invalidate(keys as any[]);
    }
  }, [isCompleted, refreshKeys]);

  return queryData;
}

export const getLegacyJob = (
  x: gqlV2.job | gqlV2.jobPlan,
  overwrites: {
    pattern: string;
    message: string;
  }[]
) =>
  getWorkflowActionRow(
    {
      status: x.status,
      title: x.title,
      id: x.id,
      automationId: x.automationId,
      schema: {
        uniqueRef: 'automation',
      },
      sourceLinks() {
        return [];
      },
      inputJson() {
        return undefined;
      },
      outputJson() {
        return undefined;
      },
    } as any,
    overwrites
  );
