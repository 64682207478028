import { activeStatus_enum, recurringFee_bool_exp } from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';

export function whereRecurringFees({
  search,
  tenantId,
  status,
  listingIds,
}: {
  tenantId: string;
  search?: Maybe<string>;
  status?: Maybe<activeStatus_enum>;
  listingIds?: Maybe<string[]>;
}) {
  return ensure<recurringFee_bool_exp>({
    tenantId: { _eq: tenantId },
    title: search ? { _ilike: `%${search}%` } : undefined,
    status: status ? { _eq: status } : { _eq: 'active' },
    listingSubscriptions: listingIds
      ? { listingId: { _in: listingIds } }
      : undefined,
  });
}
