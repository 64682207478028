import { Query } from '@finalytic/graphql';
import {
  DEMO_TEST_PARTNER_TENANT_ID,
  VRP_TENANT_ID,
  XIMPLIFI_TENANT_ID,
} from '../consts';
import { whereAutomationTemplateVisibility } from './whereAutomations';
import { whereConnectionStatusDefault } from './whereConnectionStatusDefault';

export const deleteCheckTenant = (
  q: Query,
  { tenantId }: { tenantId: string }
) => {
  const tenant = q.tenantById({ id: tenantId });
  const listings =
    tenant
      ?.listings_aggregate({
        where: {
          status: {
            // TODO: VRP-4868 remove enabled/disabled
            _nin: ['inactive', 'disabled'],
          },
        },
      })
      .aggregate?.count() || 0;
  const connections =
    tenant
      ?.connections_aggregate({
        where: {
          appId: {
            _neq: 'finalytic',
          },
          status: whereConnectionStatusDefault,
        },
      })
      ?.aggregate?.count() || 0;
  const automations =
    tenant
      ?.automations_aggregate({
        where: {
          ttemplate: {
            visibility: whereAutomationTemplateVisibility({
              isPartnerAdmin: false,
              isSuperAdmin: false,
              isVrpAdmin: false,
            }),
          },
        },
      })
      ?.aggregate?.count() || 0;
  /*
  const owners =
    tenant
      ?.members_aggregate({
        where: {
          role: {
            _eq: 'owner',
          },
          status: {
            _eq: 'active',
          },
          user: {
            status: {
              _neq: 'archived',
            },
          },
        },
      })
      .aggregate?.count() || 0;
  */
  const partnerTeams = tenant?.clients_aggregate().aggregate?.count() || 0;

  return {
    canDelete:
      !listings &&
      !connections &&
      !automations &&
      !partnerTeams &&
      ![
        VRP_TENANT_ID,
        DEMO_TEST_PARTNER_TENANT_ID,
        XIMPLIFI_TENANT_ID,
      ].includes(tenantId),
    listings,
    connections,
    automations,
    partnerTeams,
  };
};

export const deleteCheckConnection = (
  q: Query,
  { connectionId }: { connectionId: string }
) => {
  const ownerStatements =
    q
      .ownerStatementAggregate({
        where: {
          lines: {
            _or: [
              {
                reservation: {
                  connectionId: { _eq: connectionId },
                },
              },
              {
                source: {
                  connectionId: { _eq: connectionId },
                },
              },
            ],
          },
        },
      })
      ?.aggregate?.count() || 0;
  const listings =
    q
      .listingAggregate({
        where: {
          status: {
            // TODO: VRP-4868 remove enabled/disabled
            _nin: ['inactive', 'enabled'],
          },
          connectionId: {
            _eq: connectionId,
          },
        },
      })
      ?.aggregate?.count() || 0;
  const automations =
    q
      .automationAggregate({
        where: {
          _or: [
            {
              leftConnectionId: { _eq: connectionId },
            },
            {
              rightConnectionId: { _eq: connectionId },
            },
          ],
        },
      })
      ?.aggregate?.count() || 0;

  return {
    canDelete: !ownerStatements && !listings && !automations,
    ownerStatements: ownerStatements,
    listings: listings,
    automations: automations,
  };
};

export const deleteCheckListing = (
  q: Query,
  { listingId }: { listingId: string }
) => {
  const ownerStatementsTotal =
    q
      ?.ownerStatementAggregate({ where: { listingId: { _eq: listingId } } })
      ?.aggregate?.count() || 0;

  return {
    canDelete: !ownerStatementsTotal,
    ownerStatements: ownerStatementsTotal,
  };
};

export const deleteCheckOwner = (
  q: Query,
  { ownerId, tenantId }: { ownerId: string; tenantId: string }
) => {
  const ownerStatementsTotal =
    q
      ?.ownerStatementAggregate({
        where: {
          owners: { ownerId: { _eq: ownerId } },
          tenantId: { _eq: tenantId },
        },
      })
      ?.aggregate?.count() || 0;

  return {
    canDelete: !ownerStatementsTotal,
    ownerStatements: ownerStatementsTotal,
  };
};

export const deleteCheckNewOwner = (
  q: Query,
  { ownerId, tenantId }: { ownerId: string; tenantId: string }
) => {
  const ownerStatementsTotal =
    q
      ?.ownerStatementAggregate({
        where: {
          owners: { newOwnerId: { _eq: ownerId } },
          tenantId: { _eq: tenantId },
        },
      })
      ?.aggregate?.count() || 0;

  const taxStatementsTotal =
    q
      ?.taxStatementAggregate({
        where: {
          ownerId: { _eq: ownerId },
          tenantId: { _eq: tenantId },
          status: { _nin: ['draft', 'ignored'] },
        },
      })
      ?.aggregate?.count() || 0;

  const usersTotal =
    q
      ?.ownerUserAccessAggregate({
        where: {
          ownerId: { _eq: ownerId },
        },
      })
      ?.aggregate?.count() || 0;

  const isPmsOwner = !!q.owner({ id: ownerId })?.sourceId;

  return {
    canDelete:
      !isPmsOwner &&
      !ownerStatementsTotal &&
      !usersTotal &&
      !taxStatementsTotal,
    ownerStatements: ownerStatementsTotal,
    userAccess: usersTotal,
    taxStatements: taxStatementsTotal,
    isPmsOwner,
  };
};
