import { Box, Group, Modal } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../button';
import { Input, InputWrapper } from '../input';

type RenameModalProps = {
  opened: boolean;
  onClose: () => void;
  title: string;
  inputTitle: string;
  inputPlaceholder?: string;
  defaultValue?: string;
  onSubmit: (value: string) => void;
  zIndex?: number;
  loadingQuery?: boolean;
  loadingMutation?: boolean;
};

export const RenameModal = ({
  opened,
  onClose,
  title,
  inputTitle,
  inputPlaceholder = '',
  defaultValue,
  onSubmit,
  zIndex,
  loadingQuery,
}: RenameModalProps) => {
  type FormInputs = {
    rename: string;
  };

  const methods = useForm<FormInputs>({
    values: {
      rename: defaultValue || '',
    },
  });

  const submit = async (data: FormInputs) => {
    return onSubmit(data.rename?.trim());
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={500}
      title={title}
      zIndex={zIndex}
      centered
      styles={{
        title: {
          fontWeight: 500,
        },
      }}
    >
      <Box
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        onReset={onClose}
      >
        <Box mt={'xs'} mb={'xl'}>
          <Controller
            control={methods.control}
            name="rename"
            rules={{
              validate: (value: string) =>
                !!value.trim() || `${inputTitle} is required`,
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper
                id={field.name}
                label={inputTitle}
                error={error?.message}
              >
                <Input
                  name={field.name}
                  placeholder={inputPlaceholder}
                  type="text"
                  value={field.value}
                  setValue={field.onChange}
                  error={!!error}
                  data-autofocus
                  loadingQuery={loadingQuery}
                />
              </InputWrapper>
            )}
          />
        </Box>
        <Group justify="right" my={5}>
          <Button type="reset" disabled={methods.formState.isSubmitting}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={methods.formState.isSubmitting}
          >
            Submit
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
