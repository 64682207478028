import { create } from 'zustand';
import { TableFilterSlice, createTableFilterSlice } from './table-filter.slice';
import {
  TableSelectionSlice,
  createTableSelectionSlice,
} from './table-selection.slice';

export const useGenericTableStore = create<
  TableSelectionSlice & TableFilterSlice & { resetStore: () => void }
>()((set, get, store) => ({
  ...createTableFilterSlice(set, get, store),
  ...createTableSelectionSlice(set, get, store),
  resetStore: () => set(store.getInitialState()),
}));
