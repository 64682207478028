import { journalEntry as JournalEntry } from '@finalytic/graphql';
import { JournalEntryType } from '../queries/whereJournalEntries';

export function getJournalEntryType(
  journalEntry: JournalEntry
): JournalEntryType | null {
  const recurringFeeId = journalEntry.recurringFeeId;
  const transactionType = journalEntry.transaction?.type;
  const reservationId = journalEntry.reservationId;

  if (recurringFeeId) return 'recurringFee';

  if (transactionType === 'deposit') return 'deposit';
  if (transactionType === 'expense') return 'expense';
  if (transactionType === 'transfer') return 'transfer';

  if (reservationId) return 'reservation';

  return null;
}
