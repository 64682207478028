import { gqlV2, useTeamId } from '@finalytic/data';
import { activeStatus_enum, paidStatus_enum } from '@finalytic/graphql';
import { Maybe, hasValue } from '@finalytic/utils';
import { whereTransactions } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useExpenseTableFilter } from './ExpenseTableFilter';

export function useWhereExpenses(status: activeStatus_enum) {
  const [teamId] = useTeamId();
  const { filter } = useExpenseTableFilter();

  const listingIds = (filter.listingIds || []).filter(hasValue);
  const search = filter.search?.trim();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return useMemo<gqlV2.transaction_bool_exp>(
    () =>
      whereExpenses({
        tenantId: teamId,
        date: filter.date,
        listingIds,
        search,
        status,
        accountId: filter.accountId,
        paidStatus: filter.paidStatus as Maybe<paidStatus_enum>,
        reconciled: filter.reconciled,
        contactId: filter.contactId,
      }),
    [
      teamId,
      filter.date,
      ...listingIds,
      search,
      status,
      filter.accountId,
      filter.paidStatus,
      filter.reconciled,
      filter.contactId,
    ]
  );
}

export const whereExpenses = (
  input: Omit<Parameters<typeof whereTransactions>[0], 'type'>
): gqlV2.transaction_bool_exp =>
  whereTransactions({ ...input, type: 'expense' });
