import { Button } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ReactNode } from 'react';

type FilterByCategoryButtonProps = {
  children: ReactNode;
  onClick: () => void;
  isActive: boolean;
  sx?: EmotionSx;
};

export const FilterByCategoryButton = ({
  children,
  onClick,
  isActive,
  sx = {},
}: FilterByCategoryButtonProps) => {
  const sx1: EmotionSx = (theme, u) => {
    return Object.assign(
      {
        height: '35px',
        position: 'relative',
        color: 'initial',
        fontSize: '15px',
        textDecoration: 'none',
        flexShrink: 0,
        border: 0,
        borderRadius: 0,
        fontWeight: 400,
        '&:after': {
          position: 'absolute',
          content: '""',
          bottom: 0,
          left: 0,
          right: 0,
          height: 3,
          backgroundColor: isActive ? theme.colors[theme.primaryColor][8] : '',
          borderRadius: '5px 5px 0 0',
        },
        '.view-filter': {
          padding: '3px 6px',
          // borderRadius: 10,
        },
        '&:hover .view-filter': {
          backgroundColor: !isActive ? '#EEEFF1' : '',
        },
        '&:hover .view-filter-dropdown': {
          opacity: 1,
        },
        button: {
          height: 25,
        },
      },
      typeof sx === 'function' ? sx(theme, u) : sx
    );
  };
  return (
    <Button variant="white" onClick={onClick} pb={2} px={12} sx={sx1}>
      {children}
    </Button>
  );
};
