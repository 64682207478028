import { sortBy } from '@finalytic/utils';
import { Box, Checkbox, Stack } from '@mantine/core';
import {
  Address,
  getCountryByIsoCode,
  getStateByIsoCode,
  getStatesOfCountry,
} from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, InputWrapper } from '../Input';
import { InputSelect } from '../InputSelect';
import { InputAddressAutocomplete } from './InputAddressAutocomplete';
import { InputCountry, useInputCountryOptions } from './InputCountry';

type FormData = { address: Address };

const defaultCountryCode = 'US';

const trapSpacesForRequiredFields = (value: string | undefined) =>
  !!value?.trim() || false;

export function AddressFormInputs({
  fieldsetDisabled,
  alwaysRequired,
}: { fieldsetDisabled?: boolean; alwaysRequired?: boolean }) {
  const [disabled, setDisabled] = useState(false);

  const methods = useFormContext<FormData>();

  const required = alwaysRequired ? true : fieldsetDisabled ? false : !disabled;

  return (
    <Stack>
      <Box
        component="fieldset"
        disabled={fieldsetDisabled || disabled}
        sx={{
          padding: 0,
          border: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <CountrySelect required={required} />

        <StreetInput required={required} />

        <Controller
          control={methods.control}
          name="address.line2"
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <InputWrapper label="Unit (optional)" sx={{ flex: 1 }}>
              <Input
                {...field}
                placeholder="Apartment, suite, etc. (optional)"
                autoComplete="off"
                error={!!error}
              />
            </InputWrapper>
          )}
        />

        <Box
          sx={(theme) => ({
            alignItems: 'flex-start',
            justifyContent: 'stretch',
            display: 'flex',
            gap: theme.spacing.md,
          })}
        >
          <PostalcodeInput required={required} />
          <Controller
            control={methods.control}
            name="address.city"
            defaultValue=""
            rules={{
              required,
              validate: !required ? () => true : trapSpacesForRequiredFields,
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper
                sx={{ flex: 2 }}
                label="City"
                error={error?.message}
                required={required}
              >
                <Input
                  {...field}
                  placeholder="City"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />
        </Box>

        <StateSelect required={required} />
      </Box>

      {!alwaysRequired && (
        <Checkbox
          label="I do not have an address"
          size="xs"
          data-testid="no-address-checkbox"
          checked={fieldsetDisabled ? false : disabled}
          disabled={fieldsetDisabled}
          onChange={() => {
            setDisabled((e) => !e);
            methods.clearErrors('address');

            methods.setValue(
              'address',
              {
                city: '',
                line1: '',
                line2: '',
                postcode: '',
                stateCode: 'AL',
                countryCode: 'US',
              },
              {
                shouldDirty: true,
                shouldTouch: true,
              }
            );
          }}
        />
      )}
    </Stack>
  );
}

const StreetInput = ({ required }: { required: boolean }) => {
  const methods = useFormContext<FormData>();

  const countryCode = methods.watch('address.countryCode');

  const isUSA = countryCode === 'US';

  return (
    <Controller
      control={methods.control}
      name="address.line1"
      defaultValue=""
      rules={{
        required,
        validate: !required ? () => true : trapSpacesForRequiredFields,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputWrapper
            label="Address"
            error={error?.message}
            required={required}
          >
            {!isUSA ? (
              <Input
                {...field}
                placeholder="Address"
                autoComplete="off"
                error={!!error}
              />
            ) : (
              <InputAddressAutocomplete
                value={field.value}
                onChange={field.onChange}
                onAddressSelect={(address) => {
                  const countryCode =
                    getCountryByIsoCode(address.countryCode)?.isoCode || '';
                  const stateCode =
                    getStateByIsoCode(address.stateCode, countryCode)
                      ?.isoCode || '';

                  methods.clearErrors('address');

                  methods.setValue(
                    'address',
                    {
                      ...address,
                      line2: '',
                      countryCode,
                      stateCode,
                    },
                    {
                      shouldDirty: true,
                      shouldTouch: true,
                    }
                  );

                  if (!stateCode) {
                    methods.setError(
                      'address.stateCode',
                      {
                        message: 'Please select state manually',
                        type: 'validate',
                      },
                      { shouldFocus: true }
                    );
                  }

                  if (!countryCode) {
                    methods.setError(
                      'address.countryCode',
                      {
                        message: 'Please select country manually',
                      },
                      { shouldFocus: true }
                    );
                  }
                }}
                placeholder="Street"
                error={!!error}
                countryCode={countryCode}
              />
            )}
          </InputWrapper>
        );
      }}
    />
  );
};

const PostalcodeInput = ({ required }: { required: boolean }) => {
  const methods = useFormContext<FormData>();

  const countryCode = methods.watch('address.countryCode');
  const isUSA = countryCode === 'US';

  const label = isUSA ? 'ZIP' : 'Postal code';

  return (
    <Controller
      control={methods.control}
      name="address.postcode"
      defaultValue=""
      rules={{
        required,
        validate: !required ? () => true : trapSpacesForRequiredFields,
      }}
      render={({ field, fieldState: { error } }) => (
        <InputWrapper
          label={label}
          sx={{ flex: 1 }}
          error={error?.message}
          required={required}
        >
          <Input
            {...field}
            placeholder={label}
            autoComplete="off"
            error={!!error}
          />
        </InputWrapper>
      )}
    />
  );
};

const CountrySelect = ({ required }: { required: boolean }) => {
  const methods = useFormContext<FormData>();

  const country = methods.watch('address.countryCode');

  useEffect(() => {
    if (!country) {
      methods.setValue('address.countryCode', defaultCountryCode, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [country]);

  return (
    <Controller
      control={methods.control}
      name="address.countryCode"
      defaultValue=""
      rules={{
        required,
      }}
      render={({ field, fieldState: { error } }) => {
        const options = useInputCountryOptions();

        const value = !required ? null : field.value || null;

        useEffect(() => {
          if (
            field.value &&
            !options.all.some((option) => option.value === field.value)
          ) {
            methods.setError(
              'address.countryCode',
              {
                message: 'Please select a country',
              },
              { shouldFocus: true }
            );
          }
        }, [field.value, options]);

        return (
          <InputWrapper
            label="Country"
            error={error?.message}
            required={required}
          >
            <InputCountry
              value={value}
              setValue={(v) => {
                if (!v?.value) return;

                field.onChange(v.value);
                methods.clearErrors('address.countryCode');
              }}
              disabled={!required}
              error={error?.message}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

const StateSelect = ({ required: formRequired }: { required: boolean }) => {
  const methods = useFormContext<FormData>();

  const country = methods.watch('address.countryCode');
  const state = methods.watch('address.stateCode');

  const options = useMemo<
    { label: string; value: string; country: string }[]
  >(() => {
    const states = getStatesOfCountry(country);
    return sortBy(
      states.map((state) => ({
        value: state.isoCode,
        label: state.name,
        country: state.country,
      })),
      (x) => x.label,
      'asc'
    );
  }, [country]);

  const optionRequired = options.length > 0;

  const required = formRequired && optionRequired;

  useEffect(() => {
    // update state if it's not available in the list => e.g. when country was changed we need to update to the first state in the new list
    if (options.length && !options.some((option) => option.value === state)) {
      methods.setValue('address.stateCode', options[0]?.value, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [state, options]);

  return (
    <Controller
      control={methods.control}
      name="address.stateCode"
      defaultValue=""
      rules={{
        required,
      }}
      render={({ field, fieldState: { error } }) => {
        const value = !required
          ? null
          : field.value
            ? options.find((option) => option.value === field.value) || null
            : null;

        return (
          <InputWrapper
            label="State"
            error={error?.message}
            required={required}
          >
            <InputSelect
              data={{
                options: options,
              }}
              type="single"
              value={value}
              setValue={(v) => v?.value && field.onChange(v.value)}
              dropdownProps={{
                width: 'target',
                withinPortal: true,
                zIndex: 400,
              }}
              inputProps={{
                placeholder: !optionRequired ? 'No state available' : 'State',
                disabled: !required,
                error: !!error?.message,
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};
