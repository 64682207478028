import { InputSelect } from '@finalytic/components';
import { useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import { source_bool_exp } from '@finalytic/graphql';
import { Text } from '@mantine/core';
import { getSourceDescription } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

type SourceType = {
  name: string;
  namespace:
    | {
        name: string;
      }
    | string;
};
type SelectDataType = {
  sourceType: string | SourceType;
  connectionId?: string | null;
};

type Props = SelectDataType & FormInputBaseType;

export function getNamespaceAndType(
  sourceType?: string | SourceType | undefined | null
): Readonly<[string, string]> {
  if (!sourceType) return ['', ''];
  if (typeof sourceType === 'string') {
    return sourceType.includes('.')
      ? (sourceType.split('.') as any)
      : ['', sourceType];
  }
  if (sourceType.namespace) {
    if (typeof sourceType.namespace === 'string')
      return [sourceType.namespace, sourceType.name];
    return [sourceType.namespace.name, sourceType.name];
  }
  return ['', ''];
  // name, namespace.name
}

function useSources(
  sourceType: string | undefined | SourceType,
  connectionId?: string | null
) {
  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { sourceType, teamId, search, connectionId }, { limit, offset }) => {
      const [namespace, name] = getNamespaceAndType(sourceType);
      const where: source_bool_exp = {
        type: { _eq: name },
        connectionId: connectionId ? { _eq: connectionId } : undefined,
        connection:
          namespace && !connectionId
            ? { appId: { _eq: namespace } }
            : undefined,
        tenantId: { _eq: teamId },
        _or: search
          ? [
              {
                description: { _ilike: `%${search.trim()}%` },
              },
              {
                remoteId: { _ilike: `%${search.trim()}%` },
              },
            ]
          : undefined,
      };

      const aggregate = q.sourceAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .source({
          where,
          order_by: [{ description: 'asc' }],
          limit,
          offset,
        })
        .map((source) => ({
          value: source.id,
          label: getSourceDescription(source),
          // type: source.type,
          // description: getSourceDescription(source),
        }));

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        sourceType,
        teamId,
        connectionId,
        search,
      },
      skip: !teamId || !sourceType,
      queryKey: ['sources'],
    }
  );

  return {
    ...queryData,
    setSearch,
  };
}

function useSingleSource(sourceId: string | undefined) {
  const { data, isLoading: loading } = useQuery(
    (q, { sourceId }) => {
      const source = q.sourceById({
        id: sourceId,
      });
      return {
        id: source?.id,
        description: getSourceDescription(source),
      };
    },
    {
      variables: {
        sourceId,
      },
      skip: !sourceId,
      queryKey: 'sources',
    }
  );

  return {
    source: data,
    loading,
  };
}

export const SelectData = ({
  connectionId,
  label,
  defaultValue,
  formKey,
  placeholder,
  required,
  sourceType,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  const queryData = useSources(sourceType, connectionId);

  return (
    <>
      {label && (
        <Text size="sm" fw={500}>
          {label}
        </Text>
      )}
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue || undefined}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          const { source, loading: loadingSingleSource } =
            useSingleSource(value);

          const selectValue = useMemo(
            () =>
              source?.id
                ? {
                    label: source.description as string,
                    value: source.id as string,
                  }
                : null,
            [source?.id]
          );

          const extendedOnChange = (val: string | undefined) => {
            onValueChange?.(formKey, val);
            onChange(val);
          };

          return (
            <InputSelect
              infiniteData={queryData}
              value={selectValue}
              setValue={(item) => extendedOnChange(item?.value || '')}
              type="single"
              inputProps={{
                loadingQuery: loadingSingleSource,
                placeholder,
              }}
              dropdownProps={{
                width: 'target',
              }}
              // data={sources}
              // withSearch
              // loading={loading}
              // value={selectValue}
              // setValue={(item) => extendedOnChange(item?.value)}
              // removeValue={() => extendedOnChange('')}
              // popoverWidth="target"
              // onSearchInput={setSearch}
              // withBorder
              // placeholder={placeholder}
              // searchPlaceholder={placeholder}
              // inputLoading={loadingSingleSource}
            />
          );
        }}
      />
    </>
  );
};
