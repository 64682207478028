import { z } from 'zod';

export const expenses_csv_import_schema = z
  .object({
    'Bill ID': z.string().optional(),
    'Issue Date': z.string().refine(
      (date) => {
        return date.match(/^\d{4}-\d{2}-\d{2}$/);
      },
      {
        message: 'Please provide a date in the format YYYY-MM-DD',
      }
    ),
    'Payment Date': z.string().refine(
      (date) => {
        return date.match(/^\d{4}-\d{2}-\d{2}$/);
      },
      {
        message: 'Please provide a date in the format YYYY-MM-DD',
      }
    ),
    Currency: z.string({ required_error: 'Currency is required' }),
    Contact: z.string({ required_error: 'Contact is required' }),
    Memo: z.string().optional(),
    Reservation: z.string().optional(),
    Listing: z.string().optional(),
    Account: z.string({
      required_error: 'Account is required',
    }),
    'Bill to Owner': z.boolean({
      required_error: 'Bill to Owner is required',
      invalid_type_error: 'Bill to Owner must be a boolean (TRUE or FALSE)',
    }),
    Amount: z.number({
      required_error: 'Amount is required',
      invalid_type_error: 'Amount must be a number',
    }),
  })
  .superRefine((data, ctx) => {
    const reservation = data.Reservation?.trim();
    const listing = data.Listing?.trim();

    if (!reservation && !listing) {
      ctx.addIssue({
        code: 'custom',
        message: 'Either Reservation or Listing must be provided',
      });
    }
  });
