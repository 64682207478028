import { Badge } from '@finalytic/components';
import { useQuery } from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { type Maybe, toTitleCase, utc } from '@finalytic/utils';
import { Box, Tooltip } from '@mantine/core';
import {
  formatAddress,
  formatOwnerName,
  formatUserName,
  getListingName,
} from '@vrplatform/ui-common';
import type { PropsWithChildren } from 'react';
import type { ActivityDeltaJson } from '../_types';

export const ActivityDeletedItemBadge = ({
  type,
  objectId,
}: {
  type:
    | 'owner_statement'
    | 'listing_collection'
    | 'user'
    | 'listing'
    | 'owner'
    | 'address'
    | 'contact';
  objectId: string;
}) => {
  if (type === 'address') return <Address objectId={objectId} />;
  if (type === 'listing') return <Listing objectId={objectId} />;
  if (type === 'listing_collection')
    return <ListingCollection objectId={objectId} />;
  if (type === 'owner' || type === 'contact')
    return <Owner objectId={objectId} type={type} />;
  if (type === 'user') return <User objectId={objectId} />;
  if (type === 'owner_statement') return <OwnerStatement objectId={objectId} />;

  return <Badge color="gray">Deleted {toTitleCase(type)}</Badge>;
};

const DeletedBadge = ({
  label,
  loading,
  children,
}: PropsWithChildren<{ label: Maybe<string>; loading: boolean }>) => (
  <Tooltip
    label={
      loading ? (
        <LoadingIndicator size={'xs'} color="white" mt={5} />
      ) : (
        label || ''
      )
    }
    disabled={!label}
    withinPortal
    withArrow
  >
    <Box display="inline">
      <Badge color="dark">{children}</Badge>
    </Box>
  </Tooltip>
);

const OwnerStatement = ({ objectId }: { objectId: string }) => {
  const { data: query1, isLoading: loading1 } = useQuery(
    (q, args) => {
      if (!args.objectId) return null;

      return (
        q
          .auditLogs({
            where: {
              objectId: { _eq: args.objectId },
              op: { _eq: 'delete' },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((log) => {
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            const listingName = getListingName(log.listing, {
              allowEmpty: true,
            });

            const month = delta.find((d) => d.key === 'start_at')?.value;

            return {
              month: utc(month).format('MM/YYYY'),
              listingName,
              deletedListingId: listingName ? null : log.listingId,
            };
          })[0] || null
      );
    },
    {
      skip: !objectId,
      variables: {
        objectId,
      },
    }
  );

  const { data: query2, isLoading: loading2 } = useQuery(
    (q, args) => {
      if (!args.deletedListingId) return null;

      return (
        q
          .auditLogs({
            where: {
              objectId: { _eq: args.deletedListingId },
              op: { _eq: 'delete' },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((log) => {
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            const title = delta.find((d) => d.key === 'title')?.value;
            const name = delta.find((d) => d.key === 'name')?.value;

            return {
              listingName: title || name || 'No name',
            };
          })[0] || null
      );
    },
    {
      skip: !query1?.deletedListingId,
      variables: {
        objectId,
        deletedListingId: query1?.deletedListingId,
      },
    }
  );

  const month = query1?.month;
  const listingName = query1?.listingName || query2?.listingName;

  const label = month && listingName ? `${listingName} - ${month}` : null;
  const loading = loading1 || loading2;

  return (
    <DeletedBadge loading={loading} label={label}>
      Deleted Owner Statement
    </DeletedBadge>
  );
};

const ListingCollection = ({ objectId }: { objectId: string }) => {
  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      if (!args.objectId) return null;

      return (
        q
          .auditLogs({
            where: {
              objectId: { _eq: args.objectId },
              op: { _eq: 'delete' },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((log) => {
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            return {
              name: (delta.find((d) => d.key === 'name')?.value ||
                '') as string,
            };
          })[0] || null
      );
    },
    {
      skip: !objectId,
      variables: {
        objectId,
      },
    }
  );

  return (
    <DeletedBadge loading={loading} label={data?.name || ''}>
      Deleted Listing Group
    </DeletedBadge>
  );
};

const Owner = ({
  objectId,
  type,
}: { objectId: string; type: 'owner' | 'contact' }) => {
  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      if (!args.objectId) return null;

      return (
        q
          .auditLogs({
            where: {
              objectId: { _eq: args.objectId },
              op: { _eq: 'delete' },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((log) => {
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            const firstName = (delta.find((d) => d.key === 'first_name')
              ?.value || '') as string;
            const name = (delta.find((d) => d.key === 'name')?.value ||
              '') as string;

            return {
              name: formatOwnerName(
                {
                  firstName,
                  name,
                },
                { showEmpty: true }
              ),
            };
          })[0] || null
      );
    },
    {
      skip: !objectId,
      variables: {
        objectId,
      },
    }
  );

  return (
    <DeletedBadge loading={loading} label={data?.name || ''}>
      Deleted {toTitleCase(type)}
    </DeletedBadge>
  );
};

const User = ({ objectId }: { objectId: string }) => {
  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      if (!args.objectId) return null;

      return (
        q
          .auditLogs({
            where: {
              objectId: { _eq: args.objectId },
              op: { _eq: 'delete' },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((log) => {
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            const firstName = (delta.find((d) => d.key === 'first_name')
              ?.value || '') as string;
            const lastName = (delta.find((d) => d.key === 'last_name')?.value ||
              '') as string;

            return {
              name: formatUserName(
                {
                  firstName,
                  lastName,
                },
                { showEmpty: true }
              ),
            };
          })[0] || null
      );
    },
    {
      skip: !objectId,
      variables: {
        objectId,
      },
    }
  );

  return (
    <DeletedBadge loading={loading} label={data?.name || ''}>
      Deleted User
    </DeletedBadge>
  );
};

const Listing = ({ objectId }: { objectId: string }) => {
  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      if (!args.objectId) return null;

      return (
        q
          .auditLogs({
            where: {
              objectId: { _eq: args.objectId },
              op: { _eq: 'delete' },
            },
            order_by: [{ createdAt: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((log) => {
            const delta = (log.deltaJson() || []) as ActivityDeltaJson;

            const title = (delta.find((d) => d.key === 'title')?.value ||
              '') as string;
            const name = (delta.find((d) => d.key === 'name')?.value ||
              '') as string;

            return {
              name: title || name || 'No name',
            };
          })[0] || null
      );
    },
    {
      skip: !objectId,
      variables: {
        objectId,
      },
    }
  );

  return (
    <DeletedBadge loading={loading} label={data?.name || ''}>
      Deleted Listing
    </DeletedBadge>
  );
};

const Address = ({ objectId }: { objectId: string }) => {
  const { data, isLoading: loading } = useQuery(
    (q, args) => {
      if (!args.objectId) return null;

      const address =
        q
          .address({
            where: {
              id: { _eq: args.objectId },
            },
          })
          .map((address) =>
            formatAddress({
              line1: address?.line || '',
              line2: address?.lineDetails || '',
              city: address?.city || '',
              postcode: address?.postalCode || '',
              state: address?.state,
              stateCode: address?.stateCode,
              country: address?.country,
              countryCode: address?.countryCode,
            })
          )[0] || null;

      const log = q
        .auditLogs({
          where: {
            objectId: { _eq: args.objectId },
            op: { _eq: 'delete' },
          },
          order_by: [{ createdAt: 'desc_nulls_last' }],
          limit: 1,
        })
        .map((log) => {
          const delta = (log.deltaJson() || []) as ActivityDeltaJson;

          const title = (delta.find((d) => d.key === 'title')?.value ||
            '') as string;
          const name = (delta.find((d) => d.key === 'name')?.value ||
            '') as string;

          return {
            name: title || name || 'No name',
          };
        })[0];

      return {
        address,
        log,
      };
    },
    {
      skip: !objectId,
      variables: {
        objectId,
      },
    }
  );

  if (loading) return <LoadingIndicator size="xs" />;

  if (data?.address) return data.address;

  return (
    <DeletedBadge loading={loading} label={data?.log?.name || ''}>
      Deleted Address
    </DeletedBadge>
  );
};
