import { gqlV2, useQuery, useTeamId } from '@finalytic/data';
import { whereSettingAcrossAutomations } from '@vrplatform/ui-common';

type Props = {
  automation: {
    automationId: string;
    leftConnectionId: string;
    rightConnectionId: string;
  };
  parentMapping: {
    settingKey: string;
    rightType: string;
    isLocal: boolean;
  };
  parentSettingId: string | null;
  mapping: {
    leftType: string | undefined;
  };
  isDefaultExceptions: boolean;
};

export function useAutomationOverrideAggregateQuery(variables: Props) {
  const [teamId] = useTeamId();

  return useQuery(
    (
      q,
      {
        automation,
        mapping,
        parentMapping,
        parentSettingId,
        teamId,
        isDefaultExceptions,
      }
    ) => {
      const defaultWhere: gqlV2.setting_bool_exp = {
        tenant_id: { _eq: teamId },
        key: { _eq: parentMapping.settingKey },
        parentSettingId: { _is_null: true },
        _not: { childSettings: {} },
        target: { _neq: '*' },
        leftType: { _eq: mapping.leftType, _is_null: false },
        localAutomationId: parentMapping.isLocal
          ? { _eq: automation.automationId }
          : { _is_null: true },
        _or: whereSettingAcrossAutomations({
          leftConnectionId: automation.leftConnectionId,
          rightConnectionId: automation.rightConnectionId,
          leftSchema: undefined,
          rightSchema: undefined,
          automationId: automation.automationId,
        }),
      };

      const childSettingWhere: gqlV2.setting_bool_exp = {
        tenant_id: { _eq: teamId },
        automationId: { _eq: automation.automationId },
        target: { _neq: '*' },
        localAutomationId: parentMapping.isLocal
          ? { _eq: automation.automationId }
          : { _is_null: true },
        key: { _eq: parentMapping.settingKey },
        parentSettingId: parentSettingId
          ? { _eq: parentSettingId }
          : { _is_null: true },
        _or: whereSettingAcrossAutomations({
          leftConnectionId: automation.leftConnectionId,
          rightConnectionId: automation.rightConnectionId,
          leftSchema: mapping.leftType,
          rightSchema: parentMapping.rightType,
          automationId: automation.automationId,
        }),
      };

      const where = isDefaultExceptions ? defaultWhere : childSettingWhere;

      return (
        q
          .setting_aggregate({
            where,
          })
          ?.aggregate?.count() || 0
      );
    },
    {
      queryKey: ['settings'],
      variables: { ...variables, teamId },
    }
  );
}
