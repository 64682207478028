import { gqlV2 } from '@finalytic/data';
import { Maybe, toTitleCase } from '@finalytic/utils';

import { Badge } from '@finalytic/components';
import { owner_statement_status_enum } from '@finalytic/graphql';
import { DefaultMantineColor } from '@mantine/core';

type Props = {
  status: Maybe<gqlV2.owner_statement_status_enum>;
};

export const StatementStatusBadge = ({ status }: Props) => {
  if (!status) return null;

  return (
    <Badge
      color={STATEMENT_STATUS_COLORS[status as owner_statement_status_enum]}
    >
      {toTitleCase(status)}
    </Badge>
  );
};

export const STATEMENT_STATUS_COLORS: Record<
  owner_statement_status_enum,
  DefaultMantineColor
> = {
  draft: 'yellow',
  inReview: 'violet',
  posted: 'cyan',
  published: 'green',
  void: 'gray',
};
