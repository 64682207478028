import { Maybe } from '@finalytic/utils';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export type SetupAutomationContextProps = {
  templateType: string;
  addAutomationWithConnection: (val: {
    leftConnectionId: string;
    leftAppId: string;
    rightConnectionId: string;
    rightAppId: string;
    templateType: string;
  }) => Promise<{ automationId: Maybe<string> }>;
};

type View = 'choose-first-connection' | 'choose-second-connection';
type FirstConnection = {
  connectionId: string;
  appId: string;
  otherAllowedAppId: Maybe<string>;
};

type context = {
  setView: (view: View) => void;
  view: View;
  firstConnection: FirstConnection | null;
  setFirstConnection: (connection: FirstConnection | null) => void;
} & SetupAutomationContextProps;

const SetupAutomationModal = createContext<context | null>(null);

export const useSetupAutomationModal = () =>
  useContext(SetupAutomationModal) as context;

export const SetupAutomationModalProvider = ({
  children,
  ...context
}: PropsWithChildren<SetupAutomationContextProps>) => {
  const [view, setView] = useState<context['view']>('choose-first-connection');
  const [firstConnection, setFirstConnection] =
    useState<context['firstConnection']>(null);

  const val = useMemo(
    () => ({
      ...context,
      view,
      setView,
      firstConnection,
      setFirstConnection,
    }),
    [context, view, setView, firstConnection, setFirstConnection]
  );

  return (
    <SetupAutomationModal.Provider value={val}>
      {children}
    </SetupAutomationModal.Provider>
  );
};
