import {
  DEFAULT_THEME,
  type DefaultMantineColor,
  type MantineThemeOverride,
  MultiSelect,
  Select,
  Text,
} from '@mantine/core';
import { THEME_COLORS } from '@vrplatform/ui-common';

export type CustomThemes = 'finalytic' | 'vrplatform';
export type TailwindColors = 'neutral';
export type DashboardThemes = CustomThemes | PortalTheme;
export type ExtendedCustomColors =
  | CustomThemes
  | DefaultMantineColor
  | TailwindColors
  | PortalTheme;
//
type PortalTheme =
  | 'portal.orange'
  | 'portal.teal'
  | 'portal.indigo'
  | 'portal.brown'
  | 'portal.green'
  | 'portal.blue'
  | 'portal.gray'
  | 'portalblack'
  | 'portal.red'
  | 'portal.violett'
  | 'portal.deepPurple'
  | 'portal.purple'
  | 'portal.hostingBlue';

export const theme: MantineThemeOverride = {
  // colorScheme: 'light',
  primaryColor: 'dark', // Mantine color "dark" from default color scheme
  fontFamily: 'Inter',
  radius: { ...DEFAULT_THEME.radius, md: '0.375rem' },
  fontSizes: {
    xs: '0.8125rem',
  },
  components: {
    Text: Text.extend({
      defaultProps: {
        size: 'sm',
      },
    }),
    Select: Select.extend({
      defaultProps: {
        radius: 'md',
      },
      styles: (theme) => ({
        input: {
          '&:focus': {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[theme.primaryColor][4]
            }40`,
          },
        },
      }),
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        radius: 'md',
      },
      styles: (theme) => ({
        input: {
          '&:focus-within': {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[theme.primaryColor][4]
            }40`,
          },
        },
      }),
    }),
  },
  headings: {
    sizes: {
      h1: { fontSize: '2rem' },
      h2: { fontSize: '1.5rem' },
      h3: { fontSize: '1.25rem' },
      h4: { fontSize: '1rem' },
      h5: { fontSize: '0.875rem' },
      h6: { fontSize: '0.75rem' },
    },
  },
  shadows: {
    xxs: '0px 1px 1px 0px rgba(0, 0, 0, 0.06)',
  },
  other: {
    border: {
      button: '1px solid rgba(23, 23, 23, 0.07)',
    },
  },
  colors: THEME_COLORS,
};
