import {
  ApiClientProvider,
  DBUserProvider,
  GtqyClientProvider,
} from '@finalytic/data';
import { ReactNode } from 'react';

type AppRouterProps = {
  children: ReactNode;
};

export function DBProvider(props: AppRouterProps) {
  return (
    <GtqyClientProvider>
      <DBUserProvider>
        <ApiClientProvider>{props.children}</ApiClientProvider>
      </DBUserProvider>
    </GtqyClientProvider>
  );
}
