import { BooleanParam, useQueryParam } from '@finalytic/ui';

export function usePlaidSandbox() {
  const [plaidSandbox, setPlaidSandbox] = useQueryParam(
    'plaid-sandbox',
    BooleanParam
  );

  return {
    plaidSandbox,
    setPlaidSandbox,
  };
}
