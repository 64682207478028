import { user as User } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';
import { formatAddress } from './format-address';

export const getUserAddress = (user: Maybe<User>) => {
  const values: Parameters<typeof formatAddress>[0] = {
    line1: user?.addressRelation?.line || user?.addressLine1 || '',
    line2: user?.addressRelation?.lineDetails || '',
    city: user?.addressRelation?.city || user?.addressCity || '',
    postcode: user?.addressRelation?.postalCode || user?.addressPostcode || '',
    state: user?.addressRelation?.state,
    stateCode: user?.addressRelation?.stateCode,
    country: user?.addressRelation?.country || user?.addressCountry,
    countryCode: user?.addressRelation?.countryCode,
  };

  const full =
    formatAddress(values) || user?.addressRelation?.full || user?.address;

  return {
    values,
    full,
  };
};
