import { Button, Input, InputWrapper } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import {
  AvatarGroup,
  Logo,
  Modal,
  showErrorNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import { Box, Center, Text } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  opened: boolean;
  close: () => void;
  type: 'PMS' | 'Accounting';
  onSubmit: (
    integrationName: string
  ) => Promise<{ ok: boolean; errorMessage?: boolean }>;
};

type FormValues = {
  website: string;
};

export const MissingIntegrationModal = ({
  close,
  opened,
  onSubmit,
  type,
}: Props) => {
  const methods = useForm<FormValues>({ defaultValues: { website: '' } });

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        methods.reset();
      }, 300);
    }
  }, [opened, methods.reset]);

  const successMessages: Record<
    Props['type'],
    { title: string; message: string }
  > = {
    Accounting: {
      title: 'Thank you for letting us know!',
      message:
        'We will notify you once your Accounting software is supported via email.',
    },
    PMS: {
      title: 'Thanks for your input!',
      message:
        'We will notify you once your PMS is supported. Would you like to connect to different PMS, meanwhile?',
    },
  };

  const handleSuccess = async (data: FormValues) => {
    try {
      const res = await onSubmit(data.website);
      if (res?.ok) {
        return showSuccessNotification(successMessages[type]);
      } else if (res?.errorMessage) {
        return showErrorNotification({
          message:
            res?.errorMessage || 'Something went wrong. Please try again.',
        });
      }
    } catch (error: any) {
      console.log(error?.message || error);
      showErrorNotification({ message: error?.message || error });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      size={500}
      styles={{
        body: {
          padding: 20,
          paddingTop: 'initial',
        },
      }}
    >
      <Center>
        <AvatarGroup
          spacing={10}
          size={60}
          leftIcon={<Logo width={40} />}
          leftBgColor="white"
          rightIcon={<Icon icon="CommentQuestionIcon" />}
          rightBgColor="white"
        />
      </Center>
      <Text component="h2" size="xl" fw={500} my="lg" ta="center">
        Request {type}
      </Text>
      <Text component="p" size="sm" ta="center" mb="xl">
        Let us know what {type} you are missing from our list. We will notify
        you once it&apos;s added.
      </Text>
      <Controller
        name="website"
        control={methods.control}
        rules={{
          // Copilot regex
          pattern: {
            value:
              /^(https?:\/\/)?(www\.)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}|((\d{1,3}\.){3}\d{1,3}))(\:[0-9]{1,5})?(\/.*)?$/i,
            message: 'Please enter a valid URL',
          },
          required: 'Please enter a website. (https://integration.com)',
        }}
        render={({ field, fieldState: { error } }) => (
          <Box mb="xl">
            <InputWrapper
              error={error?.message}
              required
              label={`Website of ${type}`}
            >
              <Input
                value={field.value}
                onChange={field.onChange}
                type="url"
                name={field.name}
                placeholder="https://"
                required
                error={error?.message}
              />
            </InputWrapper>
          </Box>
        )}
      />

      <Box>
        <Button
          variant="primary"
          onClick={methods.handleSubmit(handleSuccess)}
          loading={methods.formState.isSubmitting}
          sx={{
            width: '100%',
          }}
        >
          Send integration request
        </Button>
      </Box>
    </Modal>
  );
};
