import {
  gqlV2,
  useDashboard,
  usePartnerTeams,
  useTeamId,
} from '@finalytic/data';
import { whereConnections } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useConnectionFilter } from './ConnectionFilter';

export function useWhereConnections({
  onlyArchived,
}: { onlyArchived: boolean }) {
  const { filter } = useConnectionFilter();

  const [teamId] = useTeamId();
  const [teams] = usePartnerTeams();
  const [dashboard] = useDashboard();
  const appId = filter.appId;
  const search = filter.search;
  const status = onlyArchived ? 'archived' : (filter.status as any);

  const where = useMemo<gqlV2.connection_bool_exp>(
    () =>
      whereConnections({
        teamId,
        search,
        status,
        appId,
      }),
    [teamId, dashboard, appId, search, status, teams]
  );

  return where;
}
