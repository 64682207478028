import { day } from '@finalytic/utils';
import { OnboardingSidebarCard } from './OnboardingSidebarCard';

type Props = {
  type: 'pricing' | 'settings';
};

export const OnboardingSidebar = ({ type }: Props) => {
  if (type === 'pricing')
    return (
      <OnboardingSidebarCard
        title="How the trial works"
        items={[
          {
            id: 'today',
            title: 'Today: Free Access',
            description:
              'Enjoy instant access to all VRPlatform features. Explore everything at no cost.',
            icon: 'CheckCircleIcon',
          },
          {
            id: 'day-13',
            title: 'Day 13: Email Reminder',
            description:
              "We'll send you a reminder email so you do not miss the end of your trial.",
            icon: 'EmailIcon',
          },
          {
            id: 'day-14',
            title: 'Day 14: Subscription begins',
            description: `Your subscription begins on ${day().add(14, 'days').format('LL')}.`,
            icon: 'CreditCardOutcomeIcon',
          },
        ]}
      />
    );

  return (
    <OnboardingSidebarCard
      title="Owner Statement Settings "
      items={[
        {
          id: 'start-date',
          title: 'Start date',
          description:
            'We will generate your first owner statement based on your selected start date. You will be billed from this date.',
          icon: 'CalendarEventIcon',
        },
        {
          id: 'location',
          title: 'Primary location of business',
          description:
            'Default tax rates will be preset based on this location.',
          icon: 'PinIcon',
        },
      ]}
    />
  );
};
