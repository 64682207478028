import { day, hasValue } from '@finalytic/utils';
import { Box, Popover } from '@mantine/core';
import { DateValue, MonthPicker } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { FilterPill } from './FilterPill';

type BaseProps = {
  label: string;
  numberOfColumns?: number;
  columnsToScroll?: number;
};

type ValueProps =
  | {
      value: [DateValue, DateValue] | undefined | null;
      setValue: (v: [DateValue, DateValue] | undefined | null) => void;
      type: 'range';
    }
  | {
      value: DateValue | undefined | null;
      setValue: (v: DateValue | undefined | null) => void;
      type: 'default';
    };

type Props = BaseProps & ValueProps;

export const MonthFilter = ({
  label: defaultLabel,
  value,
  setValue,
  columnsToScroll = 1,
  numberOfColumns = 2,
  type,
}: Props) => {
  const [input, setInput] = useState(value);
  const [opened, handlers] = useDisclosure(false);

  const isDefault = useMemo<boolean>(() => {
    if (type === 'range') return !value || value.filter(hasValue).length === 0;

    return !value;
  }, [value, type]);

  const onClose = () => {
    handlers.close();
    if (input !== value) setValue(input as any);
  };

  useEffect(() => {
    if (input !== value) setInput(value);
  }, [value]);

  const label = useMemo(() => {
    if (isDefault) return defaultLabel;

    if (type === 'range') {
      const i = input as [DateValue, DateValue] | undefined | null;
      const startDate = i?.[0] && day(i?.[0]);
      const endDate = i?.[1] && day(i?.[1]);

      const showStartDateYear =
        !endDate || startDate?.year() !== endDate?.year();

      return [
        startDate?.format(showStartDateYear ? 'MMMM YYYY' : 'MMMM'),
        endDate?.format('MMMM YYYY'),
      ]
        .filter(hasValue)
        .join(' - ');
    } else {
      const i = input as DateValue | undefined | null;
      if (!i) return defaultLabel;
      return day(i).format('MMMM YYYY');
    }
  }, [value, defaultLabel, isDefault, type]);

  return (
    <Popover
      opened={opened}
      onClose={onClose}
      position="bottom-start"
      radius="md"
      shadow="md"
    >
      <Popover.Target>
        <Box>
          <FilterPill
            opened={opened}
            isDefault={isDefault}
            onClick={handlers.toggle}
            label={label}
            resetFilter={undefined}
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <MonthPicker
          type={type}
          numberOfColumns={numberOfColumns}
          columnsToScroll={columnsToScroll}
          value={input || (type === 'range' ? [null, null] : null)}
          onChange={type === 'default' ? (setValue as any) : setInput}
          color="primary"
          styles={(theme) => ({
            monthsListControl: {
              '&:hover': {
                backgroundColor: theme.colors[theme.primaryColor][0] + 20,
              },
              '&[data-selected]': {
                backgroundColor: theme.colors[theme.primaryColor][6],
                '&:hover': {
                  backgroundColor: theme.colors[theme.primaryColor][7],
                },
              },
            },
          })}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
