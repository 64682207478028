import { Button } from '@finalytic/components';
import { LoadingIndicator } from '@finalytic/ui';
import { Box, Group, JsonInput, Stack } from '@mantine/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type FormInputs = {
  userData: string;
};

export const ReservationEditForm = ({
  handleSubmit,
  initialValues,
  onReset,
  isLoading,
}: {
  handleSubmit: (values: FormInputs) => Promise<void>;
  onReset: () => void;
  initialValues: FormInputs;
  isLoading: boolean;
}) => {
  const methods = useForm<FormInputs>({
    values: initialValues,
    mode: 'onChange',
  });

  if (isLoading) return <LoadingIndicator isFullPageLoading size="sm" />;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
        onSubmit={methods.handleSubmit(handleSubmit)}
        onReset={onReset}
      >
        <Stack>
          <Controller
            control={methods.control}
            name="userData"
            rules={{
              validate: (value) => {
                // validate json
                try {
                  JSON.parse(value?.trim() || '{}');
                } catch (_e: any) {
                  return 'Invalid JSON';
                }

                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <JsonInput
                {...field}
                formatOnBlur
                autosize
                minRows={10}
                label="Reservation user data"
                description="Update user data to set custom data on this reservation."
                validationError="Invalid JSON"
                placeholder={`{ "key": "value" }`}
                mb="xs"
                error={fieldState.error?.message}
              />
            )}
          />
        </Stack>

        <Group
          mt="md"
          sx={(theme) => ({
            position: 'sticky',
            bottom: 0,
            height: 60,
            left: 0,
            right: 0,
            paddingBottom: theme.spacing.md,
            backgroundColor: theme.white,
            borderTop: `1px solid ${theme.colors.gray[2]}`,
            paddingTop: theme.spacing.sm,
            paddingInline: theme.spacing.xs,
            marginRight: `-${theme.spacing.xs}`,
            marginLeft: `-${theme.spacing.xs}`,
          })}
        >
          <Button type="reset">Cancel</Button>
          <Button
            disabled={!methods.formState.isDirty}
            sx={{ flexGrow: '1!important' as any }}
            type="submit"
            variant="primary"
          >
            Save changes
          </Button>
        </Group>
      </Box>
    </FormProvider>
  );
};
