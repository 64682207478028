import { Stack, Title, rem } from '@mantine/core';
import { useNavbarExpanded } from '../hooks';

export const NavigationSection = ({
  children,
  title,
}: { children: React.ReactNode; title?: string }) => {
  const { isDesktopExpanded } = useNavbarExpanded();

  return (
    <Stack gap={rem(5)} my={rem(3)}>
      {title && isDesktopExpanded && (
        <Title order={5} c="white" fw={400} ml={8}>
          {title}
        </Title>
      )}
      {children}
    </Stack>
  );
};
