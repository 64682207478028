import { Button, IconButton, UserAvatar } from '@finalytic/components';
import { useDashboard, useInvalidateQueries } from '@finalytic/data';
import { CheckIcon, Icon } from '@finalytic/icons';
import {
  EllipsisMenu,
  EllipsisMenuDangerItem,
  EllipsisMenuItem,
  LoadingIndicator,
} from '@finalytic/ui';
import { day } from '@finalytic/utils';
import {
  useDeleteAllInboxNotifications,
  useDeleteInboxNotification,
  useMarkAllInboxNotificationsAsRead,
  useMarkInboxNotificationAsRead,
  useUnreadInboxNotificationsCount,
} from '@liveblocks/react';
import {
  Box,
  Center,
  Group,
  LoadingOverlay,
  Button as MantineButton,
  Popover,
  Stack,
  Tabs,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { formatUserName } from '@vrplatform/ui-common';
import { PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { ActivityIcon } from '../../../drawers/activity-drawer/ActivityIcon';
import { NotificationRow, useNotificationsQuery } from '../../../queries';

const NOTIFICATION_QUERY_KEY = 'notifications';

type View = 'all' | 'unread';

function useGotoInbox() {
  const [dashboard] = useDashboard();
  const goto = useNavigate();

  return useCallback(() => {
    if (dashboard === 'overview') return;

    goto('/dashboard/inbox');
  }, [dashboard, goto]);
}

export const NavbarNotificationsIconButton = () => {
  const [dashboard] = useDashboard();
  const { count } = useUnreadInboxNotificationsCount();
  const [opened, handlers] = useDisclosure(false);
  const [tab, setTab] = useState<View>('all');
  const markAllAsRead = useMarkAllInboxNotificationsAsRead();
  const deleteAll = useDeleteAllInboxNotifications();
  const gotoInbox = useGotoInbox();

  const invalidate = useInvalidateQueries([NOTIFICATION_QUERY_KEY]);
  return (
    <>
      <Popover
        position="bottom-start"
        opened={opened}
        onClose={handlers.close}
        shadow="lg"
        withinPortal
        radius={'md'}
        width={380}
      >
        <Popover.Target>
          <IconButton
            icon="NotificationIcon"
            size={16}
            color={(theme) => theme.colors.neutral[3]}
            onClick={handlers.toggle}
            count={count}
          />
        </Popover.Target>
        <Popover.Dropdown
          p={0}
          sx={{
            // overflow: 'hidden',
            height: 500,
            maxHeight: '80dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Tabs
            defaultValue="all"
            onChange={(v) => v && setTab(v as View)}
            value={tab}
            styles={() => ({
              root: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                flex: 1,
              },
              panel: {
                height: '100%',
                flex: 1,
                maxWidth: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
            })}
          >
            <Tabs.List
              sx={{
                ':before': {
                  borderBottomWidth: '1px',
                },
              }}
            >
              <Tabs.Tab value="all">All</Tabs.Tab>
              <Tabs.Tab value="unread">Unread</Tabs.Tab>

              <Center ml="auto" mr="xs">
                <EllipsisMenu withinPortal={false}>
                  <EllipsisMenuItem
                    customIcon={<CheckIcon size={18} />}
                    onClick={() => {
                      markAllAsRead();
                      invalidate();
                    }}
                  >
                    Mark all as read
                  </EllipsisMenuItem>
                  <EllipsisMenuDangerItem
                    onClick={() => {
                      deleteAll();
                      invalidate();
                    }}
                  >
                    Delete all
                  </EllipsisMenuDangerItem>
                </EllipsisMenu>
              </Center>
            </Tabs.List>
            <Content />
          </Tabs>

          {dashboard === 'propertyManager' && (
            <MantineButton
              variant="white"
              onClick={() => {
                gotoInbox();
                handlers.close();
              }}
              sx={(theme) => ({
                display: 'block',
                height: 'auto',
                border: 'none',
                borderTop: `1px solid ${theme.colors.gray[2]}`,
                paddingTop: rem(10),
                paddingBottom: rem(10),
                marginTop: 'auto',
                width: '100%',
                textAlign: 'center',
                color: theme.colors[theme.primaryColor][6],
                fontWeight: 'normal',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              })}
            >
              Go to Inbox
            </MantineButton>
          )}
        </Popover.Dropdown>
      </Popover>
      {/* <Global
        styles={{
          'div[data-radix-popper-content-wrapper]': {
            zIndex: `${1000}!important`,
          },
        }}
      /> */}
    </>
  );
};

const Content = () => {
  const theme = useMantineTheme();

  const { loading, error, queryData, isInitialLoading, refetch } =
    useNotificationsQuery();

  if (isInitialLoading || (loading && !queryData)) {
    return (
      <Center flex={1} h="100%">
        <LoadingIndicator size="sm" />
      </Center>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Center
        flex={1}
        h="100%"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Icon
          icon="AlertTriangleIcon"
          size={28}
          color={(theme) => theme.colors.red[6]}
        />
        <Text ta="center" my="md" c="red">
          Failed to load notifications
        </Text>
        <Button
          onClick={() => refetch()}
          leftIcon={'RefreshCwIcon'}
          variant="light"
          size="xs"
        >
          Try again
        </Button>
      </Center>
    );
  }

  if (!queryData) {
    return (
      <Center
        flex={1}
        h="100%"
        maw={250}
        mx="auto"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Icon
          icon="InboxIcon"
          size={28}
          color={(theme) => theme.colors.gray[5]}
        />
        <Text
          ta="center"
          mt="md"
          mb="xs"
          size="md"
          c={theme.colors.neutral[5]}
          fw={500}
        >
          You're all caught up
        </Text>
        <Text ta="center" mb="md" size="sm" c="gray">
          You'll be notified if anything
          <br />
          new comes in.
        </Text>

        <Button
          onClick={() => refetch()}
          leftIcon={'RefreshCwIcon'}
          variant="light"
          size="xs"
        >
          Refresh
        </Button>
      </Center>
    );
  }

  return (
    <>
      <Tabs.Panel value="all">
        <List notifications={queryData.all} />
      </Tabs.Panel>

      <Tabs.Panel value="unread">
        <List notifications={queryData.unread} />
      </Tabs.Panel>

      <LoadingOverlay visible={loading} />
    </>
  );
};

const List = ({
  notifications,
}: {
  notifications: NotificationRow[];
}) => {
  const theme = useMantineTheme();

  if (!notifications.length)
    return (
      <Center
        flex={1}
        h="100%"
        maw={250}
        mx="auto"
        sx={{
          flexDirection: 'column',
        }}
      >
        {' '}
        <Icon
          icon="InboxIcon"
          size={28}
          color={(theme) => theme.colors.gray[5]}
        />
        <Text
          ta="center"
          mt="md"
          mb="xs"
          size="md"
          c={theme.colors.neutral[5]}
          fw={500}
        >
          You're all caught up
        </Text>
        <Text ta="center" mb="md" size="sm" c="gray">
          You'll be notified if anything
          <br />
          new comes in.
        </Text>
      </Center>
    );

  return (
    <Stack gap={0}>
      {notifications.map((notification) => (
        <NotificationItem {...notification} key={notification.id}>
          {notification.content}
        </NotificationItem>
      ))}
    </Stack>
  );
};

const NotificationItem = ({
  actor,
  notifiedAt: date,
  readAt,
  icon,
  children,
  id,
  to,
}: PropsWithChildren<NotificationRow>) => {
  const deleteNotification = useDeleteInboxNotification();
  const markAsRead = useMarkInboxNotificationAsRead();

  const name = actor?.firstName ? formatUserName(actor) : 'System';
  const diff = (day(date) as any)?.fromNow?.();
  const goto = useNavigate();
  const gotoInbox = useGotoInbox();

  return (
    <Group
      wrap="nowrap"
      gap="xs"
      p="xs"
      onClick={() => {
        if (to) goto(to);
        else gotoInbox();
        markAsRead(id);
      }}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
        '.notification-date': {
          opacity: 1,
          transition: 'opacity 0.2s ease-in-out',
        },
        '#notification-menu-target': {
          opacity: 0,
          transition: 'opacity 0.2s ease-in-out',
        },
        ':hover': {
          backgroundColor: theme.colors[theme.primaryColor][0] + 40,
          cursor: 'pointer',
          '.notification-date': {
            opacity: 0,
          },
          '#notification-menu-target': {
            opacity: 1,
          },
        },
      })}
    >
      <Box
        pos="relative"
        sx={{
          flexShrink: 1,
        }}
      >
        <UserAvatar size={33} user={actor} v2VrpIcon />
        <ActivityIcon icon={icon} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Group
          wrap="nowrap"
          gap="xs"
          justify="space-between"
          w="100%"
          pos="relative"
        >
          <Text component="span" c="gray" size="xs" display="block">
            {name}
          </Text>
          <Text
            component="span"
            size="xs"
            className="notification-date"
            display="block"
            c="gray"
            sx={{
              position: 'absolute',
              top: 5,
              right: 0,
            }}
          >
            {!readAt && (
              <Box
                sx={(theme) => ({
                  display: 'inline-block',
                  width: 5,
                  height: 5,
                  marginBottom: 2,
                  marginRight: 8,
                  backgroundColor: theme.colors[theme.primaryColor][6],
                  borderRadius: '100%',
                })}
              />
            )}
            {diff}
          </Text>
          <EllipsisMenu
            id="notification-menu"
            withinPortal={false}
            width={undefined}
          >
            {!readAt && (
              <EllipsisMenuItem
                onClick={() => markAsRead(id)}
                customIcon={<CheckIcon size={18} />}
              >
                Mark as read
              </EllipsisMenuItem>
            )}
            <EllipsisMenuDangerItem onClick={() => deleteNotification(id)}>
              Delete
            </EllipsisMenuDangerItem>
          </EllipsisMenu>
        </Group>
        {children}
      </Box>
    </Group>
  );
};

// interface CustomNotificationProps
//   extends Omit<InboxNotificationCustomProps, 'children'> {
//   to?: string;
//   icon: IconDefinition;
//   iconColor?: ExtendedCustomColors;
//   title: string;
//   description: string;
//   tenantId: string;
// }

// const CustomNotification = ({
//   icon,
//   iconColor,
//   title,
//   description,
//   to,
//   tenantId,
//   ...props
// }: CustomNotificationProps) => {
//   const { memberships } = useMe();

//   const { isInitialLoading, data: queryData } = useQuery(
//     (q, args) => {
//       const tenant = q
//         .tenant({
//           where: {
//             id: { _eq: args.tenantId },
//           },
//           limit: 1,
//         })
//         .map((tenant) => ({
//           id: tenant.id,
//           name: tenant.name || 'No name',
//         }))[0];

//       return {
//         tenant,
//       };
//     },
//     {
//       variables: {
//         tenantId: tenantId,
//       },
//     }
//   );

//   const hasMultipleMemberships =
//     memberships.filter((x) => x.status === 'active').length > 1;

//   return (
//     <InboxNotification.Custom
//       {...props}
//       style={{
//         zIndex: 1000,
//       }}
//       color="red"
//       title={
//         <Title order={4} fw={500} size="sm">
//           Owner statement ready for review
//         </Title>
//       }
//       components={
//         to
//           ? {
//               Anchor: (props) => <Link {...props} to={to} />,
//             }
//           : undefined
//       }
//       aside={
//         <Center mt={5}>
//           <Icon
//             icon={icon}
//             color={(theme) => theme.colors[iconColor || theme.primaryColor][6]}
//             size={20}
//           />
//         </Center>
//       }
//     >
//       {isInitialLoading ? (
//         <LoadingIndicator size="xs" />
//       ) : (
//         <>
//           {hasMultipleMemberships && queryData && (
//             <Text mb={rem(5)} c="gray">
//               {queryData.tenant.name}
//             </Text>
//           )}
//           <Text size="xs">{description}</Text>
//         </>
//       )}
//     </InboxNotification.Custom>
//   );
// };
