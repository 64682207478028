import { Address, listing as Listing } from '@finalytic/graphql';
import { Maybe } from '@finalytic/utils';
import { FormatAddressOptions, formatAddress } from './format-address';

export const getListingAddress = (
  listing: Maybe<Listing>,
  options?: FormatAddressOptions
) => {
  const pmsAddress = (listing?.pmsAddressData() ?? null) as Address | null;
  const userAddress = (listing?.addressData() ?? null) as Address | null;

  const relation = {
    line1: listing?.addressRelation?.line || '',
    line2: listing?.addressRelation?.lineDetails || '',
    city: listing?.addressRelation?.city || '',
    postcode: listing?.addressRelation?.postalCode || '',
    state: listing?.addressRelation?.state || '',
    stateCode: listing?.addressRelation?.stateCode || '',
    country: listing?.addressRelation?.country || '',
    countryCode: listing?.addressRelation?.countryCode || '',
  };

  const values: typeof relation = (() => {
    if (userAddress?.line1)
      return {
        ...userAddress,
        line2: '',
        postcode: userAddress.postalCode || '',
        state: '',
        country: '',
      };
    if (pmsAddress?.line1)
      return {
        ...pmsAddress,
        line2: '',
        postcode: pmsAddress.postalCode || '',
        state: '',
        country: '',
      };
    return relation;
  })();

  const full =
    formatAddress(values, options) ||
    listing?.addressRelation?.full ||
    listing?.address;

  return {
    values,
    full,
  };
};
