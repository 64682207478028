import { Box } from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { ReactNode } from 'react';

export const SettingsViewContainer = ({
  children,
  maxWidth = 540,
  sx,
}: {
  children: ReactNode;
  maxWidth?: number;
  sx?: EmotionSx;
}) => {
  return (
    <Box
      sx={(theme, u) =>
        Object.assign({
          maxWidth,
          width: '100%',
          margin: '0 auto',
          ...(typeof sx === 'function' ? sx(theme, u) : sx),
        })
      }
    >
      {children}
    </Box>
  );
};
