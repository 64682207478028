import { ArrayParam, StringParam, useQueryParams } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';

export const useExtractModal = () => {
  const [{ extractConnectionIds, extractType }, setExtract] = useQueryParams({
    extractConnectionIds: ArrayParam,
    extractType: StringParam,
  });

  const close = () =>
    setExtract({ extractConnectionIds: undefined, extractType: undefined });

  const open = ({
    connectionIds,
    extractType,
  }: { connectionIds: string[]; extractType: string | undefined }) =>
    setExtract({ extractConnectionIds: connectionIds, extractType });

  const ids = extractConnectionIds?.filter(hasValue) || [];

  return {
    opened: !!ids.length,
    close,
    open,
    data: { extractType, extractConnectionIds: ids },
  };
};
