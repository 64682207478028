import { Badge, Button, Filter } from '@finalytic/components';
import {
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useTeamId,
} from '@finalytic/data';
import { Icon, IconDefinition } from '@finalytic/icons';
import { InfiniteTable, MRT_ColumnDef } from '@finalytic/table';
import { StringParam, useQueryParamSet, useQueryParams } from '@finalytic/ui';
import { day, ensure } from '@finalytic/utils';
import { Box, Center, Group, Text } from '@mantine/core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { IssueBadge } from '../../components';
import { useListingClassMappingInfo } from '../../hooks';
import { IssueFilter, IssueRow, getIssues } from '../../queries';

export const PmIssuesTable = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    level: StringParam,
  });

  const queryData = useIssueTableQuery(filter as IssueFilter);

  const columns = useMemo(
    () =>
      ensure<MRT_ColumnDef<IssueRow>[]>([
        {
          header: 'Section',
          accessorKey: 'table',
          size: 60,
          maxSize: 60,
          minSize: 60,
          Cell: ({ row }) => {
            const tables: Record<
              IssueRow['table'],
              {
                icon: IconDefinition;
                label: string;
              }
            > = {
              'owner-statement': {
                icon: 'ClipboardTextIcon',
                label: 'Owner statement',
              },
              connection: {
                icon: 'RouteIcon',
                label: 'Connection',
              },
              listing: {
                icon: 'HomeIcon',
                label: 'Listing',
              },
              account: {
                icon: 'RouteIcon',
                label: 'Account',
              },
            };

            const table = tables[row.original.table];

            if (!table) return null;

            return (
              <Badge
                leftIcon={<Icon icon={table.icon} size={12} />}
                color="neutral"
                sx={(theme) => ({
                  color: theme.colors.neutral[6],
                  backgroundColor: `${theme.colors.neutral[1]} !important`,
                })}
              >
                {table.label}
              </Badge>
            );
          },
        },
        {
          header: 'Item',
          accessorKey: 'item',
        },
        {
          header: 'Issue',
          Cell: ({ row }) => {
            return (
              <Group wrap="nowrap" gap="sm">
                <Center sx={{ flexShrink: 0 }}>
                  <IssueBadge status={row.original.level} />
                </Center>
                <Text>{row.original.message}</Text>
              </Group>
            );
          },
        },
        {
          header: 'Date',
          size: 60,
          maxSize: 60,
          minSize: 60,
          Cell: ({ row }) => {
            const date = row.original.date;

            return (
              <Text c="neutral" size="xs">
                {!date ? '-' : day(date).format('D MMM, YYYY')}
              </Text>
            );
          },
        },
        {
          size: 68,
          maxSize: 68,
          minSize: 68,
          header: 'Button',
          Header: '',
          mantineTableBodyCellProps: { align: 'right' },
          Cell: ({ row }) => <IssueFixButton {...row.original} />,
        },
      ]),
    []
  );

  const severityOptions = useMemo(
    () => [
      {
        label: 'Warning',
        value: 'warning' as const,
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
      {
        label: 'Issue',
        value: 'error' as const,
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.red[6],
            })}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <InfiniteTable
        queryData={queryData}
        columns={columns}
        table={{
          emptyRowsFallback: 'No issues found',
        }}
        resetFilter={() => setFilter({ search: undefined })}
      >
        <Group>
          <Filter.Search
            value={filter.search || ''}
            setValue={(search) => setFilter({ search })}
          />
          <Filter.Select
            type="single"
            label="Severity"
            data={{
              options: severityOptions,
            }}
            value={
              severityOptions.find((x) => x.value === filter.level) || null
            }
            setValue={(v) => setFilter({ level: v?.value })}
          />
        </Group>
      </InfiniteTable>
    </>
  );
};

function useIssueTableQuery(filter: IssueFilter) {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();
  const listingClassMapping = useListingClassMappingInfo();
  const { id: meId } = useMe();

  return useInfiniteQuery(
    (q, args, opts) => {
      return getIssues(q, args, opts);
    },
    {
      variables: ensure<Parameters<typeof getIssues>[1]>({
        teamId,
        search: filter.search?.trim(),
        GL,
        onlyAggregate: false,
        level: filter.level,
        listingClassMapping,
        meId,
      }),
      refetchOnWindowFocus: true,
      queryKey: 'issues',
    }
  );
}

const IssueFixButton = ({ type, id }: IssueRow) => {
  const setListing = useQueryParamSet('listing', StringParam);
  const goto = useNavigate();

  const fix = () => {
    switch (type) {
      case 'listing-owner-missing':
        setListing(id);
        break;
      case 'connection-error':
        goto('/connections');
        break;
      case 'listing-class-missing':
        setListing(id);
        break;
      case 'connection-version-outdated':
        goto('/connections');
        break;
      case 'account-assignment-missing':
        goto('/settings/integrations/accounts');
        break;
      default:
        break;
    }
  };

  const label = useMemo(() => {
    const labels: Record<IssueRow['type'], string> = {
      'listing-owner-missing': 'Add ownership',
      'connection-error': 'Reconnect',
      'connection-version-outdated': 'Reconnect',
      'listing-class-missing': 'Assign class',
      'owner-statement-reservation': 'Assign reservation',
      'account-assignment-missing': 'Assign item',
    };

    return labels[type];
  }, [type]);

  return (
    <Button size="xs" onClick={fix}>
      {label}
    </Button>
  );
};
