import { Badge, Button } from '@finalytic/components';
import {
  useApiMutation,
  useInvalidateQueries,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  CreditCardIncomeIcon,
  HashtagIcon,
  LoaderIcon,
  NoteTextIcon,
  OfficeIcon,
} from '@finalytic/icons';
import { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  ExtendedCustomColors,
  showErrorNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import { Maybe, day, formatCurrency, sum, toTitleCase } from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo } from 'react';
import { getBankRecord } from '../../views/reconciliation/bank-accounts/view/bank-feed/useBankRecordsTableQuery';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useDepositDetailDrawer } from '../deposit-drawer';
import { useExpenseDetailDrawer } from '../expense-drawer';
import { useBankRecordDetailDrawer } from './useBankRecordDetailDrawer';

function useBankRecordQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .bankRecords({
            where: {
              id: { _eq: args.id },
            },
            limit: 1,
          })
          .map((bankRecord) => getBankRecord(bankRecord))[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'bankRecords',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type BankRecord = ReturnType<typeof getBankRecord>;

const BAGDE_OPTIONS: Record<
  NonNullable<BankRecord['status']>,
  { label: string; color: ExtendedCustomColors }
> = {
  excluded: {
    label: 'Excluded',
    color: 'gray',
  },
  overpaid: {
    label: 'Overpaid',
    color: 'red',
  },
  paid: {
    label: 'Reconciled',
    color: 'green',
  },
  unpaid: {
    label: 'Unreconciled',
    color: 'yellow',
  },
  underpaid: {
    label: 'Underpaid',
    color: 'yellow',
  },
};

export const BankRecordStatusBadge = ({
  status,
}: {
  status: NonNullable<BankRecord['status']>;
}) => {
  if (!status || !BAGDE_OPTIONS[status]) return null;

  return (
    <Badge color={BAGDE_OPTIONS[status].color} size="md">
      {BAGDE_OPTIONS[status].label}
    </Badge>
  );
};

export const BankRecordDetailDrawer = () => {
  const { opened, close, bankRecordId } = useBankRecordDetailDrawer();
  const { isLoading, data: bankRecord } = useBankRecordQuery(bankRecordId);

  return (
    <Drawer opened={opened} onClose={close} size={550}>
      <DrawerHeader
        closeDrawer={close}
        loading={isLoading}
        type="Bank record"
        title={
          bankRecord && (
            <Group wrap="nowrap" gap="xs" mt={rem(5)}>
              {/* <Center w={20}>
                {bankRecord.connection.logo ? (
                  <Avatar
                    src={bankRecord.connection.logo}
                    size="sm"
                    styles={{
                      placeholder: { visibility: 'hidden' },
                    }}
                    sx={(theme) => ({
                      border: `1px solid ${theme.colors.gray[2]}`,
                    })}
                  />
                ) : (
                  <Icon icon="UserIcon" size={20} />
                )}
              </Center> */}
              <Box>
                <Title order={4} fw={500} component="p" m={0}>
                  {bankRecord.description}
                </Title>
              </Box>
            </Group>
          )
        }
      />
      {!bankRecord && !isLoading ? (
        'No expense details found'
      ) : (
        <Content bankRecord={bankRecord} isLoading={isLoading} />
      )}
    </Drawer>
  );
};

const Content = ({
  bankRecord,
  isLoading,
}: { bankRecord: Maybe<BankRecord>; isLoading: boolean }) => {
  if (!bankRecord) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text: formatCurrency(
              bankRecord.centTotal / 100,
              bankRecord.currency
            ),
          },
          {
            icon: LoaderIcon,
            title: 'Status',
            text:
              bankRecord.bankAccount.category === 'trust' ? (
                <BankRecordStatusBadge status={bankRecord.status} />
              ) : null,
          },
          {
            icon:
              bankRecord.bankAccount.type === 'creditCard'
                ? CreditCardIncomeIcon
                : OfficeIcon,
            title: 'Bank account',
            text: bankRecord.bankAccount.title,
          },
          {
            icon: CalendarEventIcon,
            title: 'Date',
            text: day(bankRecord.date).format('MMM D, YYYY'),
          },
          {
            icon: HashtagIcon,
            title: 'Reference',
            text: bankRecord.uniqueRef,
          },
          {
            icon: NoteTextIcon,
            title: 'Description',
            text: bankRecord.description,
          },
        ]}
      />

      <Lines
        lines={bankRecord.reconciledTransactions}
        currency={bankRecord.currency}
        bankRecordId={bankRecord.id}
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type ExpenseLine = BankRecord['reconciledTransactions'][number];

const Lines = ({
  lines,
  currency,
  bankRecordId,
}: { lines: ExpenseLine[]; currency: string; bankRecordId: string }) => {
  const { open: openExpense } = useExpenseDetailDrawer();
  const { open: openDeposit } = useDepositDetailDrawer();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const total = useMemo(
    () => formatCurrency(sum(lines, (x) => x.centTotal) / 100, currency),
    [currency, ...lines.map((line) => line.centTotal)]
  );

  const invalidate = useInvalidateQueries([
    'bankRecords',
    'expenses',
    'deposits',
    'transactions',
  ]);

  const { isPending: loadingMutation, mutateAsync } = useApiMutation(
    'put',
    '/bank-records/{id}',
    {
      onError: (error: any) => {
        showErrorNotification({
          title: 'Failed to update bank records',
          message:
            error?.message ||
            'Failed to update bank records. Please reach out to support if the issue persists.',
        });
      },
      onSuccess: () => {
        showSuccessNotification({
          title: 'Success!',
          message: 'We matched the transactions to your bank record.',
        });
        invalidate();
      },
    }
  );

  const columns = useMemo<MRT_ColumnDef<ExpenseLine>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
      },
      {
        header: 'Badge',
        size: 100,
        Cell: ({ row }) => {
          const line = row.original;

          return <Badge>{toTitleCase(line.type)}</Badge>;
        },
      },
      {
        header: 'Split',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          sx: (theme) => ({
            paddingRight: theme.spacing.xs,
            fontWeight: 'bold',
          }),
        },
        size: 100,
        Cell: ({ row }) => {
          const line = row.original;

          return formatCurrency(line.centTotal / 100, currency);
        },
        Footer: !lines.length ? undefined : () => total,
      },
    ],
    [currency, lines.length, total]
  );

  return (
    <DrawerCollapsableTable
      title="Matched transactions"
      rightSection={
        lines.length && (
          <Tooltip label="Reset all matched transactions" withArrow>
            <Button
              variant="light"
              loading={loadingMutation}
              size="xs"
              sx={(theme) => ({
                paddingInline: theme.spacing.xs,
                paddingTop: 0,
              })}
              onClick={async () => {
                await mutateAsync({
                  params: {
                    path: {
                      id: bankRecordId,
                    },
                  },
                  body: {
                    reconciledTransactionIds: [],
                  },
                });
              }}
              rightIcon={'CrossCircleIcon'}
              rightIconSize={14}
            >
              Remove
            </Button>
          </Tooltip>
        )
      }
      onRowClick={{
        handler: (row) => {
          if (row.original.type === 'expense')
            return openExpense(row.original.id, 'push');
          else if (row.original.type === 'deposit')
            openDeposit(row.original.id, 'push');
        },
        disabled: (row) => !row.original.type,
      }}
      rowData={lines}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No matched transactions
          </Text>
        </Center>
      )}
    />
  );
};
