import { Badge } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import { Maybe, toTitleCase, utc } from '@finalytic/utils';
import { Center, Tooltip } from '@mantine/core';
import { useMemo } from 'react';

type Props = {
  status: Maybe<string>;
  cancelledAt?: string | null;
};

export const ReservationStatusBadge = ({ status, cancelledAt }: Props) => {
  const { color, label, info } = useMemo(() => {
    let color: 'green' | 'yellow' | 'red' = 'yellow';
    const label = toTitleCase(status || '');
    let info = undefined;

    switch (status) {
      case 'booked':
        color = 'green';
        break;
      case 'cancelled':
        color = 'red';
        info = cancelledAt
          ? `Cancelled on
            ${utc(cancelledAt).format('MMM DD, YYYY')}`
          : undefined;
        break;
      case 'inquiry':
        color = 'yellow';
        break;
      default:
        break;
    }

    return {
      color,
      label,
      info,
    };
  }, [status, cancelledAt]);

  if (!label) return null;

  return (
    <Badge
      color={color}
      rightIcon={
        info ? (
          <Tooltip withinPortal label={info} withArrow>
            <Center>
              <Icon
                icon="InfoIcon"
                size={12}
                color={(theme) => theme.colors[color][9]}
              />
            </Center>
          </Tooltip>
        ) : undefined
      }
    >
      {label}
    </Badge>
  );
};
