import { Progress, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';

export const ConnectionProgressBar = () => {
  const { primaryColor, colors } = useMantineTheme();

  const [progressValue, setProgressValue] = useState(2);

  useEffect(() => {
    setTimeout(() => {
      if (progressValue < 100) {
        setProgressValue((n) => (n % 2 ? n + 5 : n + 15));
      }
    }, 2000);
  }, [progressValue]);

  return (
    <Progress
      radius={20}
      striped={false}
      value={progressValue}
      color={colors[primaryColor][6]}
    />
  );
};
