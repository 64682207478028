import {
  Button,
  Input,
  InputAmount,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  captureSentryError,
  useApiClient,
  useApiMutation,
  useInfiniteQuery,
  useInvalidateQueries,
  useTeamId,
} from '@finalytic/data';
import type { payment_line_bool_exp } from '@finalytic/graphql';
import {
  SelectItem,
  showErrorNotification,
  showWarnNotification,
} from '@finalytic/ui';
import { bankersRound, toTitleCase } from '@finalytic/utils';
import { Box, Group, Modal } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

type FormInputs = {
  description: string;
  lineType: string | null;
  centTotal?: number;
};

type Props = {
  reservationId: string | null;
  closeModal: () => void;
  reservationLine?: FormInputs | null;
  zIndex?: number;
};

export const ReservationLineFormModal = ({
  reservationId,
  closeModal: cl,
  reservationLine,
  zIndex,
}: Props) => {
  const opened = !!reservationLine;

  const methods = useForm<FormInputs>({
    values: reservationLine
      ? {
          centTotal: undefined,
          description: reservationLine.description,
          lineType: reservationLine.lineType,
        }
      : undefined,
  });

  const invalidate = useInvalidateQueries(['reservations']);

  const { mutateAsync } = useApiMutation('put', '/reservations/{id}', {
    onSuccess: () => {
      invalidate();
    },
  });

  const $api = useApiClient();

  const submit = async (data: FormInputs) => {
    if (!reservationId) {
      captureSentryError('Missing reservationId');
      return showWarnNotification({
        title: 'Missing reservation',
        message: 'Please reach out to support if the issue persists.',
      });
    }

    try {
      const existing_lines = await $api
        .GET('/reservations/{id}', {
          params: {
            path: { id: reservationId },
          },
        })
        .then((x) => x.data?.lines || []);

      type ReservationLine = NonNullable<
        Parameters<typeof mutateAsync>[0]['body']['lines']
      >[number];

      const lines: ReservationLine[] = [
        ...existing_lines.map<ReservationLine>((line) => ({
          amount: line.amount,
          accountId: line.account?.id,
          description: line.description ?? undefined,
          type: line.type,
          uniqueRef: line.uniqueRef ?? undefined,
          id: line.id,
        })),
        {
          description: data.description.trim(),
          type: data.lineType!,
          amount: bankersRound(data.centTotal ?? 0),
          connectionId: null as any,
        },
      ];

      return await mutateAsync({
        params: {
          path: {
            id: reservationId,
          },
        },
        body: {
          lines,
        },
      }).then(closeModal);
    } catch (error: any) {
      const message =
        error?.message ||
        'We failed to update the reservation. Please try again later and if the problem persists, contact support.';

      showErrorNotification({
        title: 'Failed to update reservation',
        message,
      });
    }
  };

  const closeModal = () => {
    cl();
    methods.reset({});
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title="Add financial"
      styles={{
        title: { fontWeight: 500 },
      }}
      zIndex={zIndex}
      centered
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        <Controller
          control={methods.control}
          rules={{
            required: 'Description is required',
            validate: trapSpacesForRequiredFields,
          }}
          name="description"
          render={({ field, fieldState }) => (
            <InputWrapper label="Description" error={fieldState.error?.message}>
              <Input
                value={field.value}
                setValue={field.onChange}
                error={!!fieldState.error}
                placeholder="Cleaning Fee Adjustment"
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={methods.control}
          rules={{
            required: 'Please select a type',
          }}
          name="lineType"
          render={({ field, fieldState }) => {
            const [teamId] = useTeamId();
            const [search, setSearch] = useState('');

            const queryData = useInfiniteQuery(
              (q, args, { limit, offset }) => {
                const where: payment_line_bool_exp = {
                  tenantId: { _eq: args.teamId },
                  type2: args.search
                    ? { _ilike: `%${args.search}%` }
                    : { _is_null: false },
                  paymentId: { _is_null: true },
                };

                const aggregate =
                  q
                    .paymentLineAggregate({
                      where,
                      distinct_on: ['type2'],
                    })
                    ?.aggregate?.count() || 0;

                const list = q
                  .paymentLines({
                    where,
                    distinct_on: ['type2'],
                    order_by: [{ type2: 'asc' }],
                    limit,
                    offset,
                  })
                  .map<SelectItem>((lineType) => {
                    const type2 = lineType.type2!;
                    const splitted = type2?.split('_') || '';

                    const accountMappings = lineType
                      .lineTypeAccounts({
                        where: {
                          tenantId: { _eq: args.teamId },
                        },
                      })
                      .map((accountReservationLineType) => ({
                        id: accountReservationLineType.id,
                        accountId: accountReservationLineType.accountId,
                      }));

                    const disabled =
                      !accountMappings.length ||
                      accountMappings.some((x) => !x.accountId);

                    return {
                      label: toTitleCase(splitted[splitted.length - 1])!,
                      value: lineType.type2!,
                      group: toTitleCase(splitted[0]) || 'Ungrouped',
                      disabled,
                      description: disabled
                        ? 'Please add an account mapping'
                        : undefined,
                    };
                  });

                return {
                  aggregate,
                  list,
                };
              },
              {
                queryKey: ['accounts'],
                variables: {
                  search: search?.trim(),
                  teamId,
                },
              }
            );

            const value = useMemo(() => {
              if (!field.value) return null;

              const splitted = field.value.split('_');
              return {
                label: toTitleCase(splitted[splitted.length - 1]),
                value: field.value,
              };
            }, [field.value]);

            return (
              <InputWrapper label="Type" error={fieldState.error?.message}>
                <InputSelect
                  value={value}
                  setValue={(value) => field.onChange(value?.value)}
                  type="single"
                  inputProps={{
                    error: !!fieldState.error,
                    placeholder: 'Select type',
                    withClearButton: true,
                  }}
                  infiniteData={{ ...queryData, setSearch }}
                />
              </InputWrapper>
            );
          }}
        />

        <Controller
          control={methods.control}
          rules={{
            validate: (value) => {
              if (typeof value !== 'number') {
                return 'Please enter a number';
              }
              return true;
            },
          }}
          name="centTotal"
          render={({ field, fieldState }) => (
            <InputWrapper label="Amount" error={fieldState.error?.message}>
              <InputAmount
                value={
                  field.value === undefined
                    ? ''
                    : Number(field.value || '0') / 100
                }
                setValue={(value) => field.onChange(Number(value || 0) * 100)}
                decimalScale={2}
                error={!!fieldState.error?.message}
                placeholder="0.00"
                leftSection={<span>$</span>}
              />
            </InputWrapper>
          )}
        />

        <Group justify="right" mt="md">
          <Button
            onClick={closeModal}
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={methods.handleSubmit(submit, (err) => console.log(err))}
            loading={methods.formState.isSubmitting}
          >
            Add financial
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
