import { iso31661, iso31662 } from 'iso-3166';

export const COUNTRIES = iso31661.map((country) => ({
  isoCode: country.alpha2,
  name: country.name,
}));

export const STATES = iso31662.map((state) => {
  const isoCode = state.code.split('-')[1];

  return {
    isoCode,
    country: state.parent,
    name: state.name,
  };
});

export const getStateByIsoCode = (isoCode: string, country: string) => {
  const state = STATES.find((state) => {
    return state.isoCode === isoCode && state.country === country;
  });

  return state;
};

export const getCountryByIsoCode = (isoCode: string) => {
  const country = COUNTRIES.find((country) => {
    return country.isoCode === isoCode;
  });

  return country;
};

export const getStatesOfCountry = (countryIsoCode: string) => {
  const states = STATES.filter((state) => {
    return state.country === countryIsoCode;
  });

  return states;
};
