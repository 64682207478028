import { useQuery, useTeamId } from '@finalytic/data';
import { useStatementAutomation } from '../../_hooks';

export function useTaxAccountCollectionQuery(props?: { skip: boolean }) {
  const { automation } = useStatementAutomation();
  const [teamId] = useTeamId();

  const { data, isLoading } = useQuery(
    (q, args) => {
      // if no tax account collection or no mappings to that collection return true

      const collectionId = q
        .accountCollections({
          where: {
            tenantId: { _eq: args.teamId },
            automationId: { _eq: args.automationId },
            type: {
              _eq: 'taxStatementCollection',
            },
          },
          limit: 1,
        })
        .map((col) => ({
          id: col.id,
        }))[0]?.id;

      return {
        collectionId,
      };
    },
    {
      skip: props?.skip || !automation?.automationId,
      queryKey: ['taxStatementCollection'],
      variables: {
        teamId,
        automationId: automation?.automationId,
      },
    }
  );

  return {
    loading: isLoading,
    collectionId: data?.collectionId,
  };
}
