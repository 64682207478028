import { user as User } from '@finalytic/graphql';

export function getOwnerStatus(owner: User, teamId: string) {
  const tenantUser = owner.memberships({
    where: { tenantId: { _eq: teamId } },
    limit: 1,
  })[0];

  const tenantUserStatus = (tenantUser?.status || 'inactive') as
    | 'inactive'
    | 'unconfirmed'
    | 'active'
    | 'archived';

  const status = owner?.status === 'archived' ? 'archived' : tenantUserStatus;

  const isReinvite = status !== 'inactive';
  const isArchived = status === 'archived';

  return { status, tenantUser, isArchived, isReinvite };
}
