import { useAccountingConnection, useTeam } from '@finalytic/data';
import { useStatementAutomation } from '../views/statements/_hooks';

export function useListingClassMappingInfo() {
  const [{ finalyticConnectionId }] = useTeam();
  const { accounting } = useAccountingConnection();
  const { billAutomation: automation } = useStatementAutomation();
  const accountingConnectionId = accounting?.id;

  const rightType =
    accounting?.appId === 'xero'
      ? 'xero.trackingCategory'
      : `${accounting?.appId}.class`;
  const leftType = 'finalytic.listing';
  const key = 'class';

  return {
    mapping: {
      rightType,
      leftType,
      rightParams: automation?.mappings?.[key]?.right?.params || {},
      leftParams:
        automation?.mappings?.[key]?.left?.params ||
        automation?.mappings?.[key]?.params ||
        {},
      key,
    },
    automation: {
      automation,
      finalyticConnectionId,
      accountingConnectionId,
    },
    accounting,
  };
}
