import { gqlV2, useTeamId } from '@finalytic/data';
import { Maybe, day, isUUID } from '@finalytic/utils';
import { useMemo } from 'react';
import { useBankAccountId } from '../useBankAccountId';
import { useBankRecordsTableFilter } from './BankRecordsTableFilter';

export function useWhereBankRecords() {
  const [teamId] = useTeamId();
  const id = useBankAccountId();
  const { filter } = useBankRecordsTableFilter();

  const trimmed = filter.search?.trim();

  return useMemo<gqlV2.bankRecord_bool_exp>(
    () =>
      whereBankRecords({
        tenantId: teamId,
        bankAccountId: id!,
        date: filter.date,
        search: trimmed,
      }),
    [teamId, id, trimmed, filter.date]
  );
}

const whereBankRecords = ({
  tenantId,
  bankAccountId,
  search,
  date,
}: {
  tenantId: string;
  bankAccountId: string;
  search: Maybe<string>;
  date: Maybe<string>;
}): gqlV2.bankRecord_bool_exp => {
  const [start, end] = date?.split('...') || [];

  return {
    tenantId: { _eq: tenantId },
    bankAccountId: isUUID(bankAccountId) ? { _eq: bankAccountId } : undefined,
    date: start
      ? {
          _gte: start,
          _lt: end ? undefined : day(start).add(1, 'day').format('YYYY-MM-DD'),
          _lte: end ? end : undefined,
        }
      : undefined,
    _or: search
      ? [
          {
            description: { _ilike: `%${search}%` },
          },
          {
            uniqueRef: { _ilike: `%${search}%` },
          },
        ]
      : undefined,
  };
};
