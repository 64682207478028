import _Intercom, {
  boot,
  hide,
  onHide,
  onShow,
  onUnreadCountChange,
  show,
  showArticle,
  showNewMessage,
  shutdown,
  trackEvent,
  update,
} from '@intercom/messenger-js-sdk';
import {
  InitType,
  IntercomSettings,
} from '@intercom/messenger-js-sdk/dist/types';
import { useEffect, useMemo } from 'react';
import { create } from 'zustand';

const INTERCOM_APP_ID = 'ec8spyh1';

let isReady = false;
let hasUserHash = false;

const useIntercomStore = create<{
  unreadCount?: number;
  setUnreadCount: (count: number) => void;
}>()((set, _get) => ({
  unreadCount: undefined,
  setUnreadCount: (count) => set({ unreadCount: count }),
}));

export const Intercom = (props: Partial<InitType>) => {
  isReady = true;

  _Intercom({ app_id: INTERCOM_APP_ID, ...props });
};

export const useIntercom = () => {
  const [unreadMessageCount, setUnread] = useIntercomStore((state) => [
    state.unreadCount,
    state.setUnreadCount,
  ]);
  useEffect(() => {
    if (isReady && hasUserHash) onUnreadCountChange(setUnread);
  }, [isReady, hasUserHash]);

  return useMemo(
    () => ({
      unreadMessageCount,
      // Functions
      trackEvent: isReady ? trackEvent : undefined,
      update: isReady && hasUserHash ? update : undefined,
      hide: isReady ? hide : undefined,
      onHide: isReady ? onHide : undefined,
      show: isReady ? show : undefined,
      onShow: isReady ? onShow : undefined,
      showArticle: isReady ? showArticle : undefined,
      // modified
      showNewMessage: isReady ? (x?: string) => showNewMessage(x!) : undefined,
      boot: isReady
        ? (arg: Partial<IntercomSettings>) => {
            hasUserHash = !!arg.user_hash;

            boot({ app_id: INTERCOM_APP_ID, ...arg });
          }
        : undefined,
      shutdown: isReady
        ? (...args: Parameters<typeof shutdown>) => {
            hasUserHash = false;

            shutdown(...args);
          }
        : undefined,
    }),
    [unreadMessageCount]
  );
};
