import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const ReservationVolumeCard = (props: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      {...props}
      title="Reservation Volume"
      href="/reservations"
      total="sum"
      isCurrencyAmount
      type="reservationVolume"
    />
  );
};
