import { Icon } from '@finalytic/icons';
import { AutomationIcon, useAppName } from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import { Center, Group, Text, Tooltip } from '@mantine/core';
import { AutomationTemplateVisibility } from '@vrplatform/ui-common';

type Props = {
  templateType: Maybe<string>;
  name: Maybe<string>;
  templateVisibility: Maybe<AutomationTemplateVisibility>;
};

export const AutomationNameCell = ({
  name,
  templateType,
  templateVisibility,
}: Props) => {
  const showInvisibleIcon =
    templateVisibility === 'admin' || templateVisibility === 'partnerAdmin';
  const { appName } = useAppName();

  return (
    <Group gap={'xs'} wrap="nowrap" justify="left">
      {templateType && (
        <Tooltip
          label={toTitleCase(templateType.split('.').reverse()[0])}
          withArrow
          withinPortal
        >
          <Center>
            <AutomationIcon
              size={18}
              templateType={templateType}
              color="gray"
              sx={{ flexShrink: 0 }}
            />
          </Center>
        </Tooltip>
      )}
      {name && (
        <Text component="span" m={0} lineClamp={1} display="block">
          {name}
        </Text>
      )}
      {showInvisibleIcon && (
        <Tooltip
          withArrow
          withinPortal
          label={`Only visible to ${
            templateVisibility === 'admin'
              ? `${appName} admins`
              : 'partner admins'
          }`}
        >
          <Center>
            <Icon icon="EyeSlashIcon" size={16} />
          </Center>
        </Tooltip>
      )}
    </Group>
  );
};
