import { owner_statement_owner_bool_exp } from '@finalytic/graphql';
import { day, ensure } from '@finalytic/utils';
import { z } from 'zod';

export const taxStatementsFilterInput = {
  search: z.string().nullish(),
  status: z
    .enum(['draft', 'inReview', 'ready', 'submitted', 'ignored'])
    .nullish(),
  year: z.number(),
  startAt: z.string().nullish(),
  endAt: z.string().nullish(),
};

const taxStatementsFilter = z.object(taxStatementsFilterInput);

export type TaxStatementsFilterParams = z.infer<typeof taxStatementsFilter>;

type BaseWhere = {
  tenantId: string;
};

export function whereTaxStatements({
  search: s,
  tenantId,
  year,
  status,
  endAt,
  startAt,
}: BaseWhere & TaxStatementsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<owner_statement_owner_bool_exp[]>([
        {
          newOwner: {
            _or: [
              {
                firstName: { _ilike: `%${search}%` },
              },
              {
                name: { _ilike: `%${search}%` },
              },
              {
                email: { _ilike: `%${search}%` },
              },
            ],
          },
        },
      ])
    : undefined;

  const startDate =
    startAt || day().set('year', year).startOf('year').yyyymmdd();
  const endDate = endAt || day().set('year', year).endOf('year').yyyymmdd();

  return ensure<owner_statement_owner_bool_exp>({
    statement: {
      tenantId: { _eq: tenantId },
      startAt: { _gte: startDate, _lte: endDate },
      status: { _in: ['posted', 'published'] },
      // automationId: { _eq: statementAutomationId },
    },
    role: { _in: ['owner', 'company'] },
    newOwnerId: { _is_null: false },
    newOwner: status
      ? {
          taxStatements:
            status !== 'draft'
              ? {
                  year: { _eq: year },
                  status: { _eq: status },
                }
              : undefined,
          _or:
            status === 'draft'
              ? [
                  {
                    taxStatements: {
                      year: { _eq: year },
                      status: { _eq: 'draft' },
                    },
                  },
                  {
                    taxStatements: {
                      year: { _eq: year },
                      status: { _is_null: true },
                    },
                  },
                  {
                    _not: {
                      taxStatements: {
                        year: { _eq: year },
                      },
                    },
                  },
                ]
              : undefined,
        }
      : {
          _not: {
            taxStatements: {
              year: { _eq: year },
              status: { _eq: 'ignored' },
            },
          },
        },
    _or: searchQuery,
  });
}
