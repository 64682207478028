import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { OfficeIcon, UserIcon } from '@finalytic/icons';
import {
  ArrayParam,
  LoadingIndicator,
  SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import { Avatar, Group } from '@mantine/core';
import {
  formatOwnerName,
  orderByContact,
  orderByOwner,
  whereContacts,
  whereOwners,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { AutomationFilter } from '../../../components';
import { useGenericTableStore } from '../../../stores';

export const useListingFilter = () => {
  const [queryFilter, setQueryFilter] = useQueryParams({
    search: StringParam,
    status: StringParam,
    collectionId: StringParam,
    ownerIds: ArrayParam,
    automationIds: ArrayParam,
    pmsConnectionId: StringParam,
  });

  const filterType = useGenericTableStore((s) => s.filterType);
  const stateFilter = useGenericTableStore((s) => s.filter);
  const resetSelection = useGenericTableStore((s) => s.resetSelection);
  const setStateFilter = useGenericTableStore((s) => s.setFilter);

  const filter = useMemo(() => {
    if (filterType === 'query-params') {
      return queryFilter;
    }
    return stateFilter as typeof queryFilter;
  }, [filterType, stateFilter, queryFilter]);

  return {
    filter,
    setFilter: (filter: Partial<typeof stateFilter>) => {
      if (filterType === 'query-params') {
        setQueryFilter(filter);
      } else {
        setStateFilter(filter);
      }
      resetSelection();
    },
    reset: () => {
      const initial = {
        search: undefined,
        status: undefined,
        ownerIds: undefined,
        automationIds: undefined,
        collectionId: undefined,
        pmsConnectionId: undefined,
      };

      setStateFilter(initial);
      setQueryFilter(initial);
      resetSelection();
    },
  };
};

export const ListingFilter = ({
  hide,
}: {
  hide?: (
    | 'status'
    | 'owner'
    | 'automation'
    | 'collection'
    | 'pms-connection'
  )[];
}) => {
  const [dashboard] = useDashboard();
  const { GL, GL_WITH_LEGACY_AUTOMATIONS } = useEnabledFeatures();

  return (
    <Group>
      <SearchFilter />
      {dashboard !== 'owner' && (
        <>
          {!hide?.includes('status') && <StatusFilter />}
          {!hide?.includes('collection') && <CollectionFilter />}
          {!hide?.includes('owner') && <OwnerFilter />}
          {!hide?.includes('automation') &&
            (!GL || GL_WITH_LEGACY_AUTOMATIONS) && <AFilter />}
          {!hide?.includes('pms-connection') && <PmsFilter />}
        </>
      )}
    </Group>
  );
};

const SearchFilter = () => {
  const { filter, setFilter } = useListingFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const StatusFilter = () => {
  const { filter, setFilter } = useListingFilter();

  return (
    <Filter.Status
      value={(filter.status as 'active' | 'inactive') || 'active'}
      setValue={(v) => v && setFilter({ status: v })}
      inactiveLabel="Disabled"
    />
  );
};

const OwnerFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useListingFilter();
  const { GL } = useEnabledFeatures();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search, GL }, { limit, offset }) => {
      if (GL) {
        const where = whereContacts({
          tenantId: teamId,
          search,
          type: 'owner',
        });

        const list = q
          .contacts({
            limit,
            offset,
            order_by: orderByContact,
            where,
          })
          .map<SelectItem>((contact) => ({
            label: formatOwnerName(contact),
            value: contact.id,
            group: contact.companyType ? 'Company' : 'Individual',
            icon: contact.companyType ? (
              <OfficeIcon size={16} />
            ) : (
              <UserIcon size={16} />
            ),
          }));

        const aggregate = q.contactAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const where = whereOwners({
        teamId,
        search,
      });

      const list = q
        .owners({
          limit,
          offset,
          order_by: [{ type: 'asc' }, orderByOwner],
          where,
        })
        .map<SelectItem>((owner) => ({
          label: formatOwnerName(owner),
          value: owner.id,
          group: owner.type === 'company' ? 'Company' : 'Individual',
          icon:
            owner.type === 'company' ? (
              <OfficeIcon size={16} />
            ) : (
              <UserIcon size={16} />
            ),
        }));

      const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { ownerIds, GL }) => {
      if (GL) {
        const value = q
          .contacts({
            where: {
              id: { _in: ownerIds },
            },
          })
          .map<SelectItem>((contact) => ({
            label: formatOwnerName(contact),
            value: contact.id,
          }));

        return {
          value,
        };
      }

      const value = q
        .owners({
          where: {
            id: { _in: ownerIds },
          },
        })
        .map<SelectItem>((owner) => ({
          label: formatOwnerName(owner),
          value: owner.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        ownerIds: filter.ownerIds || [],
        GL,
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ ownerIds: v.map((i) => i.value) })}
      label="Owner"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const CollectionFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useListingFilter();

  const queryData = useQuery(
    (q, { teamId }) => {
      const where: gqlV2.listing_collection_bool_exp = {
        tenantId: { _eq: teamId },
        listings: {},
      };

      const list = q
        .listingCollections({
          where,
          order_by: [
            {
              name: 'asc',
            },
          ],
        })
        .map<SelectItem>((collection) => ({
          label: collection.name || 'No name',
          value: collection.id,
        }));

      return {
        list,
      };
    },
    {
      skip: !teamId,
      queryKey: 'listings',
      variables: {
        teamId,
      },
    }
  );

  const unmappedItem: SelectItem = {
    label: 'Ungrouped',
    value: 'null',
  };

  const value = useMemo(() => {
    if (filter.collectionId === 'null') return unmappedItem;

    return (
      queryData.data?.list.find((x) => x.value === filter.collectionId) || null
    );
  }, [queryData.data?.list, filter.collectionId]);

  return (
    <Filter.Select
      type="single"
      value={value}
      setValue={(v) => setFilter({ collectionId: v?.value })}
      label="Listing Group"
      withinPortal
      data={{
        options: queryData.data?.list || [],
        error: queryData.error,
        loading: queryData.isLoading,
      }}
      pinnedItems={[unmappedItem]}
    />
  );
};

const PmsFilter = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useListingFilter();

  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.connection_bool_exp = {
        tenantId: { _eq: teamId },
        app: {
          category: { _eq: 'propertyManagementSystem' },
          name: search ? { _ilike: `%${search}%` } : undefined,
        },
      };

      const aggregate =
        q.connectionAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .connection({
          where,
          limit,
          offset,
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem>((connection) => ({
          label: connection.name || 'No name',
          value: connection.id,
          icon: connection.app.iconRound ? (
            <Avatar size={16} mt={3} src={connection.app.iconRound} />
          ) : undefined,
        }));

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'listings',
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data, isInitialLoading: loadingValue } = useQuery(
    (q, { pmsConnectionId, teamId }) => {
      const hasMultiplePms =
        q
          .connection({
            where: {
              app: { category: { _eq: 'propertyManagementSystem' } },
              tenantId: { _eq: teamId },
            },
            limit: 2,
          })
          .map((x) => x.id)
          .filter(hasValue).length > 1;

      if (!pmsConnectionId) return { value: null, hasMultiplePms };

      const value = q
        .connection({
          where: {
            id: { _eq: pmsConnectionId },
          },
        })
        .map<SelectItem>((connection) => ({
          label: connection.name || 'No name',
          value: connection.id,
          icon: connection.app.iconRound ? (
            <Avatar size={16} mt={3} src={connection.app.iconRound} />
          ) : undefined,
        }))[0];

      return {
        value,
        hasMultiplePms,
      };
    },
    {
      skip: !teamId,
      queryKey: 'connections',
      keepPreviousData: true,
      variables: {
        pmsConnectionId: filter.pmsConnectionId,
        teamId,
      },
    }
  );

  if (loadingValue) return <LoadingIndicator size="xs" />;

  if (!data?.hasMultiplePms) return null;

  return (
    <Filter.Select
      type="single"
      value={data?.value || null}
      setValue={(v) => setFilter({ pmsConnectionId: v?.value })}
      label="PMS"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
      hideIcon
    />
  );
};

const AFilter = () => {
  const { filter, setFilter } = useListingFilter();
  return (
    <AutomationFilter
      automationIds={filter.automationIds}
      setAutomationIds={(ids) => setFilter({ automationIds: ids })}
    />
  );
};
