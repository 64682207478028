import { Title, TitleProps } from '@mantine/core';
import { ReactNode } from 'react';

export const DrawerSectionTitle = ({
  children,
  ...props
}: { children: ReactNode } & TitleProps) => (
  <Title order={4} mt="xl" mb="xs" {...props}>
    {children}
  </Title>
);
