import { Filter } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import type { journalEntry_bool_exp } from '@finalytic/graphql';
import { type SelectItem } from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';

type Props = {
  value: Maybe<string>;
  setValue: (value: Maybe<string>) => void;
};

export const JournalEntryPartyFilter = ({
  value: initial,
  setValue,
}: Props) => {
  const [teamId] = useTeamId();

  const queryData = useQuery(
    (q, { teamId }) => {
      const where: journalEntry_bool_exp = {
        party: { _is_null: false },
        tenantId: { _eq: teamId },
      };

      const list = q
        .journalEntries({
          where,
          order_by: [{ party: 'asc_nulls_last' }],
          distinct_on: ['party'],
        })
        .map<SelectItem>((res) => ({
          value: res.party!,
          label: toTitleCase(res.party)!,
        }));

      return {
        list,
      };
    },
    {
      skip: !teamId,
      queryKey: ['journalEntries'],
      variables: {
        teamId,
      },
    }
  );

  const { data } = useQuery(
    (q, { party }) => {
      if (!party) return null;

      const value =
        q
          .parties({ where: { name: { _eq: party } } })
          .map<SelectItem>((item) => ({
            label: toTitleCase(item.name),
            value: item.name!,
          }))[0] || null;

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['journalEntries'],
      keepPreviousData: true,
      variables: {
        party: initial,
      },
    }
  );

  const value = data?.value || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setValue(v?.value)}
      type="single"
      label="Party"
      withinPortal
      hideSearch
      data={{
        options: queryData.data?.list || [],
        loading: queryData.isLoading,
        error: queryData.error,
      }}
    />
  );
};
