import { address_bool_exp } from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';

export function whereAddress({ search: s }: { search: Maybe<string> }) {
  const search = s?.trim();

  return ensure<address_bool_exp>({
    _or: search
      ? [
          {
            line: { _ilike: `%${search}%` },
          },
          {
            lineDetails: { _ilike: `%${search}%` },
          },
          {
            city: { _ilike: `%${search}%` },
          },
          {
            state: { _ilike: `%${search}%` },
          },
          {
            country: { _ilike: `%${search}%` },
          },
          {
            postalCode: { _ilike: `%${search}%` },
          },
          {
            full: { _ilike: `%${search}%` },
          },
        ]
      : undefined,
  });
}
