import { Maybe } from '@finalytic/utils';
import {
  faCheckCircle,
  faEllipsisH,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMantineTheme } from '@mantine/core';

export const AutomationActionStatusIcon = ({
  status: value,
}: { status: Maybe<string> }) => {
  const { colors } = useMantineTheme();

  if (!value) return null;

  const status =
    value?.toLowerCase().includes('partial') ||
    value?.toLowerCase().includes('skipped')
      ? 'partial'
      : value?.toLowerCase().includes('running') ||
          value?.toLowerCase().includes('pending') ||
          value?.toLowerCase().includes('queued') ||
          value?.toLowerCase().includes('starting')
        ? 'running'
        : value?.toLowerCase().includes('fail') ||
            value?.toLowerCase().includes('failed')
          ? 'fail'
          : 'ok';
  return (
    <FontAwesomeIcon
      icon={
        status === 'running'
          ? faEllipsisH
          : status === 'fail'
            ? faTimesCircle
            : faCheckCircle
      }
      color={
        status === 'partial' || status === 'running'
          ? colors.yellow[5]
          : status === 'fail'
            ? colors.red[5]
            : colors.green[5]
      }
    />
  );
};
