import { StringParam, UrlUpdateType, useQueryParams } from '@finalytic/ui';

type View = 'overview' | 'edit';

export function useOwnerDetailDrawer() {
  const [opts, set] = useQueryParams({
    owner: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.owner,
    open: (id: string, view?: 'overview' | 'edit', type?: UrlUpdateType) =>
      set({ owner: id, view }, type),
    close: () => set({ owner: undefined, view: undefined }),
    ownerId: opts.owner,
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}
