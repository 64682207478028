import { useTrackingInit } from '@finalytic/data';
import {
  type ExtendedCustomColors,
  RootThemeProvider,
  V2Provider,
} from '@finalytic/ui';
import { type ReactNode } from 'react';
import { ErrorBoundary } from '../components';
import { ReloadPrompt } from './ReloadPrompt';

export const RootWrapper = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => {
  useTrackingInit();

  return (
    <ErrorBoundary>
      <V2Provider>
        <RootThemeProvider themePrimaryColor={themePrimaryColor}>
          {children}
          <ReloadPrompt />
        </RootThemeProvider>
      </V2Provider>
    </ErrorBoundary>
  );
};
