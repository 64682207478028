import {
  DividerProps,
  Divider as MDivider,
  useMantineColorScheme,
} from '@mantine/core';

type Props = DividerProps;

export const Divider = ({ sx, ...props }: Props) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <MDivider
      sx={(theme, u) => ({
        borderTopColor: theme.colors.gray[colorScheme === 'dark' ? 7 : 2],
        ...(typeof sx === 'function' ? (sx(theme, u) as any) : sx),
      })}
      {...props}
    />
  );
};
