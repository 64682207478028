import { tenant as Tenant } from '@finalytic/graphql';
import { FormatAddressOptions, formatAddress } from './format-address';

export const getTenantAddress = (
  tenant: Tenant,
  options?: FormatAddressOptions
) => {
  const values: Parameters<typeof formatAddress>[0] = {
    line1: tenant.billingAddress?.line || tenant.addressLine1 || '',
    line2: tenant.billingAddress?.lineDetails || '',
    city: tenant.billingAddress?.city || tenant.addressCity || '',
    postcode: tenant.billingAddress?.postalCode || tenant.addressPostcode || '',
    state: tenant.billingAddress?.state,
    stateCode: tenant.billingAddress?.stateCode,
    country: tenant.billingAddress?.country || tenant.addressCountry,
    countryCode: tenant.billingAddress?.countryCode,
  };

  const full =
    formatAddress(values, options) ||
    tenant.billingAddress?.full ||
    tenant.address;

  return {
    values,
    full,
  };
};
