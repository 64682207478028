import { type MantineFontSize, Text } from '@mantine/core';
import type { ReactNode } from 'react';

export const ActivityDeltaComparison = ({
  size = 'sm',
  type,
  value,
  oldValue,
}: {
  size?: MantineFontSize;
  type: string | ReactNode;
  oldValue?: ReactNode;
  value: ReactNode;
}) => (
  <Text size={size} color="gray">
    <Text component="span" color="black" inherit>
      {type}
    </Text>{' '}
    changed{' '}
    {!!oldValue && (
      <>
        from{' '}
        {typeof oldValue === 'string' ? (
          <Text component="span" color="black" inherit td="line-through">
            {oldValue}
          </Text>
        ) : (
          oldValue
        )}{' '}
      </>
    )}{' '}
    to{' '}
    {typeof value === 'string' ? (
      <Text component="span" c="black" inherit>
        {value}
      </Text>
    ) : (
      value
    )}
  </Text>
);
