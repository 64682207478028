import { useQuery } from '@finalytic/data';
import { Maybe, hasValue, isUUID, uniqueBy, utc } from '@finalytic/utils';
import { InboxNotificationData } from '@liveblocks/client';
import { useInboxNotifications } from '@liveblocks/react';
import { Text } from '@mantine/core';
import {
  formatOwnerName,
  formatUserName,
  getListingName,
} from '@vrplatform/ui-common';
import { ReactNode } from 'react';
import { ActivityIcon as ActivityIconType } from '../drawers';

export type NotificationRow = InboxNotificationData & {
  actor?: {
    id: string;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    email: string;
    avatar?: string;
  };
  to?: string;
  icon?: ActivityIconType;
  content: ReactNode;
};

const NOTIFICATION_QUERY_KEY = 'notifications';

export function useNotificationsQuery() {
  const {
    inboxNotifications,
    isLoading: loadingInbox,
    error: liveBlocksError,
  } = useInboxNotifications();
  const {
    data: queryData,
    isLoading: loadingQuery,
    error,
    isInitialLoading,
    refetch,
  } = useQuery(
    (q, args) => {
      if (!args.notifications?.length)
        return {
          all: [],
          unread: [],
        };

      const userIds = uniqueBy(
        args.notifications
          .map((x) => {
            if (
              x.kind === '$ownerStatementInReview' ||
              x.kind === '$ownerStatementPublished'
            ) {
              const data = x.activities[0].data;

              if ('triggeredByUserId' in data) {
                return data.triggeredByUserId;
              }
            }

            return undefined;
          })
          .filter(hasValue)
      );

      const actors = q
        .user({
          where: {
            id: { _in: userIds },
          },
        })
        .map((user) => ({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email!,
          avatar: undefined,
        }));

      const all = args.notifications.map<NotificationRow>((x) => {
        if (
          x.kind === '$ownerStatementInReview' ||
          x.kind === '$ownerStatementPublished' ||
          x.kind === '$inviteOwnerAccepted' ||
          x.kind === '$inviteTeamMemberAccepted'
        ) {
          const data = x.activities[0].data;
          let actor: NotificationRow['actor'] = undefined;

          if ('triggeredByUserId' in data) {
            actor = actors.find((x) => x.id === data.triggeredByUserId);
          }

          const formatted: {
            content: NotificationRow['content'];

            to?: NotificationRow['to'];
          } = (() => {
            // $ownerStatementPublished OR $ownerStatementInReview
            if ('ownerStatementId' in data && data.ownerStatementId) {
              const statement = q
                .ownerStatements({
                  where: {
                    id: { _eq: data.ownerStatementId },
                  },
                  limit: 1,
                })
                .map(
                  (st) =>
                    `${getListingName(st.listing)} - ${utc(st.startAt).format('MM/YYYY')}`
                )[0];

              const content = (
                <Text c="gray">
                  Updated{' '}
                  <Text span fw={500} c="dark">
                    {statement || 'owner statement'}
                  </Text>{' '}
                  to{' '}
                  {x.kind === '$ownerStatementInReview'
                    ? 'in review'
                    : 'published'}
                </Text>
              );

              return {
                content,
                to: `/statement/${data.listingId}?date=${utc(data.startAt).format('YYYY-MM-01')}&sti=${data.tenantId}`,
              };
            }

            // $1099StatementPublished
            if ('taxStatementId' in data && data.taxStatementId) {
              const statement = q
                .taxStatements({
                  where: {
                    id: { _eq: data.taxStatementId },
                  },
                  limit: 1,
                })
                .map((st) => `${formatOwnerName(st.owner)} - ${data.year}`)[0];

              const content = (
                <Text c="gray">
                  Updated{' '}
                  <Text span fw={500} c="dark">
                    {statement || 'tax statement'}
                  </Text>{' '}
                  to {data.status === 'ready' ? 'ready' : 'filed'}
                </Text>
              );

              return {
                content,
                to: `/statements/tax-statements?year=${data.year}&status=${data.status}`,
              };
            }

            // $inviteOwnerAccepted OR $inviteTeamMemberAccepted
            if ('userId' in data) {
              const user = !isUUID(data.userId)
                ? ''
                : q
                    .user({
                      where: {
                        id: { _eq: data.userId },
                      },
                      limit: 1,
                    })
                    .map((user) => formatUserName(user))[0];

              const defaultName =
                x.kind === '$inviteOwnerAccepted' ? 'Owner' : 'Member';

              const content = (
                <Text c="gray">
                  <Text span fw={500} c="dark">
                    {user || defaultName}
                  </Text>{' '}
                  accepted{' '}
                  {x.kind === '$inviteOwnerAccepted'
                    ? 'an invitation to the owner portal'
                    : 'an invitation to the team'}
                </Text>
              );

              return {
                content,
              };
            }

            return {
              content: <Text c="gray">Missing notification</Text>,
            };
          })();

          return {
            ...x,
            actor,
            icon: 'update',
            content: formatted.content,
            to: formatted.to,
          };
        }

        return { ...x, content: <Text>Missing notification</Text> };
      });

      return {
        all,
        unread: all.filter((x) => !x.readAt),
      };
    },
    {
      queryKey: [NOTIFICATION_QUERY_KEY],
      skip: !inboxNotifications?.length,
      keepPreviousData: true,
      variables: {
        notifications: inboxNotifications,
      },
    }
  );

  return {
    loading: loadingInbox || loadingQuery,
    isInitialLoading,
    error: liveBlocksError || error || undefined,
    queryData,
    refetch,
  };
}
