import {
  Button,
  InputDay,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  useQuery,
  useTeamId,
  useTeamRole,
  useTrpcMutation,
  useTrpcQuery,
} from '@finalytic/data';
import { HiddenFeatureIndicator, useRunDrawer } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import { RouterOutput } from '@finalytic/trpc-api';
import { Modal, SelectItem, showErrorNotification } from '@finalytic/ui';
import {
  Maybe,
  day,
  emptyUUID,
  hasValue,
  isUUID,
  toTitleCase,
} from '@finalytic/utils';
import { Avatar, Box, Group, Skeleton, Stack, Text } from '@mantine/core';
import { Switch } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useExtractModal } from './useExtractModal';

// allow to extract all of connection
// allow to extract only certain key from connection
// prefill modal when opening from mapping
// some systems allow extracting by date ranges

const extractAllKey = 'extract_all';

type FormInputs = {
  dateRange: [Date | null, Date | null] | undefined | null;
  forceUpdate: Maybe<boolean>;
  extractType: Maybe<string>;
};

export const ExtractModal = () => {
  const {
    close: closeModal,
    opened,
    data: { extractConnectionIds, extractType },
  } = useExtractModal();

  const methods = useForm<FormInputs>();

  const { runExtraction } = useExtractMutation({
    closeModal,
    extractConnectionIds,
  });

  const { connection, extractors, loading, refetch } = useConnectionData({
    extractConnectionIds,
    opened,
  });

  useEffect(() => {
    if (opened) {
      methods.reset({ extractType, dateRange: [null, null] });
    }
  }, [extractType, opened]);

  useEffect(() => {
    if (
      extractType &&
      !extractors
        .map((x: RouterOutput['extractSchema']['extractors'][number]) => x.name)
        .includes(extractType)
    ) {
      methods.setValue('extractType', null);
    }
  }, [extractType, extractors]);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={
        extractConnectionIds.length > 1 ? (
          <Group wrap="nowrap" gap="sm">
            <Icon icon="RefreshCwIcon" size={18} />

            <Text fw={500} size="md">
              Fetch connections
            </Text>
          </Group>
        ) : loading ? (
          ''
        ) : connection ? (
          <Group wrap="nowrap">
            <Avatar size="sm" src={connection.logo} />
            <Text lineClamp={1} size="md" fw={500}>
              {connection.name}
            </Text>
          </Group>
        ) : (
          <Text>Missing connection</Text>
        )
      }
    >
      {loading ? (
        <Stack mb="lg">
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </Stack>
      ) : !connection && extractConnectionIds.length < 2 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          mt="sm"
          mb="xl"
          px="sm"
        >
          <Icon
            icon="AlertTriangleIcon"
            size={24}
            color={(theme) => theme.colors.orange[6]}
          />
          <Text ta="center" component="h3" fw={500} mx="auto" my={'xs'}>
            Failed to fetch connection
          </Text>
          <Text ta="center" component="p" mx="auto" mb="xl">
            An error has occurred. Please try fetching query again.
          </Text>
          <Group
            wrap="nowrap"
            sx={{
              width: '90%',
            }}
          >
            <Button
              onClick={closeModal}
              sx={{
                width: 150,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={refetch}
              sx={{
                width: '100%',
              }}
            >
              Refetch
            </Button>
          </Group>
        </Box>
      ) : (
        <Box component="form" onSubmit={methods.handleSubmit(runExtraction)}>
          <Stack mb="lg">
            {/* Select for extractTypes */}
            {extractConnectionIds.length === 1 && (
              <Controller
                control={methods.control}
                name="extractType"
                rules={{
                  required: 'Data type is required',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const extractAllItem: SelectItem = useMemo(
                    () => ({
                      label: 'Fetch all types',
                      value: extractAllKey,
                    }),
                    []
                  );

                  const data = useMemo<SelectItem[]>(
                    () =>
                      extractors.map(
                        (
                          ex: RouterOutput['extractSchema']['extractors'][number]
                        ) => ({
                          label: toTitleCase(ex.name) || '',
                          value: ex.name,
                        })
                      ),
                    [extractors]
                  );

                  const selectValue = useMemo<SelectItem | undefined>(
                    () =>
                      [...data, extractAllItem].find((d) => d.value === value),
                    [data, value, extractAllItem]
                  );

                  return (
                    <InputWrapper
                      label="Data type"
                      error={error?.message}
                      required
                    >
                      <InputSelect
                        data={{ options: data }}
                        value={selectValue || null}
                        type="single"
                        setValue={(val) => onChange(val?.value || null)}
                        inputProps={{
                          placeholder: 'Data type',
                          error: !!error,
                          withClearButton: true,
                        }}
                        pinnedItems={[extractAllItem]}
                        dropdownProps={{
                          searchPlaceholder: 'Search data type...',
                          width: 'target',
                          withinPortal: true,
                          zIndex: 510,
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
            )}
            <Controller
              control={methods.control}
              name="dateRange"
              render={({
                field: { name, onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <InputWrapper label="Date range" error={error?.message}>
                    <InputDay
                      type="range"
                      value={value || undefined}
                      name={name}
                      onChange={onChange}
                      error={!!error?.message}
                      placeholder="Date range (optional)"
                      clearable
                      allowSingleDateInRange
                      popoverProps={{
                        withinPortal: true,
                        zIndex: 510,
                      }}
                    />
                  </InputWrapper>
                );
              }}
            />
            <HiddenFeatureIndicator permission="super-admin">
              <InputWrapper label="Force update">
                <Controller
                  control={methods.control}
                  name="forceUpdate"
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <Switch
                        checked={value || undefined}
                        name={name}
                        onChange={onChange}
                        disabled={loading}
                        label="Update even if no changes detected"
                        labelPosition="left"
                        styles={(theme) => ({
                          body: {
                            justifyContent: 'space-between',
                          },
                          label: {
                            color: theme.colors.gray[7],
                            fontSize: theme.fontSizes.xs,
                          },
                        })}
                      />
                    );
                  }}
                />
              </InputWrapper>
            </HiddenFeatureIndicator>
          </Stack>

          <Group justify="right" mb="md">
            <Button type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={methods.formState.isSubmitting}
              type="submit"
            >
              Fetch data
            </Button>
          </Group>
        </Box>
      )}
    </Modal>
  );
};

const useExtractMutation = ({
  extractConnectionIds,
  closeModal,
}: { extractConnectionIds: string[]; closeModal: () => void }) => {
  const [teamId] = useTeamId();
  const { setWorkflowIds } = useRunDrawer();

  const { mutate } = useTrpcMutation('extractMultiple');
  const { isSuperAdmin } = useTeamRole();

  const runExtraction = async (data: FormInputs) => {
    try {
      if (!extractConnectionIds.length)
        return showErrorNotification({
          message: 'Missing connection id',
          color: 'yellow',
        });

      const isRange =
        data.dateRange?.every((i) => !!i) && data.dateRange.length === 2;

      const extractType = data.extractType || undefined;
      const isExtractAll = extractType === extractAllKey;

      const range = isRange
        ? {
            start: day(data.dateRange![0]!).yyyymmdd(),
            end: day(data.dateRange![1]!).yyyymmdd(),
          }
        : undefined;

      const results = await mutate({
        teamId,
        connections: extractConnectionIds.map((connectionId) => ({
          connectionId,
          type:
            isExtractAll || extractConnectionIds.length !== 1
              ? undefined
              : extractType,
          forceUpdate: isSuperAdmin ? !!data.forceUpdate : false,
          range,
        })),
      });

      const workflowIds = results
        .map((i: RouterOutput['extractMultiple'][number]) => i.workflowId)
        .filter(hasValue);
      const syncIds = results
        .map((i: RouterOutput['extractMultiple'][number]) => i.syncId)
        .filter(hasValue);

      closeModal();
      setWorkflowIds(workflowIds, syncIds);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    runExtraction,
  };
};

const useConnectionData = ({
  extractConnectionIds,
  opened,
}: { extractConnectionIds: string[]; opened: boolean }) => {
  const disableQueries = extractConnectionIds.length !== 1 || !opened;

  const [teamId] = useTeamId();

  const {
    data,
    loading: loadingSchema,
    refetch: refetchSchema,
  } = useTrpcQuery(
    'extractSchema',
    {
      teamId,
      connectionId: extractConnectionIds[0],
    },
    { skip: disableQueries }
  );

  const extractors = data?.extractors ?? [];

  const {
    data: queryConnection,
    isLoading: loadingQuery,
    refetch: refetchQuery,
  } = useQuery(
    (q, args) => {
      if (!args.connectionId || !isUUID(args.connectionId)) return null;

      const connection = q.connectionById({
        id: args.connectionId || emptyUUID,
      });

      return {
        id: connection?.id,
        name: connection?.name,
        logo: connection?.app?.iconRound,
      };
    },
    {
      skip: disableQueries,
      variables: {
        connectionId: extractConnectionIds[0],
      },
    }
  );

  const [debouncedConnection] = useDebouncedValue(queryConnection, 300);

  const connection = queryConnection || debouncedConnection;

  const loading = loadingSchema || loadingQuery;

  const refetch = () => {
    refetchSchema();
    refetchQuery();
  };

  return {
    loading,
    connection,
    extractors,
    refetch,
  };
};
