import { journalEntry_bool_exp } from '@finalytic/graphql';
import { createStore, useStore } from 'zustand';

type State = {
  where: journalEntry_bool_exp | null;
};

type Actions = {
  open: (where: journalEntry_bool_exp) => void;
  close: () => void;
};

const initialState: State = {
  where: null,
};

export const journalEntriesModalStore = createStore<State & Actions>((set) => ({
  close: () => set({ where: null }),
  open: (where) => set({ where }),
  ...initialState,
}));

export const useJournalEntriesModal = () => {
  const open = useStore(journalEntriesModalStore, (state) => state.open);
  const close = useStore(journalEntriesModalStore, (state) => state.close);

  return {
    open,
    close,
  };
};

export const useJournalEntriesModalWhere = () => {
  const where = useStore(journalEntriesModalStore, (state) => state.where);

  return where;
};
