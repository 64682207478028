import {
  Box,
  Drawer as MantineDrawer,
  useMantineColorScheme,
} from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { useDebouncedValue } from '@mantine/hooks';
import { ReactNode } from 'react';
import { BaseDrawerProps } from './_types';

type Props = {
  containerSx?: EmotionSx;
  header?: ReactNode;
};

export const Drawer = ({
  children,
  opened,
  onClose,
  containerSx,
  header,
  styles,
  ...props
}: BaseDrawerProps & Props) => {
  const [opened1] = useDebouncedValue(opened, 0);
  const { colorScheme } = useMantineColorScheme();

  return (
    <MantineDrawer
      opened={opened1}
      onClose={onClose}
      position="right"
      transitionProps={{
        duration: 300,
      }}
      withinPortal
      withCloseButton={false}
      overlayProps={{
        color: '#bababa',
        opacity: 0.2,
        blur: 1,
      }}
      styles={{
        content: {
          overflowY: 'scroll',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowX: 'hidden',
        },
        body: {
          display: 'flex',
          padding: 0,
          flex: 1,
          height: '100vh',
        },
        ...styles,
      }}
      {...props}
    >
      {header}
      <Box
        sx={(theme) => {
          const sideBarPadding = theme.spacing.sm;

          return {
            backgroundColor:
              colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
            flex: 1,
            margin: sideBarPadding,
            borderRadius: theme.spacing.md,
            padding: `0px ${theme.spacing.sm}`,
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            ...containerSx,
          } as any;
        }}
      >
        {children}
      </Box>
    </MantineDrawer>
  );
};
