import { Filter } from '@finalytic/components';
import { StringParam, useQueryParams } from '@finalytic/ui';
import { day, hasValue } from '@finalytic/utils';
import { Group } from '@mantine/core';

export const useBankRecordsTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    date: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () => {
      const initial = {
        search: undefined,
        date: undefined,
      };

      setFilter(initial);
    },
  };
};

export const BankRecordsTableFilter = () => {
  const { filter, setFilter } = useBankRecordsTableFilter();

  return (
    <Group>
      <Filter.Search
        value={filter.search || ''}
        setValue={(v) => setFilter({ search: v })}
      />
      <DateFilter />
    </Group>
  );
};

const DateFilter = () => {
  const { filter, setFilter } = useBankRecordsTableFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};
