import { IconButton } from '@finalytic/components';
import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  useSubscription,
  useTeamRole,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { Button as MantineButton } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { useNavbarExpanded } from '../../../hooks';

export const NavbarFeaturesButton = () => {
  const [dashboard] = useDashboard();
  const { realUserId } = useMe();
  const { isPartnerAdmin } = useTeamRole();
  const { isDesktopExpanded } = useNavbarExpanded();
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();
  const goto = useNavigate();

  const skip = !isPartnerAdmin || dashboard === 'owner';

  const { data: issues } = useSubscription(
    (q) => {
      return (
        q
          .featureAggregate({
            where: {
              status: { _nin: ['deployed', 'ready'] },
            },
          })
          ?.aggregate?.count() || 0
      );
    },
    { userId: realUserId },
    {
      skip,
      queryKey: ['features', 'featureApprovals'],
    }
  );

  if (skip || !issues) return null;

  if (LIVEBLOCKS_NOTIFICATIONS)
    return (
      <IconButton
        icon="RocketIcon"
        color={(theme) => theme.colors.yellow[5]}
        onClick={() => goto('/features')}
        count={issues}
        tooltip="View features"
      />
    );

  return (
    <MantineButton
      variant="light"
      component={Link}
      to={'/features'}
      color="yellow"
      sx={(theme) => ({
        marginBottom: theme.spacing.xs,
        background: 'none',
        border: 'none',
        height: 35,
        '&:disabled': {
          background: 'transparent',
        },
      })}
      fw={500}
      radius={'md'}
      styles={{
        label: isDesktopExpanded
          ? {
              marginRight: 'auto',
              opacity: '1 !important',
            }
          : {
              fontSize: 16,
              opacity: '1 !important',
            },
        rightIcon: {
          fontSize: 16,
        },
      }}
      px="xs"
      mx="xs"
      size="xs"
      leftSection={
        isDesktopExpanded && (
          <Icon
            icon="AlertCircleIcon"
            size={16}
            color={(theme) => theme.colors.yellow[6]}
          />
        )
      }
      rightSection={isDesktopExpanded && issues?.toString()}
    >
      {isDesktopExpanded ? 'Features in Testing' : issues || 0}
    </MantineButton>
  );
};
