import { useQuery } from '@finalytic/data';
import { ConfirmModal } from '@finalytic/data-ui';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { useDisclosure } from '@mantine/hooks';
import { type ComponentProps, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConnectionModal, type ConnectionModalProps } from './ConnectionModal';
import type { IntegrationType } from './_types';
import { useConnect, useRedirectedApp } from './hooks';

type Props = {
  onSuccess: () => void;
  opened: boolean;
  open: () => void;
  close: () => void;
  integrations: IntegrationType[];
  presetApp: IntegrationType | undefined;
  resetType?: ComponentProps<typeof ConnectionModal>['resetType'];
};

export const AddConnectionModal = ({
  onSuccess,
  close,
  open,
  opened,
  integrations,
  presetApp,
  resetType,
}: Props) => {
  const [oauthCompletionModalOpen, oauthCompletionModalHandlers] =
    useDisclosure(false);

  const [callback, setCallback] = useQueryParam('callback', StringParam);

  const [connectionId, setConnectionId] = useQueryParam(
    'connectionId',
    StringParam
  );

  const connection = useQuery(
    (q, { connectionId }) => {
      if (!connectionId) return null;
      const connection = q.connectionById({ id: connectionId });
      return {
        appId: connection?.appId,
      };
    },
    {
      skip: !connectionId,
      queryKey: 'connections',
      variables: {
        connectionId,
      },
    }
  );
  const appId = connection.data?.appId;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (callback === 'true') {
      oauthCompletionModalHandlers.open();
    }

    if (appId) {
      open();
    }
  }, [callback, appId]);

  return (
    <>
      <BoundConnectionModal
        opened={opened}
        onClose={close}
        refetchConnections={onSuccess}
        apps={integrations}
        presetApp={presetApp}
        resetType={resetType}
        closeModal={close}
      />

      <OauthCompletionModal
        opened={oauthCompletionModalOpen}
        onClose={() => {
          oauthCompletionModalHandlers.close();
          setConnectionId(undefined);
          setCallback(undefined);
        }}
        onSuccess={onSuccess} // refetch connections
      />
    </>
  );
};

function BoundConnectionModal(
  props: Omit<ConnectionModalProps, 'addConnection'> & {
    appId?: string;
  }
) {
  const { redirectedApp } = useRedirectedApp();
  const connect = useConnect();

  const [loadingText, setLoadingText] = useState('');

  return (
    <ConnectionModal
      {...props}
      refetchConnections={() => {
        setLoadingText('');
        if (props.refetchConnections) props.refetchConnections();
      }}
      presetApp={redirectedApp || props.presetApp}
      loadingText={loadingText}
      addConnection={connect}
    />
  );
}

function OauthCompletionModal({
  onClose,
  opened,
  onSuccess,
}: {
  opened: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
}) {
  const [state] = useQueryParam('state', StringParam);
  const [query] = useSearchParams({});
  const connect = useConnect();

  return (
    <ConfirmModal
      autoSubmit
      opened={opened}
      onSuccess={onSuccess}
      onClose={onClose}
      title="Complete OAuth Connection"
      onSubmit={async () => {
        const resp = await connect({
          params: {
            ...Object.fromEntries(query as any),
            sessionKey: state!,
          },
          sessionKey: state!,
        });
        if (resp.type === 'success') {
          return {
            message: 'Successfully added connection!',
            isSuccess: true,
            autoCloseOnSuccess: true,
          };
        }
        return {
          message: resp.issue?.message || resp.error?.message || 'Error',
          isSuccess: false,
        };
      }}
    />
  );
}
