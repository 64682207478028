import { useLocalStorage } from '@mantine/hooks';
import { MRT_VisibilityState } from 'mantine-react-table';

export type TableKey =
  | 'payments'
  | 'reservations'
  | 'owner_statements'
  | 'listings'
  | 'owners'
  | 'files'
  | 'connections'
  | 'accounts'
  | 'expenses'
  | 'deposits'
  | 'syncs';

type TableSettings = {
  [_table_key in TableKey]?: {
    visibility: MRT_VisibilityState;
    groupBy?: string[];
  };
};

export function useSavedTableSettings() {
  const [value, setValue] = useLocalStorage<TableSettings>({
    key: 'table-settings',
    defaultValue: {},
  });

  return {
    value,
    setTableSettings: (
      tableKey: TableKey,
      settings: Partial<TableSettings[TableKey]>
    ) =>
      setValue((prev) => ({
        ...prev,
        [tableKey]: { ...prev[tableKey], ...settings },
      })),
    resetTableSettings: (tableKey: TableKey) =>
      setValue({
        ...value,
        [tableKey]: undefined,
      }),
  };
}
