import { Logo } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { Avatar, AvatarProps, Group, Text, Tooltip } from '@mantine/core';
import { formatUserName } from '@vrplatform/ui-common';

type Props = {
  user: Maybe<{
    firstName: Maybe<string>;
    lastName: Maybe<string>;
  }>;
  size?: AvatarProps['size'];
  withLabel?: boolean;
  v2VrpIcon?: boolean;
};

export const UserAvatar = (props: Props) => {
  if (props.withLabel) {
    const user = props.user;
    const name = user?.firstName ? formatUserName(user) : 'System';

    return (
      <Group wrap="nowrap">
        <Icon {...props} />
        {props.withLabel && <Text>{name}</Text>}
      </Group>
    );
  }

  return <Icon {...props} />;
};

const Icon = ({ user, withLabel, size = 'sm', v2VrpIcon }: Props) => {
  const hasUser = !!(user?.firstName || user?.lastName);
  const name = hasUser ? formatUserName(user) : 'System';

  const initials = (hasUser ? name : '')
    ?.split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase();

  return (
    <Tooltip label={name} disabled={withLabel} withArrow withinPortal>
      <Avatar
        size={size}
        radius="xl"
        styles={(theme) => ({
          placeholder: {
            backgroundColor: !hasUser ? 'white' : theme.colors.cyan[5],
            fontWeight: 400,
            color: theme.white,
          },
        })}
      >
        {initials || (v2VrpIcon ? <VrpLogo size={33} /> : <Logo width={20} />)}
      </Avatar>
    </Tooltip>
  );
};

const VrpLogo = ({ size = 20 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#1D4ED8" />
      <path
        d="M8.04207 11.337C7.28243 10.0653 7.63599 8.44002 8.8278 7.56033C10.141 6.59104 11.9921 6.87481 12.9546 8.19297L15.8795 12.1986C17.3956 14.2748 15.8216 16.794 13.8274 18.4164C12.9173 19.1567 9.82598 14.3234 8.04207 11.337Z"
        fill="white"
        fillOpacity="0.8"
      />
      <path
        d="M4.88436 11.3318C3.97412 10.0986 4.23593 8.361 5.46912 7.45076C6.70231 6.54053 8.4399 6.80233 9.35014 8.03552L14.2456 14.6678C15.1558 15.901 14.894 17.6386 13.6608 18.5489C12.4276 19.4591 10.69 19.1973 9.77977 17.9641L4.88436 11.3318Z"
        fill="white"
      />
      <path
        d="M14.7385 9.46623C14.7385 8.10761 15.8399 7.00623 17.1985 7.00623C18.5572 7.00623 19.6585 8.10761 19.6585 9.46623C19.6585 10.8249 18.5572 11.9262 17.1985 11.9262C15.8399 11.9262 14.7385 10.8249 14.7385 9.46623Z"
        fill="white"
      />
    </svg>
  );
};
