import { Maybe } from '@finalytic/utils';
import { StateCreator } from 'zustand';

type Filter = {
  [key: string]: Maybe<
    string | Maybe<string>[] | Record<string, string | undefined>
  >;
};

export type TableFilterType = 'query-params' | 'state';

export interface TableFilterSlice {
  filter: Filter;
  filterType: TableFilterType;
  setFilterType: (type: TableFilterType) => void;
  setFilter: (filter: Partial<Filter>) => void;
  getFilter: () => Filter;
  resetFilter: () => void;
}

export const createTableFilterSlice: StateCreator<TableFilterSlice> = (
  set,
  get
) => ({
  filter: {},
  filterType: 'query-params',
  setFilterType: (filterType) => set({ filterType }),
  getFilter: () => get().filter,
  resetFilter: () => set({ filter: {} }),
  setFilter: (filter) => {
    set({ filter: { ...get().filter, ...filter } });
  },
});
