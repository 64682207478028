import { gqlV2 } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { SelectItem } from '@finalytic/ui';
import { TaxStatementStatus } from './TaxStatementStatusSelect';

export const TaxStatementFiledIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833344 8.50009C0.833344 4.54202 4.04199 1.33337 8.00006 1.33337C11.9581 1.33337 15.1668 4.54202 15.1668 8.50009C15.1668 12.4582 11.9581 15.6668 8.00006 15.6668C4.04199 15.6668 0.833344 12.4582 0.833344 8.50009ZM11.3536 7.18696C11.5489 6.9917 11.5489 6.67512 11.3536 6.47986C11.1584 6.2846 10.8418 6.2846 10.6465 6.47986L7.33339 9.793L6.02026 8.47987C5.825 8.28461 5.50842 8.28461 5.31316 8.47987C5.11789 8.67513 5.11789 8.99172 5.31316 9.18698L6.97983 10.8537C7.0736 10.9474 7.20078 11.0001 7.33339 11.0001C7.466 11.0001 7.59317 10.9474 7.68694 10.8537L11.3536 7.18696Z"
      fill="#16A34A"
    />
  </svg>
);

export const TaxStatementInReviewIcon = ({
  color = '#0284C7',
}: { color?: string }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6668 8.50009C14.6668 12.182 11.682 15.1668 8.00006 15.1668C4.31813 15.1668 1.33334 12.182 1.33334 8.50009C1.33334 4.81816 4.31813 1.83337 8.00006 1.83337C11.682 1.83337 14.6668 4.81816 14.6668 8.50009Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00025 3.88001C8.00025 3.60386 8.22464 3.37747 8.49947 3.4044C9.33578 3.48633 10.1418 3.77319 10.8448 4.24288C11.6867 4.80547 12.343 5.60511 12.7305 6.54067C13.118 7.47622 13.2194 8.50568 13.0219 9.49887C12.8243 10.4921 12.3367 11.4043 11.6206 12.1204C10.9046 12.8364 9.99229 13.3241 8.99911 13.5216C8.00593 13.7192 6.97647 13.6178 6.04091 13.2303C5.10535 12.8427 4.30572 12.1865 3.74312 11.3445C3.27343 10.6416 2.98657 9.83553 2.90464 8.99923C2.87772 8.7244 3.10411 8.5 3.38025 8.5H7.50025C7.77639 8.5 8.00025 8.27615 8.00025 8V3.88001Z"
      fill={color}
    />
  </svg>
);

export function useTaxStatementStatusOptions(
  status: TaxStatementStatus,
  selectType: 'single' | 'multi'
): SelectItem<gqlV2.tax_statement_status_enum>[] {
  const disabledTooltip =
    selectType === 'single'
      ? "Set the tax statement to 'In Review' or 'Ready' before filing."
      : "Please filter tax statements to 'In Review' or 'Ready' to multi-file statements.";

  return [
    {
      value: 'ignored',
      label: 'Ignored',
      description: 'Hide statement from the list',
      icon: <Icon icon="CrossCircleIcon" size={16} />,
    },
    {
      label: 'Draft',
      description: 'Editable statement',
      value: 'draft',
      icon: (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6668 8.50009C14.6668 12.182 11.682 15.1668 8.00006 15.1668C4.31813 15.1668 1.33334 12.182 1.33334 8.50009C1.33334 4.81816 4.31813 1.83337 8.00006 1.83337C11.682 1.83337 14.6668 4.81816 14.6668 8.50009Z"
            stroke="#F59E0B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00024 3.87817C8.00024 3.60203 8.22464 3.37564 8.49947 3.40255C9.67459 3.51759 10.7798 4.03619 11.6219 4.87832C12.5825 5.83885 13.1221 7.14161 13.1221 8.5C13.1221 9.85839 12.5825 11.1611 11.6219 12.1217C10.7798 12.9638 9.67459 13.4824 8.49947 13.5975C8.22464 13.6244 8.00024 13.398 8.00024 13.1218L8.00024 8.5V3.87817Z"
            fill="#F59E0B"
          />
        </svg>
      ),
    },
    {
      label: 'In Review',
      description: 'Statement is locked',
      value: 'inReview',
      icon: <TaxStatementInReviewIcon />,
    },
    {
      label: 'Ready',
      description: 'Not filed yet, but owners can view',
      value: 'ready',
      icon: <TaxStatementInReviewIcon color="#20C997" />,
    },
    {
      label: 'Filed',
      description: 'Statement will be filed with the tax authority',
      value: 'submitted',
      icon: <TaxStatementFiledIcon />,
      disabled:
        status === 'draft' || status === 'ignored' || status === 'error'
          ? disabledTooltip
          : undefined,
    },
  ];
}
