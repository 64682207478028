import { Box } from '@mantine/core';
import { useMemo } from 'react';
import { SelectFilter } from './SelectFilter';

type activeStatus_enum = 'active' | 'inactive';
type SelectItem<TValue extends string> = {
  label: string;
  value: TValue;
  icon?: React.ReactNode;
};

export const StatusFilter = ({
  setValue,
  value = 'active',
  activeLabel = 'Active',
  inactiveLabel = 'Archived',
}: {
  value: activeStatus_enum | undefined;
  setValue: (status: activeStatus_enum | undefined) => void;
  activeLabel?: string;
  inactiveLabel?: string;
}) => {
  const options: SelectItem<activeStatus_enum>[] = useMemo(
    () => [
      {
        label: activeLabel,
        value: 'active',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: inactiveLabel,
        value: 'inactive',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ],
    [activeLabel, inactiveLabel]
  );

  const formatted = useMemo(
    () => options.find((o) => value === o.value) || null,
    [options, value]
  );

  return (
    <SelectFilter
      type="single"
      label={'Status'}
      value={formatted}
      data={{
        options,
      }}
      setValue={(v) => v?.value && setValue(v.value)}
      hideSearch
      hideIcon
      hideClearButton
    />
  );
};
