import { useInvalidateQueries } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { Drawer, IconButton } from '@finalytic/ui';
import { Box, LoadingOverlay, useMantineColorScheme } from '@mantine/core';
import { ComponentProps } from 'react';
import { DrawerHeader } from '../_components';
import { ActivityTable } from './ActivityTable';

type Props = {
  opened: boolean;
  close: () => void;
  title: string;
  loading?: boolean;
};

export const ActivityDrawer = ({
  close,
  opened,
  title,
  children,
  loading,
  ...tableProps
}: Props & ComponentProps<typeof ActivityTable>) => {
  const { colorScheme } = useMantineColorScheme();

  const invalidate = useInvalidateQueries(['activity']);

  return (
    <Drawer opened={!!opened} onClose={close} size={600}>
      <DrawerHeader closeDrawer={close} title={title} type="Activity">
        <IconButton onClick={() => invalidate(['activity'])}>
          <Icon icon="RefreshCwIcon" size={16} />
        </IconButton>
      </DrawerHeader>

      <Box
        pb="sm"
        pos="relative"
        sx={(theme) => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '.mantine-Table-tr-detail-panel': {
            backgroundColor:
              theme.colors.neutral[colorScheme === 'dark' ? 9 : 0],
            width: '100%',
            height: '100%',
            borderBottom: 'none!important',
            '& td': {
              borderBottom: 'none!important',
              paddingBlock: '0px!important',
              padding: 0,
              width: '100%',
              minWidth: '100%',
              display: 'grid',
            },
          },
        })}
      >
        <ActivityTable {...tableProps}>{children}</ActivityTable>
        <LoadingOverlay visible={!!loading} />
      </Box>
    </Drawer>
  );
};
