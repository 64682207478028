import {
  PmDashboardChartBase,
  PmDashboardChartBaseBaseProps,
} from './PmDashboardChartBase';

export const PaymentVolumeCard = (props: PmDashboardChartBaseBaseProps) => {
  return (
    <PmDashboardChartBase
      {...props}
      title="Payment Volume"
      href="/payments"
      total="sum"
      isCurrencyAmount
      type="paymentVolume"
    />
  );
};
