import { Box, Group, Text } from '@mantine/core';

export const Legend = ({
  legendColor,
  label,
}: { legendColor?: string; label: string }) => {
  return (
    <Group gap={8}>
      <Box
        sx={(theme) => ({
          width: 10,
          height: 10,
          borderWidth: '3px',
          borderStyle: 'solid',
          borderRadius: '100%',
          borderColor: legendColor || theme.colors.gray[5],
        })}
      />
      <Text
        component="p"
        m={0}
        sx={(theme) => ({ color: theme.colors.gray[7] })}
      >
        {label}
      </Text>
    </Group>
  );
};
