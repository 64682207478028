import { hasValue } from '@finalytic/utils';

export const formatUserName = (
  user?: {
    firstName?: string | null;
    lastName?: string | null;
    companyName?: string | null;
    email?: string | null;
  } | null,
  options?: { lastNameFirst?: boolean; showEmpty?: boolean }
) => {
  const first = user?.firstName?.trim() || '';
  const last = user?.lastName?.trim() || '';
  const company = user?.companyName?.trim() || '';
  const email = user?.email?.trim() || '';

  if (!first && !last && !company) {
    if (email) return email;

    return options?.showEmpty ? '' : 'Missing name';
  }

  if (first || last) {
    if (options?.lastNameFirst)
      return [last, first].filter(hasValue).join(', ');

    return [first, last].filter(hasValue).join(' ');
  }

  return `${company}`;
};
