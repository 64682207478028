import { CrossIcon, SearchIcon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import { MantineSize, rem, useMantineTheme } from '@mantine/core';
import { forwardRef, useState } from 'react';
import { Input } from '../input';

type Props = {
  value: string;
  setValue: (v: string) => void;
  debounce?: number;
  width?: string | number;
  radius?: MantineSize;
};

export const SearchFilter = forwardRef<HTMLInputElement, Props>(
  ({ setValue, value, debounce, width, radius }, ref) => {
    const { colors } = useMantineTheme();

    const [isFocused, setIsFocused] = useState(false);

    return (
      <Input
        ref={ref}
        value={value}
        width={width}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        setValue={setValue}
        placeholder={'Search ...'}
        leftSection={<SearchIcon size={16} color={colors.neutral[5]} />}
        rightSection={
          value && (
            <IconButton onClick={() => setValue('')}>
              <CrossIcon size={20} color={colors.neutral[4]} />
            </IconButton>
          )
        }
        debounce={debounce}
        styles={(theme) => ({
          wrapper: {
            minWidth: '0px',
            width: width ? undefined : isFocused ? '250px' : '130px',
            transition: 'width 200ms ease',
          },
          input: {
            minWidth: '0px',
            width: '100%',
            height: rem(32),
            minHeight: rem(32),
            borderRadius: theme.radius[radius || 'xl'],
            ':focus': {
              boxShadow: `0px 0px 0px 2px ${
                theme.colors[theme.primaryColor][4]
              }40`,
            },
            '::placeholder': {
              color: theme.colors.neutral[5],
            },
          },
        })}
      />
    );
  }
);
