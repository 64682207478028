import { LoadingIndicator } from '@finalytic/ui';
import { NumberInput, NumberInputProps, Text } from '@mantine/core';
import { forwardRef } from 'react';

interface Props extends NumberInputProps {
  loadingQuery?: boolean;
  loadingMutation?: boolean;
}

export const InputPercentage = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onChange,
      radius,
      placeholder,
      decimalScale = 2,
      styles,
      loadingMutation,
      loadingQuery,
      leftSection: icon,
      ...props
    },
    ref
  ) => {
    return (
      <NumberInput
        ref={ref}
        value={loadingQuery ? '' : value}
        onChange={onChange}
        sx={{ height: '100%' }}
        radius={radius}
        placeholder={placeholder}
        inputMode="decimal"
        decimalScale={decimalScale}
        max={100}
        leftSection={
          loadingQuery ? <LoadingIndicator size="xs" /> : icon || <Text>%</Text>
        }
        rightSection={loadingMutation && <LoadingIndicator size="xs" />}
        styles={(...themeProps) => {
          const theme = themeProps[0];

          return {
            root: {
              height: '100%',
            },
            input: {
              height: '100%',
              '&:focus': {
                boxShadow: `0px 0px 0px 2px ${
                  theme.colors[theme.primaryColor][4]
                }40`,
              },
            },
            wrapper: {
              height: '100%',
            },
            ...(typeof styles === 'function' ? styles(...themeProps) : styles),
          };
        }}
        {...props}
      />
    );
  }
);
