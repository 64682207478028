import * as gql from '@finalytic/graphql';
import { Maybe, emptyUUID, hasValue } from '@finalytic/utils';
import { XIMPLIFI_TENANT_ID } from '../consts';
import { whereConnectionStatusDefault } from './whereConnectionStatusDefault';

export const queryReservationFinancials = (
  reservation: Maybe<gql.reservation>,
  {
    tenantId,
    partnerId,
    GL,
  }: {
    tenantId: string;
    partnerId: string;
    GL: boolean;
  }
) =>
  reservation
    ?.paymentLines({
      where: {
        paymentId: { _is_null: true },
      },
      order_by: [{ type2: 'asc_nulls_last' }, { centTotal: 'desc_nulls_last' }],
    })
    .map((line) => {
      const legacy = isLineAccountingType(line, 'invoice', {
        tenantId,
        partnerId,
      });

      const reservationBookingChannel = reservation.channel?.uniqueRef;

      const ledgerLineTypeMappings = !GL
        ? []
        : line
            .lineTypeAccounts({
              where: {
                tenantId: { _eq: tenantId },
              },
            })
            .map((x) => {
              return {
                id: x.id,
                bookingChannel: x.bookingChannel,
                accountId: x.accountId as string | null,
              };
            });

      const isLineType: boolean = GL
        ? (() => {
            const withBookingChannel = ledgerLineTypeMappings.find(
              (x) => x.bookingChannel === reservationBookingChannel
            );

            if (reservationBookingChannel && withBookingChannel) {
              return !!withBookingChannel?.accountId;
            }

            const noBookingChannel = ledgerLineTypeMappings.find(
              (x) => !x.bookingChannel
            );

            return !!noBookingChannel?.accountId;
          })()
        : legacy.isLineType;

      return {
        id: line.id as string,
        description: line.description,
        centTotal: line.centTotal,
        currency: line.reservation?.currency,
        lineItemType: getPaymentLineItemType(line),
        type2: line.type2,
        isInvoice: isLineType ? ('invoice' as const) : ('excluded' as const),
        lineItemSetting: legacy.setting,
        connectionId: line.connectionId as Maybe<string>,
      };
    }) || [];

export type PaymentLineType =
  | 'adjustment'
  | 'reservationPayment'
  | 'resolution';

export const getPaymentLineItemType = (
  line: gql.payment_line
): PaymentLineType => {
  const lowerCase = line.description?.toLowerCase();
  if (lowerCase?.includes('refund')) return 'resolution';
  if (lowerCase?.includes('resolution')) return 'resolution';
  if (lowerCase?.includes('adjustment')) return 'adjustment';

  return 'reservationPayment';
};

export const queryReservationPaymentLines = (
  reservation: Maybe<gql.reservation>,
  options?: {
    includeAllType2s?: boolean;
  }
) =>
  reservation
    ?.paymentLines({
      where: {
        skipReconcile: { _eq: false },
        type2: options?.includeAllType2s
          ? undefined
          : {
              _in: [
                'stripe_charge',
                'reservation',
                // Remove these
                'airbnb_passThroughTot',
                'airbnb_coHostPayout',
                // 'airbnb_managementFee',
              ],
            },
        payment: {
          status: { _neq: 'cancelled' },
          type: { _in: ['payout', 'custom'] },
        },
        connection: {
          status: whereConnectionStatusDefault,
        },
      },
      order_by: [{ createdAt: 'asc' }],
    })
    .map((line) => {
      // const v = isLineAccountingType(line, 'journalEntry', { tenantId, partnerId });
      return {
        id: line?.id,
        description: line?.description,
        centTotal: line?.centTotal,
        paymentId: line?.paymentId,
        type: getPaymentLineItemType(line),
        isResevationPayment: [
          'stripe_charge',
          'stripe_refund',
          'reservation',
          // Remove these
          'airbnb_passThroughTot',
          'airbnb_coHostPayout',
          // 'airbnb_managementFee',
        ].includes(line.type2 || ''),
        payment: {
          id: line?.paymentId,
          payedAt: line?.payment?.payedAt,
          description: line?.payment?.description,
        },
      };
    }) || [];

export function isLineAccountingType(
  line: gql.payment_line,
  accountingType: 'invoice' | 'journalEntry',
  {
    tenantId,
    partnerId,
  }: {
    tenantId: string;
    partnerId?: string;
  }
) {
  // Fallback to ximplifi partner settings
  const ximplip = XIMPLIFI_TENANT_ID;
  const ids = [
    line.type2,
    line.reservation?.bookingPlatform,
    line.reservation?.channelId,
    line.reservation?.channel?.uniqueRef,
    line.reservation?.listingConnection?.listingId,
    line.reservation?.listingConnectionId,
  ];
  const [map1, map2, map3] = [
    getMapping({
      list: line
        .settingsByType2({
          where: {
            tenant_id: { _eq: tenantId },
            key: { _eq: 'inclusion' },
            localAutomationId: { _is_null: true },
          },
        })
        .map((setting) => settingToResultLight(undefined, setting)),
      key: 'inclusion',
      ids,
    }),
    partnerId
      ? getMapping({
          list: line
            .settingsByType2({
              where: {
                tenant_id: { _eq: partnerId },
                key: { _eq: 'accountingType' },
                localAutomationId: { _is_null: true },
              },
            })
            .map((setting) => settingToResultLight(undefined, setting)),
          key: 'accountingType',
          ids,
        })
      : undefined,
    getMapping({
      list: line
        .settingsByType2({
          where: {
            tenant_id: { _eq: ximplip },
            key: { _eq: 'accountingType' },
            localAutomationId: { _is_null: true },
          },
        })
        .map((setting) => settingToResultLight(undefined, setting)),
      key: 'accountingType',
      ids,
    }),
  ] as const;

  let setting = map1?.right?.value
    ? map1
    : map2?.right?.value
      ? map2
      : map3?.right?.value
        ? map3
        : undefined;
  const child = setting?.childs?.find((item: any) =>
    ids.includes(item?.left?.value || emptyUUID)
  );
  if (child) setting = child;
  const v = setting?.right?.value;

  // if (line.type2?.toLowerCase().includes('passthrough')) {
  //   console.log(line.type2, v, map1?.right?.value, map2?.right?.value);
  // }
  return {
    isLineType:
      accountingType === 'journalEntry'
        ? ['journalEntry', undefined].includes(v)
        : accountingType === 'invoice'
          ? ['invoice', undefined].includes(v)
          : true,
    setting,
  };
}
export function isLineClassificationAccountingType(
  line: gql.payment_line_classification,
  accountingType: 'invoice' | 'journalEntry' | undefined,
  { tenantId, partnerId }: { tenantId: string; partnerId: string }
) {
  const [map1, map2] = [
    getMapping({
      list: line
        .settingsRight({
          where: {
            tenant_id: { _eq: tenantId },
            key: { _eq: 'inclusion' },
            localAutomationId: { _is_null: true },
          },
        })
        .map((setting) => settingToResultLight(undefined, setting)),
      key: 'inclusion',
      ids: [line.name],
      // we look for any that is not "exclude"
      emphasize: accountingType,
    }),
    partnerId
      ? getMapping({
          list: line
            .settingsRight({
              where: {
                tenant_id: { _eq: partnerId },
                key: { _eq: 'accountingType' },
                localAutomationId: { _is_null: true },
              },
            })
            .map((setting) => settingToResultLight(undefined, setting)),
          key: 'accountingType',
          ids: [line.name],
          // we look for any that is not "exclude"
          emphasize: accountingType,
        })
      : undefined,
  ];
  const v = map1?.right?.value || map2?.right?.value;

  // if (line.name?.toLowerCase().includes('passthrough')) {
  //   console.log(line.name, v, map1?.right?.value, map2?.right?.value);
  // }

  return accountingType === 'journalEntry'
    ? ['journalEntry', undefined].includes(v)
    : accountingType === 'invoice'
      ? ['invoice', undefined].includes(v)
      : true;
}

export function getMapping({
  list,
  key,
  ids,
  consider,
  emphasize,
}: {
  list: any[];
  key: string;
  ids: Maybe<string>[];
  consider?: any;
  emphasize?: string;
}) {
  consider = consider || ['defaults', 'mappings', 'overwrites'];
  ids = ids.filter(hasValue);
  // console.log(`Looking up ${String(key)} on ${ids.length} ids`);
  const filtered1 = list?.filter((x) => x?.key === key) || [];
  const filtered = ids.reduce<Record<any, any>[]>((acc, id) => {
    const items = filtered1?.filter((x) =>
      [x?.left?.value, x?.right?.value].includes(id || emptyUUID)
    );
    if (items) acc.push(...items);
    return acc;
  }, []);
  const defaults = filtered1?.filter((x) => x?.left?.value === '*') || [];
  let value: Record<any, any> | undefined = undefined;
  // console.log(
  //   `Found ${filtered.length} / ${defaults.length} of ${filtered1.length} settings`
  // );
  if (filtered?.[0]) {
    // console.log(key, ids, filtered[0]);
    const validChildren = (filtered[0]?.childs || []).filter(
      (x: any) =>
        ids.includes(x?.left?.value || emptyUUID) ||
        ids.includes(x?.right?.value || emptyUUID) ||
        (emphasize && x?.right?.value === emphasize) ||
        (emphasize && x?.left?.value === emphasize)
    );
    // console.log(validChildren, ids);
    if (validChildren?.length && consider.includes('overwrites'))
      value = validChildren.find(hasValue) as any;
    else if (consider.includes('mappings')) value = filtered[0];
  }
  if (!value && consider.includes('defaults') && defaults?.[0]) {
    value = defaults?.[0];
  }
  // console.log(
  //   `Using ${value?.left?.uniqueRef || value?.left?.value} for ${value?.key}`
  // );
  return value!;
}

export function settingToResultLight(mapping: any, setting: any) {
  function inner(setting: any): Record<any, any> {
    const item: Record<any, any> = {
      id: setting?.id!,
      key: setting?.key!,
      left: {
        value: setting?.target,
        id: setting?.left_id,
        type: setting?.leftType,
        uniqueRef: setting?.leftSource?.remoteId,
        data: setting?.leftSource?.json(),
      },
      right: {
        value: setting?.value,
        id: setting?.right_id,
        type: setting?.rightType,
        uniqueRef: setting?.rightSource?.remoteId,
        data: setting?.rightSource?.json(),
      },
    };
    if (!item?.id) return {} as any;
    if (
      item.right.type?.startsWith(`${mapping?.left?.namespace.name}.`) ||
      item.left.type?.startsWith(`${mapping?.right?.namespace.name}.`)
    ) {
      return {
        ...item,
        right: item.left,
        left: item.right,
      };
    }
    return item;
  }
  const item = inner(setting);
  return {
    id: item.id!,
    key: item.key!,
    right: item.right!,
    left: item.left!,
    childs: setting?.childSettings()?.map((child: any) => inner(child)) || [],
  };
}
