import { RenameModal } from '@finalytic/components';
import { useMutation } from '@finalytic/data';
import { DeleteModal, showErrorNotification } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';

export const ListingGroupModals = ({
  deleteModal,
  listingGroup: initial,
  renameModal,
}: {
  listingGroup: {
    id: string;
    name: Maybe<string>;
  } | null;
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  renameModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const group = initial || debounced;

  const { mutate: deleteGroup } = useMutation(
    (q, args: { collectionId: string }) => {
      return q.deleteListingCollection({
        id: args.collectionId,
      })?.id;
    },
    {
      invalidateQueryKeys: ['listings', 'listingCollections'],
      successMessage: {
        message: 'Listing group has been deleted',
      },
    }
  );

  const { mutate: renameGroup } = useMutation(
    (q, args: { collectionId: string; name: string }) => {
      return q.updateListingCollection({
        pk_columns: {
          id: args.collectionId,
        },
        _set: {
          name: args.name,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['listings', 'listingCollections'],
      successMessage: {
        message: 'Listing group has been renamed',
      },
    }
  );

  if (!group) return null;

  const removeGroup = async () => {
    return deleteGroup({
      args: {
        collectionId: group.id,
      },
    });
  };

  const rename = async (newName: string) => {
    if (!newName.trim())
      return showErrorNotification({
        icon: null,
        color: 'yellow',
        message: 'Please enter a group name.',
        title: 'Name is required',
      });

    if (newName.trim() === group.name) return renameModal.closeModal();

    return renameGroup({
      args: {
        collectionId: group.id,
        name: newName?.trim(),
      },
    })?.then(renameModal.closeModal);
  };

  return (
    <>
      <DeleteModal
        opened={deleteModal.opened}
        onClose={deleteModal.closeModal}
        title={`Are you sure to delete ${
          group.name ? group.name : 'this listing'
        }?`}
        subtitle="Once deleted, the group's mappings and settings cannot be recovered."
        onSubmit={removeGroup}
      />
      <RenameModal
        inputTitle="Listing Group"
        onClose={renameModal.closeModal}
        opened={renameModal.opened}
        onSubmit={async (newName) => await rename(newName)}
        title="Rename Listing Group"
        defaultValue={group.name || ''}
        inputPlaceholder="Listing group"
      />
    </>
  );
};
