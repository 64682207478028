import { DepositFormInputs } from '../_hooks/useDepositForm';

export const getDepositReservationLinesCentTotal = (
  lines: DepositFormInputs['reservations']
) => {
  return lines.reduce((acc, line) => {
    // add all payments
    return (
      acc +
      line.paymentCentTotal +
      line.channelFeeCentTotal +
      line.merchantFeeCentTotal +
      line.reserveCentTotal
    );
  }, 0);
};
