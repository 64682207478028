import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  navLink: {
    '> div': {
      height: 35,
    },
    '& path, & rect': {
      fill: undefined,
      stroke: '#dadada',
    },
    ':hover > div, &.active > div': {
      backgroundColor: '#00000060',
      borderRadius: theme.radius.md,
      cursor: 'pointer',

      span: {
        opacity: 1,
      },

      '& path, & rect': {
        fill: undefined,
        stroke: 'white',
      },
    },
  },
  subNavLink: {
    '& div:hover, &.active > div': {
      backgroundColor: '#00000060',

      span: {
        opacity: 1,
      },
    },
  },
}));
