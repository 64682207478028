import { Button, Group, Text } from '@mantine/core';

type PopoverHeaderProps = {
  title: string;
  handleFilterReset: () => void;
};

export const PopoverHeader = ({
  title,
  handleFilterReset,
}: PopoverHeaderProps) => {
  return (
    <Group justify="space-between" mb={10}>
      <Group gap={12}>
        <Text size="lg" fw={600}>
          {title}
        </Text>
      </Group>
      <Button
        radius={3}
        variant="white"
        onClick={handleFilterReset}
        size="xs"
        p={0}
        sx={{
          height: '20px',
        }}
      >
        Clear
      </Button>
    </Group>
  );
};
