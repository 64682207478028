import { Button } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { Alert, Text } from '@mantine/core';
import { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { usePlaidSandbox } from '../../../hooks';
import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';

export const ConnectionPlaidView = () => {
  const { app, connectApp, issue, setView } = useConnectionModalContext();

  const { plaidSandbox } = usePlaidSandbox();
  const [connectionId] = useQueryParam('connectionId', StringParam);
  const isReconnect = !!connectionId;

  const token = (issue?.action.value || '') as string;

  const onSuccess = useCallback(
    async (code: string) => {
      await connectApp({
        input: {
          code,
          environment: plaidSandbox ? 'sandbox' : undefined,
        },
      });
    },
    [plaidSandbox, connectApp]
  );

  const { open, ready, error } = usePlaidLink({
    token,
    onSuccess,
    onExit: () => setView('close-view'),
  });

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        <>
          <Text size="md">{isReconnect ? 'Reconnect' : 'Connect'} your</Text>
          <Text fw={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            Bank account using Plaid
          </Text>
        </>
      }
    >
      {error && (
        <Alert
          color="red"
          my="xs"
          radius="md"
          icon={
            <Icon
              icon="AlertTriangleIcon"
              size={18}
              color={(theme) => theme.colors.red[6]}
            />
          }
        >
          {error.message}
        </Alert>
      )}
      <Button
        variant="primary"
        onClick={() => open()}
        loading={!ready && !error}
        disabled={!!error}
      >
        Connect now
      </Button>
    </ConnectingContainer>
  );
};
