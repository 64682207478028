import { FileDownloadIcon } from '@finalytic/icons';
import { forwardRef } from 'react';
import { Button } from './Button';

type Props = {
  onClick: () => void;
  loading?: boolean;
  children: React.ReactNode;
};

export const ExportButton = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, children, loading }, ref) => {
    return (
      <Button
        variant="transparent"
        onClick={onClick}
        ref={ref}
        loading={loading}
        leftIcon={FileDownloadIcon}
      >
        {children}
      </Button>
    );
  }
);
