import { useQuery, useTeamId } from '@finalytic/data';
import { VRP_TENANT_ID } from '@vrplatform/ui-common';

export function useVrpRetoolQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      if (args.teamId !== VRP_TENANT_ID) return [];

      return q
        .views({
          where: {
            tenantId: { _eq: args.teamId },
          },
        })
        .map((view) => ({
          id: view.id!,
          title: view.title || '',
          url: view.retoolViewId!,
          group: view.group?.toLowerCase() || '',
        }));
    },
    {
      variables: {
        teamId,
      },
    }
  );
}
