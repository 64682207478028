import { useAuth } from '@clerk/clerk-react';
import { useQuery, useTeam } from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { VerifyTokenView } from '../../views/invite-redirect/VerifyTokenView';
import { getOwnerPortalSettings } from '../../views/settings/SettingsFeaturesOwnerPortal';

const OwnerReservationsView = lazy(
  () => import('../../views/reservations/OwnerReservationsView')
);

const OwnerCalendarView = lazy(
  () => import('../../views/reservations/OwnerCalendarView')
);

const OwnerPortalListingsView = lazy(
  () => import('../../views/listings/OwnerPortalListingsListView')
);
const StatementView = lazy(() => import('../../views/statements/statement'));
const StatementListView = lazy(
  () =>
    import('../../views/statements/statement-list/OwnerPortalStatementListView')
);

const StatementSummaryView = lazy(
  () => import('../../views/statements/summary')
);

const FilesView = lazy(() => import('../../views/files'));

const SettingsView = lazy(() => import('../../views/settings'));

export const OwnerRoutes = () => {
  const [{ id: teamId, membershipStatus }] = useTeam();

  const { actor } = useAuth();
  const { search } = useLocation();

  const isUserPreview = !!actor?.sub;

  const { data } = useQuery(
    (q, args) => {
      const settings = getOwnerPortalSettings(q, args.teamId);

      return {
        showReservationSetting: !!settings.showReservationSettingId,
      };
    },
    {
      variables: {
        teamId,
      },
    }
  );

  const isArchived = membershipStatus === 'archived';
  const showReservations = !!data?.showReservationSetting && !isArchived;

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        <Route
          path="token-verify"
          element={<VerifyTokenView isAuthenticated />}
        />

        <Route
          index
          element={<Navigate to={`/statements${search}`} replace />}
        />

        <Route
          path="owners"
          element={<Navigate to={`/statements${search}`} />}
        />

        {/* Redirect from old path to new */}
        <Route
          path="unit-statements"
          element={<Navigate to={`/statements${search}`} />}
        />

        <Route path="statements">
          <Route index element={<Navigate to="owner-statements" replace />} />
          <Route path=":tabValue" element={<StatementListView />} />

          <Route path="summary" element={<StatementSummaryView />} />
          <Route path="summary" element={<StatementSummaryView />} />
        </Route>

        <Route path="files" index element={<FilesView />} />

        {showReservations && (
          <>
            <Route path="reservations" element={<OwnerReservationsView />} />
            <Route path="calendar" element={<OwnerCalendarView />} />
          </>
        )}

        {!isArchived && (
          <Route path="listings" element={<OwnerPortalListingsView />} />
        )}
        <Route path="statement">
          <Route path=":listingId" element={<StatementView />} />
        </Route>

        {!isUserPreview && (
          <Route path="settings/*" element={<SettingsView />} />
        )}
        {!isUserPreview && (
          <Route
            path="login"
            element={<Navigate to={`/statements${search}`} replace />}
          />
        )}
      </Routes>
    </Suspense>
  );
};
