import { TrashIcon } from '@finalytic/icons';
import { Box, Button, ButtonProps, Stack, Text } from '@mantine/core';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { useLegacyColors } from '../../../hooks';
import { Modal } from '../Modal';

type DeleteModalProps = {
  opened: boolean;
  onClose: () => void;
  title: ReactNode;
  subtitle: ReactNode;
  onSubmit: () => void;
  submitTitle?: string;
  size?: number;
  zIndex?: number;
  icon?: ReactNode;
  loading?: boolean;
};

export const DeleteModal = ({
  opened,
  onClose,
  title,
  subtitle,
  onSubmit,
  size,
  zIndex,
  submitTitle = 'Delete',
  loading,
  icon = <TrashIcon size={80} color="#E6E7EA" />,
}: DeleteModalProps) => {
  const { gray } = useLegacyColors();

  const onDangerSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={size || 'sm'}
      withCloseButton={false}
      zIndex={zIndex}
      styles={{ body: { paddingBottom: 15 } }}
    >
      <Stack align="center" mb={20}>
        <Box mt={20}>{icon}</Box>
        <Box mb={10} sx={{ textAlign: 'center' }}>
          <Text fw={500} mb={10}>
            {title}
          </Text>
          <Text size="sm">{subtitle}</Text>
        </Box>
      </Stack>
      <DangerButton
        onClick={onDangerSubmit}
        data-testid="delete-submit"
        loading={loading}
        fullWidth
        mb={10}
        sx={{ height: 40 }}
      >
        {submitTitle}
      </DangerButton>
      <Button
        variant="outline"
        disabled={loading}
        sx={(theme) => ({
          width: '100%',
          border: 'none',
          borderRadius: theme.radius.md,
          padding: 5,
          color: `${gray.dark}!important`,
          '& .mantine-Button-label': {
            opacity: 0.7,
          },
          ':hover': {
            backgroundColor: `${gray.dark}30`,
          },
        })}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Modal>
  );
};

const DangerButton = ({
  children,
  onClick,
  sx,
  ...props
}: ButtonProps & ComponentPropsWithoutRef<'button'>) => {
  return (
    <Button
      onClick={onClick}
      radius={'md'}
      px={10}
      color="red"
      sx={(theme) => ({
        backgroundColor: theme.colors.red[7],
        ':hover': {
          backgroundColor: theme.colors.red[8],
        },
        ...(sx as any),
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
