import { Filter } from '@finalytic/components';
import {
  gqlV2,
  useDashboard,
  useInfiniteQuery,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { SelectItem, StringParam, useQueryParams } from '@finalytic/ui';
import { Group } from '@mantine/core';
import {
  TenantStatus,
  whereConnectionStatusDefault,
  whereTenants,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';

export const usePartnerConnectionsTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    status: StringParam,
    appId: StringParam,
    tenantId: StringParam,
  });

  return {
    filter,
    setFilter,
    reset: () =>
      setFilter({
        search: undefined,
        appId: undefined,
        tenantId: undefined,
        status: undefined,
      }),
  };
};

export const PartnerConnectionsTableFilter = ({
  hide,
}: { hide?: ('team' | 'app' | 'status')[] }) => {
  return (
    <Group>
      <Search />
      {!hide?.includes('status') && <Status />}
      {!hide?.includes('team') && <Teams />}
      {!hide?.includes('app') && <App />}
    </Group>
  );
};

const Search = () => {
  const { filter, setFilter } = usePartnerConnectionsTableFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const Status = () => {
  const { filter, setFilter } = usePartnerConnectionsTableFilter();

  const options = useMemo<SelectItem<TenantStatus | 'onboarding'>[]>(
    () => [
      {
        label: 'Onboarding',
        value: 'onboarding',
      },
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Suspended',
        value: 'inactive',
      },
    ],
    []
  );

  const status = options.find((o) => o.value === filter.status) || null;

  return (
    <Filter.Select
      type="single"
      value={status}
      data={{
        options,
      }}
      label="Status"
      setValue={(v) => {
        setFilter({ status: v?.value });
      }}
    />
  );
};

const App = () => {
  const { filter, setFilter } = usePartnerConnectionsTableFilter();

  const [search, setSearch] = useState('');
  const [teamId] = useTeamId();

  const appId = filter.appId;

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: gqlV2.app_bool_exp = {
        connections: {
          tenant: {
            partnerId: { _eq: args.teamId },
          },
          status: whereConnectionStatusDefault,
          appId: { _neq: 'finalytic' },
        },
        name: args.search ? { _ilike: `%${args.search}%` } : undefined,
      };

      const list = q
        .app({
          where,
          order_by: [{ name: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((app) => ({
          label: app.name || '',
          value: app.id || '',
        }));

      const aggregate = q.appAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'connections',
      variables: {
        teamId,
        search: search.trim(),
      },
    }
  );

  const options = queryData.data?.pages.flatMap((page) => page.list) || [];

  const value = options.find((option) => option.value === appId) || null;

  return (
    <Filter.Select
      type="single"
      value={value}
      infiniteData={{ ...queryData, setSearch }}
      label="App"
      setValue={(v) => {
        setFilter({ appId: v?.value });
      }}
    />
  );
};

const Teams = () => {
  const { filter, setFilter } = usePartnerConnectionsTableFilter();

  const [dashboard] = useDashboard();
  const [partnerId] = useTeamId();

  const [search, setSearch] = useState('');

  const tenantId = filter.tenantId;

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: gqlV2.tenant_bool_exp = whereTenants({
        partnerId: args.partnerId,
        search: args.search,
        dashboard: args.dashboard,
      });

      const list = q
        .tenant({
          where,
          order_by: [{ name: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((tenant) => ({
          label: tenant.name || '',
          value: tenant.id || '',
        }));

      const aggregate = q.tenantAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !partnerId,
      queryKey: 'connections',
      variables: {
        partnerId,
        search: search.trim(),
        tenantId: filter.tenantId,
        dashboard,
      },
    }
  );

  const { data } = useQuery(
    (q, args) => {
      if (!args.tenantId) return null;

      return (
        q
          .tenant({
            where: {
              id: { _eq: args.tenantId },
            },
            limit: 1,
          })
          .map((x) => ({
            value: x.id,
            label: x.name!,
          }))[0] || null
      );
    },
    {
      skip: !tenantId,
      variables: {
        tenantId,
      },
    }
  );

  const value = data || null;

  return (
    <Filter.Select
      type="single"
      value={value}
      infiniteData={{ ...queryData, setSearch }}
      label="Team"
      setValue={(v) => {
        setFilter({ tenantId: v?.value });
      }}
    />
  );
};
