import { LoadingIndicator } from '@finalytic/ui';
import { Maybe, formatCurrency } from '@finalytic/utils';
import { Box, Text, Tooltip } from '@mantine/core';
import {
  TaxStatementRow,
  useTaxStatementCalculatedAmountQuery,
} from './useTaxStatementListTableQuery';

export const TaxStatementAmountCell = (statement: TaxStatementRow) => {
  const currency = statement.currency;
  const amount = statement.amount;
  const status = statement.status;

  const showFinalAmount =
    ['submitted', 'ready'].includes(status) && typeof amount === 'number';

  if (showFinalAmount) return formatCurrency(amount, currency);

  return <CalculatedAmountCell statement={statement} />;
};

const CalculatedAmountCell = ({
  statement,
}: { statement: TaxStatementRow }) => {
  const currency = statement.currency;
  const amount = statement.amount;

  const { isLoading, data } = useTaxStatementCalculatedAmountQuery(statement);

  const calculatedAmount = data?.calculatedAmount;

  const hasTaxAmount = typeof amount === 'number';

  const formatAmount = (amount: Maybe<number>) => {
    if (typeof amount !== 'number') return '-';

    return formatCurrency(amount, currency);
  };

  if (isLoading) return <LoadingIndicator size="xs" />;

  if (hasTaxAmount && calculatedAmount !== amount) {
    return (
      <Box maw="100%">
        <Text component="p" display="block" lineClamp={1} m={0}>
          {formatAmount(amount)}
        </Text>

        <Tooltip
          maw={350}
          withArrow
          withinPortal
          position="bottom-end"
          multiline
          label="There is a difference in the overwritten amount and our running calculated amount."
        >
          <Text
            component="p"
            size="xs"
            m={0}
            color="gray"
            display="block"
            lineClamp={1}
          >
            vs. {formatAmount(calculatedAmount)}
          </Text>
        </Tooltip>
      </Box>
    );
  }

  return formatAmount(calculatedAmount);
};
