import {
  type gqlV2,
  useDashboard,
  useInfiniteQuery,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { type Maybe, isUUID } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { formatOwnerName, getListingName } from '@vrplatform/ui-common';
import {
  ACTIVITY_START_DATE,
  ActivityDeletedItemBadge,
  type ActivityDeltaJson,
  ActivityDrawer,
  type ActivityOperation,
  type ActivityRow,
  type ActivityTable,
  getActivityActor,
  whereActorUserId,
} from '../../../drawers';
import { LISTING_ACTIVITY_TABLES, getListingActivityDetails } from './_queries';

type FilterState = {
  search: string;
  actorUserId: Maybe<string>;
  op: Maybe<ActivityOperation>;
};

export function useListingActivityDrawer() {
  const [listingId, setListingId] = useQueryParam(
    'listing_activity',
    StringParam
  );

  return {
    listingId,
    open: (id: string) => setListingId(id, 'push'),
    close: () => setListingId(null, 'push'),
  };
}

export const ListingActivityDrawer = () => {
  const { close, listingId } = useListingActivityDrawer();

  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  const { data } = useQuery(
    (q, args) => {
      if (!args.listingId) return null;

      const listing = q.listing({ id: args.listingId });

      return {
        id: listing?.id,
        title: getListingName(listing),
      };
    },
    {
      skip: !listingId,
      variables: {
        listingId,
      },
    }
  );
  const title = data?.title;

  const [filter, setFilter] = useSetState<FilterState>({
    actorUserId: null,
    search: '',
    op: null,
  });

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      if (!args.teamId || !isUUID(args.listingId || ''))
        return {
          aggregate: 0,
          list: [],
        };

      const listingCollections = q
        .listingCollections({
          where: {
            tenantId: { _eq: args.teamId },
          },
        })
        .map((x) => ({ id: x.id, name: x.name || '' }));

      const where: gqlV2.audit_log_bool_exp = {
        tenantId: { _eq: args.teamId },
        createdAt: { _gte: args.ACTIVITY_START_DATE },
        tableName: { _in: args.LISTING_ACTIVITY_TABLES },
        op: args.op ? { _eq: args.op } : undefined,
        actorUserId: whereActorUserId(args.actorUserId),
        _or: [
          {
            listingId: { _eq: args.listingId },
          },
          {
            objectId: { _eq: args.listingId },
          },
        ],
      };

      const aggregate = q.auditLogAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .auditLogs({
          where,
          order_by: [{ createdAt: 'desc' }],
          offset,
          limit,
        })
        .flatMap<ActivityRow>((log) => {
          const op = log.op as ActivityOperation;
          const tableName = log.tableName as ActivityTable;

          // tables: listing, listing_owner
          const initial: ActivityRow = {
            id: log.id,
            objectId: log.objectId,
            actor: getActivityActor(log),
            date: log.createdAt,
            op,
            icon: op,
            label: '',
          };

          if (op === 'delete' || op === 'insert') {
            if (tableName === 'listing') {
              const actions: Record<ActivityOperation, string> = {
                insert: 'Created',
                delete: 'Deleted',
                update: 'Updated',
              };

              const listingName = getListingName(log.listing);

              return [
                {
                  ...initial,
                  label: (
                    <Text>
                      <Text component="span" color="gray">
                        {actions[op]}
                      </Text>{' '}
                      {listingName ? (
                        <Text fw={500} component="span">
                          {listingName}
                        </Text>
                      ) : (
                        <ActivityDeletedItemBadge
                          objectId={log.listingId}
                          type="listing"
                        />
                      )}
                    </Text>
                  ),
                },
              ];
            }
          }

          if (op === 'update' || tableName !== 'listing') {
            const listingName = getListingName(log.listing);

            return getListingActivityDetails(
              {
                date: log.createdAt,
                delta: (log.deltaJson() || []) as ActivityDeltaJson,
                id: log.id,
                objectId: log.objectId,
                op,
                tableName,
              },
              {
                id: log.listingId,
                name: listingName,
              },
              {
                id: log.listingCollectionId,
                name: log.listingCollection?.name || '',
              },
              {
                id: log.ownerId,
                name: formatOwnerName(log.owner),
              },
              listingCollections
            ).map((update, index) => ({
              ...initial,
              id: `${log.id}-${log.objectId}-${update.updateKey}-${update.date}-${index}`,
              label: update.label,
            }));
          }

          return [];
        });

      return {
        aggregate,
        list,
        count: list.length,
      };
    },
    {
      queryKey: 'activity',
      skip: !listingId || !data,
      variables: {
        teamId,
        search: filter.search?.trim(),
        dashboard,
        actorUserId: filter.actorUserId,
        LISTING_ACTIVITY_TABLES,
        op: filter.op,
        listingId,
        ACTIVITY_START_DATE,
      },
    }
  );

  return (
    <ActivityDrawer
      opened={!!listingId}
      close={close}
      title={title || 'Listing'}
      data={queryData}
      setFilter={setFilter}
      tables={LISTING_ACTIVITY_TABLES}
      // renderDetailPanel={renderDetailPanel}
    />
  );
};

// const renderDetailPanel = ({ row, isExpanded }: ActivityPanelProps) => {
//   const ids = row.details?.map((update) => update.logId);

//   const data = useQuery(
//     (q, args) => {
//       const logs = q
//         .auditLogs({
//           where: {
//             id: { _in: args.ids },
//           },
//           order_by: [{ createdAt: 'desc' }],
//         })
//         .map((log) => ({
//           ...getListingActivity(log),
//           delta: log.deltaJson() as ActivityDeltaJson,
//           listingName: getListingName(log.listing),
//         }));

//       const ownerStatement =
//         q
//           .ownerStatements({
//             where: {
//               id: { _eq: args.objectId },
//             },
//           })
//           .map((x) => ({
//             currency: x.currency,
//           }))[0] || null;

//       // const deletedLog =
//       //   q
//       //     .auditLogs({
//       //       where: { objectId: { _eq: args.objectId }, op: { _eq: 'delete' } },
//       //     })
//       //     .map((l) => {
//       //       return {
//       //         isCompany:
//       //           (l.deltaJson() as ActivityDeltaJson)?.find(
//       //             (d) => d.key === 'type'
//       //           )?.value === 'company',
//       //       };
//       //     })[0] || null;

//       return {
//         logs,
//         currency: ownerStatement?.currency || 'USD',
//         // isCompany: !!owner?.isCompany || !!deletedLog?.isCompany,
//       };
//     },
//     {
//       queryKey: 'activity',
//       skip: !ids?.length || !isExpanded,
//       variables: {
//         ids,
//         objectId: row.objectId,
//       },
//     }
//   );

//   const rows: any[] = [];

//   return {
//     loading: data.isLoading,
//     error: data.error,
//     rows,
//   };
// };
