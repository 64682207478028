import { ChevronIcon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import {
  Box,
  Group,
  Collapse as MCollapse,
  MantineSize,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { EmotionSx } from '@mantine/emotion';
import { useDisclosure } from '@mantine/hooks';
import { PropsWithChildren, useEffect } from 'react';

type Props = {
  title: React.ReactNode;
  rightSection?: React.ReactNode;
  hideCollapse?: boolean;
  hideToggle?: boolean;
  defaultOpened?: boolean;
  backgroundColor?: string;
  spacing?: MantineSize | 0;
  minHeight?: number | string;
  headerSx?: EmotionSx;
  contentSx?: EmotionSx;
} & (
  | {
      opened?: undefined;
      toggle?: undefined;
    }
  | {
      opened: boolean;
      toggle: () => void;
    }
);

export const Collapse = ({
  children,
  title,
  rightSection,
  hideCollapse,
  opened: o2,
  toggle: toggle2,
  defaultOpened = true,
  spacing = 'sm',
  hideToggle = false,
  backgroundColor,
  minHeight = 40,
  headerSx = {},
  contentSx = {},
}: PropsWithChildren<Props>) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const [o, handlers] = useDisclosure(defaultOpened);

  useEffect(() => {
    if (defaultOpened) handlers.open();
  }, [defaultOpened]);

  if (hideCollapse) return children;

  const toggle = () => {
    if (toggle2) return toggle2();
    handlers.toggle();
  };

  const opened = o2 ?? o;

  return (
    <>
      <Group
        justify="space-between"
        sx={(theme, u) =>
          Object.assign(
            {
              backgroundColor:
                backgroundColor ||
                (colorScheme === 'dark'
                  ? theme.colors.dark[6]
                  : theme.colors.neutral[1]),
              padding: '0.3rem 0.5rem',
              borderRadius: theme.radius.md,
              marginBottom: spacing === 0 || !o ? 0 : theme.spacing[spacing],
              minHeight,
            },
            typeof headerSx === 'function' ? headerSx(theme, u) : headerSx
          )
        }
      >
        <Group wrap="nowrap" gap="xs">
          {!hideToggle && (
            <IconButton onClick={toggle}>
              <Box
                sx={{
                  transform: `rotate(${opened ? 0 : -90}deg)`,
                  transition: 'transform 100ms ease-in-out',
                }}
              >
                <ChevronIcon
                  size={20}
                  color={
                    backgroundColor ? colors.neutral[8] : colors.neutral[5]
                  }
                />
              </Box>
            </IconButton>
          )}
          {typeof title === 'string' ? (
            <Text
              fw={600}
              size="1rem"
              sx={{ lineHeight: '1.4rem', letterSpacing: '-0.01125rem' }}
            >
              {title}
            </Text>
          ) : (
            title
          )}
        </Group>
        {rightSection}
      </Group>
      <MCollapse
        in={opened}
        sx={(theme, u) =>
          Object.assign(
            typeof contentSx === 'function' ? contentSx(theme, u) : contentSx
          )
        }
      >
        {children}
      </MCollapse>
    </>
  );
};
