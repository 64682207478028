import { contact_order_by } from '@finalytic/graphql';

export const orderByContact: contact_order_by[] = [
  {
    type: 'asc_nulls_last',
  },
  {
    name: 'asc_nulls_last',
  },
];
