import { MRT_PaginationState } from 'mantine-react-table';
import { useState } from 'react';

export const useTablePagination = (initialPageSize = 25) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  return {
    pagination,
    setPagination,
  };
};
