import { Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';

export const SettingsTitle = ({
  children,
  type,
  ...props
}: {
  children: ReactNode;
  type: 'view-title' | 'heading' | 'sub-heading';
} & TextProps) => {
  if (type === 'view-title') {
    return (
      <Text
        component="h1"
        m={0}
        {...props}
        sx={
          {
            fontWeight: 600,
            fontSize: '1.5rem',
            lineHeight: 1.5,
            ...props.sx,
          } as any
        }
        mb={'sm'}
      >
        {children}
      </Text>
    );
  }

  if (type === 'heading') {
    return (
      <Text component="h3" m={0} fw={600} size={'1rem'} {...props}>
        {children}
      </Text>
    );
  }

  if (type === 'sub-heading') {
    return (
      <Text component="h3" mb={5} fw={500} size="0.875rem" {...props}>
        {children}
      </Text>
    );
  }

  return <>{children}</>;
};
