import { createContext, useContext } from 'react';

export type SpotlightContext = {
  hypervisorQueue?: string;
  triggerEnv?: 'production' | 'staging' | 'dev';
  set: SpotlightSetState;
};
export type SpotlightSetState = React.Dispatch<
  React.SetStateAction<Omit<SpotlightContext, 'set'>>
>;

export const spotlightContext = createContext<SpotlightContext>(
  undefined as any
);

export function useSpotlightContext() {
  return useContext(spotlightContext);
}
