import { useMe } from './team';

export const usePlaywright = () => {
  const me = useMe();

  const automatedTestUserEmails = [
    'lars+checkly@finalytic.co',
    'vrp-testing@finalytic.io',
  ];

  const isPlaywright =
    me?.firstName === 'Playwright' ||
    automatedTestUserEmails.includes(me?.email || '');

  return {
    isPlaywright,
  };
};
