import { LoadingIndicator } from '@finalytic/ui';
import { Maybe, day } from '@finalytic/utils';
import { Box, Center, Group, Text, useMantineColorScheme } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  renderDetailPanel: () => {
    rows: Maybe<{ label: ReactNode; date: string }[]>;
    loading: boolean;
    error: Error | null;
  };
};

export const ActivityDetailPanel = ({ renderDetailPanel }: Props) => {
  const { colorScheme } = useMantineColorScheme();

  const { rows, error, loading } = renderDetailPanel();

  const lines = rows?.filter((x) => x.label);

  return (
    <Box
      className={loading ? '' : 'open'}
      sx={(theme) => ({
        backgroundColor: theme.colors.neutral[colorScheme === 'dark' ? 9 : 0],
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.xs,
        paddingBlock: theme.spacing.xs,
        maxHeight: loading ? '2rem' : '0px',
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out',
        '&.open': {
          maxHeight: '80vh',
        },
      })}
    >
      {loading ? (
        <Center>
          <LoadingIndicator size={'1rem'} />
        </Center>
      ) : error ? (
        <Text color="red">Error loading updates</Text>
      ) : !lines?.length ? (
        <Text color="gray">System updates</Text>
      ) : (
        lines?.map((update, i) => {
          return (
            <Group justify="space-between" wrap="nowrap" key={i}>
              {update.label}
              <Text size="xs" color="gray" sx={{ flexShrink: 0 }}>
                {day(update.date).format('MMM D, YYYY - h:mm A')}
              </Text>
            </Group>
          );
        })
      )}
    </Box>
  );
};
