import {
  activeStatus_enum,
  contactType_enum,
  contact_bool_exp,
} from '@finalytic/graphql';
import { Maybe, ensure } from '@finalytic/utils';

type Props = {
  tenantId: string;
  search?: Maybe<string>;
  type: contactType_enum | null;
  status?: activeStatus_enum | null;
  companyType?: 'individual' | 'company' | null;
};

export function whereContacts({
  search: s,
  tenantId,
  type,
  status,
  companyType,
}: Props) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<contact_bool_exp[]>([
        { name: { _ilike: `%${search}%` } },
        { firstName: { _ilike: `%${search}%` } },
        { name: { _ilike: `%${search}%` } },
        { email: { _ilike: `%${search}%` } },
        { phone: { _ilike: `%${search}%` } },
      ])
    : undefined;

  const statusFilter: contact_bool_exp =
    status === 'inactive'
      ? {
          _or: [
            {
              status: { _eq: 'inactive' },
            },
            {
              pmsStatus: { _eq: 'inactive' },
              status: { _is_null: true },
            },
          ],
        }
      : {
          _or: [
            {
              status: { _is_null: true },
              pmsStatus: { _is_null: true },
            },
            {
              pmsStatus: { _neq: 'inactive' },
              status: { _is_null: true },
            },
            {
              status: { _eq: 'active' },
            },
          ],
        };

  return ensure<contact_bool_exp>({
    tenantId: { _eq: tenantId },
    type: type ? { _eq: type } : undefined,
    _or: searchQuery,
    companyType: companyType
      ? {
          _is_null: companyType === 'individual',
        }
      : undefined,
    _and: [statusFilter],
  });
}
