import { Logo } from '@finalytic/ui';
import { AppShell, Burger, Group } from '@mantine/core';
import { Link } from 'react-router-dom';

export const Header = ({
  toggleNavbar,
  opened,
}: {
  toggleNavbar: (v?: boolean) => void;
  opened: boolean;
}) => {
  const title = opened ? 'Close navigation' : 'Open navigation';

  return (
    <AppShell.Header
      sx={{
        boxShadow: '0 3px 5px rgba(57, 63, 72, 0.1)',
      }}
    >
      <Group justify="space-between" mx={10} sx={{ height: '100%' }}>
        <Link to="/">
          <Logo />
        </Link>

        <Burger opened={opened} onClick={() => toggleNavbar()} title={title} />
      </Group>
    </AppShell.Header>
  );
};
