import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Group,
  Input,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { FilterPopover, PopoverButton, PopoverHeader } from '../_components';
import { AmountIcon } from '../_icons';

type AmountFilterProps = {
  defaultButtonLabel: string;
  filterValue?: { lt?: number; gt?: number };
  setFilterValue: ({ lt, gt }: { lt?: number; gt?: number }) => void;
  customButton?: (props: {
    buttonLabel: string;
    togglePopover: () => void;
    opened: boolean;
  }) => JSX.Element;
  withinPortal?: boolean;
};

export const AmountFilter = ({
  setFilterValue,
  filterValue,
  defaultButtonLabel,
  customButton: CustomButton,
  withinPortal = false,
}: AmountFilterProps) => {
  const [opened, handlers] = useDisclosure(false);

  const possibleFilterValues = [
    'Equals',
    'Greater',
    'Between',
    'Less',
  ] as const;

  const [buttonLabel, setButtonLabel] = useState('');

  // Setting "Less than" | "Between" | "More than"
  const [selectValue, setSelectValue] = useState<
    (typeof possibleFilterValues)[number]
  >(possibleFilterValues[0]);

  const resetFilter = () => {
    handlers.close();
    setFilterValue({});
    setButtonLabel('');
  };

  const lt = filterValue?.lt;
  const gt = filterValue?.gt;

  // However, set the button label immediately when input was changed
  useEffect(() => {
    if (lt || gt) {
      setButtonLabel(
        `${selectValue} $${selectValue !== 'Less' ? gt : lt || 0} ${
          selectValue === 'Between' ? `- $${lt || 0}` : ''
        }`
      );
    } else {
      setButtonLabel('');
    }
  }, [selectValue, lt, gt]);

  useEffect(() => {
    setFilterValue({ lt: undefined, gt: undefined });
  }, [selectValue]);

  return (
    <FilterPopover
      onClose={handlers.close}
      opened={opened}
      withinPortal={withinPortal}
      target={
        CustomButton ? (
          <Box>
            <CustomButton
              buttonLabel={buttonLabel || defaultButtonLabel}
              togglePopover={handlers.toggle}
              opened={opened}
            />
          </Box>
        ) : (
          <PopoverButton
            onClick={handlers.toggle}
            isActive={!!buttonLabel}
            Icon={AmountIcon}
            title={buttonLabel || defaultButtonLabel}
          />
        )
      }
    >
      <PopoverHeader
        title={defaultButtonLabel}
        handleFilterReset={resetFilter}
      />
      <Stack sx={{ maxWidth: '280px' }}>
        <SegmentedControl
          radius={20}
          data={possibleFilterValues as unknown as string[]}
          value={selectValue}
          onChange={(e) => setSelectValue(e as any)}
          sx={{ width: '280px' }}
        />

        <Group>
          {['Greater', 'Between', 'Equals'].includes(selectValue) && (
            <Input
              placeholder="0"
              variant="filled"
              radius={10}
              type="number"
              leftSection={<Text>$</Text>}
              value={typeof gt === 'number' ? gt : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                if (
                  selectValue === 'Equals' &&
                  value.match(/^\d*(\.\d{0,2})?$/)
                ) {
                  setFilterValue({
                    ...filterValue,
                    gt: parseFloat(value),
                    lt: parseFloat(value) + 1,
                  });
                } else if (value.match(/^\d*(\.\d{0,2})?$/)) {
                  setFilterValue({ ...filterValue, gt: parseFloat(value) });
                }
              }}
              sx={{ input: { backgroundColor: '#F7F7F8}', flex: 1 } }}
              rightSection={
                filterValue?.gt ? (
                  <ActionIcon
                    size="sm"
                    onClick={() =>
                      setFilterValue({
                        lt:
                          selectValue === 'Equals' ? undefined : filterValue.lt,
                        gt: undefined,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faXmark} size="sm" />
                  </ActionIcon>
                ) : (
                  <></> // Without this the input right section jumps onChange
                )
              }
            />
          )}
          {selectValue === 'Between' && <Text ta="center">-</Text>}

          {['Less', 'Between'].includes(selectValue) && (
            <Input
              placeholder="0"
              variant="filled"
              radius={10}
              type="number"
              leftSection={<Text>$</Text>}
              value={typeof lt === 'number' ? lt : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;

                if (value.match(/^\d*(\.\d{0,2})?$/)) {
                  setFilterValue({ ...filterValue, lt: parseFloat(value) });
                }
              }}
              sx={{ input: { backgroundColor: '#F7F7F8}', flex: 1 } }}
              rightSection={
                filterValue?.lt ? (
                  <ActionIcon
                    size="sm"
                    onClick={() =>
                      setFilterValue({ ...filterValue, lt: undefined })
                    }
                  >
                    <FontAwesomeIcon icon={faXmark} size="sm" />
                  </ActionIcon>
                ) : (
                  <></> // Without this the input right section jumps onChange
                )
              }
            />
          )}
        </Group>
      </Stack>
    </FilterPopover>
  );
};
