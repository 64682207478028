import { useLocalStorage } from '@mantine/hooks';
import { createContext, useContext, useMemo } from 'react';

export function useV2UI() {
  return useContext(context);
}
const context = createContext({
  isV2: false,
  toggleV2: () => {},

  isDarkTheme: false,
  toggleIsDarkTheme: () => {},
});
export function V2Provider({ children }: { children: React.ReactNode }) {
  const [isV2, toggleV2] = useLocalStorage({
    key: 'v2Ui',
    defaultValue: false,
  });

  const [isDarkTheme, toggleIsDarkTheme] = useLocalStorage({
    key: 'darkTheme',
    defaultValue: false,
  });

  const value = useMemo(
    () => ({
      isV2,
      toggleV2: () => toggleV2((state) => !state),
      isDarkTheme,
      toggleIsDarkTheme: () => toggleIsDarkTheme((state) => !state),
    }),
    [isV2, toggleV2, isDarkTheme, toggleIsDarkTheme]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}
