import { InputSelect } from '@finalytic/components';
import {
  useInfiniteQuery,
  useQuery,
  useTeamId,
  useTeamRole,
} from '@finalytic/data';
import { automation_bool_exp } from '@finalytic/graphql';
import { Text } from '@mantine/core';
import { whereAutomations } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputBaseType } from './_base-input-types';

type Props = FormInputBaseType;

function useAutomations() {
  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');
  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();

  const queryData = useInfiniteQuery(
    (
      q,
      { teamId, search, isPartnerAdmin, isSuperAdmin, isVrpAdmin },
      { limit, offset }
    ) => {
      const where: automation_bool_exp = whereAutomations({
        tenantId: teamId,
        isPartnerAdmin,
        isSuperAdmin,
        isVrpAdmin,
        search,
      });

      const aggregate =
        q.automationAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .automations({
          where,
          order_by: [{ title: 'asc' }],
          limit,
          offset,
        })
        .map((automation) => {
          const template = automation?.ttemplate?.title;
          const description = automation?.title;

          return {
            value: automation.id,
            label: description || template || 'No name',
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        teamId,
        search: search?.trim(),
        isPartnerAdmin,
        isSuperAdmin,
        isVrpAdmin,
      },
      skip: !teamId,
      queryKey: ['automations'],
    }
  );

  return {
    ...queryData,
    setSearch,
  };
}

function useSingleAutomation(sourceId: string | undefined) {
  const { data, isLoading: loading } = useQuery(
    (q, { sourceId }) => {
      const source = q.automation({
        id: sourceId,
      });
      const template = source?.ttemplate?.title;
      const description = source?.title;
      return {
        id: source?.id,
        description: description || template,
      };
    },
    {
      variables: {
        sourceId,
      },
      skip: !sourceId,
      queryKey: 'sources',
    }
  );

  return {
    source: data,
    loading,
  };
}

export const SelectAutomation = ({
  label,
  defaultValue,
  formKey,
  placeholder,
  required,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  const queryData = useAutomations();

  return (
    <>
      {label && (
        <Text size="sm" fw={500}>
          {label}
        </Text>
      )}
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue || undefined}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          const { source, loading: loadingSingleAutomation } =
            useSingleAutomation(value);

          const selectValue = useMemo(
            () =>
              source?.id
                ? {
                    label: source.description as string,
                    value: source.id as string,
                  }
                : null,
            [source?.id]
          );

          const extendedOnChange = (val: string | undefined) => {
            onValueChange?.(formKey, val);
            onChange(val);
          };

          return (
            <InputSelect
              infiniteData={queryData}
              value={selectValue}
              setValue={(item) => extendedOnChange(item?.value || '')}
              type="single"
              inputProps={{
                loadingQuery: loadingSingleAutomation,
                placeholder,
              }}
              dropdownProps={{
                width: 'target',
              }}

              // data={sources}
              // withSearch
              // loading={loading}
              // value={selectValue}
              // setValue={(item) => extendedOnChange(item?.value)}
              // removeValue={() => extendedOnChange('')}
              // popoverWidth="target"
              // onSearchInput={setSearch}
              // withBorder
              // placeholder={placeholder}
              // searchPlaceholder={placeholder}
              // inputLoading={loadingSingleAutomation}
            />
          );
        }}
      />
    </>
  );
};
