import { Button } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import { useAppName } from '@finalytic/ui';
import { Avatar, Box, Card, Center, Text, Title, rem } from '@mantine/core';
import {
  ErrorBoundary as Boundary,
  ErrorBoundaryProps,
  FallbackRender,
} from '@sentry/react';

type Props = Parameters<FallbackRender>[0] & {
  appName: string;
};

const ErrorFallback = ({ error, resetError, appName }: Props) => {
  const chunkFailedMessage = /Failed to fetch dynamically imported module/;
  const mimeTypeMessage = /is not a valid JavaScript MIME type/;
  const isChunkError =
    error?.message &&
    (chunkFailedMessage.test(error.message) ||
      mimeTypeMessage.test(error.message));

  if (isChunkError) {
    return (
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          margin: 0,
          inset: 0,
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          overflow: 'hidden',
        }}
      >
        <Card withBorder maw={400} p={rem(32)} radius="md">
          <Icon
            icon="RefreshCwIcon"
            size={30}
            color={(theme) => theme.colors[theme.primaryColor][5]}
            sx={(theme) => ({
              display: 'block',
              marginInline: 'auto',
              marginTop: theme.spacing.md,
              marginBottom: theme.spacing.xl,
            })}
          />
          <Title order={3} c="dark" ta="center" mb="md">
            Update Available
          </Title>
          <Text component="p" ta="center" mb="xl" c="neutral">
            New content available. Update now to use the latest version of{' '}
            {appName}.{' '}
          </Text>
          <Button
            onClick={() => window.location.reload()}
            sx={{
              width: '100%',
            }}
          >
            Reload
          </Button>
        </Card>
      </Box>
    );
  }

  return (
    <Center sx={{ height: '100%', flex: 0.8 }}>
      <Card
        role="alert"
        sx={() => ({
          width: '100%',
          maxWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        })}
      >
        <Avatar color="red" variant="filled" mt="md" size={35} radius={35}>
          <Icon icon="CrossIcon" color={'#fff'} size={24} />
        </Avatar>
        <Title order={3} c="dark" ta="center" fw={500} mb="md" mt="lg">
          {error.message || 'Missing error message.'}
        </Title>

        <Text mb="lg" ta="center" c="neutral" lh="1.6">
          Something went wrong! Please try to reload the page and if the problem
          persists, contact our support.
        </Text>
        <Button
          onClick={resetError}
          sx={{
            width: '100%',
            maxWidth: '200px',
          }}
        >
          Try again
        </Button>
      </Card>
    </Center>
  );
};

export function ErrorBoundary({
  children,
  onReset,
  ...props
}: ErrorBoundaryProps) {
  const { appName } = useAppName();

  return (
    <Boundary
      fallback={(props) => <ErrorFallback {...props} appName={appName} />}
      onReset={onReset}
      {...props}
    >
      {children}
    </Boundary>
  );
}
