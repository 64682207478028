import {
  address_select_column,
  listing_owner_select_column,
  owner_select_column,
  owner_user_access_select_column,
} from '@finalytic/graphql';
import { Maybe, toCamelCase, toTitleCase } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { ReactNode } from 'react';
import {
  ActivityDeletedItemBadge,
  ActivityDeltaComparison,
  ActivityDeltaJson,
  ActivityOperation,
  ActivityTable,
} from '../../../drawers';

export const getOwnerActivityTables = (isGL: boolean): ActivityTable[] =>
  isGL
    ? ['contact', 'owner_user_access']
    : ['owner', 'contact', 'listing_owner', 'owner_user_access'];

export const getOwnerActivityDetails = (
  audit_log: {
    tableName: ActivityTable;
    id: string;
    op: ActivityOperation;
    date: string;
    delta: ActivityDeltaJson;
    objectId: string;
  },
  entities: {
    owner: {
      id: string;
      name: Maybe<string>;
      isCompany: boolean;
    };
    listing: {
      name: Maybe<string>;
      id: string;
    };
    user: {
      name: Maybe<string>;
      id: string;
    };
  }
): { label: ReactNode; date: string }[] => {
  const date = audit_log.date;

  const { listing, owner, user } = entities;

  const formatValue = (v: string | number | undefined, key: string) => {
    if (v === undefined || v === null) return undefined;

    if (key === 'split') return `${v}%`;
    if (key === 'email') return v as string;
    if (typeof v === 'string') return toTitleCase(v);

    return v.toString();
  };

  const action: Record<ActivityOperation, string> = {
    update: 'Updated ',
    delete: 'Deleted ',
    insert: 'Created ',
  };

  const ListingBadge = () =>
    listing.name ? (
      <Text component="span" fw={500} c="black">
        {listing.name}
      </Text>
    ) : (
      <ActivityDeletedItemBadge objectId={listing.id} type={'listing'} />
    );

  const OwnerBadge = () =>
    owner.name ? (
      <Text component="span" fw={500} c="black">
        {owner.name}
      </Text>
    ) : (
      <ActivityDeletedItemBadge objectId={owner.id} type={'owner'} />
    );

  const UserBadge = () =>
    user.name ? (
      <Text component="span" fw={500} c="black">
        {user.name}
      </Text>
    ) : (
      <ActivityDeletedItemBadge objectId={owner.id} type={'user'} />
    );

  if (audit_log.op === 'delete' || audit_log.op === 'insert') {
    if (audit_log.tableName === 'address') {
      const label = (
        <Text c="gray">
          {action[audit_log.op]}{' '}
          <ActivityDeletedItemBadge
            type="address"
            objectId={audit_log.objectId}
          />
        </Text>
      );

      return [{ label, date }];
    }

    if (audit_log.tableName === 'owner' || audit_log.tableName === 'contact') {
      const label = (
        <Text c="gray">
          {action[audit_log.op]} <OwnerBadge />
        </Text>
      );

      return [{ label, date }];
    }

    if (audit_log.tableName === 'listing_owner') {
      if (audit_log.op === 'delete')
        return [
          {
            date,
            label: (
              <Text c="gray">
                Removed listing <ListingBadge />
              </Text>
            ),
          },
        ];

      return [
        {
          date,
          label: (
            <Text c="gray">
              Added listing <ListingBadge />
            </Text>
          ),
        },
      ];
    }

    if (audit_log.tableName === 'owner_user_access') {
      if (audit_log.op === 'delete')
        return [
          {
            date,
            label: (
              <Text c="gray">
                Removed user <UserBadge />
              </Text>
            ),
          },
        ];

      return [
        {
          date,
          label: (
            <Text c="gray">
              Added user <UserBadge />
            </Text>
          ),
        },
      ];
    }

    return [];
  }

  const formatDelta = (update: ActivityDeltaJson[number]) => {
    let key: ReactNode | undefined = undefined;

    if (
      update.key === 'company_type' &&
      audit_log.tableName === 'contact' &&
      update.type !== 'UPDATE'
    ) {
      if (update.type === 'REMOVE') {
        return (
          <ActivityDeltaComparison
            type={'Company Type'}
            oldValue={formatValue(update.value, update.key)}
            value={formatValue('individual', update.key)!}
          />
        );
      } else if (update.value) {
        return (
          <ActivityDeltaComparison
            type={'Company Type'}
            oldValue={formatValue('individual', update.key)!}
            value={formatValue(update.value, update.key)!}
          />
        );
      }
    }

    if (audit_log.tableName === 'owner' || audit_log.tableName === 'contact') {
      const updateKey = toCamelCase(update.key) as owner_select_column;

      const keys: Partial<{
        [k in owner_select_column]: string;
      }> = {
        firstName: 'First Name',
        name: owner.isCompany ? 'Company Name' : 'Last Name',
        companyType: 'Company Type',
        email: 'Email',
        phone: 'Phone',
        taxId: 'Tax ID',
        type: 'Type',
        status: 'Status',
        pmsStatus: 'PMS Status',
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (audit_log.tableName === 'listing_owner') {
      const updateKey = toCamelCase(update.key) as listing_owner_select_column;

      const keys: Partial<{
        [k in listing_owner_select_column]: ReactNode;
      }> = {
        split: (
          <>
            <ListingBadge /> split
          </>
        ),
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (audit_log.tableName === 'address') {
      if (update.key !== 'full') return null;

      const updateKey = toCamelCase(update.key) as address_select_column;

      const keys: Partial<{
        [k in address_select_column]: ReactNode;
      }> = {
        full: 'Address',
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (audit_log.tableName === 'owner_user_access') {
      const updateKey = toCamelCase(
        update.key
      ) as owner_user_access_select_column;

      const keys: Partial<{
        [k in owner_user_access_select_column]: ReactNode;
      }> = {
        role: (
          <>
            <UserBadge /> role
          </>
        ),
      };

      if (keys[updateKey]) key = keys[updateKey];
    }

    if (!key) return null;

    return (
      <ActivityDeltaComparison
        type={key}
        value={formatValue(update.value, update.key)!}
        oldValue={formatValue(update.oldValue, update.key)}
      />
    );
  };

  return audit_log.delta.map((update) => {
    return {
      date,
      label: formatDelta(update),
    };
  });
};
