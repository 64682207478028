import { owner_statement_bool_exp } from '@finalytic/graphql';
import { ensure } from '@finalytic/utils';
import { z } from 'zod';
import { Dashboard } from '../types';
import { whereAddress } from './whereAddress';

export const ownerStatementsFilterInput = {
  search: z.string().nullable().optional(),
  status: z
    .enum(['draft', 'inReview', 'void', 'published', 'posted'])
    .nullable()
    .optional(),
  ownerId: z.string().nullable().optional(),
  listingIds: z.array(z.string()).nullable().optional(),
  listingGroup: z.string().nullable().optional(),
  month: z.string().nullable().optional(),
};

const ownerStatementsFilter = z.object(ownerStatementsFilterInput);

export type OwnerStatementsFilterParams = z.infer<typeof ownerStatementsFilter>;

type BaseWhere = {
  dashboard: Dashboard;
  tenantId: string;
  meId: string;
};

export function whereOwnerStatements({
  search: s,
  status,
  dashboard,
  tenantId,
  listingGroup,
  listingIds,
  month,
  ownerId,
  meId,
}: BaseWhere & OwnerStatementsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<owner_statement_bool_exp[]>([
        {
          listing: {
            ownerships: {
              newOwner: {
                _or: [
                  {
                    name: { _ilike: `%${search}%` },
                  },
                  {
                    firstName: { _ilike: `%${search}%` },
                  },
                  {
                    email: { _ilike: `%${search}%` },
                  },
                ],
              },
            },
          },
        },
        {
          listing: {
            _or: [
              { calculated_title: { _ilike: `%${search}%` } },
              { address: { _ilike: `%${search}%` } },
              {
                addressRelation: whereAddress({ search }),
              },
            ],
          },
        },
      ])
    : undefined;

  return ensure<owner_statement_bool_exp>({
    tenantId: { _eq: tenantId },
    status:
      dashboard === 'owner'
        ? { _in: ['published', 'posted'] }
        : status
          ? { _eq: status }
          : undefined,
    startAt: { _eq: month },
    listingId: listingIds?.length ? { _in: listingIds } : undefined,
    owners: ownerId
      ? {
          newOwnerId: { _eq: ownerId },
        }
      : {
          newOwner: {
            id: dashboard === 'owner' ? undefined : { _is_null: false },
            userAccesses:
              dashboard === 'owner'
                ? {
                    userId: { _eq: meId },
                  }
                : undefined,
          },
        },
    listing: listingGroup ? { collectionId: { _eq: listingGroup } } : undefined,
    _or: searchQuery,
  });
}
