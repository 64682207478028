import { DepositFormInputs } from '../_hooks/useDepositForm';

export const getDepositManualLinesCentTotal = (
  lines: DepositFormInputs['manuals']
) => {
  return lines.reduce((acc, line) => {
    return (
      acc + (line.paymentCentTotal || 0) + (line.merchantFeeCentTotal || 0)
    );
  }, 0);
};
