import { gqlV2 } from '@finalytic/data';
import { EyeIcon, OfficeIcon, UserIcon } from '@finalytic/icons';
import { ExtendedCustomColors } from '@finalytic/ui';
import { Maybe, toTitleCase } from '@finalytic/utils';
import {
  Badge,
  Center,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';

export type ListingOwnerRole = gqlV2.listing_owner_role_enum;

type Props = {
  role: ListingOwnerRole;
  name: Maybe<string>;
  variant?: 'icon' | 'badge';
  tooltip?: {
    icon?: string;
    text?: string;
  };
};

export const useListingOwnerColorScheme = (role: ListingOwnerRole) => {
  const { primaryColor, colors } = useMantineTheme();

  const ROLE_COLORS: Record<ListingOwnerRole, ExtendedCustomColors> = {
    owner: primaryColor,
    ownerMigrated: primaryColor,
    company: 'violet',
    spectator: 'cyan',
  };

  const colorScheme = ROLE_COLORS[role];

  return { colorScheme, color: colors[colorScheme] };
};

export const ListingOwnerBadge = ({
  role,
  name,
  variant = 'badge',
  tooltip,
}: Props) => {
  const { colorScheme, color } = useListingOwnerColorScheme(role);
  const { colorScheme: darkScheme } = useMantineColorScheme();

  if (variant === 'badge') {
    const textLimit = 25;

    const withEllipsis = (name?.length || 0) > textLimit;

    return (
      <Badge
        variant="light"
        color={colorScheme}
        size="lg"
        leftSection={
          <Tooltip
            label={tooltip?.icon || toTitleCase(role)}
            withArrow
            withinPortal
          >
            <Center>
              <Icon role={role} size={16} />
            </Center>
          </Tooltip>
        }
        sx={(theme) => ({
          textTransform: 'none',
          color: darkScheme === 'dark' ? undefined : theme.black,
          fontWeight: 400,
          wordWrap: 'break-word',
        })}
      >
        {!withEllipsis ? (
          name
        ) : (
          <Tooltip withArrow withinPortal label={tooltip?.text || name}>
            <Text component="span" inherit>
              {`${name?.slice(0, textLimit)}...`}
            </Text>
          </Tooltip>
        )}
      </Badge>
    );
  }

  return (
    <Tooltip label={toTitleCase(role)} withArrow withinPortal>
      <Center
        sx={(theme) => ({
          backgroundColor: color[0],
          padding: rem(4),
          borderRadius: theme.radius.md,
        })}
      >
        <Icon role={role} />
      </Center>
    </Tooltip>
  );
};

const Icon = ({
  role,
  size = 18,
}: { role: ListingOwnerRole; size?: number }) => {
  const { color } = useListingOwnerColorScheme(role);

  if (role === 'owner') {
    return <UserIcon color={color[6]} strokeWidth={1.5} size={size} />;
  }

  if (role === 'spectator') {
    return <EyeIcon color={color[6]} strokeWidth={1.5} size={size} />;
  }

  return <OfficeIcon size={size + 1} color={color[6]} strokeWidth={1.5} />;
};
