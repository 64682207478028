import { LoadingIndicator, SelectItem } from '@finalytic/ui';
import { ComponentProps, useMemo } from 'react';
import { Select, SelectDataProps } from '../select';
import { SelectValueProps } from '../select/_types';
import { FilterPill } from './FilterPill';

type Props<TSelectValue extends string> = {
  label: string;
  popoverWidth?: number;
  popoverHeight?: number;
  withinPortal?: boolean;
  loadingMode?: 'indicator';
  searchPlaceholder?: string;
  hideClearButton?: boolean;
  hideSearch?: boolean;
  hideIcon?: boolean;
  noOptionsText?: string;
  pinnedItems?: SelectItem[];
  customBottomActions?: ComponentProps<typeof Select>['customBottomActions'];
} & SelectDataProps<TSelectValue> &
  SelectValueProps<TSelectValue>;

export const SelectFilter = <TSelectValue extends string>({
  setValue,
  value,
  label: filterLabel,
  withinPortal = true,
  popoverWidth = 250,
  // popoverHeight,
  loadingMode,
  searchPlaceholder,
  hideClearButton,
  hideSearch,
  hideIcon,
  noOptionsText,
  customBottomActions,
  ...dataProps
}: Props<TSelectValue>) => {
  const inputLoading = dataProps?.data?.loading;

  return (
    <Select
      {...dataProps}
      setValue={setValue as any}
      value={value as any}
      inputProps={{
        loadingQuery: inputLoading,
      }}
      dropdownProps={{
        position: 'bottom-start',
        preventCloseOnSelect: true,
        width: popoverWidth,
        // height: popoverHeight,
        withinPortal,
        searchPlaceholder,
        hideSearch,
        noOptionsText,
      }}
      customBottomActions={customBottomActions}
    >
      {({ value: v, loadingQuery, opened, type, setValue }) => {
        const label = useMemo(() => {
          if (type === 'multiple') {
            const value = v as SelectItem[] | null;

            let label = filterLabel;

            if (value?.length === 1) label = value[0].label;
            else if (value?.length)
              label = `${value.length} ${filterLabel.toLowerCase()}`;

            return label;
          } else if (type === 'single') {
            const value = v as SelectItem | null;
            if (!value) return filterLabel;
            return value.label;
          }
        }, [v, filterLabel, type]);

        const isDefault = label === filterLabel;

        if (loadingQuery && loadingMode === 'indicator')
          return <LoadingIndicator size="xs" />;

        return (
          <FilterPill
            opened={opened}
            isDefault={isDefault}
            icon={
              type === 'single' ? !hideIcon && (value as any)?.icon : undefined
            }
            label={label || ''}
            resetFilter={
              hideClearButton
                ? undefined
                : () =>
                    type === 'single'
                      ? setValue(null, { type: 'removed', value: v! })
                      : setValue([], { type: 'removed', value: v[0] })
            }
          />
        );
      }}
    </Select>
  );
};
